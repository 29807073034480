import {
	CheckoutModel,
	Customer,
	DeliveryAddress,
} from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import {
	BasketHeader,
	DeliveryMethod,
	PaymentMethod,
} from 'components/basket'
import { BasketAsideStore } from 'components/basket/BasketAside/index.props'
import { CardSelectionStore } from 'components/basket/CardSelection/index.store'
import { UserDataForms } from 'components/basket/UserDataForms'
import { Spinner } from 'components/global/Spinner'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import {
	Col,
	Container,
	Row,
} from 'reactstrap'
import { MetaService } from 'services/MetaService'
import styles from './styles.scss'

// Enable get meta data
const setMetaInfo = () => MetaService.setInfo({}, { title: 'Корзина' })

const userAddressQuery = DataProvider
	.buildQuery()
	.addBody({
		query: {
			filter:  { isDefault: true },
			expands: ['city'],
		},
	}, true)

const getInitialState = (user, userAddress) => ({
	data: {
		deliveryMethod: 'pickupMinsk',
		paymentMethod: 'delivery',
		firstName: user?.getFirstName?.() ?? '',
		lastName: user?.getLastName?.() ?? '',
		phone: user?.getSettings?.()?.getPhone?.() ?? '',
		email: user?.getSettings?.()?.getEmail?.() ?? '',
		cityId: user?.getSettings?.()?.getCityId?.() ?? 0,
		street: userAddress?.getStreet?.() ?? '',
		house: userAddress?.getHouse?.() ?? '',
		block: userAddress?.getBlock?.() ?? '',
		apartment: userAddress?.getApartment?.() ?? '',
		comment: '',
	},
	params: {
		deliveryMethod: {
			error: false,
			required: false,
		},
		paymentMethod: {
			error: false,
			required: false,
		},
		firstName: {
			error: false,
			required: true,
		},
		lastName: {
			error: false,
			required: true,
		},
		phone: {
			error: false,
			required: true,
		},
		email: {
			error: false,
			required: false,
		},
		cityId: {
			error: false,
			required: false,
		},
		street: {
			error: false,
			required: false,
		},
		house: {
			error: false,
			required: false,
		},
		block: {
			error: false,
			required: false,
		},
		apartment: {
			error: false,
			required: false,
		},
		comment: {
			error: false,
			required: false,
		},
	},
	success: false,
})

/**
 * Render checkout page
 *
 * @param {Object} userState
 * @param {Object} addressListState
 * @param {Function} getCustomerAddress
 * @param {Function} checkout
 * @param {Object} checkoutState
 *
 * @return {*}
 * @constructor
 */
export const Checkout = ({
	userState,
	addressListState,
	getCustomerAddress,
	checkoutState,
	checkout,
}) => {
	const [attributes, setAttributes] = useState(getInitialState({}, {}))

	const checkoutData       = CheckoutModel.create(checkoutState)
	const isFetching         = StateService.create(checkoutState).isFetching()
	const user               = useMemo(() => Customer.create(userState), [userState])
	const userAddress        = useMemo(() => DeliveryAddress.create(addressListState?.result?.list?.[0]),
		[addressListState])
	const userAddressService = useMemo(() => StateService.create(addressListState), [addressListState])

	useEffect(() => {
		setMetaInfo()
		checkout()
		getCustomerAddress(userAddressQuery)
	}, [getCustomerAddress, checkout])

	useEffect(() => {
		if (user.isExist() && !userAddressService.isFetching()) {
			setAttributes(getInitialState(user, userAddress))
		}
	}, [user, userAddress, userAddressService])

	const inputsHandler = (event) => {
		const {name, value} = event?.target

		setAttributes( prevState => {
			const { data, params, success } = prevState
			return { success, data: {...data, [name]: value}, params: {...params, [name.error]: false}}
		})
	}

	const paymentMethodHandler = useCallback(event => {
		const {name, value} = event?.target

		setAttributes( prevState => {
			const { data, params, success } = prevState
			const email = { error: false, ...(value === 'online' ? { required: true } : { required: false })}
			const newParam = { ...params, [name.error]: false, email }

			return { success, data: {...data, [name]: value}, params: newParam}
		})
	}, [])

	const deliveryMethodHandler = useCallback(event => {
		const {name, value} = event?.target

		setAttributes(({ data, params, success }) => {
			const requireParams = {}
			if(value === 'courierRB' || value === 'courierMinsk'){
				requireParams.cityId = {error: false, required: true}
				requireParams.street = {error: false, required: true}
		        requireParams.house = {error: false, required: true}
			}
			else {
				requireParams.cityId = {error: false, required: false}
				requireParams.street = {error: false, required: false}
				requireParams.house = {error: false, required: false}
			}
			return { success, data: {...data, [name]: value}, params: {...params, ...requireParams} }
		})
	}, [])

	const onBlurHandler = (event) => {
		const { name, value, required } = event?.target
		if(required) {
			let error = false
			if(name === 'phone') error = value.length < 19
			else error = value.length === 0

			setAttributes(prevState => {
				const { success, data, params  } = prevState
				const { [name]: prev } = params

				return { success, data, params: { ...params, [name]: { ...prev, error } }}
			})
		}
	}

	const errorFields = useCallback(({success, data, params}) => {
		setAttributes({success, data, params})
	}, [])

	return (
		<section className={styles.basket}>
			<Spinner block isFetching={isFetching} />
			<Container>
				<Row>
					<Col xs="12" lg="8">
						<div className={styles.container}>
							<BasketHeader title="Корзина - Шаг 2" />
							<DeliveryMethod
								methods={checkoutData.getDeliveryMethods()}
								currentMethod={attributes?.data?.deliveryMethod}
								toggle={deliveryMethodHandler}
							/>
							<PaymentMethod
								methods={checkoutData.getPaymentMethods()}
								currentMethod={attributes?.data?.paymentMethod}
								toggle={paymentMethodHandler}
							/>
							<CardSelectionStore attributes={attributes} />
							<UserDataForms
								attributes={attributes}
								onChange={inputsHandler}
								onBlur={onBlurHandler}
							/>
						</div>
					</Col>
					<Col xs="12" lg="4">
						<BasketAsideStore
							checkoutData={checkoutData}
							attributes={attributes}
							errorFields={errorFields}
						/>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

Checkout.propTypes = {
	addressListState:   PropTypes.object,
	checkout:           PropTypes.func,
	checkoutState:      PropTypes.object,
	getCustomerAddress: PropTypes.func,
	userState:          PropTypes.object,
}

Checkout.defaultProps = {
	addressListState:   {},
	checkout:           () => null,
	checkoutState:      {},
	getCustomerAddress: () => null,
	userState:          {},
}
