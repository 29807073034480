import styles from 'components/global/GoodsItem/styles.scss'
import { StarRating } from 'components/global/StarRating'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'

const ServicesItem = ({ href }) => {

	const imageSrc = 'https://zooqi.by/uploads-asset/image_catalog_preview/company-avatar/293/367559_581.png?1533032620'

	return (
		<Col xs={6} md={4} className={styles.wrapper}>
			<div className={[styles.item, styles.item_service].join(' ')}>
				<Link to={href} className={styles.box}>

					<StarRating initialRating={4} readonly />

					<div className={styles.image}>
						<img src={imageSrc} alt="service" />
					</div>

					<p className={styles.title}>
						Ветеринарная клиника «Альфа-Вет»
					</p>

					<div className={styles.name}>
						3 офиса
					</div>

				</Link>
			</div>
		</Col>
	)
}

ServicesItem.propTypes = {
	href: PropTypes.string.isRequired,
}

export { ServicesItem }
