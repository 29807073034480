import IcomoonReact from 'icomoon-react'
import * as PropTypes from 'prop-types'
import React from 'react'
import iconSet from './selection.json'

/**
 * Render icomoon
 *
 * @param {string} color
 * @param {string|number} size
 * @param {string} icon
 * @param {string} className
 *
 * @returns {*}
 * @constructor
 */
const Icon = ({ color, size, icon, className }) => (
	<IcomoonReact
		iconSet={iconSet}
		color={color}
		size={size}
		icon={icon}
		className={className}
	/>
)

Icon.propTypes = {
	color:     PropTypes.string,
	className: PropTypes.string,
	icon:      PropTypes.string.isRequired,
	size:      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Icon.defaultProps = {
	color:     '#2d373a',
	className: '',
	size:      '100%',
}

export default Icon

