import { connect } from 'react-redux'
import { Checkout } from './index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'

const CheckoutStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Checkout)

export { CheckoutStore }
