import { VisionPanel } from 'components/visionPanel/index'
import { connect } from 'react-redux'

import {
	mapDispatchToProps,
} from './index.props'

const VisionPanelStore = connect(
	null,
	mapDispatchToProps,
)(VisionPanel)

export { VisionPanelStore }
