import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render count
 *
 * @param {Function} setCount
 * @param {object}  item
 * @return {*}
 * @constructor
 */
export const Count = ({ setCount, item}) => {
	const {count} = item
	return (
		<div className={styles.count}>
			<div className={styles.title}>{item.title}</div>
			<div className={styles.group}>
				<button
					name={item.name}
					type='button'
					onClick={(e) => count >= 1 && setCount(e, count - 1)}
					className={[styles.button, styles.decrease].join(' ')}
				>
					-
				</button>

				<span className={styles.input}>{count}</span>

				<button
					name={item.name}
					type='button'
					onClick={(e) => setCount(e, count + 1)}
					className={[styles.button, styles.increase].join(' ')}
				>
					+
				</button>
			</div>
		</div>
	)
}
Count.propTypes = {
	item:    PropTypes.object,
	setCount: PropTypes.func,
}

Count.defaultProps = {
	item:     {},
	setCount: () => null,
}
