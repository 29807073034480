import { connect } from 'react-redux'
import { Error } from './index'
import {
	mapDispatchToProps,
	mapStateToProps
} from './index.props'

const ErrorInStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Error)

export { ErrorInStore }
