import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	favoritesState: StateSelector.customersFavorites.list(state),
})
const mapDispatchToProps = {
	addToFavorite:   DispatchSelector.customersFavorites.create,
	deleteFavorite:  DispatchSelector.customersFavorites.delete,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
