import { PageHeading } from 'components/global/PageHeading'
import { Container } from 'containers/User/Home/Container'
import { Navigator } from 'containers/User/Home/Navigator'
import * as PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { MetaService } from 'services/MetaService'
import styles from './styles.scss'

// Enable get meta data
const setMetaInfo = () => MetaService.setInfo({}, { title: 'Личный кабинет' })

/**
 * Render user profile
 *
 * @param {Function} getCustomerAddress
 * @param {Function} getDiscountCard
 *
 * @return {*}
 * @constructor
 */
export const UserPage = ({ getCustomerAddress, getDiscountCard }) => {
	const { activeTab } = useParams()

	useEffect(() => {
		setMetaInfo()
		getCustomerAddress()
		getDiscountCard()
	}, [getCustomerAddress, getDiscountCard])

	return (
		<main className={styles.main}>
			<PageHeading
				title="Личный кабинет"
				breadcrumbs
			/>
			<section className={styles.section}>
				<Navigator activeTab={activeTab} />
				<Container />
			</section>
		</main>
	)
}

UserPage.propTypes = {
	getCustomerAddress: PropTypes.func,
	getDiscountCard:    PropTypes.func,
}

UserPage.defaultProps = {
	getCustomerAddress: () => null,
	getDiscountCard:    () => null,
}
