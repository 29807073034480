import { Loader } from 'components/global/Loader'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render payment loader
 *
 * @return {*}
 * @constructor
 */
export const LoaderWithTitle = ({ title }) => (
	<div className={styles.wrapper}>
		<div>
			<Loader color={'#00a675'} size={50} />
		</div>
		<div className={styles.title}>
			{title}
		</div>
	</div>
)

LoaderWithTitle.propTypes = {
	title: PropTypes.string,
}

LoaderWithTitle.defaultProps = {
	title: '',
}
