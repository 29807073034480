import {
	MenuItem,
	TextField,
} from '@material-ui/core'
import styles from 'components/global/InputField/styles.scss'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render input field for model form
 *
 * @param {string} field
 * @param {string} value
 * @param {Function} onChange
 * @param {string} groupClassName
 * @param {Object} innerRef
 * @param {Object} invalid
 * @param {Object} inputProps
 *
 * @return {*}
 * @constructor
 */
export const InputField = ({
	field,
	value,
	onChange,
	invalid,
	groupClassName,
	innerRef,
	...inputProps
}) => {
	const { id, title, type, children, formText, disabled, options = [], component } = field

	const getElement = () => React.cloneElement(component, {
		...component.type.props, handler: onChange, value, invalid, field, ...inputProps, className: groupClassName,
	})

	return (
		<div className={[styles.wrapper, groupClassName].join(' ')}>
			{(component && getElement()) ||
			 <TextField
				 select={type === 'select'}
				 id={id}
				 label={title}
				 name={id}
				 type={type}
				 value={value}
				 disabled={disabled}
				 onChange={onChange}
				 innerRef={innerRef}
				 error={invalid.result}
				 helperText={invalid.message}
				 autoComplete="off"
				 size="small"
				 variant="outlined"
				 {...inputProps}
			 >
				 {children}
				 {options.map(option => (
				 	<MenuItem className={styles.option} key={option.value} value={option.value}>
			 			{option.label}
				 	</MenuItem>
				 ))}
			 </TextField>}
			<div className={styles.formText}>{formText}</div>
		</div>
	)
}

InputField.propTypes = {
	field:          PropTypes.object,
	groupClassName: PropTypes.string,
	invalid:        PropTypes.object,
	onChange:       PropTypes.func,
	innerRef:       PropTypes.object,
	value:          PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

InputField.defaultProps = {
	field:          {
		id:       '',
		title:    '',
		type:     'text',
		children: null,
	},
	innerRef:       null,
	groupClassName: '',
	invalid:        {
		result:  false,
		message: '',
	},
	onChange:       () => null,
	value:          '',
}
