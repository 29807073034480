import * as PropTypes from 'prop-types'
import React, {
	Component,
	Fragment,
} from 'react'
import { Tab } from './Tab'

import styles from './tabs.scss'

export class Tabs extends Component {
	constructor(props) {
		super(props)
		const { active, children } = this.props

		this.state = {
			activeTab: active === null ? children[0].props.id : active,
		}
	}

	onClickTabItem = tab => this.setState({ activeTab: tab })

	render() {
		const {
				  children,
				  tabClass,
				  tabActiveClass,
				  tabsClass,
			  } = this.props

		const { activeTab } = this.state

		return (
			<Fragment>
				<div className={[styles.list, tabsClass].join(' ')}>
					{children.map((child) => {
						const { title, id } = child.props

						return (
							<Tab
								activeTab={activeTab}
								key={title}
								id={id}
								title={title}
								onClick={this.onClickTabItem}
								tabClass={tabClass}
								tabActiveClass={tabActiveClass}
							/>
						)
					})}
				</div>
				<div>
					{children.map((child) => {
						if (child.props.id !== activeTab) return undefined
						return child.props.children
					})}
				</div>
			</Fragment>
		)
	}
}

Tabs.propTypes = {
	children:       PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Array),
	]).isRequired,
	tabClass:       PropTypes.string,
	tabActiveClass: PropTypes.string,
	tabsClass:      PropTypes.string,
	active:         PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Tabs.defaultProps = {
	tabClass:       '',
	tabActiveClass: '',
	tabsClass:      '',
	active:         null,
}
