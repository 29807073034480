import Icon from 'assets/icomoon/Icon'
import { Backdrop } from 'components/global/Backdrop'
import { BlankButton } from 'components/global/BlankButton'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import { useStopScrolling } from 'components/global/useStopScrolling'
import PropTypes from 'prop-types'
import React, {
	useRef,
	useState,
} from 'react'
import { Carousel } from 'react-responsive-carousel'
import styles from './styles.scss'


/**
 * Render images carousel
 *
 * @param {Array} images
 *
 * @return {*}
 * @constructor
 */
export const ImageCarousel = ({ images }) => {
	const [isActive, setIsActive] = useState(false)
	const carouselRef             = useRef()

	const drawImage = images.map(image => (
		<div className={styles.carouselImageButton} key={image}>
			<img src={image} alt="" />
		</div>
	))

	useStopScrolling(isActive)
	useCheckOutsideClick(carouselRef, isActive, setIsActive, isActive)

	return (
		<>
			<Backdrop isActive={isActive} isFullScreen />
			<div
				className={`${styles.carouselWrapper} ${isActive && styles.carouselFullScreen}`}
				ref={carouselRef}
			>
				{isActive &&
				 <BlankButton
					 onClick={() => setIsActive(false)}
					 className={styles.closeBtn}
				 >
					 <Icon icon="close" size={25} />
				 </BlankButton>}
				<Carousel
					className={styles.carousel}
					showArrows
					swipeable
					infiniteLoop
					showIndicators
					showStatus={false}
					showThumbs={false}
					onClickItem={() => setIsActive(true)}
				>
					{drawImage}
				</Carousel>
			</div>
		</>
	)
}

ImageCarousel.propTypes    = {
	images: PropTypes.array,
}
ImageCarousel.defaultProps = {
	images: [],
}
