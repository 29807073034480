import { ProductsSlider } from 'components/global/ProductsSlider'
import { DataBlock } from 'components/home/DataBlock'
import { PromoItem } from 'components/static/promo/PromoItem'
import moment from 'moment'
import React, {
	useCallback,
	useMemo,
} from 'react'
import { Row } from 'reactstrap'
import PropTypes from 'prop-types'
import styles from './styles.scss'


const getTitle = type => {
	switch (type){
		case 'collection':
			return 'Новые коллекции'
		case 'event':
			return 'Мероприятия'
		default:
			break
	}
	return 'undefined'
}

/**
 * Render news
 *
 * @return {*}
 * @constructor
 */
export const News = ({ articlesState }) => {
	const buyers = useMemo(() => articlesState?.result?.buyers?.['ac-news'] ?? {}, [articlesState])

	const formatDate = (item) => {
		return item.startAt ?
			`${moment(item.startAt).format('L')} - ${moment(item.endAt).format('L')}`:
			moment(item.createdAt).format('L')
	}

	const render = useCallback(() => {
		if(buyers != null){
			const result = []
			for(const[key, value] of Object.entries(buyers)){
				result.push(
					<DataBlock key={key} title={getTitle(key)} link={`/buyers/ac-news/${key}`}>
						<Row className={styles.row}>
							<ProductsSlider
								settings={{ slidesToShow: value.length > 3 ? 3 : value.length }}
							>
								{
									value.map(item => (
										<PromoItem
											key={item.id}
											image={item.previewImage}
											discount={item.description}
											title={item.title}
											date={formatDate(item)}
											link={`/buyers/ac-news/${key}/${item.id}`}
										/>
									))
								}
							</ProductsSlider>
						</Row>
					</DataBlock>
				)
			}
			return result
		}
		return null
	}, [buyers])

	return (
		<>
			{
				render()
			}
		</>
	)
}

News.propTypes = {
	articlesState: PropTypes.object,
}
News.defaultProps = {
	articlesState: {},
}
