import React, {
	Component,
	Fragment,
} from 'react'
import * as PropTypes from 'prop-types'
import { MenuTab } from './MenuTab'

class MenuTabs extends Component
{
	constructor(props)
	{
		super(props)
		const { children } = this.props

		this.state = {
			activeTab: children[0].props.title,
		}
	}

	onMouseEnterTabItem = (tab) => {
		this.setState({ activeTab: tab })
	}

	render()
	{
		const {
				  children,
				  tabClass,
				  tabActiveClass,
				  tabsClass,
				  contentClass,
			  } = this.props
		const {
				  activeTab,
			  } = this.state

		return (
			<Fragment>
				<div className={tabsClass}>
					{children.map((child) => {
						const { title, href } = child.props

						return (
							<MenuTab
								activeTab={activeTab}
								key={title}
								title={title}
								onMouseEnter={this.onMouseEnterTabItem}
								tabClass={tabClass}
								tabActiveClass={tabActiveClass}
								href={href}
							/>
						)
					})}
				</div>
				<div className={contentClass}>
					{children.map((child) => {
						if (child.props.title !== activeTab) return undefined
						return child.props.children
					})}
				</div>
			</Fragment>
		)
	}
}

MenuTabs.propTypes = {
	children:       PropTypes.instanceOf(Array).isRequired,
	tabClass:       PropTypes.string,
	tabActiveClass: PropTypes.string,
	tabsClass:      PropTypes.string,
	contentClass:   PropTypes.string,
}

MenuTabs.defaultProps = {
	tabClass:       '',
	tabActiveClass: '',
	tabsClass:      '',
	contentClass:   '',
}

export { MenuTabs }

// eslint-disable-next-line
{/*
 Example

 <MenuTabs>
	 <div title='first title' href='/'>
	 	first content
	 </div>
	 <div title='second title' href='/'>
	 	second content
	 </div>
 </MenuTabs>
 */
}
