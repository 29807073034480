import { project } from 'containers/SignIn/data'
import React from 'react'
import document from 'assets/media/contract.pdf'
import styles from './styles.scss'

/**
 * Render terms of use for auth page
 *
 * @return {*}
 * @constructor
 */
export const Terms = () => (
	<div className={styles.terms}>
		<p>{`Используя сервисы ${project} Вы соглашаетесь с условиями`}</p>
		<a href={document} target="_blank" rel="noopener noreferrer">Договора публичной оферты</a>
		{' '}
		и даете согласие на обработку персональных данных.
	</div>
)
