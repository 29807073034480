import { Category } from '@kakadu-dev/base-frontend-components'
import { Accordion } from 'components/global'
import { MainSectionStore } from 'components/market/Home/CategorySection/index.store'
import * as PropTypes from 'prop-types'
import React from 'react'
import AnimateHeight from 'react-animate-height'
import { Link } from 'react-router-dom'
import styles from './styles.scss'


const noWay = ['cooperation', 'appeals', 'buyers', 'for-business']
/**
 * Render mobile menu
 *
 * @param {node} children
 * @param {object} hierarchicalState
 * @param {function} closeMenu
 * @param {boolean} isOpen
 *
 * @param isMobile
 * @return {*}
 * @constructor
 */
const MobileNavigation = ({ children, hierarchicalState, closeMenu, isOpen,  isMobile }) => {
	const settings = {
		classCustom:        styles.item,
		headingClassCustom: styles.title,
		headingClassActive: styles.title_active,
		icon:               'mobile-menu-arrow',
		iconSize:           15,
		rotate180:          true,
		mobileMenuIsOpen:   isOpen,
	}

	const height = isOpen ? 'auto' : '0'

	return (
		<div className={styles.container}>
			<AnimateHeight duration={280} height={height} animateOpacity>
				<div
					className={[
						styles.wrapper,
						height === '0' ? styles.wrapper_hidden : '',
					].join(' ')}
				>
					<Accordion {...settings}>
						{hierarchicalState.result && Category.map(hierarchicalState, category => {
							if (!category.getChildrens()) {
								return (
									// без вложенности
									<Link
										key={`firstContainer-${category.getId()}`}
										className={styles.item}
										to={`/${category.getAlias()}`}
										onClick={closeMenu}
									>
										<div className={styles.box}>
											<p className={styles.title}>
												{category.getTitle()}
											</p>
										</div>
									</Link>
								)
							}

							if (!category.getChildrens()[0].childrens) {
								return (
									// простая вложенность, когда аккордион со второй вложенностью внутри не требуется
									<div
										key={`secondContainer-${category.getId()}`}
										title={category.getTitle()}
										id={category.getId()}
									>
										<div className={styles.column}>
											{
												!noWay.includes(category.getAlias()) &&
												<Link
													key={`secondCat-${category.getId()}`}
													onClick={closeMenu}
													to={`/${category.getAlias()}`}
													className={[styles.title, styles.subtitle].join(' ')}
												>
													{category.getTitle()}
												</Link>
											}
											{Category.map(category.getChildrens(), secondLevelCategory => (
												<Link
													key={`secondCat-${secondLevelCategory.getId()}`}
													onClick={closeMenu}
													to={`/${category.getAlias()}/${secondLevelCategory.getAlias()}`}
													className={[styles.title, styles.subtitle].join(' ')}
												>
													{secondLevelCategory.getTitle()}
												</Link>
											))}
										</div>
									</div>
								)
							}

							return (
								// сложная вложенность, когда аккордион со второй вложенностью и с группами внутри
								<div
									key={`thirdContainer-${category.getId()}`}
									title={category.getTitle()}
									id={`thirdContainer-${category.getId()}`}
								>
									<MainSectionStore
										id={category.getId()}
										hierarchicalState={hierarchicalState}
										isMobile={isMobile}
										closeMenu={closeMenu}
									/>
								</div>
							)
						})}
					</Accordion>

					<div className={styles.data}>
						{children}
					</div>
				</div>
			</AnimateHeight>
		</div>
	)
}

MobileNavigation.propTypes = {
	children:          PropTypes.node.isRequired,
	closeMenu:         PropTypes.func.isRequired,
	hierarchicalState: PropTypes.object,
	isOpen:            PropTypes.bool,
	isMobile:          PropTypes.bool.isRequired,
}

MobileNavigation.defaultProps = {
	hierarchicalState: {},
	isOpen:            false,
}

export { MobileNavigation }
