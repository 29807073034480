/**
 * Error helper class
 * Format output errors
 */
class ErrorHelper
{
	/**
	 * Output format error log
	 *
	 * @param {Error} error
	 *
	 * @return {undefined}
	 */
	static log(error)
	{
		const message = `REQUEST ERROR: ${error?.messageData?.config?.data ?? 'unknown'} \n\n` +
						`HEADERS: ${error?.messageData?.request?._header}` +
						`DATA: ${JSON.stringify(error?.messageData?.data ?? {})} \n\n` +
						`MESSAGE & STATUS: ${error?.message} :: ${error?.status} \n`

		console.error(message)
	}
}

export default ErrorHelper
