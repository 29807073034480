const telephones = [
	// {
	// 	icon:      'telephone',
	// 	iconSize:  24,
	// 	telephone: '+375 (29) 334-24-24',
	// 	id:        1,
	// },
]

export default telephones
