import { connect } from 'react-redux'
import { ProductFavorites } from 'components/market/ProductPage/ProductFavorites/index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from 'components/market/ProductPage/ProductFavorites/index.props'

const ProductFavoritesStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProductFavorites)

export { ProductFavoritesStore }
