import { BlankButton } from 'components/global/BlankButton'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

const tabs = [
	{ value: 'main', label: 'Основное' },
	{ value: 'contacts', label: 'Контакты' },
	{ value: 'address', label: 'Адрес' },
]

/**
 * Render data tabs
 *
 * @param activeTab
 * @param tabHandler
 * @return {*}
 * @constructor
 */
export const AnchorList = ({ activeTab, tabHandler }) => (
	<div className={styles.container}>
		{tabs.map(tab => (
			<BlankButton
				key={tab.value}
				className={[styles.anchor, tab.value === activeTab && styles.active].join(' ')}
				title={tab.label}
				onClick={() => tabHandler(tab.value)}
			/>
		))}
	</div>
)

AnchorList.propTypes = {
	activeTab:  PropTypes.string,
	tabHandler: PropTypes.func,
}

AnchorList.defaultProps = {
	activeTab:  '',
	tabHandler: () => null,
}
