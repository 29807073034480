import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import Icon from 'assets/icomoon/Icon'
import { Loader } from 'components/global/Loader'
import styles from 'components/market/ProductPage/ProductOffer/styles.scss'
import * as PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

export const ProductOffer = ({ addToCartState, addProductToCart, disabled }) => {
	const addToCartService = useMemo(() => StateService.create(addToCartState), [addToCartState])
	const fetching = useMemo(() => addToCartService.isFetching(), [addToCartService])
	const disabledButton = useMemo(() => fetching || disabled, [fetching, disabled])

	return (
		<div className={styles.offer}>
			<button
				type='button'
				disabled={disabledButton}
				onClick={addProductToCart}
				className={[
					styles.button,
					disabledButton ? styles.disabled : '',
				].join(' ')}
			>
				<i className={styles.icon}>
					{fetching
						? <Loader size={24} color='#ffffff' />
						: <Icon icon='shopping-cart' size={24} color='white' />
					}
				</i>
				<p className={styles.description}>Добавить в корзину</p>
			</button>
			<Link to="/" className={styles.link}>
				<i className={styles.icon}>
					<Icon icon='shopping-cart' size={24} color='white' />
				</i>
				<p className={styles.description}>Перейти в корзину</p>
			</Link>
		</div>
	)
}

ProductOffer.propTypes = {
	addToCartState:   PropTypes.object,
	addProductToCart: PropTypes.func,
	disabled:         PropTypes.bool,
}

ProductOffer.defaultProps = {
	addToCartState:   {},
	addProductToCart: () => null,
	disabled:         false,
}
