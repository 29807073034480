import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import * as PropTypes from 'prop-types'
import React, {
	Fragment,
	useEffect,
	useState,
} from 'react'
import { DropzoneComponent } from 'react-dropzone-component'
import { Label } from 'reactstrap'
import { previewTemplate } from './previewTemplate'

/**
 * Add init preview
 *
 * @param {Dropzone} dropzone
 * @param {} images
 *
 * @return {undefined}
 */
const addInitPreview = (dropzone, images) => {
	let files = []

	if (typeof images === 'string') {
		files.push(images)
	} else if (typeof images === 'object' && images.length && images.length > 0) {
		files = images
	}

	files.map((image, index) => {
		let img = {}

		// Prepare
		if (typeof image === 'string') {
			img.url = image
		} else {
			img = { ...image }
		}

		// Push to dropzone
		if (img.url) {
			const mockFile = {
				id:     img.id || null,
				name:   `Uploaded: ${index + 1}`,
				type:   img.type || null,
				size:   img.size || 0,
				status: 'success',
			}

			dropzone.emit('addedfile', mockFile, img.url)
			dropzone.emit('thumbnail', mockFile, img.url)
			dropzone.emit('complete', mockFile)
		}

		return null
	})
}

/**
 * Get post url
 *
 * @param {function|string} postUrl
 * @param {function} getSearchQuery
 * @param {number|string} primaryKey
 *
 * @return {{options: {}, url: string}}
 */
const getPostUrl = (postUrl, getSearchQuery, primaryKey) => {
	return typeof postUrl === 'function'
		? postUrl(getSearchQuery, primaryKey)
		: {
			url:     typeof postUrl === 'string' ? postUrl : 'empty',
			options: {},
		}
}

let updatedUrl = null

/**
 * Image input component
 *
 * @param {object} props
 *
 * @return {*}
 * @constructor
 */
const ImageInput = ({ label, name, onChange, value, previews, ref, postUrl, deleteUrl, maxFiles, getSearchQuery }) => {
	const sQ = getSearchQuery || (() => DataProvider.buildQuery())

	const [requestOptions, setRequestOptions] = useState(getPostUrl(postUrl, sQ))

	useEffect(() => {
		if (requestOptions.then) {
			requestOptions.then(v => setRequestOptions(v))
		}
	}, requestOptions)

	return (
		<Fragment>
			<Label for={name}>{label}</Label>
			{requestOptions && !requestOptions.then && (
				<DropzoneComponent
					ref={ref}
					config={{
						postUrl: requestOptions.url,
					}}
					eventHandlers={{
						addedfile:  (file) => {
							const values = typeof value === 'object' ? value : []

							values.push(file)

							onChange(values)
						},
						init:       (dropzone) => {
							updatedUrl = null

							if (previews) {
								const images = typeof previews === 'function' ? previews() : previews

								addInitPreview(dropzone, images)
							}
						},
						// eslint-disable-next-line object-shorthand,func-names
						processing: function() {
							if (updatedUrl) {
								// eslint-disable-next-line react/no-this-in-sfc
								this.options.url = updatedUrl.url
							}
						},
					}}
					djsConfig={{
						autoProcessQueue:   false,
						maxFiles:           maxFiles || 1,
						maxFilesize:        2, // MB
						paramName:          name,
						thumbnailHeight:    160,
						previewTemplate,
						dictDefaultMessage: 'Перетащите файл(ы) в эту зону',
						sending:            (file, xhr) => {
							if (!requestOptions.options || !requestOptions.options.headers) {
								return
							}

							const { options: { headers } } = requestOptions

							if (headers) {
								Object.entries(headers).map(([n, v]) => {
									if (n.toLowerCase() !== 'content-type') {
										xhr.setRequestHeader(n, v)
									}

									return null
								})
							}
						},
						removedfile:        async (file) => {
							if (file.status && file.status === 'success') {
								// eslint-disable-next-line no-alert
								const answer = window.confirm('Удалить безвозвратно?')
								if (!answer) return false

								const deleteOptions = await deleteUrl(sQ, file.id)

								fetch(deleteOptions.url, deleteOptions.options)
							}

							file.previewElement.remove()

							return true
						},
						updatePostUrl:      async (primaryKey) => {
							updatedUrl = await getPostUrl(postUrl, sQ, primaryKey)
						},
					}}
				/>
			)}
		</Fragment>
	)
}

ImageInput.propTypes = {
	onChange:       PropTypes.func,
	value:          PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	label:          PropTypes.string,
	name:           PropTypes.string,
	previews:       PropTypes.func,
	ref:            PropTypes.func,
	postUrl:        PropTypes.oneOf([PropTypes.string, PropTypes.func]),
	deleteUrl:      PropTypes.func,
	maxFiles:       PropTypes.number,
	getSearchQuery: PropTypes.func,
}

ImageInput.defaultProps = {
	onChange:       () => null,
	ref:            () => null,
	deleteUrl:      () => null,
	postUrl:        '',
	previews:       () => [],
	value:          '',
	label:          '',
	name:           '',
	maxFiles:       1,
	getSearchQuery: () => DataProvider.buildQuery(),
}

export default ImageInput
