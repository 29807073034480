import image from 'assets/images/market/water.png'
import { StarRating } from 'components/global'
import { getMarketUrl } from 'helpers/LinkHelper'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.scss'

const ProductItem = ({ sale, salePage }) => {
	return (
		<Link
			to={getMarketUrl()}
			className={[
				styles.item,
				sale ? styles.sale : '',
				salePage ? styles.sale_page : '',
			].join(' ')}
		>
			<img src={image}
				 alt=""
				 className={styles.image}
			/>

			<div className={styles.rating}>
				<StarRating
					initialRating={4}
					readonly
				/>
			</div>

			<p className={styles.title}>
				{'Минеральная вода Asahi минеральная ванадиум 0.6 л пэт'}
			</p>

			<div className={styles.price}>
				<p className={styles.price_sale}>
					70.50 руб.
				</p>
				<p className={styles.price_real}>
					30 руб.
				</p>
			</div>

			{salePage && (
				<div className={styles.offer}>
					<button type='button' className={styles.button}>
						В корзину
					</button>
					<Link to="/basket" className={styles.link}>
						Уже в корзине
					</Link>
				</div>
			)}

			<div className={styles.badge}>
				Скидка
			</div>
		</Link>
	)
}

ProductItem.propTypes = {
	sale:     PropTypes.bool,
	salePage: PropTypes.bool,
}

ProductItem.defaultProps = {
	sale:     false,
	salePage: false,
}

export { ProductItem }
