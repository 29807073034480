import { getArrayFromFiles } from 'components/global/Slider'
import img from './media/about-us/0.jpg'
import img1 from './media/about-us/1.jpg'
import img2 from './media/about-us/2.jpg'
import img3 from './media/about-us/3.jpg'
import img4 from './media/about-us/4.jpg'
import img5 from './media/about-us/5.jpg'
import img6 from './media/about-us/6.jpg'
import img15 from './media/other/03.jpg'
import img16 from './media/other/04.jpg'
import img17 from './media/other/05.jpg'
import img18 from './media/other/06.jpg'
import img19 from './media/other/07.jpg'
import img20 from './media/other/08.jpg'
import img7 from './media/trade-hall/01.jpg'
import img8 from './media/trade-hall/02.jpg'
import img9 from './media/trade-hall/03.jpg'
import img10 from './media/trade-hall/04.jpg'
import img11 from './media/trade-hall/05.jpg'
import img12 from './media/trade-hall/06.jpg'
import img13 from './media/trade-hall/07.jpg'
import img14 from './media/trade-hall/08.jpg'

export const aboutUsImages    = getArrayFromFiles(img, img1, img2, img3, img4, img5, img6)
export const tradeHallImages  = getArrayFromFiles(img7, img8, img9, img10)
export const tradeHallImages2 = getArrayFromFiles(img11, img12, img13, img14)
export const historyImages    = getArrayFromFiles(img15, img16, img17, img18, img19, img20)
