import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { default as BaseSagaHelper } from '@kakadu-dev/base-frontend-helpers/helpers/Redux/SagasHelper'
import { all, call, put } from 'redux-saga/effects'
import { ProductsActions } from './actionCreators'
import { ProductsApi } from "./api"

/**
 * Base components sage helper
 */

class SagasHelperLists extends BaseSagaHelper {

	static *listsAction(action, api, responseHandler, beforeResponse, afterResponse) {
		const {
			payload: queries,
			type
		} = action
		const {
			request,
			success,
			error
		} = RequestActionHelper.getAllActions(type)
		const searchQuery = DataProvider.getSearchQuery(queries?.[0])
		const callList = {}

		try {
			yield put(request(queries))

			if (typeof beforeResponse === 'function') {
				yield beforeResponse(queries)
			}

			queries.forEach((query, idx) => {
				callList[idx] = call(ProductsApi.list, queries[idx])
			})
			const responses = yield all(callList)

			if (!responseHandler) {
				const list = []
				let totalPage = 0
				let pageCurrent = 1
				Object.values(responses).forEach(response => {
					if(response?.result?.list.length){
						const { pageCount, currentPage } = response?.result?.pagination

						console.log(pageCount)
						console.log(currentPage)

						if(pageCount > totalPage) {
							totalPage = pageCount
						}
						if(currentPage > pageCurrent) {
							pageCurrent = currentPage
						}
						list.push(...response?.result?.list)
					}

				})

				yield put(ProductsActions.setSearch({list,
					pagination: { perPage: 20, pageCount: totalPage, currentPage: pageCurrent}}))

			} else {
				yield put(success(responseHandler(responses), queries))
			}

			if (typeof afterResponse === 'function') {
				yield afterResponse(responses, queries)
			}

			searchQuery.runSuccessCallback(responses)
		} catch (e) {
			yield put(error(searchQuery.addReduxRequestParams({
				error: e
			})))
			searchQuery.runErrorCallback(e)
		}

		searchQuery.runCallback()
	}

}

export default SagasHelperLists
