const market = 'market'

/**
 * Get market url link
 *
 * @return {string}
 */
export const getMarketUrl = () => {
	return `/${market}`
}
