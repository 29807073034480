import * as PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import styles from './styles.scss'

export const Developers = ({ intl }) => (
	<div className={styles.developers}>
		{`${intl.messages['general.developers.title']} `}
		<a
			target="_blank"
			rel="noopener noreferrer"
			href="https://kakadu.bz"
		>
			kakadu.bz
		</a>
	</div>
)

export default injectIntl(Developers)

Developers.propTypes = {
	intl: PropTypes.object,
}

Developers.defaultProps = {
	intl: {},
}
