import {
	Category,
	Product,
} from '@kakadu-dev/base-frontend-components'
import { ProductsSlider } from 'components/global/ProductsSlider'
import { DataBlock } from 'components/home/DataBlock'
import { ProductCardStore } from 'components/market/ProductCard/index.store'
import PropTypes from 'prop-types'
import React from 'react'
import { Row } from 'reactstrap'
import styles from './styles.scss'

/**
 * Render productsList block
 *
 * @param {boolean} isFetching
 * @param {Array} productsLists
 * @param {Array} productsSale
 *
 * @return {*}
 * @constructor
 */
const ProductsBlock = ({ isFetching, productsLists, productsSale }) => {
	const sale = Product.createList(productsSale)

	return (
		<>
			{
				!isFetching && (
					<>
						{
							!!sale.length && (
								<DataBlock
									title="Товары со скидкой"
								>
									<Row className={styles.row}>
										<ProductsSlider>
											{sale.map(product =>
												<ProductCardStore
													product={product}
													key={product.getId()}
													colProps={null}
													isFooterNeeded={false}
												/>
											)}
										</ProductsSlider>
									</Row>
								</DataBlock>
							)
						}
						{
							productsLists.map(productList => {
								const products = Product.createList(productList.products)
								const category = Category.create(productList.category)

								return (
									<div key={category.getId()}>
										{!!products.length &&
										<DataBlock
											title={category.getTitle()}
											link={`products/${category.getAllParents()[0].alias}/${category.getAlias()}`}
										>
											<Row className={styles.row}>
												<ProductsSlider>
													{products.map(product =>
														<ProductCardStore
															product={product}
															key={product.getId()}
															colProps={null}
															isFooterNeeded={false}
													 />,
												 )}
												</ProductsSlider>
											</Row>
										</DataBlock>}
									</div>)
							})
						}
					</>
				)
			}
		</>
	)
}

ProductsBlock.propTypes = {
	isFetching:    PropTypes.bool,
	productsLists: PropTypes.array,
	productsSale:  PropTypes.array,
}

ProductsBlock.defaultProps = {
	isFetching: false,
	productsLists: [],
	productsSale: [],
}

export default ProductsBlock

