import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const { categories: { getHierarchical: hierarchicalState } } = StateSelector

const mapStateToProps = state => ({
	productsState: StateSelector.products.search(state),
	categoryState: StateSelector.categories.view(state),
	searchCategoryState: StateSelector.categories.list(state),
	hierarchicalState: hierarchicalState(state),
})

const mapDispatchToProps = {
	setProducts: DispatchSelector.products.setSearch,
	searchProducts: DispatchSelector.products.search,
	searchProductsSub: DispatchSelector.products.searchSub,
	getCategory: DispatchSelector.categories.view,
	searchCategory: DispatchSelector.categories.list,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
