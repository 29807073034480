import { Category } from '@kakadu-dev/base-frontend-components'
import { getIcon } from 'components/market/Home/Sidebar/utils'
import _ from 'lodash'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import {
	Col,
	Row,
} from 'reactstrap'
import { HierarchicalService } from 'services/HierarchicalService'
import styles from './styles.scss'

/**
 * Render category section
 *
 * @param {Object} hierarchicalState
 * @param {number} id
 *
 * @param isMobile
 * @param closeMenu
 * @return {*}
 * @constructor
 */
export const CategorySection = ({ hierarchicalState, id , isMobile, closeMenu}) => {
	const menu = _.find(hierarchicalState.result, ['id', id])

	HierarchicalService.getInstance().setCategories(hierarchicalState.result)

	return (
		!isMobile ?
			<Row className={styles.row}>
				{menu && Category.map(menu.childrens, category => (
					<Col key={category.getId()} xs={4} className={styles.col}>
						<div className={styles.category}>
							<Link
								className={styles.link}
								to={HierarchicalService.getInstance().getCategoryLink(category.getId())}
							/>
							<div className={styles.img_wrapper}>
								<img
									className={styles.img}
									src={getIcon(category.getAlias())}
									alt={category.getAlias()}
								/>
							</div>
							<span className={styles.title}>{category.getTitle()}</span>
						</div>
					</Col>
				))}
			</Row> :
			<Col>
				{menu && Category.map(menu.childrens, category => (
					<div key={category.getId()} className={styles.mobileCategory}>
						<Link
							className={styles.link}
							to={HierarchicalService.getInstance().getCategoryLink(category.getId())}
							onClick={closeMenu}
						/>
						<span>{category.getTitle()}</span>
					</div>
				))}
			</Col>
	)
}

CategorySection.propTypes = {
	hierarchicalState: PropTypes.object,
	id:                PropTypes.number,
	isMobile:          PropTypes.bool,
	closeMenu:         PropTypes.func,
}

CategorySection.defaultProps = {
	hierarchicalState: {},
	id:                1,
	closeMenu:         () => {},
}
