import { INTL_ACTIONS } from './actionTypes'

export const setLang = (payload) => {
	return (
		{
			type: INTL_ACTIONS.SET_LANG,
			payload,
		}
	)
}
