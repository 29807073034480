import Icon from 'assets/icomoon/Icon'
import * as PropTypes from 'prop-types'
import React, { Children } from 'react'
import { Link } from 'react-router-dom'
import { Collapse } from 'reactstrap'

import styles from './accordion.scss'

const AccordionSection = props => {
	const {
			  isOpen,
			  title,
			  rotate45,
			  rotate180,
			  icon,
			  iconSize,
			  iconColor,
			  classCustom,
			  headingClassCustom,
			  headingClassActive,
			  count,
			  children,
			  id,
			  onClick,
			  to,
		  } = props

	const total = Children.count(children)

	return (
		<div
			className={[
				styles.item,
				classCustom,
			].join(' ')}
		>
			<div
				role='button'
				tabIndex='0'
				onClick={() => onClick(id)}
				onKeyDown={() => onClick(id)}
				className={[
					styles.heading,
					headingClassCustom,
					isOpen ? headingClassActive : '',
				].join(' ')}
			>
				{to ?
					<Link to={to}>
						<p className={styles.active_title}>
							{title} {count && <span className={styles.count}>({total})</span>}
						</p>
					</Link>
					: <p className={styles.title}>
						{title} {count && <span className={styles.count}>({total})</span>}
					</p>}
				{icon && (
					<div className={[
						styles.icon,
						isOpen && rotate45 ? styles.rotate45 : '',
						isOpen && rotate180 ? styles.rotate180 : '',
					].join(' ')}>
						{icon && <Icon icon={icon} size={iconSize} color={iconColor} />}
					</div>
				)}
			</div>
			<Collapse isOpen={isOpen}>
				<div className={styles.body}>
					{children}
				</div>
			</Collapse>
		</div>
	)
}

AccordionSection.propTypes = {
	children:           PropTypes.instanceOf(Object).isRequired,
	isOpen:             PropTypes.bool.isRequired,
	title:              PropTypes.string.isRequired,
	id:                 PropTypes.any,
	onClick:            PropTypes.func,
	rotate45:           PropTypes.bool.isRequired,
	rotate180:          PropTypes.bool.isRequired,
	icon:               PropTypes.string,
	to:                 PropTypes.string,
	iconSize:           PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
	iconColor:          PropTypes.string.isRequired,
	classCustom:        PropTypes.string.isRequired,
	headingClassCustom: PropTypes.string.isRequired,
	headingClassActive: PropTypes.string.isRequired,
	count:              PropTypes.bool.isRequired,
}

AccordionSection.defaultProps = {
	id:      '',
	icon:    '',
	to:      '',
	onClick: () => null,
}

export default AccordionSection
