import { connect } from 'react-redux'
import { DeliveryAddress } from './index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'

export const DeliveryAddressEditStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(DeliveryAddress)
