import { StaticPageWrapper } from 'components/static/StaticPageWrapper'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { CollapseLink } from 'components/static/CollapseLink'
import { injectIntl } from 'react-intl'
import { byMenu } from "../home/Header/Navigation/data"

// eslint-disable-next-line consistent-return
const transform = (node, index) => {
	const { attribs, type, name } = node
	if (type === 'tag' && name === 'section') {
		switch (attribs.class) {

			case 'collapse-wrapper':
				return <CollapseLink key={index} node={node} />

			default:
				break
		}
	}
}

const unClickablePath = [
	'buyers',
	'for-business',
	'cooperation',
	'appeals',
]

const checkPath = (alias, prevCrumbs) => {
	if (unClickablePath.includes(alias)){
		return ''
	}
	return prevCrumbs ? `/${prevCrumbs.alias}/${alias}` : `/${alias}`
}

export const Pages = injectIntl(({ page, hierarchical, children, intl }) => {
	const { title, body, alias } = page

	const newAlias = useMemo(() => {
		if (alias.startsWith('/')) {
			return alias.substring(1)
		}
		return alias
	}, [alias])

	const aliases = intl.locale === 'be' ? newAlias.split('/').filter((el)=> el !== 'by'): newAlias.split('/')

	const extraBreadcrumbs = useMemo(() => {
		const result = []

		let hierar = intl.locale === 'be'  ? byMenu?.result : hierarchical

		aliases.map((_alias, i, arr) => {
			if (i < (arr.length - 1)) {
				const breadcrumb = hierar.find(item => {
					const itemAlias = intl.locale === 'be' ? item?.alias.substring(3) : item?.alias
					return itemAlias === _alias
				})
				result.push({
					title: breadcrumb?.title,
					path: checkPath(breadcrumb?.alias, result[result.length - 1]),
					alias: breadcrumb?.alias,
				})
				if (breadcrumb?.childrens?.length > 0) {
					hierar = breadcrumb.childrens
				}
			}
		})

		return result
	}, [aliases, hierarchical, intl.locale])

	return (
		<StaticPageWrapper title={title} extraBreadcrumbs={extraBreadcrumbs}>
			<div className="ck-content">{ ReactHtmlParser(body, { transform }) }</div>
			{
				children
			}
		</StaticPageWrapper>
	)
})

Pages.propTypes = {
	page:             PropTypes.object,
	extraBreadcrumbs: PropTypes.array,
	hierarchical:     PropTypes.array,
}
Pages.defaultProps = {
	page:             {},
	extraBreadcrumbs: [],
	hierarchical:     [],
}
