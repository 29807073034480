import * as PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.scss'

const freeDeliveryPrice = 150000

/**
 * Render basket header
 *
 * @param {string} title
 * @param {boolean} delivery
 * @param {number} totalPrice
 *
 * @return {*}
 * @constructor
 */
export const BasketHeader = ({ title, delivery, totalPrice }) => (
	<div className={styles.header}>
		<span className={styles.title}>{title}</span>
		{/*{delivery && (*/}
		{/*	<div className={styles.missing}>*/}
		{/*		{freeDeliveryPrice <= totalPrice ?*/}
		{/*			<p className={styles.free_delivery}>Для вас доставка бесплатна!</p> :*/}
		{/*			<>*/}
		{/*				<div className={styles.few}>Вам не хватает*/}
		{/*					<span className={styles.diff_price}>*/}
		{/*						<FormattedCurrency value={(freeDeliveryPrice - totalPrice)} />*/}
		{/*					</span>*/}
		{/*					до бесплатной доставки!*/}
		{/*				</div>*/}
		{/*				<Link to="/"><p className={styles.to_catalog}>В каталог</p></Link>*/}
		{/*			</>}*/}
		{/*		<div className={styles.icon}><img src={deliveryIco} alt="delivery" /></div>*/}
		{/*	</div>*/}
		{/*)}*/}
	</div>
)

BasketHeader.propTypes = {
	title:      PropTypes.string.isRequired,
	delivery:   PropTypes.bool,
	totalPrice: PropTypes.oneOfType([
		PropTypes.string, PropTypes.number,
	]),
}

BasketHeader.defaultProps = {
	delivery:   false,
	totalPrice: 0,
}
