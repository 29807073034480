import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { Checkbox } from 'components/Form/fields/Checkbox'
import { PhoneNumber } from 'components/Form/fields/PhoneNumber'
import { Button } from 'components/global/Button'
import { InputField } from 'components/global/InputField'
import { Notification } from 'helpers/Notification'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useState,
} from 'react'
import styles from './styles.scss'

const fields = [
	{ id: 'name', title: 'Имя', inputProps: { pattern: '[A-Za-zА-Яа-яЁё]{2,}' }, required: true },
	{
		id: 'phone',
		title: 'Телефон',
		inputProps: { pattern: '\\+[0-9]{1,3}\\s+\\([0-9]{2,3}\\)\\s[0-9\\s]{9,11}' },
		required: true,
		component: <PhoneNumber />,
	},
	{ id: 'email', title: 'E-mail', required: true, type: 'email', inputProps: { autoComplete: 'on' } },
	{ id: 'age', title: 'Возраст', required: true, type: 'number', inputProps: { min: 1 } },
	{ id: 'appointmentDate', title: 'Дата встречи со стилистом', required: true, type: 'date' },
	{
		id: 'consent',
		title: 'Даю согласие на обработку персональных данных и на получение рассылки материалов рекламного и/или информационного характера посредством SMS-сервисов, мессенджеров, электронной почты и т.д. от ОАО «ГУМ».',
		type: 'checkbox',
	},
]

/**
 * Render evening with stylist form
 *
 * @param {function} createForm
 *
 * @return {*}
 * @constructor
 */
export const EveningWithStylistForm = ({ createForm }) => {
	const [fieldsData, setFieldsData] = useState({ data: {}, params: {} })

	const inputHandler = useCallback((event, value) => {
		const name = typeof event === 'string' ? event : (event?.target?.name ?? 'undefined')
		const val = value === undefined ? event?.target?.value : value

		const nextAttributes = { [name]: val }
		const nextError = { [name]: { ...fieldsData.params[name], error: false } }

		setFieldsData({
			data: { ...fieldsData.data, ...nextAttributes },
			params: { ...fieldsData.params, ...nextError },
		})
	}, [fieldsData])

	const onBlur = useCallback((event) => {
		const { name, validity: { valid } } = event?.target ?? { validity: {} }
		const nextError = { [name]: { ...fieldsData.params[name], error: !valid } }

		setFieldsData({
			data: { ...fieldsData.data },
			params: { ...fieldsData.params, ...nextError },
		})
	}, [fieldsData])

	const onSubmit = useCallback(e => {
		e.preventDefault()
		if (fieldsData.data.consent) {
			const searchQuery = DataProvider.buildQuery()
				.addBody({ ...fieldsData.data }, true)
				.setSuccessCallback(() => {
					Notification.add({
						title:   'Успех',
						message: 'Заявка принята в обработку, ожидайте звонка менеджера',
						type:    'success',
					})
					setFieldsData({ data: { phone: '+375' }, params: {} })
				})
				.setErrorCallback(() => {
					Notification.add({
						title:   'Ошибка',
						message: 'Отправка заявки временно недоступна по техническим причинам. Повторите попытку позже',
						type:    'danger',
					})
				})
			createForm(searchQuery)
		}
	}, [fieldsData, createForm])

	return (
		<div className={styles.row}>
			<form onSubmit={onSubmit}>
				{
					fields.map(field => (
						field.type === 'checkbox' ?
							<Checkbox
								key={field.id}
								id={field.id}
								title={field.title}
								handler={inputHandler}
								value={fieldsData.data[field.id] ?? false}
							/> :
							<InputField
								key={field.id}
								onChange={inputHandler}
								onBlur={onBlur}
								value={fieldsData.data[field.id] ?? ''}
								field={field}
								groupClassName={styles[field.id]}
								required={field.required}
								error={fieldsData.params[field.id]?.error}
								inputProps={field.inputProps}
							/>
					))
				}
				<Button
					type="submit"
					title="Отправить"
					style={[styles.submit, fieldsData.data.consent && styles.submitActive].join(' ')}
					color={fieldsData.data.consent ? 'green' : 'gray'}
				/>
			</form>
		</div>
	)
}
EveningWithStylistForm.propTypes    = {
	createForm: PropTypes.func,
}
EveningWithStylistForm.defaultProps = {
	createForm: () => null,
}
