import BaseForm from '@kakadu-dev/base-frontend-helpers/forms/BaseForm'
import { FormComponent } from '@kakadu-dev/base-frontend-helpers/helpers/Form'
import { AvForm } from 'availity-reactstrap-validation'
import { City } from '@kakadu-dev/base-frontend-components'
import React from 'react'
import InputMask from 'react-input-mask'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import { StateSelector } from 'reduxm/modules/selectors'
import {
	HelperText,
	WrapView,
} from './components'
import { AutocompleteInput } from './components/AutocompleteInput'
import { DateInput } from './components/DateInput'
import { DropdownInput } from './components/DropdownInput'
import ImageInput from './components/ImageInput'
import { TextInput } from './components/TextInput'

/**
 * Form
 */
class Form extends FormComponent
{
	static INPUT_MASKS = {
		[BaseForm.MASK_TYPES.PHONE]: {
			by: '+375 (99) 999-99-99',
			ru: '+7 (999) 999-99-99',
			ua: '+380 (99) 999-99-99',
			kz: '+7 (999) 999-99-99',
		},
	}

	/**
	 * Get text input props
	 *
	 * @param {object} element
	 *
	 * @return {object}
	 */
	getTextInputProps = (element) => {
		const nativeProps = this.getInputProps(element)

		const inputProps = {}

		if (nativeProps && nativeProps.maskType) {
			const { reduxState } = this.props

			// Get country
			const countryCode = City
				.create(StateSelector.user.getUserCity(reduxState))
				.getCountry()
				.getCode()

			// TODO проверить маску
			const mask = Form.INPUT_MASKS[nativeProps.maskType][countryCode] || ''

			if (mask) {
				inputProps.tag  = [
					Input,
					InputMask,
				]
				inputProps.mask = mask
			}
		}

		return {
			component: TextInput,
			...inputProps,
		}
	}

	/**
	 * @inheritDoc
	 */
	parentGetInputProps = (element, addProps) => {
		const type = this.getInputType(element)

		switch (type) {
			case BaseForm.TYPES.IMAGE:
				return { ...addProps, component: ImageInput }
			case BaseForm.TYPES.AUTOCOMPLETE:
				return { ...addProps, component: AutocompleteInput }
			case BaseForm.TYPES.DATE:
				return { ...addProps, component: DateInput }
			case BaseForm.TYPES.DROPDOWN:
				return { ...addProps, component: DropdownInput }
			case BaseForm.TYPES.INPUT:
				return { ...addProps, ...this.getTextInputProps(element) }
			default:
		}

		return addProps
	}

	/**
	 * @inheritDoc
	 */
	parentGetHelper = (element, props) => {
		return {
			...props,
			component: HelperText,
		}
	}

	/**
	 * @inheritDoc
	 */
	parentGetWrapper = props => {
		return {
			...props,
			component: WrapView,
		}
	}

	/**
	 * @inheritDoc
	 */
	render()
	{
		return (
			<AvForm className="av-tooltip tooltip-label-right" method='post' onSubmit={() => null}>
				{super.render()}
			</AvForm>
		)
	}
}

// eslint-disable-next-line react-redux/mapStateToProps-no-store
const mapStateToProps = state => ({ reduxState: state })
// eslint-disable-next-line react-redux/prefer-separate-component-file
export default connect(mapStateToProps, null, null, { forwardRef: true })(Form)
