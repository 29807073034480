// import { asyncComponent } from '@jaredpalmer/after'
import { ProfileStore } from 'containers/User'
import wrappers from 'routing/wrappers'

export const userPages = [
	{
		path:      '/user',
		exact:     true,
		component: wrappers(ProfileStore),
		// component: asyncComponent({
		// 	loader:      () => wrappers(import('containers/User/UserPage/index.store')),
		// 	Placeholder: () => <div>...LOADING...</div>, // this is optional, just returns null by default
		// }),
	},
	{
		path:      '/user/:activeTab',
		exact:     true,
		component: wrappers(ProfileStore),
	},
]
