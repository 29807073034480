import {
	Customer,
	CustomerFavorite,
} from '@kakadu-dev/base-frontend-components'
import { FavoritesListStore } from 'components/user/Favorites/index.store'
import { FavoriteAnchor } from 'containers/User/Favorites/FavoriteAnchor'
import { Header } from 'containers/User/Header'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

/**
 * Render favorites page
 *
 * @param {User} userState
 *
 * @returns {*}
 * @constructor
 */
export const Favorites = ({ userState }) => {
	const [activeCategory, setActiveCategory] = useState('product')

	return (
		<>
			<Header title="Избранное" userName={Customer.create(userState).getFullName()} />
			<FavoriteAnchor active={activeCategory} setActive={setActiveCategory} />
			<FavoritesListStore type={CustomerFavorite.types[activeCategory]} />
		</>
	)
}

Favorites.propTypes = {
	userState: PropTypes.object,
}

Favorites.defaultProps = {
	userState: {},
}
