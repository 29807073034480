/* eslint-disable react-hooks/exhaustive-deps */
import { Category } from '@kakadu-dev/base-frontend-components'
import ComponentHelper from '@kakadu-dev/base-frontend-helpers/helpers/ComponentHelper'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import SSRComponentHelper from '@kakadu-dev/base-frontend-helpers/helpers/SSRComponentHelper'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { PageHeading } from 'components/global'
import { useBreadcrumbs } from 'components/global/useBreadcrumbs'
import { usePrevious } from 'components/global/usePrevious'
import {
	DropdownSorter,
	FiltersList,
	Pagination,
} from 'components/market/ProductsList'
import { FilterPriceBlock } from 'components/market/ProductsList/FiltersList/FilterPrice'
import { Products } from 'components/market/ProductsList/Products'
import { SelectedFilters } from 'components/market/ProductsList/SelectedFilters'
import {
	COUNT_OF_PRODUCT,
	DISABLE_INITIAL_PROPS,
	IS_PROD,
	META_ENTITY_TYPE,
} from 'constants'
import { removeFilter } from 'containers/Market/ProductsList/utils'
import { Error } from 'containers/Static/Error'
import ErrorHelper from 'helpers/ErrorHelper'
import _ from 'lodash'
import * as PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { useParams } from 'react-router'
import {
	Col,
	Container,
	Row,
} from 'reactstrap'
import CategoryFilters from 'services/CategoryFilters'
import { MetaService } from 'services/MetaService'
import { mapDispatchToProps } from './index.props'
import styles from './styles.scss'

const setMetaInfo = (categoryId, title) => MetaService.setInfo({
	entity:   META_ENTITY_TYPE.CATEGORY,
	entityId: categoryId,
}, { title })

const getFilterQuery = id => DataProvider
	.buildQuery()
	.addBody({ query: { expands: ['property'] }, id })
	.cacheResponse(60 * 10, IS_PROD)

const getProductsQuery = (categoryId, properties = {}, sortBy = null, price, page = 1) => DataProvider
	.buildQuery()
	.addBody({
		extraFilter: {
			categoryId,
			properties,
			sortBy,
			price,
			isAvailable: 1,
		},
		query:       {
			expands: [
				'categories.category.allParents',
				'minPrices',
				'options',
				'properties',
				'isFavorite',
				'price',
				'offers',
			],
			filter: {
				isRemoved: false,
			},
			page,
			perPage: COUNT_OF_PRODUCT.PRODUCTS_LIST,
		},
	})
	.cacheResponse(60 * 10, IS_PROD)

const getCategoryQuery = (subtype, setError = () => null, getProducts = () => null, getFilters = () => null) =>
	DataProvider.buildQuery()
		.addBody({
			query: {
				filter:  { level: Category.levels.three, alias: subtype },
				expands: ['childrenCategories', 'parentCategories'],
			},
		})
		.setSuccessCallback(response => {
			const { id, title } = response.result.list?.[0] ?? {}

			if (id) {
				setError(false)
				getProducts(getProductsQuery(id))
				getFilters(getFilterQuery(id))
				setMetaInfo(id, title)
			} else {
				setError(true)
			}
		})
		.cacheResponse(60 * 10, IS_PROD)

/**
 * Render products list
 *
 * @param {Object} categoryState
 * @param {Object} productsState
 * @param {Function} getProducts
 * @param {Function} getFilters
 * @param {Function} getCategory
 * @param {Object} filtersState
 * @param {boolean} isFetching
 * @param {Function} setCategories
 *
 * @return {*}
 * @constructor
 */
export const ProductsList = ({
	categoryState,
	productsState,
	getProducts,
	getFilters,
	getCategory,
	filtersState,
	isFetching,
	setCategories,
}) => {
	const [activeFilters, setActiveFilters] = useState([])
	const [properties, setProperties]       = useState({})
	const [sortBy, setSortBy]               = useState('')
	const [isError, setIsError]             = useState(false)

	const rangeRef = useRef({min: '', max: ''})

	const productsService = StateService.create(productsState)
	const filtersService  = StateService.create(filtersState)
	const filters         = CategoryFilters.create(filtersState)
	const categoryService = useMemo(() => StateService.create(categoryState), [categoryState])
	const category        = useMemo(() => Category.create(categoryService.getList()?.[0]), [categoryService])

	const [categoryId, categoryTitle] = useMemo(() => [category.getId(), category.getTitle()], [category])

	const { subtype } = useParams()
	const prevSubtype = usePrevious(subtype)
	const breadcrumbs = useBreadcrumbs(category.getId())

	const categoryQueryArgs = useMemo(() => [subtype, setIsError, getProducts, getFilters],
		[getFilters, getProducts, subtype])

	const updateList = useCallback(_.debounce((id, prop, sort, price) => getProducts(
		getProductsQuery(id, prop, sort, price)),
	500), [])

	useEffect(() => {
		ComponentHelper.fetchIfNotExist(categoryState, getCategory, getCategoryQuery(...categoryQueryArgs), true)

		return () => setCategories({ list: [] })
	}, [])

	useEffect(() => {
		if (prevSubtype && prevSubtype !== subtype || category.getLevel() === 'two') {
			getCategory(getCategoryQuery(...categoryQueryArgs))
		}
	}, [categoryQueryArgs, getCategory, prevSubtype, subtype])

	const filterHandler = useCallback((event, filterItem, remove = false) => {
		const { alias, value, id, type }               = filterItem
		const { [alias]: removed, ...otherProperties } = properties
		let nextProperties

		if (type === 'string') {
			const data     = (properties[alias] || []).filter(property => property !== id)
			const isRemove = remove || !event.target.checked

			setActiveFilters(isRemove ? activeFilters.filter(filter => filter.value !== value) :
				[...activeFilters, filterItem])
			nextProperties = {
				...otherProperties, ...(isRemove ? { ...(data.length && { [alias]: data }) } :
					{ [alias]: [...(properties[alias] || []), id] }),
			}
		}

		if (type === 'boolean' || type === 'number' || type === 'price') {
			setActiveFilters([...activeFilters.filter(filter => filter.alias !== alias),
				...(!remove ? [filterItem] : [])])
			nextProperties = { ...otherProperties, ...(!remove && { [alias]: id }) }

		}

		updateList(categoryId, nextProperties, _, rangeRef.current)
		setProperties(nextProperties)
	}, [activeFilters, categoryId, properties, updateList])

	const sortHandler = useCallback(nextSortBy => {
		updateList(categoryId, properties, nextSortBy, rangeRef.current)
		setSortBy(nextSortBy)
	}, [categoryId, properties])

	const priceHandle = (price) => {
		rangeRef.current = price
	}

	return (
		!isError &&
		<>
			<div className={styles.title_wrapper}>
				<PageHeading
					title={categoryTitle}
					breadcrumbs={breadcrumbs}
					fetching={categoryService.isFetching()}
				/>
			</div>

			<section className={styles.background}>
				<Container className={styles.container}>
					<div className={styles.filters_wrapper}>
						<SelectedFilters
							list={activeFilters}
							remove={removeFilter}
							toggle={filterHandler}
						/>
						<DropdownSorter
							isVisible={!productsService.isEmptyList()}
							setSort={sortHandler}
							filtersState={filtersState}
						/>
					</div>
					<Row>
						<Col sm="12" lg="3">
							<FilterPriceBlock toggle={filterHandler} priceHandle={priceHandle} range={rangeRef.current} />
							<FiltersList
								list={filters}
								productsService={productsService}
								fetching={filtersService.isFetching()}
								toggle={filterHandler}
							/>
						</Col>
						<Col sm="12" lg="9" className={styles.goods}>
							<Products
								isFetching={isFetching}
								productsState={productsState}
								categoryState={categoryState}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<Pagination
								service={productsService}
								goToPage={page => getProducts(
									getProductsQuery(categoryId, properties, sortBy, null, page)
								)}
							/>
						</Col>
					</Row>
				</Container>
			</section>
		</> ||
		<Error />
	)
}

ProductsList.getInitialProps = SSRComponentHelper.initialProps(async (dispatchers, ctx) => {
	const { match: { params: { subtype } } }       = ctx
	const { getProducts, getFilters, getCategory } = dispatchers

	try {
		const data       = await getCategory(getCategoryQuery(subtype))
		const categoryId = Category.create(data?.result?.list?.[0] ?? {}).getId()

		if (categoryId) {
			await Promise.all([
				getProducts(getProductsQuery(categoryId)),
				getFilters(getFilterQuery(categoryId)),
			])
		}
	} catch (e) {
		ErrorHelper.log(e)
	}
}, mapDispatchToProps, DISABLE_INITIAL_PROPS)

ProductsList.propTypes = {
	categoryState: PropTypes.object,
	filtersState:  PropTypes.object,
	getCategory:   PropTypes.func,
	getFilters:    PropTypes.func,
	getProducts:   PropTypes.func,
	isFetching:    PropTypes.bool,
	productsState: PropTypes.object,
	setCategories: PropTypes.func,
}

ProductsList.defaultProps = {
	categoryState: {},
	filtersState:  {},
	getCategory:   () => null,
	getFilters:    () => null,
	getProducts:   () => null,
	isFetching:    false,
	productsState: {},
	setCategories: () => null,
}
