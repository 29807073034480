import {
	Customer,
	Product,
	ProductReview,
	ProductReviewForm,
} from '@kakadu-dev/base-frontend-components'
import Form from 'components/Form'
import { IndependentFormStore } from 'components/Form/IndependentForm'
import { StarRating } from 'components/global'
import { Button } from 'components/global/Button'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
	Col,
	Row,
} from 'reactstrap'

import styles from './styles.scss'

/**
 * Render review form
 *
 * @param {function} updateReviews
 * @param {Object} productState
 * @param {Object} userState
 *
 * @return {*}
 * @constructor
 */
export class ReviewForm extends Component {
	state = {
		submiting: false,
	}

	action = () => {
		const { updateReviews, close } = this.props

		updateReviews()
		close()
		this.setState({ submiting: false })
	}

	submit = () => {
		this.form.submit()
		this.setState({ submiting: true })
	}

	render() {
		const {
				  productState,
				  userState,
				  close,
			  }             = this.props
		const { submiting } = this.state

		const customer = Customer.create(userState)

		const form = ProductReviewForm
			.create()
			.setDefault('productId', Product.create(productState).getId())
			.setDefault('type', ProductReview.types.review)
			.setDefault('name', customer.getFirstName())
			.setDefault('vote', 3)

		const renderFields = [
			'text', 'vote', 'advantages', 'disadvantages',
		]

		if (!customer.getFirstName()) {
			renderFields.unshift('name')
		}

		return (
			<Row className={styles.main_section}>
				<Col xs="12" className={styles.reviewFormWrapper}>
					<Button style={styles.backBtn} title='К отзывам' color={'grey'} onClick={close} />
					<IndependentFormStore
						ref={f => {
							this.form = f
						}}
						onSuccess={this.action}
						isEqualForms={false}
					>
						<Form
							form={form}
							renderOnly={renderFields}
							fieldsKeep={[
								'productId', 'type', 'name',
							]}
							customInputs={{
								vote: (element, value, setValue, props) => (
									<StarRating
										readonly={false}
										prefix={'Ваша оценка:'}
										showRateString
										initialRating={value}
										size={25}
										changeValue={(r) => setValue(element, r)}
										{...props}
									/>
								),
							}}
						/>
					</IndependentFormStore>
				</Col>
				<div className={styles.buttons}>
					<Button
						color="green"
						onClick={this.submit}
						title="Сохранить"
						fetching={submiting}
					/>
				</div>
			</Row>
		)
	}
}

ReviewForm.propTypes = {
	close:         PropTypes.func,
	productState:  PropTypes.object,
	updateReviews: PropTypes.func,
	userState:     PropTypes.object,
}

ReviewForm.defaultProps = {
	close:         () => null,
	productState:  {},
	updateReviews: () => null,
	userState:     {},
}
