import { isFetching as _ } from 'helpers/FetchingHelper'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const {
		  user:               { getUser, updateUserFields, updateUserSettings },
		  customersAddresses: { list: userAddresses, create: createAddress, update: updateAddress },
	  } = StateSelector

const mapStateToProps    = state => ({
	userState:          getUser(state),
	userAddressesState: userAddresses(state),
	isFetching:         _(getUser(state), userAddresses(state), updateUserFields(state), updateUserSettings(state),
		createAddress(state), updateAddress(state)),
})
const mapDispatchToProps = {
	getUser:            DispatchSelector.user.getUser,
	getUserAddresses:   DispatchSelector.customersAddresses.list,
	createAddress:      DispatchSelector.customersAddresses.create,
	updateAddress:      DispatchSelector.customersAddresses.update,
	updateUser:         DispatchSelector.user.updateUserFields,
	updateUserSettings: DispatchSelector.user.updateUserSettings,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
