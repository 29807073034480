import { connect } from 'react-redux'
import { MailSubscribe }  from './index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'
import { injectIntl } from 'react-intl'

const MailSubscribeStore = injectIntl(
	connect(mapStateToProps, mapDispatchToProps)(MailSubscribe)
)

export { MailSubscribeStore }
