import { Pages } from 'components/pages'
import { EveningWithStylistFormInStore } from 'components/static/EveningWithStylistForm/index.store'
import PropTypes from 'prop-types'

import React, {
	useEffect,
	useMemo,
} from 'react'
import { useLocation } from 'react-router'
import { MetaService } from 'services/MetaService'

const setMetaInfo = title => MetaService.setInfo({}, { title })

/**
 * Render evening with stylist page
 *
 * @return {*}
 * @constructor
 */
export const EveningWithStylist = ({ staticPagesState, hierarchicalState }) => {
	const { pathname } = useLocation()

	const hierarchical = useMemo(() => hierarchicalState.result, [hierarchicalState])

	const page = useMemo(() => {
		const list = staticPagesState?.result?.list || []
		const page = list.find((element) => element.alias === pathname)
		return { title: page?.title || '', body: page?.body || '', alias:page?.alias || '' }
	}, [staticPagesState, pathname])

	useEffect(() => {
		setMetaInfo(page.title)
	}, [page])

	return (
		<Pages page={page} hierarchical={hierarchical}>
			<EveningWithStylistFormInStore />
		</Pages>
	)
}

EveningWithStylist.propTypes = {
	staticPagesState:  PropTypes.object,
	hierarchicalState: PropTypes.object,
}
EveningWithStylist.defaultProps = {
	staticPagesState:  {},
	hierarchicalState: {},
}
