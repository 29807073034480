import React from 'react'
import ContentLoader from 'react-content-loader'

const BreadPlaceholder = () => (
	<ContentLoader
		height={80}
		width={600}
		speed={1}
		backgroundColor="#e9e9e9"
		foregroundColor="#f5f5f5"
		uniqueKey="breadPlaceholder"
	>
		<rect x="10" y="30" rx="5" ry="5" width="140" height="15" />
		<rect x="180" y="30" rx="5" ry="5" width="100" height="15" />
		<rect x="310" y="30" rx="5" ry="5" width="80" height="15" />
		<rect x="418" y="30" rx="5" ry="5" width="160" height="15" />
		<circle cx="164" cy="38" r="8" />
		<circle cx="295" cy="38" r="8" />
		<circle cx="404" cy="38" r="8" />
	</ContentLoader>
)

export default BreadPlaceholder


