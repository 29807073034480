import { FormComponent } from '@kakadu-dev/base-frontend-helpers/helpers/Form'
import { AvInput } from 'availity-reactstrap-validation'
import { TooltipText } from 'components/utils/UIKit'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Label } from 'reactstrap'
import './styles.css'

/**
 * Text input component
 *
 * @param {object} props
 *
 * @return {*}
 * @constructor
 */
export const TextInput = (props) => {
	const {
			  label,
			  name,
			  tooltip,
			  ref,
			  ...newProps
		  } = props

	return (
		<>
			<Label for={name}>
				{tooltip ?
					<TooltipText id={name} text={tooltip}>
						<span id={name}>{label}</span>
					</TooltipText> :
					label
				}
			</Label>
			<AvInput {...FormComponent.clearProps(newProps)} name={name} getRef={ref} />
		</>
	)
}

TextInput.propTypes = {
	onChange: PropTypes.func,
	value:    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	label:    PropTypes.string,
	name:     PropTypes.string,
	tooltip:  PropTypes.string,
	ref:      PropTypes.func,
}

TextInput.defaultProps = {
	onChange: () => null,
	ref:      () => null,
	value:    '',
	label:    '',
	name:     '',
	tooltip:  '',
}
