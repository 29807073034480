import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'
import Slider from 'react-slick'
import {
	Col,
	Row,
} from 'reactstrap'
import styles from './styles.scss'

/**
 * Render sliders for product images
 */
export class ProductImages extends Component
{
	state = {
		nav1:       null,
		nav2:       null,
		imageIndex: 0,
		isOpen:     false,
	}

	componentDidMount()
	{
		this.setState({
			nav1: this.slider1,
			nav2: this.slider2,
		})
	}

	/**
	 * Toggle light box
	 *
	 * @return {undefined}
	 */
	toggleLightBox = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }))

	/**
	 * Toggle images
	 *
	 * @param {boolean} isNext
	 * @return {undefined}
	 */
	toggleImage = (isNext = true) => {
		const { imageIndex } = this.state
		const { images }     = this.props

		if (isNext) {
			this.setState({ imageIndex: (imageIndex + 1) % images.length })
		} else this.setState({
			imageIndex: (imageIndex + images.length - 1) % images.length,
		})
	}

	/**
	 * Go to previous image
	 *
	 * @return {undefined}
	 */
	prevImage = () => this.toggleImage(false)

	render()
	{
		const { nav1, nav2, imageIndex, isOpen } = this.state
		const { images }                         = this.props

		return (
			<Row className={styles.wrapper}>
				<Col xs="2" className={styles.gallery}>
					<Slider
						asNavFor={nav1}
						ref={slider => {
							this.slider2 = slider
						}}
						focusOnSelect
						slidesToShow={images.length < 5 ? images.length : 4}
						vertical
					>
						{images.map((slide, idx) => (
							<div key={slide.getId()}>
								<div
									role="button"
									tabIndex={0}
									className={styles.navItem}
									style={{ backgroundImage: `url(${slide.getUrl()})` }}
									onMouseDown={() => this.setState({ imageIndex: idx })}
								/>
							</div>
						))}
					</Slider>
				</Col>
				<Col xs="10" className={styles.slide}>
					<Slider
						asNavFor={nav2}
						ref={slider => {
							this.slider1 = slider
						}}
						fade
					>
						{images.map(slide => (
							<div
								key={slide.getId()}
								className={styles.item}
								onClick={this.toggleLightBox}
								onKeyDown={this.toggleLightBox}
								role='button'
								tabIndex='0'
							>
								<div
									className={styles.mainImage}
									style={{ backgroundImage: `url(${slide.getUrl()})` }}
								/>
							</div>
						))}
					</Slider>
				</Col>

				{isOpen && (
					<Lightbox
						reactModalStyle={{ overlay: { zIndex: 2000 } }}
						mainSrc={images[imageIndex].getUrl()}
						nextSrc={images[(imageIndex + 1) % images.length].getUrl()}
						prevSrc={images[(imageIndex + images.length - 1) % images.length].getUrl()}
						onCloseRequest={this.toggleLightBox}
						onMovePrevRequest={this.prevImage}
						onMoveNextRequest={this.toggleImage}
						animationDisabled
					/>
				)}
			</Row>
		)
	}
}

ProductImages.propTypes = {
	images: PropTypes.array,
}

ProductImages.defaultProps = {
	images: [],
}
