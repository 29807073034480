import React, {
	useCallback,
	useState,
} from 'react'
import styles from './styles.scss'

const visionPanelValue = {
	valueFont:       [{
		name:     'regular',
		text:     'Обычный',
		isActive: true,
	}, {
		name:     'medium',
		text:     'Увеличенный',
		isActive: false,
	}],
	valueBackground: [{
		name:     'white',
		text:     'Б',
		isActive: true,
	}, {
		name:     'black',
		text:     'Ч',
		isActive: false,
	}, {
		name:     'blue',
		text:     'Г',
		isActive: false,
	}],
	valueImg:        [{
		name:     'show',
		text:     'Вкл',
		isActive: true,
	}, {
		name:     'hide',
		text:     'Выкл',
		isActive: false,
	}],
}

export const VisionPanel = ({setSiteBackground, setShowImg, setFontSize, setFontKerning, setIsShow}) => {
	const [fontSize, setFontSizes]       = useState(visionPanelValue.valueFont)
	const [img, setImg]                 = useState(visionPanelValue.valueImg)
	const [kerning, setKerning] = useState(visionPanelValue.valueFont)
	const [background, setBackground]   = useState(visionPanelValue.valueBackground)

	const changeBackground = useCallback((e) => {
		const selectedBackground = e.target.name
		const newValue           = background.map(item => {
			if (item.name === selectedBackground) {
				return { ...item, isActive: true }
			}
			return { ...item, isActive: false }
		})
		setSiteBackground(selectedBackground)
		return setBackground(newValue)
	}, [background])

	const changeImg = useCallback((e) => {
		const selectedImg = e.target.name
		const isSelectedImg = e.target.name === 'show'
		const newValue    = img.map(item => {
			if (item.name === selectedImg) {
				return { ...item, isActive: true }
			}
			return { ...item, isActive: false }
		})
		setShowImg(isSelectedImg)
		return setImg(newValue)
	}, [img])

	const changeFont = useCallback((e) => {
		const selectedFont = e.target.name
		const isSelectedFont = e.target.name === 'regular'
		const newValue     = fontSize.map(item => {
			if (item.name === selectedFont) {
				return { ...item, isActive: true }
			}
			return { ...item, isActive: false }
		})
		setFontSize(isSelectedFont)
		return setFontSizes(newValue)
	}, [fontSize])

	const changeKerning = useCallback((e) => {
		const selectedKerning = e.target.name
		const isSelectedKerning = e.target.name === 'regular'
		const newValue        = kerning.map(item => {
			if (item.name === selectedKerning) {
				return { ...item, isActive: true }
			}
			return { ...item, isActive: false }
		})
		setFontKerning(isSelectedKerning)
		return setKerning(newValue)
	}, [kerning])

    const closePanel = () => {
		setIsShow(false)
	}

	return (
		<div className={styles.wrapperPanel}>
			<div className={styles.section}>
				<span>Цвет Сайта</span>
				<div className={styles.buttonWrapper}>
					{background.map(item =>
						<button
							key={item.name}
							type='button'
							onClick={changeBackground}
							name={item.name}
							className={`${styles.section_background} ${styles.button}`}
						>
							{item.text}
						</button>,
					)}
				</div>
			</div>
			<div className={styles.section}>
				<span>Изображения</span>
				<div className={styles.buttonWrapper}>
					{img.map(item =>
						<button
							key={item.name}
							type='button'
							onClick={changeImg}
							name={item.name}
							className={`${styles.button} ${item.isActive && styles.active}`}
						>
							{item.text}
						</button>,
					)}
				</div>
			</div>
			<div className={styles.section}>
				<span>Размер шрифта</span>
				<div className={styles.buttonWrapper}>
					{fontSize.map(item =>
						<button
							key={item.name}
							type='button'
							onClick={changeFont}
							name={item.name}
							className={`${styles.button} ${item.isActive && styles.active}`}
						>
							{item.text}
						</button>,
					)}
				</div>
			</div>
			<div className={styles.section}>
				<span>Межбуквенный интервал</span>
				<div className={styles.buttonWrapper}>
					{kerning.map(item =>
						<button
							key={item.name}
							type='button'
							onClick={changeKerning}
							name={item.name}
							className={`${styles.button} ${item.isActive && styles.active}`}
						>
							{item.text}
						</button>,
					)}
				</div>
			</div>
			<div className={styles.section}>
				<div className={styles.buttonWrapper}>
						<button
							type='button'
							onClick={closePanel}
							className={`${styles.section_background} ${styles.button}`}
						>
							Закрыть
						</button>
				</div>
			</div>
		</div>
	)
}
