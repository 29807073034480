import Icon from 'assets/icomoon/Icon'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render product couner
 *
 * @param {number} count
 * @param {Function} setCount
 *
 * @return {*}
 * @constructor
 */
export const ProductCount = ({ count, setCount }) => (
	<div className={styles.count}>
		<div className={styles.title}>Количество:</div>
		<div className={styles.group}>
			<button
				type='button'
				onClick={() => count > 1 && setCount(count - 1)}
				className={[styles.button, styles.decrease].join(' ')}
			>
				<Icon icon='product-minus' size={9} />
			</button>

			<span className={styles.input}>{count}</span>

			<button
				type='button'
				onClick={() => setCount(count + 1)}
				className={[styles.button, styles.increase].join(' ')}
			>
				<Icon icon='product-plus' size={9} />
			</button>
		</div>
	</div>
)

ProductCount.propTypes = {
	count:    PropTypes.number,
	setCount: PropTypes.func,
}

ProductCount.defaultProps = {
	count:    1,
	setCount: () => null,
}
