import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render user add card hidden form
 *
 * @param {Object} form
 * @param {Object} paReq
 * @param {Object} md
 * @param {Object} termURL
 *
 * @return {*}
 * @constructor
 */
export const UserAddCardHiddenForm = ({ formRefs: { form, paReq, md, termURL } }) => {
	const inputs = [
		{ id: 'PaReq', ref: paReq },
		{ id: 'MD', ref: md },
		{ id: 'TermUrl', ref: termURL },
	]

	return (
		<form ref={form} name="downloadForm" method="POST">
			{inputs.map(input => (
				<input
					key={input.id}
					ref={input.ref}
					name={input.id}
					type="hidden"
				/>
			))}
		</form>
	)
}

UserAddCardHiddenForm.propTypes = {
	formRefs: PropTypes.object,
}

UserAddCardHiddenForm.defaultProps = {
	formRefs: {},
}
