import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.scss'

export const BreadCrumb = ({ crumb, isLast }) => (
	(isLast || !crumb.path) ?
		<span className={styles.last}>
			{crumb.title}
		</span> :
		<Link className={styles.link} to={crumb.path}>
			{crumb.title}
		</Link>
)

BreadCrumb.propTypes = {
	crumb:  PropTypes.object,
	isLast: PropTypes.bool,
}

BreadCrumb.defaultProps = {
	crumb:  {},
	isLast: false,
}
