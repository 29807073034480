import { CityField } from 'components/Form/fields/City/index'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps = state => ({
	cityState:       StateSelector.cities.view(state),
	searchListState: StateSelector.cities.searchList(state),
})

const mapDispatchToProps = {
	getSearchList: DispatchSelector.cities.searchList,
	getCity:       DispatchSelector.cities.view,
}

export default connect(mapStateToProps, mapDispatchToProps)(CityField)
