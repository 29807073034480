import {
	OrdersListStore,
	PaymentFormStore,
} from 'containers/User'
import React from 'react'
import { FavoritesPageStore } from '../../Favorites/index.store'
import { ProfileEditStore } from '../../UserData/index.store'
import { DiscountFromStore } from '../../Discount/index.store'

export const navTabs = [
	{ alias: 'profile', component: <ProfileEditStore /> },
	{ alias: 'favorite', component: <FavoritesPageStore /> },
	{ alias: 'history', component: <OrdersListStore /> },
	{ alias: 'payment', component: <PaymentFormStore /> },
	{ alias: 'discount', component: <DiscountFromStore /> }
]
