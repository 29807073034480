import React from 'react'
import styles from './styles.scss'

/**
 * Render powerCard loyalty program detail
 *
 *
 * @return {*}
 * @constructor
 */
export const PowerDetail = () => (
	<div className={styles.detailWrapper}>
		<p>
			<img src={require('./media/powerCard.jpg')} alt="" />
			Дисконтная программа «Моцная картка» - это комплексная программа лояльности,
			в рамках которой держатели банковских платежных карточек с размещенным на
			них логотипом «Моцная картка» получают скидки и дополнительные бонусные
			предложения от предприятий-участников.
		</p>
		<p>
			ОАО «ГУМ» является участником дисконтной программы «Моцная картка».
		</p>
		<div className={styles.detailCardImg}>
			<img src={require('./media/powerCard1.jpg')} alt="" />
			<img src={require('./media/powerCard2.jpg')} alt="" />
		</div>
		<div className={styles.detailList}>
			<strong>Правила использования дисконтной карты «МОЦНАЯ КАРТКА»</strong>
			<ol>
				<li>
					Дисконтная программа действует в универмаге в секциях
					непродовольственных товаров (пр.Независимости,21; ул.Ленина 4,6,8)
					*. Объекты общественного питания ОАО «ГУМ» не участвуют в дисконтной программе.
				</li>
				<li>
					Дисконтная карта предъявляется владельцем на кассе перед оплатой покупки.
				</li>
				<li>
					Скидка предоставляется путем считывания штрих-кода с оборотной стороны карты.
				</li>
				<li>
					Скидка предоставляется на товары, розничная цена которых свыше (за 1 шт., 1 м.) 5,0 руб.
				</li>
				<li>
					Размер скидки составляет:
					<ul>
						<li>3% - без прохождения регистрации в электронной базе владельцев карт</li>
						<li>
							дифференцированный размер скидок в зависимости от накопленной
							суммы покупок - при условии регистрации участников дисконтной
							программы в электронной базе данных ОАО «ГУМ»:
						</li>
					</ul>
					<table className={styles.detailTable}>
						<thead>
							<tr>
								<th>накопленная сумма покупок, руб.</th>
								<th>размер скидки</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>0 руб. 00 коп. (0 руб.) - 499 руб. 99 коп.</td>
								<td>3%</td>
							</tr>
							<tr>
								<td>500 руб. 00 коп. – 1 499 руб. 99 коп.</td>
								<td>5%</td>
							</tr>
							<tr>
								<td>1 500 руб. 00 коп. и выше</td>
								<td>7%</td>
							</tr>
						</tbody>
					</table>
				</li>
				<li>
					Регистрация участников дисконтной программы «Моцная картка»
					производится в Инфоцентре универмага (г.Минск, пр.Независимости, 21)
					на основании заполненной&nbsp;
					<a href={require('./media/anketa_mocnaya_kartka.doc')} target="_blank" rel="noopener noreferrer">
						«Анкеты»
					</a>
					.
				</li>
				<li>
					Условия и размер скидок, предоставляемых другими участниками
					Дисконтной программы, определяются самостоятельно каждым предприятием
					и может со временем изменяться. С актуальной информацией о работе
					дисконтной программы «Моцная картка» можно ознакомиться на сайте программы&nbsp;
					<a href="http://www.bestcard.by/" target="_blank" rel="noopener noreferrer">
						www.bestcard.by
					</a>
				</li>
			</ol>
		</div>
		<div className={styles.detailList}>
			<strong>Какие группы товаров не участвуют в дисконтной программе?</strong>
			<ul>
				<strong>Скидка по дисконтной карте не предоставляется:</strong>
				<li>
					на товары, участие которых в акциях ограничено локальными актами ОАО «ГУМ»,
					в том числе продукция ОАО &quot;Минский часовой завод&quot;, а также холодильники,
					морозильники, стиральные машины;
				</li>
				<li>
					на товары, участвующие в специальных, сезонных акциях;
				</li>
				<li>
					на товары по сниженным ценам в результате уценки;
				</li>
				<li>
					на изделия медицинского назначения и медицинскую технику;
				</li>
				<li>
					на услуги, оказываемые в универмаге;
				</li>
				<li>
					на стоимость дисконтных карт ОАО «ГУМ»;
				</li>
				<li>
					при продаже подарочных сертификатов ОАО «ГУМ»;
				</li>
				<li>
					при продаже товаров в кредит;
				</li>
				<li>
					при продаже товаров по картам «Халва», «Smart карта», «Карта покупок», «Магнит»;
				</li>
				<li>
					на конфискованный товар;
				</li>
				<li>
					при покупке товаров юридическими лицами по безналичному расчету;
				</li>
				<strong>Суммирование нескольких видов скидок не производится.</strong>
			</ul>
		</div>
		<p>
			Дисконтные карты в рамках программы «Моцная картка» могут
			использоваться только в соответствии с&nbsp;
			<a href={require('./media/poldisc_mocnaya_kartka.doc')} target="_blank" rel="noopener noreferrer">
				Положением об обращении дисконтных карт в ОАО &quot;ГУМ&quot;
			</a>
		</p>
	</div>
)
