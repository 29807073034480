import { Search } from 'components/home/Header/Search/index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from 'components/home/Header/Search/index.props'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

const SearchStore = injectIntl(connect(mapStateToProps, mapDispatchToProps)(Search))

export { SearchStore }
