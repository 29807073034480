import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	cartListState: StateSelector.cart.list(state),
})
const mapDispatchToProps = {
	getCartList:       DispatchSelector.cart.getList,
	updateCartProduct: DispatchSelector.cart.update,
	deleteCartProduct: DispatchSelector.cart.delete,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
