import { NavItem } from 'components/home/Header/Navigation/NavItem'
import styles from 'components/home/Header/Navigation/styles.scss'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

/**
 * Render hidden nav items
 *
 * @param {boolean} isExist
 * @param {Function} setIsBackdrop
 *
 * @return {*}
 * @constructor
 */
export const HiddenNavItems = ({ items, setIsBackdrop, hierarchical }) => {
	const [isActive, setIsActive] = useState(false)

	const toggle = nextValue => {
		setIsBackdrop(nextValue)
		setIsActive(nextValue)
	}

	return (
		!!items.length &&
		<div
			onMouseEnter={() => toggle(true)}
			onMouseLeave={() => toggle(false)}
			className={[styles.link, styles.more].join(' ')}
		>
			Ещё
			<div className={[styles.dropdown, !isActive && styles.hidden].join(' ')}>
				{items.map((category, idx, array) => (
					// <Link
					// 	key={category.getId()}
					// 	to={category.getAlias()}
					// 	onClick={() => toggle(false)}
					// 	className={styles.link}
					// >
					// 	{category.getTitle()}
					// </Link>
					<NavItem
						key={category.getId()}
						category={category}
						hierarchical={hierarchical}
						setIsBackdrop={setIsBackdrop}
						isLeftRender={Math.ceil(array.length / 2) < idx}
					/>
				))}
			</div>
		</div>
	)
}

HiddenNavItems.propTypes = {
	items:         PropTypes.array,
	setIsBackdrop: PropTypes.func,
	hierarchical:  PropTypes.object,
}

HiddenNavItems.defaultProps = {
	items:         [],
	setIsBackdrop: () => null,
	hierarchical:  {},
}
