import {
	useEffect,
	useRef,
} from 'react'

/**
 * React hook to use previous value
 *
 * @param {*} value
 *
 * @returns {undefined}
 */
export const usePrevious = value => {
	const ref = useRef()

	useEffect(() => {
		ref.current = value
	}, [value])

	return ref.current
}
