/* eslint-disable react-hooks/exhaustive-deps */
import { Category } from '@kakadu-dev/base-frontend-components'
// import ComponentHelper from '@kakadu-dev/base-frontend-helpers/helpers/ComponentHelper'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import SSRComponentHelper from '@kakadu-dev/base-frontend-helpers/helpers/SSRComponentHelper'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import {
	CatalogSection,
	PageHeading,
} from 'components/global'
import { BlankButton } from 'components/global/BlankButton'
import { useBreadcrumbs } from 'components/global/useBreadcrumbs'
// import { usePrevious } from 'components/global/usePrevious'
import { ProductCardStore } from 'components/market/ProductCard/index.store'
// import FilterPlaceholder from 'components/utils/placeholders/filter-block'
import { ProductCardPlaceholder } from 'components/utils/placeholders/product-card'
import {
	DISABLE_INITIAL_PROPS,
	META_ENTITY_TYPE,
} from 'constants'
import { Error } from 'containers/Static/Error'
import ErrorHelper from 'helpers/ErrorHelper'
import _ from 'lodash'
import { Product } from 'model/Product'
import * as PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { isMobile as mobile } from "react-device-detect"
import {
	Link,
	useParams,
} from 'react-router-dom'
import {
	Col,
	Container,
	Row,
} from 'reactstrap'
import { MetaService } from 'services/MetaService'
import { mapDispatchToProps } from './index.props'
import styles from './styles.scss'

// Enable get meta data
const setMetaInfo = (categoryId, title) => MetaService.setInfo({
	entity:   META_ENTITY_TYPE.CATEGORY,
	entityId: categoryId,
}, { title })

/**
 * Render categories list
 *
 * @param {boolean} isFetching
 * @param {object} hierarchicalState
 * @param {Object} productsState
 * @param {Function} getProducts
 *
 * @returns {*}
 * @constructor
 */
export const CategoriesList = ({
	isFetching,
	hierarchicalState,
	productsState,
	getProducts,
}) => {
	const [isError, setIsError] = useState(false)
	const [activeGroupId, setActiveGroupId] = useState([])
	const [isMobile, setIsMobile]             = useState(mobile)
	const ref = useRef()

	const { type } = useParams()
	const productsService = useMemo(() => {
		const list = Object.values(productsState.result || {}).map(e => ({ ...e }))
		return StateService.create({result: { list }})
	}, [productsState])
	const menu = _.find(hierarchicalState.result)

	const category = useMemo(() => Category.create(menu?.childrens?.find?.(el => el.alias === type)), [type])
	const allChildrens = useMemo(() => category.getChildrens(), [category])
	const groups = useMemo(() => category.getGroups(), [category])

	const leftMenu = useMemo(() => {
		const childrens = Object.values(category?.getChildrens() || {}) || []
		const result = []

		groups.map(group => {
			const { categoriesIds, id, title } = group
			const categories = childrens?.filter(el => categoriesIds.includes(parseInt(el.id, 10)))
			const obj = { id, title, categories }
			if (categories.length > 0) result.push(obj)
		})

		return result
	}, [category, groups])

	const checkIsMobile = useCallback(() => {
		if (window.innerWidth <= 768 && !isMobile || window.innerWidth > 768 && isMobile) {
			setIsMobile(!isMobile)
		}
	}, [isMobile])

	useEffect(() => {
		checkIsMobile()
		window.addEventListener('resize', checkIsMobile)

		return () => {
			window.removeEventListener('resize', checkIsMobile)
		}
	}, [checkIsMobile])

	useEffect(() => {
		let categoriesIds = []
		if (leftMenu.length) {
			const currentGroup = leftMenu.find(e => e.id === activeGroupId)
			const categories = currentGroup?.categories?.slice?.(0, 10) || []
			categoriesIds = categories?.map(el => parseInt(el.id, 10) ) || []
		}
		else {
			categoriesIds = allChildrens?.map?.(el => el.id) || []
		}

		const searchQuery = DataProvider
			.buildQuery()
			.addBody({
				categoriesIds,
				limit: 3,
				query: {
					expands: [
						'categories.category.allParents',
						'minPrices',
						'options',
						'isFavorite',
						'offers',
					],
				}
			})

		if (categoriesIds.length) {
			getProducts(searchQuery)
		}
		setMetaInfo(category.getId(), category.getTitle())
	}, [category, activeGroupId, groups, leftMenu, getProducts, allChildrens])

	useEffect(() => {
		setActiveGroupId(groups?.[0]?.id)
	}, [groups])

	const setId = useCallback(id => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
		setActiveGroupId(id)
	}, [setActiveGroupId, ref])

	const breadcrumbs = useBreadcrumbs(category.getId())

	return (
		!isError ?
			<section className={styles.market}>
				<PageHeading
					title={category.getTitle()}
					breadcrumbs={breadcrumbs}
				/>
				<Container>
					<Row>
						<Col xs={12} md={3} className={styles.filters}>
							{leftMenu.length ?
								<>
									{
										leftMenu.map(group => {
											const isActive = activeGroupId === group.id
											return (
												<div key={group.id}>
													<BlankButton
														onClick={() => setId(group.id)}
														className={[styles.buttonGroup, !isActive && styles.active].join(' ')}
													>
														{group.title}
													</BlankButton>
													<div className={[styles.section, !isActive && styles.active].join(' ')}>
														{group.categories.map(_category => (
															<Link
																key={_category.alias}
																to={`${type}/${_category.alias}`}
																className={styles.category}
															>
																{_category.title}
															</Link>
														))}
													</div>
												</div>
											)}
										)
									}
								</> :
								<>
									{
										allChildrens?.map?.(childrenCategory => (
											<Link
												key={childrenCategory.alias}
												to={`${type}/${childrenCategory.alias}`}
												className={styles.category}
											>
												{childrenCategory.title}
											</Link>
										))
									}
								</>
							}
						</Col>
						{!isMobile &&
						<Col xs={12} md={9}>
							{!isFetching ?
								productsService.getList().map(productsList => {
									const childCategory = Category.create(productsList.category)
									const products      = Product.createList(productsList.products)
									const categoryLink  = `${type}/${childCategory.getAlias()}`

									return (
										!!products.length &&
										<CatalogSection
											key={childCategory.getAlias()}
											title={childCategory.getTitle()}
											category={category.getTitle()}
											categoryLink={categoryLink}
											subsectionLink={type}
											fetching={isFetching}
										>
											<Row className={styles.row}>
												{products.map(product => (
													<ProductCardStore
														link={`${categoryLink}/${product.getLinkPath()}`}
														key={product.getId()}
														product={product}
													/>
												))}
											</Row>
										</CatalogSection>
									)
								}) :
								<CatalogSection
									title={''}
									category={''}
									categoryLink={''}
									subsectionLink={type}
									fetching={isFetching}
								>
									<Row className={styles.row}>
										<ProductCardPlaceholder count={6} />
									</Row>
								</CatalogSection>
							}
						</Col>}
					</Row>
				</Container>
			</section> : <Error />
	)
}

CategoriesList.getInitialProps = SSRComponentHelper.initialProps(async (/* dispatchers, ctx */) => {
	// const { getCategories, getProducts }  = dispatchers
	// const { match: { params: { type } } } = ctx

	try {
		// const response = await getCategories(getCategoryQuery(type))

		// const category = Category.create(response.result?.list?.[0])

		// if (category.isExist()) {
		// 	await getProducts(category.getChildCategories().slice(0, 4)
		// 		.map(children => getProductsQuery(children.getId())))
		// }
	} catch (e) {
		ErrorHelper.log(e)
	}
}, mapDispatchToProps, DISABLE_INITIAL_PROPS)

CategoriesList.propTypes = {
	isFetching:        PropTypes.bool,
	hierarchicalState: PropTypes.object,
	productsState:     PropTypes.object,
	getProducts:       PropTypes.func,
}

CategoriesList.defaultProps = {
	isFetching:        false,
	hierarchicalState: {},
	productsState:     {},
	getProducts:       () => null,
}
