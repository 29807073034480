import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	userState: StateSelector.user.getUser(state),
})
const mapDispatchToProps = {
	signIn: DispatchSelector.auth.signIn,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
