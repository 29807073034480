import { City } from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { TextField } from '@material-ui/core'
import { SearchResult } from 'components/Form/fields/City/SearchList'
import styles from 'components/Form/fields/City/styles.scss'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

const getCityQuery = id => DataProvider
	.buildQuery()
	.addBody({ id }, true)

const getCitiesQuery = searchString => DataProvider
	.buildQuery()
	.addBody({ query: { filter: { title: { like: `%${searchString}%` } } } }, true)

/**
 * Render city field
 *
 * @param {Object} searchListState
 * @param {Function} getSearchList
 * @param {Object} cityState
 * @param {Function} getCity
 * @param {Object} field
 * @param {number} value
 * @param {Function} handler
 * @param {boolean} required
 *
 * @return {*}
 * @constructor
 */
export const CityField = ({
	cityState,
	getCity,
	searchListState,
	getSearchList,
	field,
	value,
	handler,
	required,
}) => {
	const [inputValue, setInputValue]                   = useState('')
	const [isSearchListVisible, setIsSearchListVisible] = useState(false)

	const containerRef = useRef()

	useCheckOutsideClick(containerRef, isSearchListVisible, setIsSearchListVisible)

	const { id, title } = field

	const cities = City.createList(searchListState)
	const city   = useMemo(() => City.create(cityState), [cityState])

	useEffect(() => {
		if (value) {
			getCity(getCityQuery(value))
		}
	}, [getCity, value])

	useEffect(() => {
		if (city.isExist()) {
			setInputValue(city.getTitle())
		}
	}, [city])

	const getCitiesList = _.debounce(searchString => getSearchList(getCitiesQuery(searchString)), 400)

	const inputHandler = useCallback(searchString => {
		setInputValue(searchString)
		getCitiesList(searchString)
	}, [getCitiesList])

	const selectCity = useCallback(cityModel => {
		setIsSearchListVisible(false)
		const event = {
			target: {
				name: id,
				value: cityModel.getId(),
			}
		}
		handler(event)
	}, [handler, id])

	return (
		<div className={styles.container} ref={containerRef}>
			<TextField
				id={id}
				name={id}
				label={title}
				value={inputValue}
				onFocus={() => setIsSearchListVisible(true)}
				onChange={event => inputHandler(event.target.value)}
				autoComplete="off"
				size="small"
				variant="outlined"
				required={required}
			/>
			{isSearchListVisible && !!cities.length && !!inputValue.length &&
			 <SearchResult action={selectCity} list={cities} />}
		</div>
	)
}

CityField.propTypes = {
	cityState:       PropTypes.object,
	field:           PropTypes.object,
	getCity:         PropTypes.func,
	getSearchList:   PropTypes.func,
	handler:         PropTypes.func,
	searchListState: PropTypes.object,
	value:           PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	required:        PropTypes.bool,
}

CityField.defaultProps = {
	cityState:       {},
	field:           {},
	getCity:         () => null,
	getSearchList:   () => null,
	handler:         () => null,
	searchListState: {},
	value:           null,
	required:        false,
}
