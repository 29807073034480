import img1 from './media/01.jpg'
import img10 from './media/010.jpg'
import img11 from './media/011.jpg'
import img2 from './media/02.jpg'
import img3 from './media/03.jpg'
import img4 from './media/04.jpg'
import img5 from './media/05.jpg'
import img6 from './media/06.jpg'
import img7 from './media/07.jpg'
import img8 from './media/08.jpg'
import img9 from './media/09.jpg'

export const ourProductsImgs = [
	{ id: 1, image: img1, title: 'БУЛОЧКА АРОМАТНАЯ С КОРИЦЕЙ' },
	{ id: 2, image: img2, title: 'БУЛОЧКА ОРИГИНАЛЬНАЯ С ШОКОЛАДНО-РОМОВОЙ НАЧИНКОЙ' },
	{ id: 3, image: img3, title: 'БУЛОЧКА С МАКОМ' },
	{ id: 4, image: img4, title: 'БУЛОЧКА СНЕЖИНКА' },
	{ id: 5, image: img5, title: 'КЕКС СТОЛИЧНЫЙ' },
	{ id: 6, image: img6, title: 'ЛЕПЕШКА ЧЕСНОЧНАЯ' },
	{ id: 7, image: img7, title: 'ПИРОГ ОТКРЫТЫЙ С ЯГОДАМИ' },
	{ id: 8, image: img8, title: 'ПОЛОСКА ПЕСОЧНАЯ  С ПОВИДЛОМ' },
	{ id: 9, image: img9, title: 'СДОБА ТВОРОЖНО - МАКОВАЯ' },
	{ id: 10, image: img10, title: 'СЛАДОСТЬ МУЧНАЯ СЫРНАЯ' },
	{ id: 11, image: img11, title: 'СОЧНИК БРЕСТСКИЙ' },
]
