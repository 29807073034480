import PropTypes from 'prop-types'
import React, {
	useEffect,
	useRef,
} from 'react'

/**
 * Render collapse height component
 *
 * @param {boolean} isActive
 * @param {Node} children
 * @param {string} className
 *
 * @return {*}
 * @constructor
 */
export const Collapse = ({ isActive, children, className }) => {
	const bodyRef = useRef()

	useEffect(() => {
		bodyRef.current.style.height = `${isActive ? bodyRef.current.scrollHeight : 0}px`
	}, [isActive])

	return (
		<div ref={bodyRef} className={className}>
			{children}
		</div>
	)
}

Collapse.propTypes = {
	children:  PropTypes.node,
	className: PropTypes.string,
	isActive:  PropTypes.bool,
}

Collapse.defaultProps = {
	children:  {},
	className: '',
	isActive:  false,
}
