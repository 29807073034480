import { NormalizeApi } from '@kakadu-dev/base-frontend-components/lib/modules/normalizeApi'

const BASE_USERS_ENDPOINT = 'users.actions'
const BASE_AUTH_ENDPOINT  = 'authentication'

export const AuthApi = NormalizeApi({
	signIn:     `${BASE_USERS_ENDPOINT}.sign-in`,
	logOut:     (sQ, next) => {
		sQ.addRequestOptions({
			headers: {
				// Don`t need result, async call without await result
				type: 'async',
			},
		})
		return next(`${BASE_USERS_ENDPOINT}.logout`)
	},
	renewToken: `${BASE_AUTH_ENDPOINT}.jwt.renew`,
})
