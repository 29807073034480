import React, {
	useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import styles from './styles.scss'
import Icon from 'assets/icomoon/Icon'

export const Lang = ({ intlState, changeLang, setIsShow, isShowPanel }) => {

	const { locale } = intlState
	const { push } = useHistory()
	const onClick = useCallback(event => {
		const { target: { id }} = event
		changeLang(id)
		push('/')
	}, [changeLang])

	const  isShow =(show)=> {
		setIsShow(show)
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.wrapperLang}>
				<div
					onClick={onClick}
					id="ru"
					className={[styles.item, (locale === 'ru') ? styles.active : null].join(' ')}
					role='button'
					tabIndex='0'
				>
					RUS
				</div>
				<div
					onClick={onClick}
					id="by"
					className={[styles.item, (locale === 'by') ? styles.active : null].join(' ')}
					role='button'
					tabIndex='0'
				>
					BEL
				</div>
			</div>
			<div className={styles.eyeless}>
				{
					isShowPanel ?
						<span onClick={()=>isShow(false)}>Обычная версия сайта</span>
						:
						<span onClick={()=>isShow(true)}><Icon icon='eyeglasses' size={35} /></span>
				}
			</div>
		</div>
	)
}

Lang.propTypes = {
	intlState:   PropTypes.object,
	isShowPanel: PropTypes.bool,
	setIsShow:   PropTypes.func,
	changeLang:  PropTypes.func,
}
Lang.defaultProps = {
	intlState:   {},
	isShowPanel: false,
	setIsShow:   ()=>{},
	changeLang:  ()=>{},
}
