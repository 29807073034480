import firebase from '@kakadu-dev/base-frontend-helpers/firebase'
import { FirebaseMessaging } from '@kakadu-dev/base-frontend-helpers/firebase/messaging'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import {
	call,
	put,
	select,
	takeLatest,
} from 'redux-saga/effects'
import {
	getJwtRefreshToken,
	removeAuthTokens,
	saveAuthTokens,
} from '@kakadu-dev/base-frontend-components/lib/api'
import { AuthorizationApi } from '@kakadu-dev/base-frontend-components/lib/modules/authorization/actions'
import {
	DispatchSelector,
	StateSelector,
} from '../selectors'
import { AUTH_ACTION } from './actionTypes'
import { AuthApi } from './api'

/**
 * Sign in firebase (google/facebook/vk/phone)
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* fetchSignIn(action)
{
	const { payload, type }           = action
	const { request, success, error } = RequestActionHelper.getAllActions(type)

	const saveUser    = DispatchSelector.user.setUser
	const searchQuery = DataProvider.getSearchQueryBody(payload)

	try {
		yield put(request(searchQuery))

		const response = yield call(AuthApi.signIn, searchQuery)

		const customParams = searchQuery.getCustomParams()
		const bodyParams   = searchQuery.getBody()

		// Check response auth tokens
		if (!response?.result?.payload?.jwtAccess) {
			throw new Error('Ошибка авторизации. Отсутствуют токены')
		}

		yield saveAuthTokens(null, response.result.payload)

		const user           = response?.result?.user ?? {}
		let userRole         = null
		let hasAuthorization = true

		try {
			const userRoleQuery = DataProvider
				.buildQuery()
				.addBody({ userId: user?.id })

			userRole = yield call(AuthorizationApi.getUserRole, userRoleQuery)
		} catch (e) {
			// Skip this error if authorization service not found
			if (e?.code !== 5 || e?.status !== 2) {
				// Authorization service attached to project, throw original error
				throw e
			}
			// Authorization service not attached to project.
			hasAuthorization = false
		}
		user.role = userRole?.result?.role ?? 'user'

		yield put(success({
			provider:     bodyParams.provider,
			realProvider: customParams.realProvider || bodyParams.provider,
			hasAuthorization,
		}, searchQuery))
		yield put(saveUser(user))

		try {
			const firebasePushToken = yield FirebaseMessaging.getInstance().getUserToken()

			if (firebasePushToken) {
				// @TODO
				// yield put(UsersActions.updatePushToken(firebasePushToken))
			}
		}
		catch (e) {
			console.log(e)
		}

		searchQuery.runSuccessCallback(response)
	} catch (e) {
		yield put(error(searchQuery.addReduxRequestParams({ error: e })))

		firebase.auth().signOut().catch(() => {
			// Skip firebase logout error
			// Dont` show unhandled promise rejection
		})
		yield removeAuthTokens()

		searchQuery.runErrorCallback(e)
	}

	searchQuery.runCallback()
}

/**
 * Log out (google/facebook/vk/phone)
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* fetchLogOut(action)
{
	const { payload, type }           = action
	const { request, success, error } = RequestActionHelper.getAllActions(type)

	const removeUser  = DispatchSelector.user.setUser
	const removeAuth  = DispatchSelector.auth.setSignIn
	const searchQuery = DataProvider.getSearchQueryBody(payload)

	try {
		const auth = yield select(StateSelector.auth.signIn)

		yield put(request(searchQuery))

		firebase.auth().signOut().catch(() => {
			// Skip firebase logout error
			// Dont` show unhandled promise rejection
		})

		yield put(success())
		yield put(removeUser(null))
		yield put(removeAuth(null))

		yield removeAuthTokens()

		// Don`t need result
		const response = yield call(AuthApi.logOut, searchQuery)

		searchQuery.runSuccessCallback(response, auth)
	} catch (e) {
		// Remove auth tokens
		yield removeAuthTokens()

		yield put(error(searchQuery.addReduxRequestParams({ error: e })))

		searchQuery.runErrorCallback(e)
	}

	searchQuery.runCallback()
}

/**
 * Renew auth tokens
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* renewToken(action)
{
	const { payload, type }           = action
	const { request, success, error } = RequestActionHelper.getAllActions(type)
	const searchQuery                 = DataProvider
		.getSearchQueryBody(payload)
		.addRequestOptions({
			headers: {
				'Authorization-Refresh': yield getJwtRefreshToken(),
			},
		}, true)

	try {
		yield put(request(searchQuery))

		const response = yield call(AuthApi.renewToken, searchQuery)

		// Check response headers
		if (!response?.result?.jwtAccess) {
			throw new Error('Ошибка обновления аутентификации. Отсутствуют токены')
		}

		yield saveAuthTokens(null, response.result)

		yield put(success())

		searchQuery.runSuccessCallback(response)
	} catch (e) {
		yield put(error(searchQuery.addReduxRequestParams({ error: e })))

		searchQuery.runErrorCallback(e)
	}

	searchQuery.runCallback()
}

export default [
	takeLatest(AUTH_ACTION.SIGN_IN, fetchSignIn),
	takeLatest(AUTH_ACTION.LOG_OUT, fetchLogOut),
	takeLatest(AUTH_ACTION.RENEW_TOKEN, renewToken),
]
