import { MaterialIcon } from 'components/global'
import { BlankButton } from 'components/global/BlankButton'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import { FilterStringList } from 'components/market/ProductsList/FiltersList/FilterStringBlock/FilterStringList'
import PropTypes from 'prop-types'
import React, {
	useRef,
	useState,
} from 'react'
import {
	Col,
	Row,
} from 'reactstrap'
import styles from './styles.scss'

/**
 * Render string filter block
 *
 * @param {PropertyType} propertyType
 * @param {function} toggle
 *
 * @return {*}
 * @constructor
 */
export const FilterStringBlock = ({ propertyType, toggle }) => {
	const [isOpen, setIsOpen] = useState(false)

	const wrapperRef = useRef()

	useCheckOutsideClick(wrapperRef, isOpen, setIsOpen)

	const alias          = propertyType.getAlias()
	const visibleFilters = propertyType.getVisibleItems()
	const hiddenFilters  = propertyType.getHiddenItems()

	return (
		<>
			<p className={styles.title}>{propertyType.getName()}</p>

			<Row>
				<Col key={propertyType.getId()} xs="12" sm="6" md="12">
					<FilterStringList
						list={visibleFilters}
						alias={alias}
						handler={toggle}
					/>
				</Col>

				{hiddenFilters.length > 0 && (
					<Col className="d-flex">
						{hiddenFilters.length > 5 &&
						<BlankButton
							onClick={() => setIsOpen(!isOpen)}
							className={styles.button}
						>
							<p>{`Все ${hiddenFilters.length} вариантов`}</p>
							<MaterialIcon icon="keyboard_arrow_right" size={16} />
						</BlankButton>}
						<div className={isOpen ? styles.active : styles.hidden} ref={wrapperRef}>
							<div className={styles.popover_wrapper}>
								<FilterStringList
									list={hiddenFilters}
									alias={alias}
									handler={toggle}
									className={styles.popover__body}
								/>
							</div>
						</div>
					</Col>
				)}
			</Row>
		</>
	)
}

FilterStringBlock.propTypes = {
	propertyType: PropTypes.object,
	toggle:       PropTypes.func,
}

FilterStringBlock.defaultProps = {
	propertyType: {},
	toggle:       () => null,
}
