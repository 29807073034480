import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import {
	AvField,
	AvForm,
	AvGroup,
} from 'availity-reactstrap-validation'
import { Count } from 'components/static/onlineGiftCertificatesForm/Count'
import { Notification } from 'helpers/Notification'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useState,
} from 'react'
import { Label } from 'reactstrap'

import styles from './styles.scss'
import {data} from './data'

/**
 * Render online Gift Certificates form
 *
 * @param {Array} fields
 * @param fieldsTextarea
 * @param {string} view
 * @param {function} createAppeals
 *
 * @return {*}
 * @constructor
 */
export const OnlineGiftCertificatesForm = ({ fields, fieldsTextarea, view, createAppeals }) => {
	const [additionalInformation, setMessage] = useState('')
	const [total, setTotal]                   = useState(data)
	const [nominalValue, setNominalValue]     = useState({})
	const [clearValues, setClearValues]       = useState(false)

	const onSubmit = useCallback((event, errors, values) => {
		let error  = false
		const result = { ...values, ...nominalValue, additionalInformation }

		if (!errors.length && !error) {
			const searchQuery = DataProvider
				.buildQuery()
				.addBody({ view, ...result }, true)
				.setSuccessCallback(() => {
					Notification.add({
						title:   'Успех',
						message: 'Ваше электронное обращение успешно отправлено',
						type:    'success',
					})
					setClearValues(true)
					setMessage('')
					setTotal(data)
				})
				.setErrorCallback(() => {
					Notification.add({
						title:   'Ошибка',
						message: 'При отправке электронное обращения, произошла ошибка',
						type:    'warning',
					})
				})
			createAppeals(searchQuery)
		}
	},
	[ additionalInformation, createAppeals, view, nominalValue ])

	const onChange = useCallback(({ target: { value } }) => {
		setMessage(value)
	}, [])

	 const setCount = useCallback((event, count) => {
		const buttonName = event.target.name
		const newTotal = total.map(item => {
			if(buttonName === item.name){
				setNominalValue({...nominalValue, [item.name]: count})
				return {...item,  count}
			}
			return item
		})
		 setTotal(newTotal)
	 }, [total, nominalValue])

	return (
		<div className={styles.formWrapper}>
			<AvForm onSubmit={onSubmit}>
				{
					fields.map(item => (
						<AvGroup key={item.id}>
							<AvField
								required
								id={item.name}
								name={item.name}
								label={item.title}
								type={item.type}
								errorMessage="Необходимо заполнить это поле"
								key={clearValues}
							/>
						</AvGroup>
					))
				}
				<AvGroup className={styles.titleText}>
					<span>Выберите количество сертификатов:</span>
					{total.map(item => <Count key={item.name} setCount={setCount} item={item}  />)}
				</AvGroup>
				<AvGroup>
					<div className={styles.titleTextarea}>
						<Label for={fieldsTextarea.name}>{fieldsTextarea.title}</Label>
						<textarea
							className={[
								'is-untouched',
								'is-pristine',
								'av-invalid',
								'form-control',
							].join(' ')}
							onChange={onChange}
							name={fieldsTextarea.name}
							value={additionalInformation}
						/>
					</div>
				</AvGroup>
				<AvGroup>
					<button type="submit">Отправить/Адправіць</button>
				</AvGroup>
			</AvForm>
		</div>
	)
}
OnlineGiftCertificatesForm.propTypes    = {
	fields:        PropTypes.array,
	createAppeals: PropTypes.func,
	view:          PropTypes.string,
	fieldsTextarea: PropTypes.object,

}
OnlineGiftCertificatesForm.defaultProps = {
	fields:        [],
	createAppeals: () => null,
	view:          '',
	fieldsTextarea: {},
}
