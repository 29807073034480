import { AvGroup } from 'availity-reactstrap-validation'
import * as PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { FormText } from 'reactstrap'

/**
 * Inputs wrapper
 *
 * @param {object} props
 *
 * @return {*}
 * @constructor
 */
export const WrapView = props => {
	const {
			  ref,
			  ...newProps
		  } = props

	return (
		<div ref={ref}>
			<AvGroup {...newProps} />
		</div>
	)
}

WrapView.propTypes = {
	ref: PropTypes.func,
}

WrapView.defaultProps = {
	ref: () => null,
}

/**
 * Input helper
 *
 * @param {object} props
 *
 * @return {*}
 * @constructor
 */
export const HelperText = props => {
	const { text, type, visible } = props

	if (!visible) {
		return null
	}

	return (
		<Fragment>
			{type === 'error' && (
				<div className="invalid-feedback d-block">
					{text}
				</div>
			)}

			{type === 'info' && (
				<FormText color="muted">
					{text}
				</FormText>
			)}
		</Fragment>
	)
}

HelperText.propTypes = {
	text:    PropTypes.string,
	type:    PropTypes.string,
	visible: PropTypes.bool,
}

HelperText.defaultProps = {
	text:    '',
	type:    '',
	visible: false,
}
