import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	userState:         StateSelector.user.getUser(state),
	userCity:          StateSelector.user.getUserCity(state),
	countriesState:    StateSelector.countries.list(state),
	hierarchicalState: StateSelector.categories.getHierarchical(state),
	isShowPanel:       state.visionPanel.isShow,
	siteBackground:    state.visionPanel.siteBackground,
})
const mapDispatchToProps = {
	getCountries:    DispatchSelector.countries.list,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
