import React from 'react'
import { injectIntl } from 'react-intl'
import styles from './styles.scss'

/**
 * Render link to administrative procedures
 *
 * @return {*}
 * @constructor
 */
export const AdministrativeProcedures = injectIntl(({intl}) => (
	<a
		className={styles.link}
		href="https://cdn1-base.kakadu.bz/gum/static-pages/docs/perechen.doc"
		download
	>
		{intl.messages['general.administrative.procedures']}
	</a>
))

AdministrativeProcedures.propTypes = {}
