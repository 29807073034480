import {
	DispatchSelector,
	StateSelector,
} from '@kakadu-dev/base-frontend-components'

const mapStateSelector = (state) => ({
	categoriesState: StateSelector.categories.view(state),
})

const mapDispatchToProps = {
	getCategories: DispatchSelector.categories.view,
}

export {
	mapStateSelector,
	mapDispatchToProps,
}
