import React from 'react'
import ContentLoader from 'react-content-loader'

import styles from './styles.scss'

const CategoriesPlaceholder = () => (
	<div className={styles.categories}>
		<ContentLoader
			speed={1}
			width={1180}
			height={50}
			viewBox="0 0 1180 50"
			backgroundColor="#e9e9e9"
			foregroundColor="#f5f5f5"
			uniqueKey="categoriesPlaceholder"
		>
			<rect x="5" y="10" rx="5" ry="5" width="100" height="15" />
			<rect x="182" y="10" rx="5" ry="5" width="150" height="15" />
			<rect x="393" y="10" rx="5" ry="5" width="110" height="15" />
			<rect x="569" y="10" rx="5" ry="5" width="70" height="15" />
			<rect x="700" y="10" rx="5" ry="5" width="120" height="15" />
			<rect x="880" y="10" rx="5" ry="5" width="120" height="15" />
		</ContentLoader>
	</div>
)

export default CategoriesPlaceholder
