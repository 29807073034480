import { connect } from 'react-redux'
import { HomeSlider } from './index'
import {
	mapDispatchToProps,
	mapStateToProps
} from './index.props'

const HomeSliderInStore = connect(
	mapStateToProps,
	mapDispatchToProps
)(HomeSlider)

export { HomeSliderInStore }
