import React from 'react'
import styles from './styles.scss'

/**
 * Render detail MTB credit
 *
 *
 * @return {*}
 * @constructor
 */
export const MtbDetail = () => (
	<div className={styles.detailWrapper}>
		<div className={styles.detailListTitle}>Условия приобретения товаров в рассрочку:</div>
		<div className={styles.detailList}>
			<div className={styles.detailListTitle}>Срок рассрочки в ГУМе:</div>
			<ul>
				<li>1 месяц - при покупке продовольственных товаров.</li>
				<li>
					4 месяца - при покупке непродовольственных товаров на сумму до 500 руб. 00 коп.
					или изделий из драгоценных металлов и натуральных камней на сумму до 100 руб. 00 коп.
				</li>
				<li>
					5 месяцев - при покупке непродовольственных товаров на сумму свыше 500 руб. 00 коп.,
				</li>
				<li>
					9 месяцев - при покупке изделий из драгоценных металлов и натуральных камней
					на сумму свыше 100 руб. 00 коп.,
				</li>
				<li>
					12 месяцев - при покупке изделий из драгоценных металлов и натуральных
					камней на сумму свыше 1000 руб. 00 коп, а также изделий из натурального меха,
				</li>
			</ul>
		</div>
		<div className={styles.detailListTitle}>Срок рассрочки в магазинах «Щедрый»: 1 месяц</div>
		<div className={styles.detailList}>
			<div className={styles.detailListTitle}>«Халва» не принимается к оплате:</div>
			<ul>
				<li>
					при продаже подарочных сертификатов ОАО «ГУМ»,
				</li>
				<li>
					при продаже дисконтных карт ОАО «ГУМ»,
				</li>
				<li>
					при оплате взносов по кредиту ОАО «ГУМ»,
				</li>
				<li>
					при продаже конфискованного товара,
				</li>
				<li>
					за услуги, оказываемые ОАО «ГУМ».
				</li>
			</ul>
		</div>
		<p>
			<strong>Скидки </strong>
			на товары при покупке по карте «Халва»
			<strong> не предоставляются</strong>
			, за исключением специальных акций, в рамках которых предусмотрено предоставление скидок.
		</p>
		<p>
			Товары, приобретаемые по карте «Халва»,
			<strong> не участвуют в дисконтных программах </strong>
			, действующих в ОАО «ГУМ».
		</p>
		<div className={styles.detailList}>
			<div className={styles.detailListTitle}>Адреса магазинов:</div>
			<ul>
				<li>универмаг ГУМ (пр.Независимости,21),</li>
				<li>салон обуви ГУМа (ул.Ленина,8),</li>
				<li>отдел Mattioli (ул. Ленина,6),</li>
				<li>салон трикотажа «Mark Formelle» (ул. Ленина,4)</li>
				<li>секция «Продовольственные товары» в ГУМе (пр.Независимости,21),</li>
				<li>продовольственный магазин «Щедрый» №1 (ул. Орловская, 31),</li>
				<li>продовольственный магазин «Щедрый» №3 (Долгиновский тракт, 30),</li>
				<li>продовольственный магазин «Щедрый» №4 (ул. Орловская, 11),</li>
				<li>продовольственный магазин «Щедрый» №5 (ул. Каховская, 47),</li>
				<li>продовольственный магазин «Щедрый» №8 (ул.Коммунистическая, 5),</li>
				<li>продовольственный магазин «Щедрый» №10 (пр.Независимости,35).</li>
			</ul>
		</div>
	</div>
)
