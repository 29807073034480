import {
	applyMiddleware,
	combineReducers,
	createStore,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
// import { intlReducer } from './intl'
import intl from './intl/reducer'
import visionPanel from './modules/visionPanel/reducer'
import { Notifications } from './middlewares/Notifications'
import { allReducers } from './modules/redux'
import reducers from './reducers'
import {SagaManager} from './sagaManager'

export function configureStore(preloadedState) {
	const sagaMiddleware = createSagaMiddleware()

	const middlewares = [
		sagaMiddleware,
		Notifications,
	]

	const devTools = process.env.NODE_ENV === "production"
		? applyMiddleware(...middlewares)
		: composeWithDevTools(applyMiddleware(...middlewares))

	const store = createStore(
		combineReducers({
			...allReducers,
			project: reducers,
			intl,
			visionPanel,
		}),
		preloadedState,
		devTools
	)

	const persistor = persistStore(store)

	let sagaTask = sagaMiddleware.run(SagaManager)
	store.cancelSagas = () => {
		sagaTask.cancel()
	}

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('./reducers', () => {
			const nextRootReducer = require('./reducers')
			store.replaceReducer(nextRootReducer)
		})

		module.hot.accept(['./sagaManager'], () => {
			const NewSagaManager = require('./sagaManager').SagaManager
			sagaTask.cancel()
			sagaTask.toPromise().then(() => {
				sagaTask = sagaMiddleware.run(NewSagaManager)
			})
		})
	}

	return store
}
