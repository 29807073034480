import {
	CustomerAddressForm,
	DeliveryAddress,
} from '@kakadu-dev/base-frontend-components'

import styles from 'components/basket/UserDataForms/UserAddressForm/styles.scss'
import Form from 'components/Form'
import { IndependentFormStore } from 'components/Form/IndependentForm'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'

/**
 * Render user delivery address
 *
 * @param {Object} addressesList
 * @param {string} title
 * @param {Function} onSuccess
 * @param {Object} formRef
 *
 * @return {*}
 * @constructor
 */
export const AddressForm = ({ addressesList, title, onSuccess, formRef }) => {
	const address = DeliveryAddress.create(addressesList?.result?.list?.[0])
	const form    = CustomerAddressForm.create()

	const ref = !formRef ? useRef(null) : formRef

	return (
		<div className={styles.col}>
			<div className={styles.title}>{title}</div>
			<IndependentFormStore ref={ref} onSuccess={onSuccess}>
				<Form form={!address.isExist() ? form : form.setDefaultFromModel(address)} />
			</IndependentFormStore>
		</div>
	)
}

AddressForm.propTypes = {
	addressesList: PropTypes.object,
	formRef:       PropTypes.object,
	onSuccess:     PropTypes.func,
	title:         PropTypes.string,
}

AddressForm.defaultProps = {
	addressesList: {},
	formRef:       {},
	onSuccess:     () => null,
	title:         '',
}
