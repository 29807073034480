import Icon from 'assets/icomoon/Icon'
import { BlankButton } from 'components/global/BlankButton'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import { useStopScrolling } from 'components/global/useStopScrolling'
import styles from 'containers/Static/OurProduction/ImagesList/styles.scss'
import PropTypes from 'prop-types'
import React, {
	useRef,
	useState,
} from 'react'

/**
 * Render images list
 *
 * @param {Array} list
 *
 * @return {*}
 * @constructor
 */
export const ImagesList = ({ list }) => {
	const [previewImage, setPreviewImage] = useState(null)

	const imgRef = useRef()

	useCheckOutsideClick(imgRef, previewImage, setPreviewImage, previewImage)

	useStopScrolling(previewImage)

	return (
		<>
			<div className={styles.imagesWrapper}>
				{list.map(image => (
					<div key={image.id} className={styles.imageItem}>
						<BlankButton className={styles.image} onClick={() => setPreviewImage(image)}>
							<img src={image.image} alt="bakery" />
						</BlankButton>
						<div className={styles.title}>
							{image.title}
						</div>
					</div>
				))}
			</div>
			{previewImage &&
			 <div className={styles.preview}>
				 <BlankButton className={styles.closeBtn} onClick={() => setPreviewImage(null)}>
					 <Icon icon="close" size={25} />
				 </BlankButton>
				 <div className={styles.previewImage}>
					 <img src={previewImage.image} ref={imgRef} alt="preview-item" />
				 </div>
				 <div className={styles.previewTitle}>
					 {previewImage.title}
				 </div>
			 </div>}
		</>
	)
}

ImagesList.propTypes = {
	list: PropTypes.array,
}

ImagesList.defaultProps = {
	list: [],
}
