import CityField from 'components/Form/fields/City/index.store'
import React from 'react'

export const userAddressFields = [
	{ id: 'cityId', title: 'Населенный пункт', component: <CityField /> },
	{ id: 'street', title: 'Улица' },
	{ id: 'house', title: 'Дом' },
	{ id: 'block', title: 'Корпус' },
	{ id: 'apartment', title: 'Квартира' },
]
