import { Orders } from 'containers/User/Orders/index'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	userState:     StateSelector.user.getUser(state),
	ordersState:   StateSelector.orders.list(state),
	productsState: StateSelector.products.list(state),
})
const mapDispatchToProps = {
	getUser:     DispatchSelector.user.getUser,
	getOrders:   DispatchSelector.orders.list,
	getProducts: DispatchSelector.products.list,
}

export const OrdersListStore = connect(
	mapStateToProps,
	mapDispatchToProps)
(Orders)
