import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render simple point of pagination
 *
 * @param {number} point
 * @param {number} currentPage
 * @param {function} goToPage
 *
 * @return {*}
 * @constructor
 */
const SimplePoint = ({ point, currentPage, goToPage }) => {
	return (
		<div
			tabIndex={0}
			role={'button'}
			key={point}
			className={[styles.pageItem, currentPage === point && styles.active].join(' ')}
			onClick={() => currentPage !== point && goToPage(point)}
		>
			{point}
		</div>
	)
}

SimplePoint.propTypes = {
	currentPage: PropTypes.number,
	goToPage:    PropTypes.func,
	point:       PropTypes.number,
}

SimplePoint.defaultProps = {
	currentPage: 1,
	goToPage:    () => null,
	point:       1,
}

export { SimplePoint }

