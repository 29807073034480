import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import {
	AvField,
	AvForm,
	AvGroup,
} from 'availity-reactstrap-validation'
import { Notification } from 'helpers/Notification'
import Icon from '../../../assets/icomoon/Icon'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useState,
} from 'react'
import { Label } from 'reactstrap'
import styles from './styles.scss'

const isMessage = (value) => (value === 'message') ? 1 : 0

export const getBase64 = data =>
	new Promise(resolve => {
		const reader = new FileReader()

		reader.readAsDataURL(data)
		reader.onload = () => {
			resolve({
				name: data.name,
				type: data.type,
				base64: reader.result,
				size: data.size,
			})
		}
	})

/**
 * Render electronic appeals form
 *
 * @param {Array} fields
 * @param {string} view
 * @param {function} createAppeals
 *
 * @return {*}
 * @constructor
 */
export const ElectronicAppealsForm = ({ fields, view, createAppeals }) => {
	const [message, setMessage]             = useState('')
	const [attachments , setAttachment ] 	= useState([])
	const [textAreaError, setTextAreaError] = useState(false)
	const [clearValues, setClearValues]     = useState(false)
	const [sizeFiles, setSizeFiles]         = useState(0)

	const onSubmit = useCallback((event, errors, values) => {
		let error  = false
		if (message.length < 5) error = true
		const result = { ...values, message, attachments }
		setTextAreaError(error)

		if (!errors.length && !error) {
			const searchQuery = DataProvider
				.buildQuery()
				.addBody({ view, ...result }, true)
				.setSuccessCallback(() => {
					Notification.add({
						title:   'Успех',
						message: 'Ваше электронное обращение успешно отправлено',
						type:    'success',
					})
					setAttachment([])
					setMessage('')
					setClearValues(true)
				})
				.setErrorCallback(() => {
					Notification.add({
						title:   'Ошибка',
						message: 'При отправке электронное обращения, произошла ошибка',
						type:    'warning',
					})
				})
			createAppeals(searchQuery)
		}
	}, [message, createAppeals, view, attachments])

	const onBlur = useCallback(() => {
		if (message.length < 5) setTextAreaError(true)
	}, [message])

	const onChange = useCallback(({ target: { value } }) => {
		setMessage(value)
		setTextAreaError(false)
	}, [])

	const onChangeFile = event => {
		const fileToUpload = event.target.files?.[0]
		const size = 30 * 1024 * 1024

		if(fileToUpload) {
			if ((sizeFiles + fileToUpload.size) > size || fileToUpload.size > size) {
				return Notification.add({
					title:   'Ошибка',
					message: 'Превышен допустимый размер файлов',
					type:    'warning',
				})
			}

			if ((sizeFiles + fileToUpload.size) < size) {
				setSizeFiles(sizeFiles => sizeFiles + fileToUpload.size)
				getBase64(fileToUpload).then(base64Obj => {
					const existsFile = attachments.some(file => file.name === base64Obj.name)
					if (!existsFile) {
						return setAttachment([...attachments, base64Obj])
					}
				})
			}
		}
	}

	const removeFile = (event, name, size) => {
		  event.preventDefault()
		  setSizeFiles(sizeFiles => sizeFiles - size)
		  const data = attachments.filter( attachmentFile => attachmentFile.name !== name)
		  return  setAttachment(data)
	  }

	return (
		<div className={styles.formWrapper}>
			<AvForm onSubmit={onSubmit}>
				{
					fields.map(item => (
						<AvGroup key={item.id}>
							{
								isMessage(item.name) ?
									<>
										<Label for={item.name}>{item.title}</Label>
										<textarea
											className={[
												'is-untouched',
												'is-pristine',
												'av-invalid',
												'form-control',
												textAreaError && 'is-invalid',
											].join(' ')}
											onBlur={onBlur}
											onChange={onChange}
											name={item.name}
											value={message}
										/>
										<div
											style={textAreaError ? { display: 'block' } : { display: 'none' }}
											className="invalid-feedback"
										>
											Необходимо заполнить это поле, не менее 5 символов
										</div>
									</> :
									<AvField
										required
										id={item.name}
										name={item.name}
										label={item.title}
										type={item.type}
										errorMessage="Необходимо заполнить это поле"
										key={clearValues}
									/>
							}
						</AvGroup>
					))
				}
				<div className={styles.inputWrapper}>
					<div className={styles.fileInputWrapper}>
						<Label
							className={styles.fileInputLabel}
						>
							Можете прикрепить файл (не более 30 мб)/можаце прымацаваць файл (не больш за 30 мб)
						</Label>
						<div>
							<label
								id='file-input'
								className={styles.inputFileButton}
							>
								Выберите файл/Вылучыце файл
								<input
									type="file"
									onChange={onChangeFile}
									className={styles.fileUpload}
									multiple
								/>
							</label>
							{attachments.map( file =>
								<div key={file.name} className={styles.inputFile}>
									<span className={styles.inputFileText}>{file.name}</span>
									<div onClick={(event)=> removeFile(event, file.name, file.size)}>
										<Icon icon='close' size={24}  />
									</div>
								</div>
							)}
						</div>
					</div>
				</div>

				<AvGroup>
					<button type="submit">Отправить/Адправіць</button>
				</AvGroup>
			</AvForm>
		</div>
	)
}
ElectronicAppealsForm.propTypes    = {
	fields:        PropTypes.array,
	createAppeals: PropTypes.func,
	view:          PropTypes.string,
}
ElectronicAppealsForm.defaultProps = {
	fields:        [],
	createAppeals: () => null,
	view:          '',
}
