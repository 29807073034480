import { ErrorOrder } from 'containers/Static/CreatedOrder/ErrorOrder'
import { SuccessOrder } from 'containers/Static/CreatedOrder/SuccessOrder'
import { Error } from 'containers/Static/Error'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useMemo,
} from 'react'
import {
	useLocation,
	useParams,
} from 'react-router'
import { MetaService } from 'services/MetaService'

// Enable get meta data
const setMetaInfo = (title) => MetaService.setInfo({}, { title })

/**
 * Render created order page
 *
 * @param {Object} createOrderState
 *
 * @return {*}
 * @constructor
 */
export const CreatedOrder = ({ createOrderState }) => {
	const { id }   = useParams()

	const { search } = useLocation()
	const getWsbTid = useMemo(() => new URLSearchParams(search).get('wsb_tid'), [])
	const getWsbOrderNum = useMemo(() => new URLSearchParams(search).get('wsb_order_num'), [])

	useEffect(() => {
		if(createOrderState.result || getWsbTid) {
			setMetaInfo('Заказ оформлен!')
		}
		else {
			if (getWsbOrderNum) setMetaInfo("Ошибка платежа!")
			else setMetaInfo('Ошибка!')
				}
	}, [createOrderState, getWsbTid, getWsbOrderNum])

	return (
		(createOrderState.result || getWsbTid) ?
			<SuccessOrder id={id} /> :
			(getWsbOrderNum ? <ErrorOrder /> : <Error />)
	)
}

CreatedOrder.propTypes = {
	createOrderState: PropTypes.object,
}

CreatedOrder.defaultProps = {
	createOrderState: {},
}
