import { userMenuLinks } from 'components/home/Header/Login/data'
import * as PropTypes from 'prop-types'
import React from 'react'
import AnimateHeight from 'react-animate-height'
import { Link } from 'react-router-dom'
import { history } from 'routing/history'
import styles from '../styles.scss'

/**
 * Render mobile layout
 *
 * @param {boolean} isOpen
 * @param {function} toggle
 * @param {function} logOut
 * @param {Customer} customer
 *
 * @returns {*}
 * @constructor
 */
const MobileMenu = ({ isOpen, toggle, logOut, customer }) => {
	/**
	 * Log out and redirect to home page
	 *
	 * @param {function} logOut
	 *
	 * @return {undefined}
	 */
	const logOutAndRedirect = () => logOut({ callback: () => history.push('/') })

	return (
		<div className={styles.container}>
			<AnimateHeight
				duration={280}
				height={isOpen ? 'auto' : '0'}
				animateOpacity
			>
				<div className={[
					styles.mobileMenu,
					!isOpen ? styles.mobileMenu_hidden : '',
				].join(' ')}>
					<div className={styles.mobileUserName}>
						{'Вы: '}{customer.getFirstName()}
					</div>
					<div className={styles.mobileWrapper}>
						{userMenuLinks.map(link => (
							<Link
								key={`userMenuLink:${link.id}`}
								className={styles.mobileItem}
								to={link.href}
								onClick={toggle}
							>
								{link.title}
							</Link>
						))}
						<div
							className={styles.mobileItem}
							onClick={logOutAndRedirect}
							tabIndex="0"
							role='button'
						>
							{'Выход'}
						</div>
					</div>
				</div>
			</AnimateHeight>
		</div>
	)
}

MobileMenu.propTypes = {
	customer: PropTypes.object,
	isOpen:   PropTypes.bool,
	toggle:   PropTypes.func,
	logOut:   PropTypes.func,
}

MobileMenu.defaultProps = {
	customer: {},
	isOpen:   false,
	toggle:   () => null,
	logOut:   () => null,
}

export { MobileMenu }
