import { Category } from '@kakadu-dev/base-frontend-components'
import { ArrowForwardIosRounded as Arrow } from '@material-ui/icons'
import { BlankButton } from 'components/global/BlankButton'
import { Controller } from 'components/market/Home/Sidebar/Subcategories/Controller'
import { SubcategoryItem } from 'components/market/Home/Sidebar/Subcategories/SubcategoryItem'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useMemo,
	useState,
} from 'react'
import { HierarchicalService } from 'services/HierarchicalService'
import styles from './styles.scss'

/**
 * Render subcategories
 *
 * @param {Category} category
 * @param {Function} close
 * @param {boolean} isLeftRender
 *
 * @return {*}
 * @constructor
 */
export const Subcategories = ({ category, close, isLeftRender }) => {
	const [activeGroupIds, setActiveGroupIds] = useState([])
	const [groupIds, setGroupIds]             = useState([])

	const hierarchy = HierarchicalService.getInstance()

	const isGroupsExist = useMemo(() => category.isGroupsExist(), [category])
	const groups        = useMemo(() => category.getGroups(), [category])

	const menu = useMemo(() => {
		const childrens = Object.values(category?.getChildrens?.() || {})
		let result = []
		groups.map(group => {
			const { categoriesIds, id, title, categoryId } = group
			const categories = childrens?.filter(el => categoriesIds.includes(parseInt(el.id)))
			const obj = { categoryId, id, title, categories }
			if (categories.length > 0) result.push(obj)
		})

		return result
	}, [category, groups])

	useEffect(() => {
		if (isGroupsExist) {
			const ids = []

			groups.forEach(group => ids.push(group.id))

			setGroupIds(ids)
			setActiveGroupIds(ids)
		}
	}, [isGroupsExist, groups])


	const handler = id => {
		if (activeGroupIds.indexOf(id) >= 0) {
			setActiveGroupIds(activeGroupIds.filter(item => item !== id))
		} else {
			setActiveGroupIds([...activeGroupIds, id])
		}
	}

	return (
		!!category.getChildrens() &&
		<ul className={[styles.subcategories, isLeftRender && styles.left].join(' ')}>
			<Controller
				isActive={isGroupsExist}
				action={setActiveGroupIds}
				ids={groupIds}
			/>
			{isGroupsExist && menu.map(group => {
				const isActive = activeGroupIds.indexOf(group.id) >= 0

				return (
					<div key={group.id}>
						{(group.categories.length > 0) &&
							<div>
								<BlankButton
									onClick={() => handler(group.id)}
									className={[styles.sectionToggler, isActive && styles.active].join(' ')}
								>
									<Arrow />
									{group.title}
								</BlankButton>
								<div className={[styles.section, isActive && styles.active].join(' ')}>
									{group.categories.map(category => (
										<SubcategoryItem
											key={category.id}
											close={close}
											title={category.title}
											link={hierarchy.getCategoryLink(parseInt(category.id))}
										/>
									))}
								</div>
							</div>
						}
					</div>
				)
			}) ||
			 Category.map(category.getChildrens(), subcategory => (
				 <SubcategoryItem
					 key={subcategory.getAlias()}
					 close={close}
					 title={subcategory.getTitle()}
					 link={hierarchy.getCategoryLink(subcategory.getId())}
				 />
			 ))}
		</ul>
	)
}

Subcategories.propTypes = {
	category:     PropTypes.object,
	close:        PropTypes.func,
	isLeftRender: PropTypes.bool,
}

Subcategories.defaultProps = {
	category:     () => null,
	close:        () => null,
	isLeftRender: false,
}
