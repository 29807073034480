import { Price } from 'components/global/Price'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render price wrapper
 *
 * @param {string} title
 * @param {number} price
 * @param {boolean} final
 *
 * @return {*}
 * @constructor
 */
export const PriceWrapper = ({ title, price, final }) => (
	<div className={styles.container}>
		<div className={styles.title}>
			{title}
		</div>
		<div className={[styles.price, final && styles.final].join(' ')}>
			<Price value={price} />
		</div>
	</div>
)

PriceWrapper.propTypes = {
	price: PropTypes.number,
	title: PropTypes.string,
	final: PropTypes.bool,
}

PriceWrapper.defaultProps = {
	price: 0,
	title: '',
	final: false,
}
