import styles from 'components/home/Header/Navigation/styles.scss'
import { SideBar } from 'components/market/Home/Sidebar'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
	Link,
	useLocation,
} from 'react-router-dom'

const noWay = ['cooperation', 'appeals', 'buyers', 'for-business']

/**
 * Render first level items
 *
 * @param {Object} category
 * @param {Object} hierarchical
 * @param {Function} setIsBackdrop
 * @param {boolean} isLeftRender
 *
 * @return {*}
 * @constructor
 */
export const NavItem = ({ category, hierarchical, setIsBackdrop, isLeftRender }) => {
	const [isActive, setIsActive] = useState(false)

	const { pathname } = useLocation()

	const activeTab = pathname.split('/')?.[1]

	const toggle = (nextValue = false) => {
		if (category.getChildrens()) {
			setIsBackdrop(nextValue)
		}

		setIsActive(nextValue)
	}

	return (
		<div
			className={styles.navItem}
			onMouseEnter={() => toggle(true)}
			onMouseLeave={() => toggle(false)}
		>
			{
				noWay.includes(category.getAlias()) ? (
					<div
						className={[
							styles.link,
							category.getAlias() === activeTab && styles.activeLink,
							category.getChildrens() && styles.more,
						].join(' ')}
					>
						{category.getTitle()}
					</div>) :
					(
						<Link
							to={`/${category.getAlias()}`}
							onClick={() => toggle(false)}
							className={[
								styles.link,
								category.getAlias() === activeTab && styles.activeLink,
								category.getChildrens() && styles.more,
								noWay.includes(category.getAlias()) && styles.prevented,
							].join(' ')}
						>
							{category.getTitle()}
						</Link>
					)
			}
			<div className={[styles.menu, !isActive && styles.hidden].join(' ')}>
				{category.getChildrens() && (
					<SideBar
						id={category.getId()}
						hierarchicalState={hierarchical}
						close={() => toggle(false)}
						isLeftRender={isLeftRender}
					/>
				)}
			</div>
		</div>
	)
}

NavItem.propTypes = {
	category:      PropTypes.object,
	hierarchical:  PropTypes.object,
	isLeftRender:  PropTypes.bool,
	setIsBackdrop: PropTypes.func,
}

NavItem.defaultProps = {
	category:      {},
	hierarchical:  {},
	isLeftRender:  false,
	setIsBackdrop: () => null,
}
