import { otherLinks, otherLinksExtra } from 'containers/Home/Footer/data'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import { injectIntl } from 'react-intl'
import styles from './styles.scss'

/**
 * Render registration
 *
 * @return {*}
 * @constructor
 */
const Registration = ({ intl }) => (
	<Container className={styles.other}>
		<div className={styles.text}>
			<span>
				{intl.messages['registration.data']}
			</span>
			<span>
				{intl.messages['registration.certificate']}
			</span>
		</div>
		<div className={styles.otherLinksWrapper}>
			<div className={styles.otherLinks}>
				{(intl.locale !== 'be') && otherLinks.map(link => (
					link.internal ?
						<Link key={link.to} to={link.to} className={styles.link}>
							<img src={link.icon} alt="other-link" />
						</Link> :
						<a
							key={link.to}
							href={link.to}
							target="_blank"
							rel="noopener noreferrer"
							className={styles.link}
						>
							<img src={link.icon} alt="other-link" />
						</a>
				))}
			</div>
		</div>
	</Container>
)

Registration.propTypes = {
	intl: PropTypes.object,
}

Registration.defaultProps = {
	intl: {},
}

export default injectIntl(Registration)
