const questions = [
	{
		id:    1,
		title: 'Равным образом рамки и место обучения',
		text:  'Равным образом рамки и место обучения кадров влечет за собой процесс внедрения и ' +
			   'модернизации системы обучения кадров, соответствует насущным потребностям. . Товарищи! ' +
			   'консультация с широким активом позволяет выполнять важные задания по разработке систем ' +
			   'массового участия. Идейные соображения высшего порядка, а также рамки и место обучения кадров ' +
			   'обеспечивает широкому кругу (специалистов) участие в формировании новых предложений.',
		alias: 'alias1',
	},
	{
		id:    2,
		title: 'Товарищи!',
		text:  'Товарищи! сложившаяся структура организации представляет собой интересный эксперимент' +
			   ' проверки направлений прогрессивного развития. Идейные соображения высшего порядка, ' +
			   'а также укрепление и развитие структуры играет важную роль в формировании существенных ' +
			   'финансовых и административных условий.',
		alias: 'alias2',
	},
	{
		id:    3,
		title: 'Таким образом реализация намеченных',
		text:  'Таким образом реализация намеченных плановых заданий позволяет оценить значение новых ' +
			   'предложений. Не следует, однако забывать, что дальнейшее развитие различных форм ' +
			   'деятельности способствует подготовки и реализации форм развития. Повседневная практика ' +
			   'показывает, что укрепление и развитие структуры обеспечивает широкому кругу ' +
			   '(специалистов) участие в формировании дальнейших направлений развития.',
		alias: 'alias3',
	},
	{
		id:    4,
		title: 'С другой стороны постоянное',
		text:  'С другой стороны постоянное информационно-пропагандистское обеспечение нашей ' +
			   'деятельности обеспечивает широкому кругу (специалистов) участие в формировании позиций, ' +
			   'занимаемых участниками в отношении поставленных задач. Товарищи! сложившаяся структура ' +
			   'организации представляет собой интересный эксперимент проверки направлений прогрессивного ' +
			   'развития.',
		alias: 'alias4',
	},
	{
		id:    5,
		title: 'Идейные соображения высшего порядка',
		text:  'Идейные соображения высшего порядка, а также рамки и место обучения кадров ' +
			   'обеспечивает широкому кругу (специалистов) участие в формировании новых предложений. ' +
			   'Товарищи! сложившаяся структура организации представляет собой интересный эксперимент ' +
			   'проверки направлений прогрессивного развития. Товарищи! постоянное информационно-пропагандистское ' +
			   'обеспечение нашей деятельности позволяет выполнять важные задания по разработке модели развития.',
		alias: 'alias5',
	},
]

export const blockQuestions = [
	{
		id:    1,
		title: 'Подача объявлений',
		questions,

	},
	{
		id:    2,
		title: 'Партнерам',
		questions,
	},
	{
		id:    3,
		title: 'Ваш аккаунт',
		questions,
	},
]
