import { FirebaseMessaging } from '@kakadu-dev/base-frontend-helpers/firebase/messaging'
import { IS_CLIENT } from 'constants'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/messaging'

let config = process.env.REACT_APP_FIREBASE_CONFIG

// For development REACT_APP_FIREBASE_CONFIG is a string.
if (typeof config === 'string') {
	try {
		config = JSON.parse(config)
	} catch (e) {
		config = {}
	}
}

const isFirebaseInit = config && Object.keys(config).length > 0

if (isFirebaseInit && !firebase.apps.length) {
	firebase.initializeApp(config)
	firebase.auth().languageCode = 'ru'
}

if (IS_CLIENT && firebase.apps.length && process.env.REACT_APP_FIREBASE_WEB_PUSH && firebase.messaging.isSupported()) {
	firebase.messaging().usePublicVapidKey(process.env.REACT_APP_FIREBASE_WEB_PUSH)

	FirebaseMessaging
		.getInstance()
		.getPermissions()

	navigator
		.serviceWorker
		.addEventListener('message', FirebaseMessaging.getInstance().receivedNotification)
}

export const getFirebaseuiConfig = (successCallback, loaderId) => ({
	callbacks:        {
		signInSuccessWithAuthResult: successCallback,
		uiShown()
		{
			document.getElementById(loaderId).style.display = 'none'
		},
	},
	signInSuccessUrl: '/',
	signInOptions:    [{
		provider:            firebase.auth.PhoneAuthProvider.PROVIDER_ID,
		recaptchaParameters: {
			size: 'invisible',
		},
		defaultCountry:      'BY',
	}],
})

// Return original firebase instance if initializeApp call or return mock
export default isFirebaseInit && firebase || new class
{
	constructor()
	{
		this.apps = { length: 0 }

		this.auth = () => ({
			onAuthStateChanged: () => null,
		})

		this.auth.GoogleAuthProvider   = () => ({ addScope: () => null })
		this.auth.FacebookAuthProvider = () => ({ addScope: () => null, setCustomParameters: () => null })
	}
}
