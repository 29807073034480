import * as PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import ContentLoader from 'react-content-loader'
import { Col } from 'reactstrap'


const ProductCardPlaceholder = ({ count, colProps }) => {

	const renderItem = useMemo(() => {
		const result = []
		for(let i = 0; i < count; i++){
			result.push(
				<Col
					key={i}
					{...(colProps !== null && {
						...ProductCardPlaceholder.defaultProps.colProps,
						...colProps,
					})}
				>
					<ContentLoader
						height={360}
						width={277}
						speed={1}
						backgroundColor="#e9e9e9"
						foregroundColor="#f5f5f5"
						uniqueKey="productCardPlaceholder"
					>
						<rect x="83" y="50" rx="5" ry="5" width="90" height="120" />
						<rect x="30" y="12" rx="5" ry="5" width="200" height="10" />
						<rect x="20" y="239" rx="4" ry="4" width="200" height="8" />
						<rect x="10" y="197" rx="5" ry="5" width="180" height="5" />
						<rect x="25" y="208" rx="5" ry="5" width="180" height="5" />
						<rect x="20" y="256" rx="5" ry="5" width="160" height="8" />
						<rect x="30" y="27" rx="5" ry="5" width="160" height="10" />
					</ContentLoader>
				</Col>
			)
		}
		return result
	}, [count])

	return renderItem
}

ProductCardPlaceholder.propTypes = {
	count:    PropTypes.number.isRequired,
	colProps: PropTypes.object,
}

ProductCardPlaceholder.defaultProps = {
	colProps: {
		xs: 12,
		md: 6,
		lg: 4,
	},
}

export { ProductCardPlaceholder }

