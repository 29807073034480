
export const HOME_CATEGORIES_IDS = [
	11,
	167,
]

export const COUNT_OF_PRODUCT = {
	HOME: 20,
}
export const DISABLE_INITIAL_PROPS = true
export const IS_PROD = false
