import firebase from './firebase'

const GoogleProvider = new firebase.auth.GoogleAuthProvider()
GoogleProvider.addScope('https://www.googleapis.com/auth/userinfo.email')
GoogleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile')
GoogleProvider.addScope('https://www.googleapis.com/auth/user.birthday.read')
GoogleProvider.addScope('https://www.googleapis.com/auth/user.emails.read')
GoogleProvider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read')

const FacebookProvider = new firebase.auth.FacebookAuthProvider()
FacebookProvider.addScope('user_birthday')
FacebookProvider.addScope('email')
FacebookProvider.addScope('user_gender')
FacebookProvider.setCustomParameters({
	'display': 'popup',
})

export {
	FacebookProvider,
	GoogleProvider,
}
