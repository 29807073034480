import styles from 'components/static/faq/FaqBlock/styles.scss'
import * as PropTypes from 'prop-types'
import React from 'react'

export const FaqBlock = ({ title, children }) => (
	<div className={styles.wrapper}>
		<p className={styles.title}>
			{title}
		</p>
		{children}
	</div>
)

FaqBlock.propTypes = {
	title:    PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
}
