import notFound from 'assets/images/search-panel/search.png'
import PropTypes from 'prop-types'
import React from 'react'
import {
	Col,
	Row,
} from 'reactstrap'
import styles from './styles.scss'

/**
 * Render search empty result hint
 *
 * @param {string} text
 *
 * @return {*}
 * @constructor
 */
const SearchResultEmpty = ({ text }) => {
	return (
		<div className={[styles.item, styles.borderNone].join(' ')}>
			<Row className={[styles.wrapper, styles.clear].join(' ')}>
				<Col xs="4" className="d-flex justify-content-end">
					{text !== '' && (
						<img
							src={notFound}
							className={styles.image}
							alt="product"
						/>
					)}
				</Col>
				<Col xs="5" className="d-flex justify-content-start">
					<p className={[styles.title, 'ml-3'].join(' ')}>
						{text}
					</p>
				</Col>
			</Row>
		</div>
	)
}

SearchResultEmpty.propTypes = {
	text: PropTypes.string,
}

SearchResultEmpty.defaultProps = {
	text: '',
}

export { SearchResultEmpty }
