import img1 from './appliances.svg'
import img2 from './beauty-and-style.svg'
import img3 from './clothing-accessories.svg'
import img4 from './decor-textile.svg'
import img10 from './default.svg'
import img5 from './dishes-and-accessories.svg'
import img6 from './footwear.svg'
import img7 from './household-chemicals.svg'
import img8 from './household-goods.svg'
import img9 from './toys.svg'
import img11 from './sport.svg'
import img12 from './discount.svg'
import img13 from './christmas-tree.svg'
import img14 from './electronics.svg'

export const icons = {
	'appliances':             img1,
	'beauty-and-style':       img2,
	'clothing-accessories':   img3,
	'decor-textile':          img4,
	'dishes-and-accessories': img5,
	'footwear':               img6,
	'household-chemicals':    img7,
	'household-goods':        img8,
	'toys':                   img9,
	'default':                img10,
	'Et-recreatio-sports':    img11,
	'Sanctus-prices':         img12,
	'Novyy-god':              img13,
	'Electronics':            img14,
}
