import { PromoBlock } from 'components/static/promo/PromoBlock'
import { PromoItem } from 'components/static/promo/PromoItem'
import { StaticPageWrapper } from 'components/static/StaticPageWrapper'
import styles from 'containers/Article/ArticleList/styles.scss'
import {
	categories,
	breadcrumbs
} from 'containers/Article/db'
import * as moment from 'moment'
import React, {
	useEffect,
	useMemo,
} from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { MetaService } from 'services/MetaService'

const setMetaInfo = title => MetaService.setInfo({}, { title })

const blocks = {
	stocks:         {
		's-in-the-hum':        [
			{ title: 'Главные акции', alias: 'regular' },
			{ title: 'Распродажа', alias: 'sale' },
			{ title: 'Подарки', alias: 'gifts' },
		],
		's-in-shops-generous': [
			{ title: 'Главные акции', alias: 'generous' },
		],
	},
	'for-business': {
		'fb-shares': [
			{ title: 'Главные акции', alias: 'business' },
		],
	},
	buyers:         {
		'ac-news': [
			{ title: 'Новые коллекции', alias: 'collection' },
			{ title: 'Мероприятия', alias: 'event' },
		],
	},
}

/**
 * Render article list page
 *
 * @return {*}
 * @constructor
 */
export const ArticleList = ({ articlesState }) => {
	const { article, category } = useParams()

	const title = categories[category]

	const articles = useMemo(() => {
		const { result } = articlesState ?? {}
		return {...result?.[article]?.[category] ?? []}
	}, [articlesState, article, category])

	useEffect(() => {
		setMetaInfo(title)
	}, [title])

	const formatDate = item => {
		return item.startAt ?
			`${moment(item.startAt).format('L')} - ${moment(item.endAt).format('L')}`:
			moment(item.createdAt).format('L')
	}

	return (
		<StaticPageWrapper
			title={title}
			className={styles.container}
			extraBreadcrumbs={[breadcrumbs[article]]}
		>
			{(blocks?.[article]?.[category] ?? []).map(block => (
				<PromoBlock key={block.alias} title={block.title} link={`/${article}/${category}/${block.alias}`}>
					{articles?.[block.alias]?.map?.(item => (
						<PromoItem
							key={item.id}
							image={item.previewImage}
							discount={item.description}
							title={item.title}
							date={formatDate(item, block.alias)}
							link={`/${article}/${category}/${block.alias}/${item.id}`}
						/>
					))}
				</PromoBlock>
			))}
			{title === 'Акции' &&
			 <Link to="/stocks/about" className={styles.link}>
				 Общие правила
			 </Link>}
		</StaticPageWrapper>
	)
}

ArticleList.propTypes = {
	articlesState: PropTypes.object,
}
ArticleList.defaultProps = {
	articlesState: {},
}
