/* eslint-disable react-hooks/exhaustive-deps */
import {
	Category,
	CategoryEntity,
	Customer,
	ProductAttachment,
} from '@kakadu-dev/base-frontend-components'
import ComponentHelper from '@kakadu-dev/base-frontend-helpers/helpers/ComponentHelper'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import SSRComponentHelper from '@kakadu-dev/base-frontend-helpers/helpers/SSRComponentHelper'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import {
	countFormatter,
	PageHeading,
	ProductsSlider,
	ratingOptions,
	reviewOptions,
	StarRating,
} from 'components/global'
import { BlankButton } from 'components/global/BlankButton'
import { Price } from 'components/global/Price'
import { useBreadcrumbs } from 'components/global/useBreadcrumbs'
import { DataBlock } from 'components/home/DataBlock'
import { ProductCardStore } from 'components/market/ProductCard/index.store'
import {
	ProductCount,
	ProductDelivery,
	ProductDescription,
	ProductImages,
	ProductOfferStore,
	ProductOptions,
	ProductProperties,
	ProductReviewItems,
	ProductReviews,
} from 'components/market/ProductPage'
import { ProductFavoritesStore } from 'components/market/ProductPage/ProductFavorites/index.store'
import { ProductCardPlaceholder } from 'components/utils/placeholders/product-card'
import ProductPagePlaceholder from 'components/utils/placeholders/product-page'
import {
	COUNT_OF_PRODUCT,
	DISABLE_INITIAL_PROPS,
	IS_PROD,
	META_ENTITY_TYPE,
} from 'constants'
import { Error } from 'containers/Static/Error'
import ErrorHelper from 'helpers/ErrorHelper'
import _ from 'lodash'
import { Product } from 'model/Product'
import * as PropTypes from 'prop-types'
import React, {
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import {
	useLocation,
	useParams,
} from 'react-router'
import {
	Col,
	Container,
	Row,
} from 'reactstrap'
import { MetaService } from 'services/MetaService'
import { mapDispatchToProps } from './index.props'
import styles from './styles.scss'

// Enable get meta data
const setMetaInfo = (productId, title) => MetaService.setInfo({
	entity:   META_ENTITY_TYPE.PRODUCT,
	entityId: productId,
}, { title })

const getAddToCartQuery = (count, product, setCount) => DataProvider
	.buildQuery()
	.addExpands(...[
		'product.categories.category.allParents',
		'offer.option.properties',
		'minPrice',
	])
	.addBody({
		count,
		offerId:   product.getOffers()?.[0]?.id,
		productId: product.getId(),
	})
	.setSuccessCallback(() => setCount(1))

const getProductsQuery = (categoryId) => DataProvider.buildQuery().addBody({
	query: {
		expands: [
			'minPrices',
			'categories.category.allParents',
			'options',
			'offers',
			'minPrice',
		],
		perPage: COUNT_OF_PRODUCT.PRODUCT_PAGE,
	},
	extraFilter: {
		categoryId,
		isAvailable: true,
	}
})

const getReviewQuery = productId => DataProvider.buildQuery().addBody({
	query: { expands: ['customer'], filter: { productId } },
})

const getAttachmentsQuery = productId => DataProvider.buildQuery().addBody({
	query: {
		filter: { productId, type: ProductAttachment.types.image },
	},
}).cacheResponse(60 * 10, IS_PROD)

const getProductQuery = (id, setError = () => null) =>
	DataProvider
		.buildQuery()
		.addBody({
			query: {
				expands: [
					'categories.category.allParents',
					'minPrices',
					'properties',
					'options',
					'offers',
					'isFavorite',
					'minPrice',
				],
				filter: {
					isRemoved: false,
				}
			},
			id,
		})
		.cacheResponse(60 * 10, IS_PROD)
		.setSuccessCallback(response => {
			setError(false)
			setMetaInfo(id, Product.create(response).getTitle())
		})
		.setErrorCallback(() => setError(true))

const getId = string => Number(string.split('-').reverse().splice(0, 1)[0])

const getCurrentCategoryId = (product) => {
	const entity = _.find(
		product.getCategoriesEntities().getRawModel(), ['category.level', Category.levels.three],
	)

	return CategoryEntity.create(entity).getCategoryId()
}

const scrollToReviews = (ref) => {
	ref.current.scrollIntoView({block: "center", behavior: "smooth"})
}

const getImages = (attachments, url) => ProductAttachment
	.createList(attachments.result.list.length ? attachments :
		[{ id: 1, isMain: true, sort: 1, type: 'image', url }])

/**
 * Render product page
 *
 * @param {Object} productState
 * @param {Object} productsListState
 * @param {Object} attachmentsState
 * @param {Object} reviewsState
 * @param{Object}  userState
 * @param {Function} getProduct
 * @param {Function} getReviews
 * @param {Function} getProductsList
 * @param {Function} getAttachments
 * @param {Function} addToCart
 * @param {Function} isFetching
 *
 * @returns {*}
 * @constructor
 */
export const ProductPage = ({
	productState,
	productsListState,
	attachmentsState,
	reviewsState,
	userState,
	getProduct,
	getReviews,
	getProductsList,
	getAttachments,
	addToCart,
	isFetching,
}) => {
	const [count, setCount]     = useState(1)
	const [isError, setIsError] = useState(false)

	const reviewsRef      = useRef()
	const { id: idParam } = useParams()
	const { hash } = useLocation()

	const productId     = useMemo(() => getId(idParam), [idParam])

	const product             = Product.create(productState)
	const customer            = Customer.create(userState)
	const productService      = StateService.create(productState)
	const productsListService = StateService.create(productsListState)
	const reviewsCount        = StateService.create(reviewsState).getPagination().getTotalItems()
	const images              = getImages(attachmentsState, product.getPreview())

	const prevProductId = product.getId()
	const categoryId 	= useMemo(() => getCurrentCategoryId(product), [product])

	useEffect(() => {
		if (hash === '#reviews') {
			scrollToReviews(reviewsRef)
		}
	}, [hash])

	useEffect(() => {
		ComponentHelper.fetchIfNotExist(productState, getProduct, getProductQuery(productId, setIsError))
		ComponentHelper.fetchIfNotExist(attachmentsState, getAttachments, getAttachmentsQuery(productId), true)
		ComponentHelper.fetchIfNotExist(reviewsState, getReviews, getReviewQuery(productId), true)
	}, [])

	useEffect(() => {
		if (prevProductId && prevProductId !== productId) {
			getProduct(getProductQuery(productId, setIsError))
			getAttachments(getAttachmentsQuery(productId))
			getReviews(getReviewQuery(productId))
		}
		getProductsList(getProductsQuery(categoryId))
	}, [getAttachments, getProduct, getProductsList, getReviews, prevProductId, productId])

	const breadcrumbs = useBreadcrumbs(categoryId, product.getTitle())

	const getIsPromo = () => {
		const minPrices = product.getMinPrices()
		return minPrices && minPrices[0] && minPrices[0].isPromo || false
	}

	const getOriginalPrice = () => {
		const minPrices = product.getMinPrices()
		return minPrices && minPrices[0] && minPrices[0].originalPrice || 0
	}

	return (!isError ?
		<section className={styles.wrapper}>
			{product.isExist() && !isFetching &&
			 <>
				 <PageHeading
					 hideMainTitle
					 breadcrumbs={breadcrumbs}
					 fetching={productService.isFetching()}
					 product={product.getTitle()}
				 />
				 <Container>
					 <Row className={styles.production}>
						 <Col lg="6">
							 <ProductImages images={images} />
						 </Col>
						 <Col lg="6">
							 <p className={styles.id}>{`ID: ${product.getId()}`}</p>
							 <h1 className={styles.title}>{product.getTitle()}</h1>
							 <div className={styles.content}>
								 <div className={styles.rating}>
									 <StarRating initialRating={product.getRating()} />
									 <div className={styles.vote}>
										 <p className={styles.score}>{countFormatter(reviewsCount, ratingOptions)}</p>
										 <BlankButton
											 className={styles.feedback}
											 onClick={() => scrollToReviews(reviewsRef)}
										 >
											 {countFormatter(reviewsCount, reviewOptions)}
										 </BlankButton>
									 </div>
								 </div>
							 </div>
							 <div className="my-5">
								 {product.isNotInStock() ?
									 <span className={styles.real}>{'Нет в наличии'}</span> :
									 <div>
										 <span
											 className={[getIsPromo() ? styles.promoPrice : styles.realPrice].join(' ')}
										 >
											 <Price value={product.getPrice()} />
										 </span>
										 {getIsPromo() && (
											 <span className={`ml-5 text-secondary ${styles.oldPrice}`}>
												 <Price value={getOriginalPrice()} />
											 </span>
										 )}
									 </div>}
							 </div>
							 <div className={styles.box}>
								 <ProductOptions product={product} />
								 <ProductCount count={count} setCount={setCount} />
							 </div>
							 <div className={styles.add_to_cart}>
								 <ProductOfferStore
									 addProductToCart={() => addToCart(getAddToCartQuery(count, product, setCount))}
									 disabled={product.isNotInStock()}
								 />
								 <ProductFavoritesStore
									 lg
									 productModel={product}
									 userId={customer.getId()}
								 />
							 </div>
							 <ProductDelivery />
						 </Col>
					 </Row>
					 <Row className={styles.info}>
						 <Col xs="12">
							 <ProductDescription info={product.getDescription()}  />
							 <ProductProperties product={product} />
							 <ProductReviews
								 innerRef={reviewsRef}
								 updateReviews={() => getReviews(getReviewQuery(productId))}
								 productState={productState}
								 userState={userState}
								 countOfReviews={reviewsCount}
							 >
								 <ProductReviewItems reviews={StateService.create(reviewsState).getList()} />
							 </ProductReviews>
						 </Col>
					 </Row>
					 <DataBlock title='Похожие товары'>
						 <Row className={styles.row}>
							 {!productsListService.isEmptyList() && (
								 <ProductsSlider>
									 {Product.map(productsListService.getList(), item =>
										 <ProductCardStore
											 isFooterNeeded={false}
											 product={item}
											 key={item.getId()}
											 colProps={null}
										 />,
									 )}
								 </ProductsSlider>
							 ) || productsListService.isFetching() &&
							  <ProductCardPlaceholder count={4} colProps={{ xl: 3 }} />}
						 </Row>
					 </DataBlock>
				 </Container>
			 </>
			 ||
			 <div className={styles.placeholder}>
				 <ProductPagePlaceholder />
			 </div>}
		</section> :
		<Error />)
}

ProductPage.getInitialProps = SSRComponentHelper.initialProps(async (dispatchers, ctx) => {
	const { getProduct, getReviews, getProductsList, getAttachments } = dispatchers
	const { match: { params: { id } } }                               = ctx

	const productId = getId(id)

	setMetaInfo(productId)

	try {
		await Promise.all([
			getProduct(getProductQuery(productId)),
			getAttachments(getAttachmentsQuery(productId)),
			getReviews(getReviewQuery(productId)),
			getProductsList(getProductsQuery()),
		])
	} catch (e) {
		ErrorHelper.log(e)
	}
}, mapDispatchToProps, DISABLE_INITIAL_PROPS)

ProductPage.propTypes = {
	addToCart:         PropTypes.func,
	attachmentsState:  PropTypes.object,
	getAttachments:    PropTypes.func,
	getProduct:        PropTypes.func,
	getProductsList:   PropTypes.func,
	getReviews:        PropTypes.func,
	isFetching:        PropTypes.bool,
	productState:      PropTypes.object,
	productsListState: PropTypes.object,
	reviewsState:      PropTypes.object,
	userState:         PropTypes.object,
}

ProductPage.defaultProps = {
	addToCart:         () => null,
	attachmentsState:  {},
	getAttachments:    () => null,
	getProduct:        () => null,
	getProductsList:   () => null,
	getReviews:        () => null,
	isFetching:        false,
	productState:      {},
	productsListState: {},
	reviewsState:      {},
	userState:         {},
}
