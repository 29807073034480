import { DateHelper } from '@kakadu-dev/base-frontend-helpers/helpers/DateHelper'
import { FormComponent } from '@kakadu-dev/base-frontend-helpers/helpers/Form'
import * as PropTypes from 'prop-types'
import React from 'react'
import DatePicker from 'react-datepicker'
import { Label } from 'reactstrap'

/**
 * Date input component
 *
 * @param {object} props
 *
 * @return {*}
 * @constructor
 */
export const DateInput = (props) => {
	const { label, name, onChange, value, ref, dateOptions } = props

	return (
		<div className="d-flex flex-column">
			<Label for={name}>
				{label}
			</Label>

			<DatePicker
				locale="ru"
				ref={ref}
				name={name}
				dropdownMode='select'
				shouldCloseOnSelect
				showYearDropdown
				selected={value && new Date(value) || null}
				dateFormat="dd.MM.yyyy"
				onChange={(v) => onChange(DateHelper.toServerFormat(v))}
				{...FormComponent.clearProps(dateOptions)}
			/>
		</div>
	)
}

DateInput.propTypes = {
	onChange:    PropTypes.func,
	value:       PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	label:       PropTypes.string,
	name:        PropTypes.string,
	ref:         PropTypes.func,
	dateOptions: PropTypes.object,
}

DateInput.defaultProps = {
	onChange:    () => null,
	ref:         () => null,
	dateOptions: {},
	value:       '',
	label:       '',
	name:        '',
}
