import { BlankButton } from 'components/global/BlankButton'
import { favoriteCategories } from 'containers/User/Favorites/data'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render favorite anchor
 *
 * @param {string} active
 * @param {Function} setActive
 *
 * @return {*}
 * @constructor
 */
export const FavoriteAnchor = ({ active, setActive }) => (
	<div className={styles.container}>
		{favoriteCategories.map(category => (
			<BlankButton
				key={category.alias}
				className={[styles.btn, active === category.alias && styles.active].join(' ')}
				id={category.alias}
				onClick={() => setActive(category.alias)}
			>
				{category.title}
			</BlankButton>
		))}
	</div>
)

FavoriteAnchor.propTypes = {
	active:    PropTypes.string,
	setActive: PropTypes.func,
}

FavoriteAnchor.defaultProps = {
	active:    '',
	setActive: () => null,
}
