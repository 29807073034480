import { put, select } from 'redux-saga/effects'
import { CartActions } from './actionCreators'
import { CartStateSelectors } from './actionSelectors'

/**
 * Cart response handler class
 */
export default class CartResponseHandler {
	static *updateListAfterCreate(data) {
		if (!data.error) {
			const { result: { headers, model } } = data

			const prevState = yield select(CartStateSelectors.list)

			const { result: prevResult, result: { list: prevList } } = prevState

			let nextList

			if (prevList.filter(item => item.id === model.id).length) {
				nextList = prevList.map(item => item.id === model.id ? model : item)
			} else {
				nextList = [...prevList, model]
			}

			const output = { ...prevResult,
				list: nextList,
				headers
			}

			yield put(CartActions.setList(output))
		}
	}

	static *updateListAfterDelete(data, searchQuery) {
		if (!data.error) {
			const {
					  result: {
								  headers
							  }
				  } = data
			const {
					  params: {
								  id
							  }
				  } = searchQuery.getBody()
			const prevState = yield select(CartStateSelectors.list)
			const {
					  result: prevResult,
					  result: {
								  list: prevList
							  }
				  } = prevState

			const nextList = prevList.filter(item => item.id !== id)
			const output = { ...prevResult,
				list: nextList,
				headers
			}
			yield put(CartActions.setList(output))
		}
	}
}
