import { userAddressFields } from 'components/Form/Basket/createOrder'
import { InputField } from 'components/global/InputField'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render user delivery address
 *
 * @param {Object} attributes
 * @param {Function} onChange
 * @param {Function} onBlur
 *
 * @return {*}
 * @constructor
 */
export const UserAddressForm = ({ attributes, onChange, onBlur }) => (
	<div>
		<div className={styles.title}>Адрес доставки</div>
		<div className={styles.container}>
			{userAddressFields.map(field => {
				const {id, required} = field
				const { [id]: value } = attributes?.data ?? {}
				const { error } = attributes?.params?.[id] ?? {}
				return (
					<InputField
						key={id}
						onChange={onChange}
						field={field}
						value={value}
						groupClassName={styles[id]}
						onBlur={onBlur}
						error={error}
						required={required}
					/>
				)
			})}
		</div>
	</div>
)

UserAddressForm.propTypes = {
	attributes: PropTypes.object,
	onChange:    PropTypes.func,
	onBlur: PropTypes.func,
}

UserAddressForm.defaultProps = {
	attributes: {},
	onChange:    () => null,
	onBlur: PropTypes.func,
}
