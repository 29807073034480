import {
	CategoriesListStore,
	HomeStore as MarketHome,
	ProductPageStore,
	ProductsListStore,
} from 'containers/Market'
import { roots } from 'routing/Market/roots'
import wrappers from 'routing/wrappers'

const rootsList = roots.join('|')

export const marketPages = [
	{
		path:      `/:root(${rootsList})`,
		exact:     true,
		component: wrappers(MarketHome),
	},
	{
		path:      `/:root(${rootsList})/:type`,
		exact:     true,
		component: wrappers(CategoriesListStore),
	},
	{
		path:      `/:root(${rootsList})/:type/:subtype`,
		exact:     true,
		component: wrappers(ProductsListStore),
	},
	{
		path:      `/:root(${rootsList})/:type/:subtype/:id`,
		exact:     true,
		component: wrappers(ProductPageStore),
	},
]
