
module.exports = {
	'general.basket': 'Корзина',
	'general.entrance': 'Войти',
	'general.search': 'Найти',
	'general.search.start': 'Начните поиск',
	'general.search.empty': 'Мы ничего не нашли',
	'general.store.map': 'Карта магазинов',
	'general.sunday': 'Воскресенье',
	'general.subscribe.button': 'Подписаться',
	'general.subscribe.input': 'Введите e-mail',
	'general.subscribe.title': 'Подпишитесь на рассылку и будьте в курсе событий!',
	'general.subscribe.success': 'Спасибо за подписку!',
	'general.social.title': 'Мы в социальных сетях',
	'general.developers.title': 'Разработка сайта',
	'general.breadCrumbs.title': 'Главная',
	'general.administrative.procedures': 'Перечень административных процедур',
	'general.about.title': 'О нас',
	'general.about.read.more': 'Читать больше',
	'general.about.introduction':
		'ГУМ — первый крупнейший универсальный магазин г. Минска. Впервые распахнул свои двери для ' +
		'покупателей и гостей 5 ноября 1951 года. \n ГУМ — достопримечательность Беларуси. Здание' +
		' универмага является памятником архитектуры и градостроительства ХХ века.',


	'registration.data': 'Открытое акционерное общество «ГУМ», УНП 100063737, 220030, ' +
						 'Республика Беларусь, г. Минск, пр. Независимости, 21\n' +
						 'Интернет–магазин' +
						 ' www.gum.by ' +
						 'зарегистрирован в торговом реестре Республики Беларусь.',
	'registration.certificate': 'Свидетельство о государственной\n' +
								'регистрации №100063737 выдано 26.04.2000г. решением Минского Горисполкома №456.'

}
