import { EveningWithStylistForm } from 'components/static/EveningWithStylistForm/index'
import { connect } from 'react-redux'
import {
	mapDispatchToProps,
} from './index.props'

const EveningWithStylistFormInStore = connect(
	null,
	mapDispatchToProps,
)(EveningWithStylistForm)

export {EveningWithStylistFormInStore}
