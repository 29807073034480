import KeyHelper from 'helpers/KeyHelper'
import * as PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-slick'
import { MaterialIcon } from '../MaterialIcon'
import styles from '../ProductsSlider/styles.scss'

const NextArrow = ({ onClick }) => { // eslint-disable-line
	return (
		<div
			role='button'
			onKeyDown={KeyHelper.enter(onClick)}
			tabIndex='0'
			className={[styles.arrow, styles.arrow_next].join(' ')}
			onClick={onClick}
		>
			<MaterialIcon icon='keyboard_arrow_right' size={32} />
		</div>
	)
}

const PrevArrow = ({ onClick }) => { // eslint-disable-line
	return (
		<div
			role='button'
			onKeyDown={KeyHelper.enter(onClick)}
			tabIndex='0'
			className={[styles.arrow, styles.arrow_prev].join(' ')}
			onClick={onClick}
		>
			<MaterialIcon icon='keyboard_arrow_left' size={32} />
		</div>
	)
}

const SimilarProducts = ({ title, settings, children }) => {
	return (
		<section className={styles.slider}>
			{title !== '' && (
				<p className={styles.title}>
					{title}
				</p>
			)}
			<div className={styles.container}>
				<Slider {...settings}>
					{children}
				</Slider>
			</div>
		</section>
	)
}

SimilarProducts.propTypes = {
	title:    PropTypes.string,
	children: PropTypes.node,
	settings: PropTypes.object,
}

SimilarProducts.defaultProps = {
	title:    '',
	children: null,
	settings: {
		infinite:       true,
		speed:          500,
		slidesToShow:   3,
		slidesToScroll: 1,
		nextArrow:      <NextArrow />,
		prevArrow:      <PrevArrow />,
		responsive:     [
			{
				breakpoint: 991,
				settings:   {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 500,
				settings:   {
					slidesToShow: 1,
				},
			},
		],
	},
}

export { SimilarProducts }
