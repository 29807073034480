import {
	useEffect,
	useState,
} from 'react'

/**
 * Timer Hook
 *
 * If startTime is true => timer start from (number) seconds to 1
 *
 * @param {boolean} startTimer
 * @param {number} seconds
 *
 * @constructor
 */
export const UseTimer = (startTimer, seconds) => {
	const [sec, setSec] = useState(seconds)

	useEffect(() => {
		if (startTimer && sec > 1) {
			setTimeout(() => setSec(sec - 1), 1000)
		}
	}, [sec, startTimer])

	return sec
}
