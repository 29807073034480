import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import _ from 'lodash'
import * as PropTypes from 'prop-types'
import React, {
	Fragment,
	useEffect,
	useRef,
	useState,
} from 'react'
import Autosuggest from 'react-autosuggest'
import {
	useDispatch,
	useSelector,
} from 'react-redux'
import { Label } from 'reactstrap'

/**
 *
 * Set init label if init value exist
 *
 * @param {function} dispatch
 * @param {function} fetch
 * @param {string} column
 * @param {string|number} initValue
 * @param {function} setValue
 *
 * @return {undefined}
 */
const getInitLabel = (dispatch, fetch, findColumn, labelColumn, initValue, setValue) => {
	if (!initValue) {
		return
	}

	dispatch(fetch(
		DataProvider.buildQuery().addFilter([
			{ [findColumn]: initValue },
		]).setSuccessCallback(response => {
			const resultList = response && response.result

			if (resultList && resultList.length > 0) {
				const valueRow  = resultList[0]
				const initLabel = valueRow && valueRow[labelColumn] || ''

				setValue(initLabel)
			}
		}),
	))
}

/**
 * Search suggestions
 *
 * @type {debounced}
 */
const searchSuggestions = _.debounce((dispatch, fetch, searchFilter, value, extraFilter) => {
	const searchQuery = DataProvider
		.buildQuery()
		.addFilter(searchFilter(value))

	if (typeof extraFilter === 'function') {
		searchQuery.addFilter(...extraFilter(value))
	}

	dispatch(fetch(searchQuery))
}, 300)

/**
 * Autocomplete input component
 *
 * @param {object} props
 *
 * @return {*}
 * @constructor
 */
export const AutocompleteInput = (props) => {
	const {
			  label, name, onChange,
			  value: inputValue, extraFilter,
			  dataState, dataFetch, renderItem,
			  searchFilter, valueColumn, labelColumn,
		  } = props

	const [value, setValue] = useState('')
	const inputEl           = useRef(null)

	const dispatch = useDispatch()

	// Get label for init value
	useEffect(() => {
		getInitLabel(dispatch, dataFetch, valueColumn, labelColumn, inputValue, (v) => {
			setValue(v)
			inputEl.current.input.dataLabel = v
		})
	}, [])

	const citiesState   = useSelector(state => dataState(state))
	const citiesService = StateService.create(citiesState)

	return (
		<Fragment>
			<Label for={name}>{label}</Label>
			<Autosuggest
				ref={inputEl}
				suggestions={citiesService.getList()}
				onSuggestionsFetchRequested={(s) => searchSuggestions(dispatch, dataFetch, searchFilter, s.value, extraFilter)}
				onSuggestionsClearRequested={() => {
					if (citiesState) citiesState.list = []
				}}
				renderSuggestion={(suggestion) => renderItem && renderItem(suggestion)
												  || (<span>{suggestion[labelColumn]}</span>)}
				getSuggestionValue={(suggestion) => {
					onChange(suggestion[valueColumn])

					inputEl.current.input.dataLabel = suggestion[labelColumn]
					setTimeout(() => inputEl.current.input.blur())

					return suggestion && suggestion[labelColumn] || ''
				}}
				inputProps={{
					placeholder: 'Начните вводить...',
					value,
					name,
					onChange:    (event, { newValue }) => setValue(newValue),
					onBlur:      () => {
						const currValue = inputEl.current.input.dataLabel

						if (!currValue || currValue !== value) {
							setValue('')
							onChange('')
						}
					},
				}}
				highlightFirstSuggestion
				theme={{
					container:            'autosuggest',
					input:                'form-control',
					inputOpen:            'react-autosuggest__input--open',
					suggestionsContainer: 'react-autosuggest__suggestions-container',
					suggestionsContainerOpen:
										  'react-autosuggest__suggestions-container--open scrollable',
					suggestionsList:      `react-autosuggest__suggestions-list ${!citiesService.isEmptyList() ? 'show' : ''}`,
					suggestionFocused:    'active',
					suggestion:           'react-autosuggest__suggestion',
				}}
			/>
		</Fragment>
	)
}

AutocompleteInput.propTypes = {
	onChange:     PropTypes.func,
	dataFetch:    PropTypes.func,
	searchFilter: PropTypes.func,
	value:        PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	label:        PropTypes.string,
	name:         PropTypes.string,
	dataState:    PropTypes.object,
	extraFilter:  PropTypes.func,
	valueColumn:  PropTypes.string,
	labelColumn:  PropTypes.string,
	renderItem:   PropTypes.func,
}

AutocompleteInput.defaultProps = {
	onChange:     () => null,
	dataFetch:    () => null,
	searchFilter: () => null,
	renderItem:   () => null,
	extraFilter:  null,
	dataState:    {},
	value:        '',
	label:        '',
	name:         '',
	valueColumn:  'value',
	labelColumn:  'title',
}
