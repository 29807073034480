import {
	Payment,
} from 'components/footer'
import Social from 'components/footer/Social'
import Registration from 'components/footer/Registration'
// import Developers from 'components/footer/Developers'
import NavBlocks from 'components/footer/NavBlocks'
import React from 'react'
import { Container } from 'reactstrap'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import styles from './styles.scss'

/**
 * Render footer
 *
 * @return {*}
 * @constructor
 */
const Footer = ({ intl }) => (
	<Container className={styles.footer}>
		<div className={styles.mainBlock}>
			<NavBlocks />
			{
				intl.locale === 'be' ? null : <Payment />
			}
			<Social />
		</div>
		<div className={styles.br} />
		<Registration />
		{/* <Developers /> */}
	</Container>
)

Footer.propTypes = {
	intl: PropTypes.object,
}

Footer.defaultProps = {
	intl: {},
}

export default injectIntl(Footer)
