import * as PropTypes from 'prop-types'
import React from 'react'
import AnimateHeight from 'react-animate-height'
import { Link } from 'react-router-dom'

import styles from './goods-item-footer.scss'

const GoodsItemFooter = ({ purchased, isOpen, height, disableAnimation }) => {
	return (
		<AnimateHeight
			duration={250}
			height={height}
			easing='ease-in-out'
			animateOpacity
		>
			<div className={[
				styles.footer,
				styles.footer_hidden,
				isOpen && !disableAnimation ? styles.footer_open : '',
			].join(' ')}>
				<div className={styles.options}>
					<p className={styles.title}>
						{'Вес, кг'}
					</p>
					<div className={styles.wrapper}>
						<div className={[
							styles.option,
							styles.active,
							// styles.sale,
							// styles.disabled
						].join(' ')}>
							0.4
						</div>

						<div className={[
							styles.option,
							// styles.active,
							styles.sale,
							// styles.disabled
						].join(' ')}>
							2
						</div>

						<div className={[
							styles.option,
							// styles.active,
							// styles.sale,
							styles.disabled
						].join(' ')}>
							4
						</div>
					</div>
				</div>

				<div className={styles.offers}>
					{!purchased && (
						<button type='button' className={styles.button}>
							{'В корзину'}
						</button>
					)}

					{purchased && (
						<Link to='/basket' className={styles.button}>
							{'Уже в корзине'}
						</Link>
					)}
				</div>
			</div>
		</AnimateHeight>
	)
}

GoodsItemFooter.propTypes = {
	purchased:        PropTypes.bool,
	isOpen:           PropTypes.bool,
	height:           PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	disableAnimation: PropTypes.bool,
}

GoodsItemFooter.defaultProps = {
	purchased:        false,
	isOpen:           false,
	disableAnimation: false,
	height:           'auto',
}

export { GoodsItemFooter }
