import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps = state => ({
	addressesListState: StateSelector.customersAddresses.list(state),
})

const mapDispatchToProps = {
	getUserDefaultAddress: DispatchSelector.customersAddresses.list,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
