import brand1 from 'assets/images/home/brands/1.png'
import brand2 from 'assets/images/home/brands/2.jpg'
import brand3 from 'assets/images/home/brands/3.png'
import brand4 from 'assets/images/home/brands/4.jpg'
import brand5 from 'assets/images/home/brands/5.jpg'
// import brand6 from 'assets/images/home/brands/6.png'
import brand7 from 'assets/images/home/brands/7.png'
import brand8 from 'assets/images/home/brands/8.png'
import brand9 from 'assets/images/home/brands/9.jpg'
import brand10 from 'assets/images/home/brands/10.png'
import brand11 from 'assets/images/home/brands/11.png'
import brand12 from 'assets/images/home/brands/12.jpg'
import brand13 from 'assets/images/home/brands/13.png'
import brand14 from 'assets/images/home/brands/14.png'
import brand15 from 'assets/images/home/brands/15.png'
import brand16 from 'assets/images/home/brands/16.jpg'
import brand17 from 'assets/images/home/brands/17.jpg'
import brand18 from 'assets/images/home/brands/18.png'
import brand19 from 'assets/images/home/brands/19.jpg'
import brand20 from 'assets/images/home/brands/20.jpg'
import brand21 from 'assets/images/home/brands/21.jpg'
import brand22 from 'assets/images/home/brands/22.png'
import brand23 from 'assets/images/home/brands/23.jpg'
import brand24 from 'assets/images/home/brands/24.jpg'
import brand25 from 'assets/images/home/brands/25.jpg'
import brand26 from 'assets/images/home/brands/26.png'
import brand27 from 'assets/images/home/brands/27.png'
import brand28 from 'assets/images/home/brands/28.png'
import brand29 from 'assets/images/home/brands/29.png'
import brand30 from 'assets/images/home/brands/30.png'
import brand31 from 'assets/images/home/brands/31.jpg'
import brand32 from 'assets/images/home/brands/32.png'
import brand33 from 'assets/images/home/brands/33.png'
import brand34 from 'assets/images/home/brands/34.jpg'
import brand35 from 'assets/images/home/brands/35.jpg'
import brand36 from 'assets/images/home/brands/36.png'
import brand37 from 'assets/images/home/brands/37.jpg'
// import brand38 from 'assets/images/home/brands/38.jpg'
import brand39 from 'assets/images/home/brands/39.png'
import brand40 from 'assets/images/home/brands/40.jpg'
import brand41 from 'assets/images/home/brands/41.jpg'
import brand42 from 'assets/images/home/brands/42.jpg'


export const images = [
	{ id: 1, image: brand1 },
	{ id: 2, image: brand2 },
	{ id: 3, image: brand3 },
	{ id: 4, image: brand4 },
	{ id: 5, image: brand5 },
	// { id: 6, image: brand6 },
	{ id: 7, image: brand7 },
	{ id: 8, image: brand8 },
	{ id: 9, image: brand9 },
	{ id: 10, image: brand10 },
	{ id: 11, image: brand11 },
	{ id: 12, image: brand12 },
	{ id: 13, image: brand13 },
	{ id: 14, image: brand14 },
	{ id: 15, image: brand15 },
	{ id: 16, image: brand16 },
	{ id: 17, image: brand17 },
	{ id: 18, image: brand18 },
	{ id: 19, image: brand19 },
	{ id: 20, image: brand20 },
	{ id: 21, image: brand21 },
	{ id: 22, image: brand22 },
	{ id: 23, image: brand23 },
	{ id: 24, image: brand24 },
	{ id: 25, image: brand25 },
	{ id: 26, image: brand26 },
	{ id: 27, image: brand27 },
	{ id: 28, image: brand28 },
	{ id: 29, image: brand29 },
	{ id: 30, image: brand30 },
	{ id: 31, image: brand31 },
	{ id: 32, image: brand32 },
	{ id: 33, image: brand33 },
	{ id: 34, image: brand34 },
	{ id: 35, image: brand35 },
	{ id: 36, image: brand36 },
	{ id: 37, image: brand37 },
	// { id: 38, image: brand38 },
	{ id: 39, image: brand39 },
	{ id: 40, image: brand40 },
	{ id: 41, image: brand41 },
	{ id: 42, image: brand42 },
]
