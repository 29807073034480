import { SagasHelper } from '@kakadu-dev/base-frontend-components'
import { takeLatest } from 'redux-saga/effects'
import SagasHelpersArticles from 'reduxm/modules/staticPages/SagasHelpersArticles'
import { STATIC_PAGES_ACTION } from './actionTypes'
import { StaticPagesApi } from './api'

/**
 * get pages
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{countries, type}>|CallEffect|PutEffect<{type}>>}
 */
function* pages(action)
{
	yield SagasHelper.defaultList(action, StaticPagesApi.pages)
}

/**
 * get articles
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{countries, type}>|CallEffect|PutEffect<{type}>>}
 */
function* articles(action)
{
	yield SagasHelpersArticles.defaultAction(action)
}

/**
 * get banners
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{countries, type}>|CallEffect|PutEffect<{type}>>}
 */
function* banners(action)
{
	yield SagasHelper.defaultList(action, StaticPagesApi.banners)
}

export default [
	takeLatest(STATIC_PAGES_ACTION.PAGES_LIST, pages),
	takeLatest(STATIC_PAGES_ACTION.ARTICLES, articles),
	takeLatest(STATIC_PAGES_ACTION.BANNERS, banners),
]
