import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render price
 *
 * @param {string|number} value
 *
 * @return {*}
 * @constructor
 */
export const Price = ({ value }) => (
	<span>{`${Number(value).toFixed(2)} руб.`}</span>
)

Price.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Price.defaultProps = {
	value: '',
}
