import {
	PromoItem,
} from 'components/static/promo'
import { StaticPageWrapper } from 'components/static/StaticPageWrapper'
import styles from 'containers/Article/ArticleCategory/styles.scss'
import {
	categories,
	types,
	breadcrumbs
} from 'containers/Article/db'
import * as moment from 'moment'
import React, {
	useEffect,
	useMemo,
} from 'react'
import { useParams } from 'react-router'
import { MetaService } from 'services/MetaService'
import PropTypes from 'prop-types'

const setMetaInfo = title => MetaService.setInfo({}, { title })

const getType = gropId => {
	switch (gropId){
		case 1:
			return 'event'
		case 2:
			return 'collection'
		case 6:
			return 'regular'
		case 7:
			return 'sale'
		case 8:
			return 'gifts'
		case 4:
			return 'generous'
		default:
			return 'undefined'
	}
}

/**
 * Render promo category
 *
 * @return {*}
 * @constructor
 */
export const ArticleCategory = ({ articlesState }) => {
	const { category, type, article } = useParams()

	const title = types[type]

	const articleTypes = useMemo(() =>
		articlesState?.result?.[article]?.[category]?.[type] ?? [],
	[
		articlesState,
		article,
		category,
		type,
	])

	useEffect(() => {
		setMetaInfo(title)
	}, [title])

	const formatDate = (item) => {
		return item.startAt ?
			`${moment(item.startAt).format('L')} - ${moment(item.endAt).format('L')}`:
			moment(item.createdAt).format('L')
	}
	const setLink = (item) => {
		if( item.groupId < 3) {
			return `/${article}/${category}/${getType(item.groupId)}/${item.id}`
		}
		if(item.groupId === 4) {

			return `/${article}/${category}/${getType(4)}/${item.id}`
		}
		return `/${article}/${category}/${getType(item.typeId)}/${item.id}`
	}

	return (
		<StaticPageWrapper
			title={title}
			extraBreadcrumbs={[
				breadcrumbs[article],
				{ title: categories[category], path: `/${article}/${category}/` }
				]}
		>
			<div className={styles.categoryWrapper}>
				{
					(articleTypes != null) &&
					articleTypes.map(item => <PromoItem
						key={item.id}
						image={item.previewImage}
						title={item.title}
						discount={item.description}
						date={formatDate(item)}
						link={setLink(item)}
					/>)
				}
			</div>
		</StaticPageWrapper>
	)
}

ArticleCategory.propTypes = {
	articlesState: PropTypes.object,
}
ArticleCategory.defaultProps = {
	articlesState: {},
}
