import React from 'react'
import styles from './styles.scss'

/**
 * Render detail VTB credit
 *
 *
 * @return {*}
 * @constructor
 */
export const VtbDetail = () => (
	<div className={styles.detailWrapper}>
		<div className={styles.detailListTitle}>
			ПРОДАЖЕ В КРЕДИТ ПОДЛЕЖАТ ТОВАРЫ, ПРЕДСТАВЛЕННЫЕ В СЕКЦИЯХ НЕПРОДОВОЛЬСТВЕННЫХ ТОВАРОВ УНИВЕРМАГА.
		</div>
		<h4><p>ОСНОВНЫЕ УСЛОВИЯ ПРОГРАММ КРЕДИТОВАНИЯ:</p></h4>
		<div className={styles.detailList}>
			<div className={styles.detailListTitle}>ЭКСПРЕСС-КРЕДИТ</div>
			<ul>
				<li>минимальная сумма кредита – 50,0 рублей;</li>
				<li>максимальная сумма кредита – 10 000,0 рублей;</li>
				<li>минимальная цена единицы товара – 3,0 рубля;</li>
				<li>
					скидка на товары– 25% с розничной цены, кроме перечня товаров,
					на которые локальными актами ОАО «ГУМ» ограничено предоставление скидок (размер скидки);
				</li>
				<li>процентная ставка – 29,9% годовых;</li>
				<li>срок кредитования – 3-36 месяцев;</li>
				<li>первоначальный взнос – 0%;</li>
				<li>соответствие кредитополучателя требованиям банка*</li>
			</ul>
		</div>
		<div className={styles.detailList}>
			<div className={styles.detailListTitle}>ЭКСПРЕСС-КРЕДИТ.РАССРОЧКА</div>
			<ul>
				<li>минимальная сумма кредита – 50,0 рублей;</li>
				<li>максимальная сумма кредита – 10 000,0 рублей;</li>
				<li>процентная ставка – 0,0001% годовых;</li>
				<li>срок кредитования (рассрочки) – 3 месяца;</li>
				<li>первоначальный взнос – 0%;</li>
				<li>соответствие кредитополучателя требованиям банка*</li>
			</ul>
		</div>
		<div className={styles.detailList}>
			<div className={styles.detailListTitle}>ДРУГИЕ УСЛОВИЯ:</div>
			<ul>
				<li>Досрочное (полное / частичное) погашение кредита – без дополнительных плат и ограничений</li>
				<li>
					Если стоимость товара превышает максимальную сумму кредита - 10.000руб,
					тогда клиенту необходимо внести разницу, оплатив первоначальный взнос в кассу универмага.
				</li>
			</ul>
		</div>
		<table className={styles.detailTable}>
			<thead>
				<tr>
					<th colSpan="2">*ТРЕБОВАНИЯ К КРЕДИТОПОЛУЧАТЕЛЯМ:</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<th scope="row">Возраст кредитополучателя</th>
					<td>
						18-69 лет. Но от 18 до 21 года, при условии приобретения одного наименования товара,
						оплаты собственными средствами не менее 20% стоимости товара и сумме кредита до 2 000 рублей
						включительно . От 64 до 69 срок «Экспресс – кредита» не должен превышать 12 месяцев.
						Если срок кредитного продукта больше 12 месяцев,
						тогда возраст кредитополучателя не должен превышать 64 года.
					</td>
				</tr>
				<tr>
					<th scope="row">Гражданство</th>
					<td>
						Гражданин Республики Беларусь или вид на жительство на территории Республики Беларусь.
					</td>
				</tr>
				<tr>
					<th scope="row">Образование</th>
					<td>
						Не ниже среднего
					</td>
				</tr>
				<tr>
					<th scope="row">Стаж работы</th>
					<td>
						Непрерывный стаж на текущем месте работы не менее 3 месяцев (за исключением пенсионеров)
					</td>
				</tr>
				<tr>
					<th scope="row">Наличие регистрации (прописки)</th>
					<td>
						Обязательна регистрация на территории Республики Беларусь
					</td>
				</tr>
				<tr>
					<th scope="row">Другие</th>
					<td>
						Кредитополучатель не находится в отпуске по беременности и родам,
						в социальном отпуске по уходу за ребенком до достижения им возраста 3-х лет,
						не является беременной женщиной.
					</td>
				</tr>
				<tr>
					<th scope="row">Необходимые документы</th>
					<td>
						Паспорт гражданина Республики Беларусь или вид на жительство.
						Кредитование Индивидуальных предпринимателей осуществляется только с паспортом и
						свидетельством о регистрации ИП
					</td>
				</tr>
			</tbody>
		</table>
		<div className={styles.detailList}>
			<div className={styles.detailListTitle}>СХЕМА ОФОРМЛЕНИЯ КРЕДИТА:</div>
			<ul>
				<li>
					Покупатель отбирает товар в секциях ГУМа и обращается в Инфоцентр для оформления счет-фактуры;
				</li>
				<li>
					Работник инфоцентра передает покупателю счет-фактуру и
					направляет его в ближайший офис банка ВТБ**;
				</li>
				<li>
					Покупатель оформляет у сотрудников банка необходимую кредитную документацию,возвращается в
					универмаг и передает данные документы сотрудникам инфоцентра;
				</li>
				<li>
					Сотрудник инфоцентра, при предъявлении покупателем кредитной документации банка,
					подтверждающей выдачу кредита, сверяет данные о покупателе в документах банка с данными,
					указанными в его паспорте,проверяет наличие подписей покупателя и сотрудников банка,
					оформляет товарную (товарно-транспортную) накладную на отпуск товара и направляет в
					секцию (секции) для получения товара.
				</li>
				<li>При необходимости покупатель оплачивает суммупервоначального взноса;</li>
			</ul>
		</div>
		<div className={styles.detailOtherList}>
			<div className={styles.detailOtherListTitle}>ПОРЯДОК ПОГАШЕНИЯ КРЕДИТА:</div>
			<p>
				Начиная с месяца, следующего за месяцем получения кредита, не позднее платежной даты,
				на открытый счет необходимо вносить соответствующий платеж. Погашение кредита возможно в
				любом из офисов банка (вне зависимости от того, где был получен кредит),
				а также в любом отделении РУП «Белпочта».
			</p>
			<p>Уточнить сумму платежа можно по контактным телефонам банка либо в офисах банка.</p>
		</div>
		<table className={styles.detailTable}>
			<thead>
				<tr>
					<th colSpan="2">** ИНФОРМАЦИЯ О БЛИЗЛЕЖАЩИХ К УНИВЕРМАГУ ОФИСАХ ЗАО БАНКА ВТБ(БЕЛАРУСЬ)</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<th scope="row">
						Доп.офис №1 220007, г. Минск, ул. Московская,
						14 метро Институт Культуры, +375 17 309 15 72
					</th>
					<td>
						Понедельник – пятница: 9.00 – 19.00, Суббота: 10.00 – 16.00,
						Последний рабочий день месяца: 9.00 – 16.00, В предпраздничные дни установленный
						график обслуживания сокращается на 1 час, Выходной: воскресенье.
					</td>
				</tr>
				<tr>
					<th scope="row">
						Доп. офис №4 220030, г. Минск, пр-т Независимости,
						29 метро Октябрьская, +375 17 327 08 44, +375 17 327 15 54
					</th>
					<td>
						Понедельник - пятница: 9.00 – 19.00, Суббота: 10.00 – 16.00,
						Последний рабочий день месяца: 9.00 – 16.00, В предпраздничные дни установленный
						график обслуживания сокращается на 1 час, Выходной: воскресенье.
					</td>
				</tr>
			</tbody>
		</table>
	</div>
)
