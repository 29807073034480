import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render backdrop
 *
 * @param {boolean} isActive
 * @param {boolean} isFullScreen
 *
 * @return {*}
 * @constructor
 */
export const Backdrop = ({ isActive, isFullScreen }) =>
	isActive && <div className={`${styles.backdrop} ${isFullScreen && styles.fullScreen}`} />

Backdrop.propTypes = {
	isActive:     PropTypes.bool,
	isFullScreen: PropTypes.bool,
}

Backdrop.defaultProps = {
	isActive:     false,
	isFullScreen: false,
}
