import Icon from 'assets/icomoon/Icon'
import {
	Loader,
	SiteModal,
} from 'components/global'
import { ModalContentStore } from 'components/home/Header'
import KeyHelper from 'helpers/KeyHelper'
import * as PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

const CitySelect = ({ isOpenModal, toggleModal, isMobile, userCity }) => (
	<>
		<div
			className={styles.location}
			onClick={toggleModal}
			onKeyDown={KeyHelper.enter(toggleModal)}
			role='button'
			tabIndex='0'
		>
			{!isMobile && <Icon icon='location' size={21} />}
			{userCity.fetching && <Loader />}
			{userCity.result &&
			<p className={styles.city}>
				{userCity.result.title}
			</p>}
		</div>

		<SiteModal
			isOpen={isOpenModal}
			toggle={toggleModal}
			contentClass={styles.content}
			withCloseIcon
		>
			<ModalContentStore toggleModal={toggleModal} />
		</SiteModal>
	</>

)

CitySelect.propTypes = {
	isOpenModal: PropTypes.bool.isRequired,
	toggleModal: PropTypes.func.isRequired,
	isMobile:    PropTypes.bool.isRequired,
	userCity:    PropTypes.object,
}

CitySelect.defaultProps = {
	userCity: {},
}

export { CitySelect }
