import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import styles from './accordion.scss'
import AccordionSection from './AccordionSection'


class Accordion extends Component
{
	state = {
		openSections: {},
	}

	componentDidUpdate(prevProps)
	{
		const { props } = this

		if (props.children !== prevProps.children) {
			const { openSections } = this.state

			props.children.forEach(child => {
				if (child.props.isOpen) {
					openSections[child.props.id] = true
				}
			})

			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({ openSections })
		}

		if (props.mobileMenuIsOpen !== prevProps.mobileMenuIsOpen && !props.mobileMenuIsOpen) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({ openSections: {} })
		}
	}

	onClick = id => {
		const { allowMultipleOpen } = this.props
		const { openSections }      = this.state

		const isOpen = !!openSections[id]

		if (allowMultipleOpen) {
			this.setState({
				openSections: {
					...openSections,
					[id]: !isOpen,
				},
			})
		} else {
			this.setState({
				openSections: {
					[id]: !isOpen,
				},
			})
		}
	}

	isFirstContainer = child => {
		const { key } = child || ''
		return key?.startsWith?.('firstContainer')
	}

	render()
	{
		const {
				  children,
				  rotate45,
				  rotate180,
				  icon,
				  iconSize,
				  iconColor,
				  classCustom,
				  headingClassCustom,
				  headingClassActive,
				  count,
			  } = this.props
		const {
				  openSections,
			  } = this.state

		let id = 0

		return (
			<div className={styles.accordion}>
				{children && children.map(child => {
					return (
						<AccordionSection
							key={id += 1}
							isOpen={!!openSections[child.props.id]}
							title={child.props.title || child.props.children.props.children.props.children}
							id={child.props.id}
							to={child.props.to}
							onClick={this.isFirstContainer(child) ? child.props.onClick : this.onClick}
							rotate45={rotate45}
							rotate180={rotate180}
							icon={child.props.title && icon}
							iconSize={iconSize}
							iconColor={iconColor}
							classCustom={classCustom}
							headingClassCustom={headingClassCustom}
							headingClassActive={headingClassActive}
							count={count}
						>
							{child.props.children}
						</AccordionSection>
					)
				})}
			</div>
		)
	}
}

Accordion.propTypes = {
	allowMultipleOpen:  PropTypes.bool,
	children:           PropTypes.instanceOf(Object),
	rotate45:           PropTypes.bool,
	rotate180:          PropTypes.bool,
	icon:               PropTypes.string,
	iconSize:           PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	iconColor:          PropTypes.string,
	classCustom:        PropTypes.string,
	headingClassCustom: PropTypes.string,
	headingClassActive: PropTypes.string,
	count:              PropTypes.bool,
	mobileMenuIsOpen:   PropTypes.bool,
}

Accordion.defaultProps = {
	children:           null,
	allowMultipleOpen:  false,
	rotate45:           true,
	rotate180:          false,
	icon:               '',
	iconSize:           20,
	iconColor:          'rgba(1, 0, 2, 0.8)',
	classCustom:        '',
	headingClassCustom: '',
	headingClassActive: '',
	count:              false,
	mobileMenuIsOpen:   false,
}

export { Accordion }

// eslint-disable-next-line
{/*
 Example

 <Accordion>
 <div title='title 1' id='1'>
 text content
 </div>
 <div title='title 2' id='2'>
 text content
 </div>
 </Accordion>
 */
}
