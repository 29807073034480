import styles from 'containers/Static/Faq/styles.scss'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import AnimateHeight from 'react-animate-height'

const Question = ({ question }) => {
	const [isActive, setIsActive] = useState(false)

	const toggle = () => setIsActive(!isActive)
	return (
		<>
			<div
				tabIndex={0}
				role={'button'}
				onClick={toggle}
				className={[styles.question, isActive && styles.active].join(' ')}
			>
				{question.title}
			</div>
			<AnimateHeight
				duration={300}
				height={isActive ? 'auto' : '0'}
				animateOpacity
			>
				<div className={styles.answer}>
					<p>{question.text}</p>

					<div className={styles.usefulInfo}>
						<p className={styles.text}>{'Информация была полезна?'}</p>
						<div className={styles.buttons}>
							<button
								type='button'
								className={[styles.button, styles.button_yes].join(' ')}
							>
								{'Да'}
							</button>

							<button
								type='button'
								className={[styles.button, styles.button_no].join(' ')}
							>
								{'Нет'}
							</button>
						</div>
					</div>
				</div>
			</AnimateHeight>
		</>
	)
}

Question.propTypes = {
	question: PropTypes.object,
}

Question.defaultProps = {
	question: {},
}

export default Question
