import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.scss'

/**
 * Render slides for main carusel
 *
 * @param {string} image
 * @param {string} linkTo
 *
 * @returns {*}
 * @constructor
 */
export const Slide = ({ image, linkTo }) => (
	<div>
		<Link
			to={linkTo}
			className={styles.background}
			style={{ backgroundImage: `url(${image})` }}
		>
			<img src={image} alt="slider" />
		</Link>
	</div>
)

Slide.propTypes = {
	image:  PropTypes.string,
	linkTo: PropTypes.string,
}

Slide.defaultProps = {
	image:  '',
	linkTo: '/',
}
