export const textParts = [
	'Доставка осуществляется для покупателей на безвозмездной основе (бесплатно).',
	' Территория доставки: город Минск в пределах внутреннего кольца МКАД (М9) и населенные пункты, расположенные ' +
	'в радиусе 10 км от внутреннего кольца МКАД.',
	'Доставка осуществляется по пятницам с 10.30 до 16.30 в первое помещение квартиры. По просьбе покупателя ' +
	'товар переносится в другие помещения квартиры, если обеспечен беспрепятственный доступ.',
	' Доставка товара до места установки осуществляется только в заводской упаковке.',
	'Товар поднимается на этаж и вносится в квартиру, если лестничные пролеты, проходы в подъезд, дом, этаж, квартиру,' +
	' комнату освобождены от посторонних предметов, которые препятствуют переносу товара или могут вызвать его ' +
	'повреждение. Также, расстояние между дверными коробками, стенами, другими препятствиями и стороной товара в ' +
	'упаковке должно быть не менее 5 см. В противном случае товар остается на месте, до которого удалось осуществить ' +
	'доставку и производится его приемка покупателем.',
	' При получении товара покупатель должен произвести осмотр товара на предмет механических повреждений, проверить ' +
	'его комплектность, и отсутствие претензий подтвердить подписью в заказ - поручении.',
	' Если покупатель желает осуществить дальнейшее перемещение товара в распакованном виде, он должен сделать в ' +
	'заказ–поручении пометку «С переноской товара в распакованном виде согласен. Ответственность за повреждение ' +
	'товара в связи с такой переноской беру на себя» и подтвердить подписью.',
	'Работы по переносу мебели, вещей внутри квартиры не осуществляются.',
	'Работники по доставке не консультируют покупателей по вопросам эксплуатации товаров и не осуществляют их сборку,' +
	' наладку, установку, подключение (данные вопросы решаются в секции при выборе и оплате товара).',
	' В случае возникновения форс-мажорных обстоятельств (поломка машины, авария, «пробки» в дороге и др..), влекущих ' +
	'задержку или невозможность исполнения заказа по доставке, Покупателю сообщается информация по телефону и ' +
	'согласовывается другое время доставки.',
	'При возникновении непредвиденных ситуаций со стороны покупателя, просьба своевременно сообщать об этом в универмаг' +
	' по тел. 37517 226-10-48, 37529 304-50-45.',
]
