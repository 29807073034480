import Icon from 'assets/icomoon/Icon'
import * as PropTypes from 'prop-types'
import React from 'react'
import {
	Modal,
	ModalHeader,
} from 'reactstrap'
import styles from './site-modal.scss'

/* в contentClass обязательно указать ширину модалки на desktop версии */
const SiteModal = ({ toggle, isOpen, contentClass, children, withCloseIcon }) => {
	const closeBtn = (
		<button type='button' className={styles.close} onClick={toggle}>
			<Icon icon='close' size={18} />
		</button>
	)

	const emptyButton = <div />

	return (
		<Modal
			isOpen={isOpen}
			toggle={toggle}
			className={styles.modal}
			cssModule={{ 'modal-content': [styles.content, [contentClass]].join(' ') }}
		>
			<ModalHeader
				toggle={toggle}
				close={withCloseIcon ? closeBtn : emptyButton}
				className={styles.header}
			/>
			{children}
		</Modal>
	)
}

SiteModal.propTypes = {
	toggle:        PropTypes.func.isRequired,
	isOpen:        PropTypes.bool.isRequired,
	contentClass:  PropTypes.string,
	children:      PropTypes.node.isRequired,
	withCloseIcon: PropTypes.bool,
}

SiteModal.defaultProps = {
	contentClass:  '',
	withCloseIcon: false,
}

export { SiteModal }
