import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { navItems } from './data'
import styles from './styles.scss'

/**
 * Render user page navigator
 *
 * @param {string} activeTab
 *
 * @return {*}
 * @constructor
 */
export const Navigator = ({ activeTab }) => (
	<div className={styles.nav}>
		{navItems.map(item => (
			<Link
				to={`/user/${item.alias}`}
				key={item.alias}
				className={[styles.navItem, activeTab === item.alias && styles.activeTabItem].join(' ')}
			>
				<div className={styles.navTitle}>
					{item.title}
				</div>
			</Link>
		))}
	</div>
)

Navigator.propTypes = {
	activeTab: PropTypes.string,
}

Navigator.defaultProps = {
	activeTab: '',
}
