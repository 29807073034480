import styles from 'components/global/styles.scss'
import * as PropTypes from 'prop-types'
import React, { Fragment } from 'react'

const Input = ({ id, type, required, description, placeholder, classLabel, classInput, value, onChange }) => {

	const requiredSymbol = required ? <span className={styles.required}>*</span> : ''

	return (
		/* eslint-disable-next-line */
		<label
			className={[
				styles.field,
				// styles.field_error,
			].join(' ')}
			htmlFor={id}
		>
			<p className={[styles.description, classLabel].join(' ')}>
				{description}{requiredSymbol}:
			</p>

			{type === 'file' ? (
				<input
					className={styles.file}
					type={type}
					id={id}
				/>
			) : (
				<Fragment>
					<input
						className={[styles.input, classInput].join(' ')}
						type={type}
						id={id}
						placeholder={placeholder}
						value={value}
						onChange={(e) => {
							onChange(id, e.target.value)
						}}
					/>
					<p className={styles.error}>
						Необходимо заполнить «{description}».
					</p>
				</Fragment>
			)}

		</label>

	)
}

Input.propTypes = {
	id:          PropTypes.string.isRequired,
	type:        PropTypes.string,
	required:    PropTypes.bool,
	description: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	classLabel:  PropTypes.string,
	classInput:  PropTypes.string,
	value:       PropTypes.any,
	onChange:    PropTypes.func.isRequired,
}

Input.defaultProps = {
	type:        'text',
	required:    false,
	placeholder: '',
	classLabel:  '',
	classInput:  '',
	value:       '',
}

export { Input }
