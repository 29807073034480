import Icon from 'assets/icomoon/Icon'
import { DeliveryModal } from 'components/global'
import React, { useState } from 'react'
import styles from './styles.scss'

const ProductDelivery = () => {
	return (
		<div className={styles.delivery}>
			<div className={styles.info}>
				<Icon icon='delivery-truck' size={20} />
				<p className={styles.region}>
					<span>
						{'Доставим ваш заказ в любую точку Беларуси.'}
					</span>
				</p>
			</div>
			<p className={styles.text}>
				Доставка крупной бытовой техники (холодильники, морозильники, стиральные машины,
				электро- и газовые плиты, встраиваемая техника, телевизоры) осуществляется
				только по г. Минску и близлежащим населенным пунктам.
			</p>
		</div>
	)
}

export { ProductDelivery }
