import React from 'react'

const types = {
	sale:       'Распродажа',
	regular:    'Главные акции',
	gifts:      'Подарки',
	business:   'Главные акции',
	collection: 'Новые коллекции',
	event:      'Мероприятия',
	generous:   'Главные акции',
}

const categories = {
	'ac-news':             'Новости',
	's-in-the-hum':        'Акции в ГУМе',
	's-in-shops-generous': 'Акции в магазинах "Щедрый"',
	'fb-shares':           'Акции бизнесу',
}

const breadcrumbs = {
	buyers: { title: 'Покупателям', path: '' },
	stocks: { title: 'Акции', path: '' },
	'for-business': { title: 'Бизнесу', path: '' },
}

export {
	types,
	categories,
	breadcrumbs,
}
