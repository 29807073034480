import { navTabs } from 'containers/User/Home/Container/navTabs'
import React from 'react'
import { useParams } from 'react-router'
import styles from './styles.scss'

/**
 * Render container
 *
 * @return {*[]}
 * @constructor
 */
export const Container = () => {
	const { activeTab } = useParams()

	return (
		navTabs.map(tab => (
			<div key={tab.alias} className={[styles.container, tab.alias === activeTab && styles.active].join(' ')}>
				{tab.component}
			</div>
		))
	)
}
