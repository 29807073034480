import { PROJECT_ACTIONS } from './actionTypes'

const initialAction = {
	backdropState: false,
}

/**
 * Project reducer
 *
 * @param {object} state
 * @param {object} action
 *
 * @return {object}
 * @constructor
 */
export function ProjectReducer(state = initialAction, action)
{
	switch (action.type) {
		case PROJECT_ACTIONS.BACKDROP_SHOW:
			return { ...state, backdropState: true }
		case PROJECT_ACTIONS.BACKDROP_HIDE:
			return { ...state, backdropState: false }
		default:
			return state
	}
}
