import { connect } from 'react-redux'
import { Discount } from './index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'

export const DiscountFromStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Discount)
