import { VISION_PANEL_ACTIONS } from 'reduxm/modules/visionPanel/actionTypes'

const INIT_STATE = {
	isShow: false,
	siteBackground: '',
	isImg: true,
	isFontNormal: true,
	isKerningNormal: true,
}

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case VISION_PANEL_ACTIONS.IS_SHOW_PANEL:
			if(!action.payload) {
				return {
					...state,
					isShow: action.payload,
					siteBackground: '',
					isImg: true,
					isFontNormal: true,
					isKerningNormal: true
				}
			}
			return {...state, isShow: action.payload}

		case VISION_PANEL_ACTIONS.SITE_BACKGROUND:
			return {...state, siteBackground: action.payload}

		case VISION_PANEL_ACTIONS.IS_IMG:
			return {...state, isImg: action.payload}

		case VISION_PANEL_ACTIONS.SET_FONT_SIZE:
			return {...state, isFontNormal: action.payload}

		case VISION_PANEL_ACTIONS.SET_FONT_KERNING:
			return {...state, isKerningNormal: action.payload}

		default:
			return { ...state }
	}
}
