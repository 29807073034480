import { connect } from 'react-redux'
import { GiftCertificates } from './index'
import {
	mapDispatchToProps,
	mapStateToProps
} from './index.props'

const GiftCertificatesInStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(GiftCertificates)

export { GiftCertificatesInStore }
