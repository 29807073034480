import {
	countFormatter,
	productOptions,
} from 'components/global/CountFormatter'
import PropTypes from 'prop-types'
import React from 'react'
import { Progress } from 'reactstrap'
import styles from './styles.scss'

/**
 * Render filter progress block
 *
 * @param {boolean} searching
 * @param {number} countOfProducts
 *
 * @return {*}
 * @constructor
 */
export const FilterProgress = ({ searching, countOfProducts }) => (
	<div className={[
		styles.status,
		!searching && !countOfProducts ? styles.empty : '',
		searching ? styles.searching : '',
	].join(' ')}
	>
		{searching ?
			<Progress
				className={styles.progress}
				animated
				color="warning"
				value={100}
			>
				<p>Поиск...</p>
			</Progress> :
			<p>{`Найдено ${countFormatter(countOfProducts, productOptions)}`}</p>}
	</div>
)

FilterProgress.propTypes = {
	countOfProducts: PropTypes.number,
	searching:       PropTypes.bool,
}

FilterProgress.defaultProps = {
	countOfProducts: 0,
	searching:       false,
}
