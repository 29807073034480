import { connect } from 'react-redux'
import { ModalContent } from 'components/home/Header/ModalContent/index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'

const ModalContentStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ModalContent)

export { ModalContentStore }


