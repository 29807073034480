import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.scss'

/**
 * Render subcategory item
 *
 * @param {string} title
 * @param {string} link
 * @param {Function} close
 *
 * @return {*}
 * @constructor
 */
export const SubcategoryItem = ({ title, link, close }) => (
	<li className={styles.subcategory}>
		<Link to={link} onClick={close}>
			{title}
		</Link>
	</li>
)

SubcategoryItem.propTypes = {
	close: PropTypes.func,
	link:  PropTypes.string,
	title: PropTypes.string,
}

SubcategoryItem.defaultProps = {
	close: () => null,
	link:  '',
	title: '',
}
