import * as PropTypes from 'prop-types'
import React, { useState } from 'react'

import styles from '../styles.scss'

/**
 * Render textarea whith words counter
 *
 * @param {string} id
 * @param {string} description
 * @param {string} placeholder
 * @param {string} classLabel
 * @param {string} classInput
 * @param {string} borderColor
 *
 * @return {*}
 * @constructor
 */
const Textarea = ({ id, description, placeholder, classLabel, classInput, borderColor, required }) => {
	const [words, setWords] = useState(0)

	/**
	 * Set to state count of words
	 *
	 * @param {Object} event
	 *
	 * @return {undefined}
	 */
	const wordsCounter = (event) => {
		if (event.target.value === '') {
			setWords(0)
		} else {
			setWords(event.target.value.match(/\S*\s*/gm).length - 1)
		}
	}

	return (
		<label className={styles.field} htmlFor={id}>
			<p className={[styles.description, classLabel].join(' ')}>
				{description}
			</p>
			<div>
				<div
					style={{ borderColor }}
					className={styles.words_counter}
				>
					Слов: {words}
				</div>
				<textarea
					required={required}
					className={[styles.input, classInput].join(' ')}
					style={{ borderColor }}
					id={id}
					placeholder={placeholder}
					onChange={wordsCounter}
				/>
			</div>
		</label>
	)
}

Textarea.propTypes = {
	description: PropTypes.string.isRequired,
	id:          PropTypes.string.isRequired,
	borderColor: PropTypes.string,
	classInput:  PropTypes.string,
	classLabel:  PropTypes.string,
	placeholder: PropTypes.string,
	required:    PropTypes.bool,
}

Textarea.defaultProps = {
	borderColor: '#cccccc',
	classInput:  '',
	classLabel:  '',
	placeholder: '',
	required:    false,
}

export { Textarea }
