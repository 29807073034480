import { Button } from 'components/global/Button'
import { getCardFields } from 'components/user/UserAddCardForm/data'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import {
	Col,
	Row,
} from 'reactstrap'
import styles from './styles.scss'

/**
 * Render card form
 *
 * @param {Object} hiddenFormRefs
 * @param {function} functionForMainForm
 * @param {function} issuer
 * @param {function} formRefs
 *
 * @return {*}
 * @constructor
 */
export const UserAddCardForm = ({
	hiddenFormRefs,
	functionForMainForm,
	issuer,
	formRefs,
}) => {
	const { activeTab } = useParams()

	const { form, submitBtn }                                       = hiddenFormRefs
	const { number }                                                = formRefs
	const { handleInputChange, handleInputFocus, createCryptogram } = functionForMainForm

	useEffect(() => {
		if (activeTab === 'payment') {
			number.current.focus()
		}
	}, [activeTab])

	return (
		<form ref={form} id="paymentFormSample" className={styles.form_wrapper} autoComplete="off">
			<Row>
				{getCardFields(formRefs).map(field => (
					<Col key={field.id} xs={field.xs} className={field.className || ''}>
						{field.children}
						<input
							type={field.type}
							id={field.id}
							ref={field.ref}
							data-cp={field['data-cp']}
							pattern={field.pattern}
							className={styles.input}
							placeholder={field.placeholder}
							autoComplete="off"
							required
							onChange={handleInputChange}
							onFocus={handleInputFocus}
						/>
					</Col>
				))}
				<input type="hidden" name="issuer" value={issuer} />
				<Button
					id="submit"
					color="green"
					type="submit"
					innerRef={submitBtn}
					onClick={createCryptogram}
					style={styles.submit}
				>
					Добавить карту
				</Button>
			</Row>
		</form>
	)
}


UserAddCardForm.propTypes = {
	formRefs:            PropTypes.object,
	functionForMainForm: PropTypes.object,
	hiddenFormRefs:      PropTypes.object,
	issuer:              PropTypes.string,
}

UserAddCardForm.defaultProps = {
	formRefs:            {},
	functionForMainForm: {},
	hiddenFormRefs:      {},
	issuer:              '',
}
