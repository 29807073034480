import { connect } from 'react-redux'
import { MobileMenu } from './index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'

const MobileMenuStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(MobileMenu)

export { MobileMenuStore }


