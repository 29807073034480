import { CollapseInfoCard } from 'components/static/installmentPlanCredit/CollapseInfoCard'
import {
	GuestDetail,
	GumDetail,
	PowerDetail,
	StudentDetail,
} from 'components/static/loyaltyProgramDetail'
import { StaticPageWrapper } from 'components/static/StaticPageWrapper'
import React, { useEffect } from 'react'
import { MetaService } from 'services/MetaService'
import styles from './styles.scss'

const title = 'Программа лояльности'

const setMetaInfo = () => MetaService.setInfo({}, { title })

const loyaltyProgramArray = [
	{
		key:         0,
		title:       'Дисконтная программа «КЛУБ ПОСТОЯННЫХ ПОКУПАТЕЛЕЙ»',
		img:         require('./media/gum.jpg'),
		description: [],
		detail:      <GumDetail />,
		detailTitle: 'Подробнее о дисконтной программе «КЛУБ ПОСТОЯННЫХ ПОКУПАТЕЛЕЙ»',
	},
	{
		key:         1,
		title:       'Дисконтная программа «КАРТА ГОСТЯ»',
		img:         require('./media/guest.jpg'),
		description: [],
		detail:      <GuestDetail />,
		detailTitle: 'Подробнее о дисконтной программе «КАРТА ГОСТЯ»',
	},
	{
		key:         2,
		title:       'Дисконтная программа «МОЦНАЯ КАРТКА»',
		img:         require('./media/power.jpg'),
		description: [],
		detail:      <PowerDetail />,
		detailTitle: 'Подробнее о дисконтной программе «МОЦНАЯ КАРТКА»',
	},
	{
		key:         3,
		title:       'Дисконтная программа «КАРТА УЧАЩЕГОСЯ»',
		img:         require('./media/student.jpg'),
		description: [],
		detail:      <StudentDetail />,
		detailTitle: 'Подробнее о дисконтной программе «КАРТА УЧАЩЕГОСЯ»',
	},
]

/**
 * Render loyalty program page
 *
 *
 * @return {*}
 * @constructor
 */
export const LoyaltyProgram = () => {
	useEffect(() => {
		setMetaInfo()
	}, [])

	return (
		<StaticPageWrapper title={title} extraBreadcrumbs={[{ title: 'Покупателям', path: '' }]}>
			<div className={styles.loyaltyProgramWrapper}>
				<div className={styles.loyaltyProgramHeader}>
					<strong>К сведению владельцев дисконтных карт!</strong>
					<p>
						В дисконтных программах, действующих в ОАО «ГУМ»,
						<strong> не участвует </strong>
						продукция отдела косметики H&B (ул.Ленина, 6)
					</p>
				</div>
				{
					loyaltyProgramArray.map(item => (<CollapseInfoCard key={item.key} info={item} />))
				}
				<p>
					<strong>К сведению владельцев дисконтных карт!</strong>
				</p>
				<p>
					<strong>С 1 мая 2018 года </strong>
					внесены изменения в порядок обращения дисконтных карт в ОАО«ГУМ»:
					<strong> секция «Продовольственные товары»</strong>
					, расположенная на 1 этаже универмага,
					<strong>исключена из участия в дисконтных программах </strong>
					- «Клуб постоянных покупателей»,
					«Моцная картка», «Карта учащегося», «Карта гостя».
				</p>
				<p>
					<strong>С 1 февраля 2021 года </strong>
					любая скидка в ОАО «ГУМ», в том числе по дисконтной карте, предоставляется на товары,
					розничная цена которых (за 1 шт., 1 м.)
					<strong> составляет 5,0 руб. и больше.</strong>
				</p>
			</div>
		</StaticPageWrapper>
	)
}
