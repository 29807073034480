/**
 * Get the right declination
 *
 * @param {number} count
 * @param {Array} titles - array of strings('one', 'fiew', 'many')
 *
 * @return {String}
 */
export const countFormatter = (count, titles) => {
	if (count % 10 === 1 && count % 100 !== 11) {
		return `${count} ${titles[0]}`
	}

	if (count % 10 >= 2 && count % 10 <= 4 && count % 100 < 10 || count % 100 >= 20) {
		return `${count} ${titles[1]}`
	}

	return `${count} ${titles[2]}`
}

export const reviewOptions  = ['отзыв', 'отзыва', 'отзывов']
export const productOptions = ['товар', 'товара', 'товаров']
export const ratingOptions  = ['оценка', 'оценки', 'оценок']

// For example:

// countFormatter(1, reviewOptions) => "1 отзыв"
// countFormatter(3, reviewOptions) => "3 отзыва"
// countFormatter(7, reviewOptions) => "7 отзывов"
