import { setSiteBackground, setShowImg, setFontSize, setFontKerning, setIsShow } from 'reduxm/modules/visionPanel/action'

const mapDispatchToProps = {
	setSiteBackground,
	setShowImg,
	setFontSize,
	setFontKerning,
	setIsShow,
}

export { mapDispatchToProps }
