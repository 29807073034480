import React from 'react'

const googleMapLink = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1857.2078588825966!2d27.556463827621904' +
					  '!3d53.900239349289315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dbcfc1f0c10db3%3A0' +
					  'x3c62938f02eb9022!2z0JPQo9Cc!5e0!3m2!1sru!2sby!4v1597920805332!5m2!1sru!2sby'

/**
 * Gum location iframe
 *
 * @return {*}
 * @constructor
 */
export const Location = () => (
	<iframe
		width="100%"
		height="100%"
		title="map"
		src={googleMapLink}
		frameBorder="0"
		aria-hidden="false"
	/>
)
