import { NormalizeApi } from '@kakadu-dev/base-frontend-components/lib/modules/normalizeApi'

const BASE_ENDPOINT = 'base.rpc.products'

export const ProductsApi = NormalizeApi({
	view:         `${BASE_ENDPOINT}.view`,
	list:         `${BASE_ENDPOINT}.index`,
	byCategories: `${BASE_ENDPOINT}.by-categories`,
	create:       `${BASE_ENDPOINT}.create`,
	update:       `${BASE_ENDPOINT}.update`,
	delete:       `${BASE_ENDPOINT}.delete`,
	batchUpload:  `${BASE_ENDPOINT}.batch-upload-attachments`,
	byBarcode:    `${BASE_ENDPOINT}.by-barcode`,
	addToStock:   `${BASE_ENDPOINT}.add-to-stock`
})
