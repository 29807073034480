import styles from 'components/user/UserAddCardForm/styles.scss'
import React from 'react'

/**
 * Get card fields
 *
 * @param {Object} formRefs
 *
 * @return {Array}
 */
export const getCardFields = (formRefs) => {
	const { number, cvc, name, month, year } = formRefs

	const backdrop = <div className={[styles.backDrop,
		month.current?.value?.length === 2 && styles.hidden].join(' ')} />

	return [
		{
			id:          'number',
			type:        'number',
			ref:         number,
			'data-cp':   'cardNumber',
			pattern:     '[\\d| ]{16}',
			placeholder: 'Номер карты',
			xs:          8,
		},
		{ id: 'cvc', type: 'number', ref: cvc, 'data-cp': 'cvv', pattern: /\d{3}/, placeholder: 'CVC', xs: 4 },
		{
			id:          'name',
			type:        'text',
			ref:         name,
			'data-cp':   'name',
			pattern:     '[a-zA-Z]{5,30}',
			placeholder: 'Имя владельца',
			xs:          6,
		},
		{
			id:          'expiryMonth',
			type:        'number',
			ref:         month,
			'data-cp':   'expDateMonth',
			pattern:     '[0|1]\\d',
			placeholder: 'Месяц',
			xs:          3,
		},
		{
			id:          'expiryYear',
			type:        'number',
			ref:         year,
			'data-cp':   'expiryYear',
			pattern:     '[2]\\d',
			placeholder: 'Год',
			xs:          3,
			className:   styles.yearInput,
			children:    backdrop,
		},
	]
}
