import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import plus from 'assets/images/basket/add.svg'
import garbage from 'assets/images/basket/delete.svg'
import minus from 'assets/images/basket/minus.svg'
import { DottedLine } from 'components/global/DottedLine'
import { Price } from 'components/global/Price'
import * as PropTypes from 'prop-types'
import React, {
	useEffect,
	useState,
	useMemo,
} from 'react'
import { Link } from 'react-router-dom'
import {
	Col,
	Row,
	Spinner,
} from 'reactstrap'
import { HierarchicalService } from 'services/HierarchicalService'
import styles from './styles.scss'

/**
 * Render basket product
 *
 * @param {CartProduct} cartProduct
 * @param {object} updateCartProductState
 * @param {object} deleteCartProductState
 * @param {function} updateProductCount
 * @param {function} deleteCartProduct
 *
 * @return {*}
 * @constructor
 */
export const BasketItem = ({
	cartProduct,
	updateCartProductState,
	deleteCartProductState,
	updateProductCount,
	deleteCartProduct,
}) => {
	const [isFetching, setIsFetching]             = useState(false)
	const [fetchedProductId, setFetchedProductId] = useState(0) // for fetching on current product

	const updateFetching = StateService.create(updateCartProductState).isFetching()
	const deleteFetching = StateService.create(deleteCartProductState).isFetching()

	const commonFetching = updateFetching || deleteFetching

	const {originalPrice, sellingPrice } = useMemo(() => cartProduct.getRawModel()?.minPrice || {}, [cartProduct])

	useEffect(() => {
		if (fetchedProductId === cartProduct.getProduct().getId()) {
			setIsFetching(true)
		} else {
			setIsFetching(false)
		}
	}, [updateFetching, deleteFetching])

	useEffect(() => {
		if (!updateFetching || !deleteFetching) {
			setFetchedProductId(0)
		}
	}, [updateFetching, deleteFetching])

	const totalPrice = useMemo(() =>
		cartProduct.getCount() * sellingPrice, [cartProduct, sellingPrice])

	const totalFullPrice = useMemo(() =>
		cartProduct.getCount() * originalPrice, [cartProduct, originalPrice])

	const link       = HierarchicalService.getProductLink(cartProduct.getProduct())

	/**
	 * Increase counter
	 *
	 * @param {boolean} isIncreese
	 * @return {boolean|undefined}
	 */
	const increaseCount = (isIncreese = true) => {
		if (!commonFetching) {
			setFetchedProductId(cartProduct.getProduct().getId())

			if (isIncreese) {
				updateProductCount(cartProduct.getId(), cartProduct.getCount() + 1)
			} else if (!isIncreese && cartProduct.getCount() > 1) {
				updateProductCount(cartProduct.getId(), cartProduct.getCount() - 1)
			}
		}
	}

	const removeProduct = () => {
		setFetchedProductId(cartProduct.getProduct().getId())

		deleteCartProduct(cartProduct.getId())
	}

	return (
		<div
			key={cartProduct.getId()}
			className={styles.item_wrapper}
		>
			<DottedLine />
			<Row className={styles.row}>

				<Col xs={3} className={styles.image}>
					<Link to={link} className={styles.link}>
						<img
							src={cartProduct.getProduct().getPreview()}
							alt="product"
						/>
					</Link>
				</Col>

				<Col xs={4} md={3} className={styles.col}>
					<Link to={link} className={styles.title}>
						{cartProduct.getProduct().getTitle()}
					</Link>
				</Col>

				<Col xs={5} md={6} className={styles.orderInfo}>
					<div className={styles.counterWrapper}>
						<div className={styles.counter}>
							<div
								role='button'
								tabIndex='0'
								onClick={() => increaseCount(false)}
								className={[
									styles.btn, styles.minus,
									commonFetching || cartProduct.getCount() === 1 ? styles.disabled : '',
								].join(' ')}
							>
								<img src={minus} alt="minus" />
							</div>
							<div className={styles.count}>
								{cartProduct.getCount()}
							</div>
							<div
								role='button'
								tabIndex='0'
								onClick={increaseCount}
								className={[
									styles.btn, styles.plus,
									commonFetching ? styles.disabled : '',
								].join(' ')}
							>
								<img src={plus} alt="plus" />
							</div>
							{updateFetching && isFetching && (
								<Spinner className={styles.spinner} color="success" />
							)}
						</div>
						{cartProduct.getCount() > 1 && (
							<div className={styles.price}>
								<Price value={sellingPrice} />
								{' /шт.'}
							</div>
						)}
					</div>
					<div className={styles.total_price}>
						<Price value={totalPrice} />
						<div className={styles.price}>
							<Price value={totalFullPrice} />
						</div>
					</div>
					<div
						role='button'
						tabIndex={0}
						className={styles.remove}
						onClick={removeProduct}
					>
						{deleteFetching && isFetching
						&& <Spinner className={styles.spinner} color="success" />
						}
						<img src={garbage} alt="garbage" />
					</div>
				</Col>
			</Row>
		</div>
	)
}

BasketItem.propTypes = {
	cartProduct:            PropTypes.object,
	updateCartProductState: PropTypes.object,
	deleteCartProductState: PropTypes.object,
	updateProductCount:     PropTypes.func,
	deleteCartProduct:      PropTypes.func,
}

BasketItem.defaultProps = {
	cartProduct:            {},
	updateCartProductState: {},
	deleteCartProductState: {},
	updateProductCount:     () => null,
	deleteCartProduct:      () => null,
}
