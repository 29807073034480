import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { CardSelection } from './index'

const mapStateToProps = state => ({
	cardsState:   StateSelector.payment.list(state),
	userState:    StateSelector.user.getUser(state),
	addCardState: StateSelector.payment.createWithRedirect(state),
})

const mapDispatchToProps = {
	getCards:    DispatchSelector.payment.list,
	addCard:     DispatchSelector.payment.createWithRedirect,
	setMainCard: DispatchSelector.payment.setMain,
}

export const CardSelectionStore = connect(mapStateToProps, mapDispatchToProps)(CardSelection)
