import { connect } from 'react-redux'
import { ArticleCategory } from './index'
import {
	mapDispatchToProps,
	mapStateToProps
} from './index.props'

const ArticleCategoryInStore = connect(
	mapStateToProps,
	mapDispatchToProps
)(ArticleCategory)

export { ArticleCategoryInStore }
