import Icon from 'assets/icomoon/Icon'
import { BlankButton } from 'components/global/BlankButton'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render arrow for slider
 *
 * @param {string} direction
 * @param {Function} onClick
 *
 * @return {*}
 * @constructor
 */
export const Arrow = ({ direction, onClick }) => (
	<BlankButton onClick={onClick} className={styles[`arrow-${direction}`]}>
		<Icon icon={`arrow-${direction}2`} size={25} color="#535353" />
	</BlankButton>
)

Arrow.propTypes = {
	direction: PropTypes.string,
	onClick:   PropTypes.func,
}

Arrow.defaultProps = {
	direction: '',
	onClick:   () => null,
}
