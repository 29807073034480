import {
	Customer,
	DeliveryAddress,
} from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import {
	CloseRounded as Cancel,
	EditRounded as Edit,
} from '@material-ui/icons'
import { userAddressFields } from 'components/Form/User/address'
import { userPersonalDataFields } from 'components/Form/User/personalData'
import { userSettingsFields } from 'components/Form/User/settings'
import { BlankButton } from 'components/global/BlankButton'
import { Button } from 'components/global/Button'
import { InputField } from 'components/global/InputField'
import { Spinner } from 'components/global/Spinner'
import { Header } from 'containers/User/Header'
import { AnchorList } from 'containers/User/UserData/AnchorList'
import { Notification } from 'helpers/Notification'
import * as PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import styles from './styles.scss'

const userQuery = DataProvider
	.buildQuery()
	.addBody({ query: { expands: ['settings', 'city.country'] } })

const userAddressQuery = DataProvider
	.buildQuery()
	.addBody({
		query: {
			filter:  { isDefault: true },
			expands: ['city'],
		},
	})

const initialActiveTab = 'main'
const forms            = {
	main:     userPersonalDataFields,
	contacts: userSettingsFields,
	address:  userAddressFields,
}

/**
 * Render user personal data page
 *
 * @param {Function} getUser
 * @param {Function} updateUser
 * @param {Object} userState
 * @param {Object} updateState
 * @param {Function} updateUserSettings
 * @param {Function} getUserAddresses
 * @param {Function} createAddress
 * @param {Function} updateAddress
 * @param {Object} userAddressesState
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const UserData = ({
	getUser,
	updateUser,
	userState,
	updateUserSettings,
	getUserAddresses,
	createAddress,
	updateAddress,
	userAddressesState,
	isFetching,
}) => {
	const [fieldsData, setFieldsData] = useState({})
	const [activeTab, setActiveTab]   = useState(initialActiveTab)
	const [isEdit, setIsEdit]         = useState(false)

	const user        = useMemo(() => Customer.create(userState), [userState])
	const settings    = useMemo(() => user.getSettings(), [user])
	const userAddress = useMemo(() => DeliveryAddress.create(userAddressesState?.result?.list?.[0]),
		[userAddressesState])

	const getInitialFieldsData = useCallback(() => (
		{
			main:     { ...user.getAttributes() },
			contacts: { ...settings.getAttributes() },
			address:  { ...userAddress.getAttributes() },
		}
	), [user, settings, userAddress])

	const addressAction = userAddress.isExist() ? updateAddress : createAddress

	useEffect(() => {
		getUserAddresses(userAddressQuery)
		getUser(userQuery)
	}, [getUser, getUserAddresses])

	useEffect(() => {
		setFieldsData(getInitialFieldsData())
	}, [getInitialFieldsData])

	const submit = () => {
		if (isEdit) {
			const { main, contacts, address } = fieldsData

			const query = DataProvider
				.buildQuery()
				.setSuccessCallback(() => Notification.add({
					title:   'Успех!',
					message: 'Данные сохранены',
					type:    'success',
				}))

			switch (activeTab) {
				case 'main':
					updateUser(query.addBody({ attributes: main }))
					break
				case 'contacts':
					updateUserSettings(query.addBody({ attributes: contacts }))
					break
				case 'address':
					addressAction(query.addBody({ ...address }))
					break
				default:
					break
			}
			setIsEdit(false)
		}
	}

	const inputHandler = (event, value) => {
		let nextAttributes

		const { name } = event?.target ?? 'undefined'

		if(name === 'gender'){
			nextAttributes = { [name]: event.target.value }
		}
		else{
			if (value !== undefined) {
				nextAttributes = { [event]: value }
			} else {
				nextAttributes = { [name]: event.target.value }
			}
		}

		setFieldsData({ ...fieldsData, [activeTab]: { ...fieldsData[activeTab], ...nextAttributes } })
	}

	const tabHandler = useCallback(nextActiveTab => {
		setIsEdit(false)
		setActiveTab(nextActiveTab)
	}, [])

	const editAction = useCallback(() => {
		if (!isEdit) {
			setIsEdit(true)
		} else {
			setFieldsData(getInitialFieldsData())
			setIsEdit(false)
		}
	}, [getInitialFieldsData, isEdit])

	return (
		<div className={styles.row}>
			<Spinner isFetching={isFetching} block />
			<Header title="Персональная информация" userName={user.getFullName()} />
			<BlankButton className={styles.editBtn} onClick={editAction}>
				{isEdit ? <Cancel /> : <Edit />}
			</BlankButton>
			<AnchorList activeTab={activeTab} tabHandler={tabHandler} />
			<div className={[styles.forms, isEdit && styles.edit].join(' ')}>
				{forms[activeTab].map(field => (
					<InputField
						key={field.id}
						onChange={inputHandler}
						value={fieldsData?.[activeTab]?.[field.id] ?? ''}
						field={field}
						groupClassName={styles[field.id]}
					/>
				))}
			</div>
			<Button
				title="Сохранить"
				disabled={!isEdit}
				fetching={isFetching}
				onClick={submit}
				style={styles.submit}
			/>
		</div>
	)
}

UserData.propTypes = {
	createAddress:      PropTypes.func,
	getUser:            PropTypes.func,
	getUserAddresses:   PropTypes.func,
	isFetching:         PropTypes.bool,
	updateAddress:      PropTypes.func,
	updateUser:         PropTypes.func,
	updateUserSettings: PropTypes.func,
	userAddressesState: PropTypes.object,
	userState:          PropTypes.object,
}

UserData.defaultProps = {
	createAddress:      () => null,
	getUser:            () => null,
	getUserAddresses:   () => null,
	isFetching:         false,
	updateAddress:      () => null,
	updateUser:         () => null,
	updateUserSettings: () => null,
	userAddressesState: {},
	userState:          {},
}
