import { StarRating } from 'components/global'
import { Price } from 'components/global/Price'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import {
	Col,
	Row,
} from 'reactstrap'
import { HierarchicalService } from 'services/HierarchicalService'
import styles from './styles.scss'

/**
 * Render search result item
 *
 * @param {Product} product
 * @param {function} onClickLink
 *
 * @return {*}
 * @constructor
 */
const SearchResultItem = ({ product, onClickLink }) => {
	const link = HierarchicalService.getProductLink(product)
	const isNotInStock = 	Boolean(product.model.isNotInStock)

	return (
		<Link
			to={link}
			className={styles.item}
			onClick={onClickLink}
		>
			<Row>
				<Col md="3" className={styles.images}>
					<img
						src={product.getPreview()}
						className={styles.img}
						alt={product.getAlias()}
					/>
				</Col>

				<Col md="9" className={styles.info}>
					<div>
						<p className={styles.title}>
							{product.getTitle()}
						</p>

						<div className={styles.rating}>
							<StarRating
								initialRating={product.getRating() || 5}
								size={20}
								readonly
							/>
						</div>
					</div>

					<p className={styles.price}>
						{isNotInStock
							?
							<span className={styles.real}>{'Нет в наличии'}</span>
							:
						<Price value={product.getPrice()} />
						}
					</p>
				</Col>
			</Row>
		</Link>
	)
}

SearchResultItem.propTypes = {
	onClickLink: PropTypes.func,
	product:     PropTypes.object,
}

SearchResultItem.defaultProps = {
	onClickLink: () => null,
	product:     {},
}

export { SearchResultItem }
