import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render user profile header
 *
 * @param {string} userName
 * @param {string} title
 *
 * @return {*}
 * @constructor
 */
export const Header = ({ userName, title }) => (
	<>
		<div className={styles.name}>
			{userName}
		</div>
		<div className={styles.title}>{title}</div>
	</>
)

Header.propTypes = {
	title:    PropTypes.string,
	userName: PropTypes.string,
}

Header.defaultProps = {
	title:    '',
	userName: '',
}
