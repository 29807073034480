import React from 'react'
import ContentLoader from 'react-content-loader'

const DropdownPlaceholder = () => (
	<ContentLoader
		speed={1}
		width={250}
		height={160}
		viewBox="0 0 250 160"
		backgroundColor="#e9e9e9"
		foregroundColor="#f5f5f5"
		uniqueKey="dropdownPlaceholder"
	>
		<rect x="1" y="5" rx="5" ry="20" width="180" height="20" />
		<rect x="190" y="5" rx="5" ry="20" width="20" height="20" />
	</ContentLoader>
)

export default DropdownPlaceholder
