import { HomeStore as BasketHome } from 'containers/Basket'
import { CheckoutStore } from 'containers/Basket/Checkout/index.store'
import { CreatedOrderStore } from 'containers/Static/CreatedOrder/index.props'
import wrappers from 'routing/wrappers'

export const basketPages = [
	{
		path:      '/basket',
		exact:     true,
		component: wrappers(BasketHome),
	},
	{
		path:      '/basket/checkout',
		exact:     true,
		component: wrappers(CheckoutStore),
	},
	{
		path:      '/basket/order-created/:id',
		exact:     true,
		component: wrappers(CreatedOrderStore),
	},
]
