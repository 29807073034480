import { connect } from 'react-redux'
import { ArticleList } from './index'
import {
	mapDispatchToProps,
	mapStateToProps
} from './index.props'

const ArticleListInStore = connect(
	mapStateToProps,
	mapDispatchToProps
)(ArticleList)

export { ArticleListInStore }
