import {
	AppealsReducers,
	CategoriesReducers,
	CitiesReducers,
	CountriesReducers,
	CustomersAddressesReducers,
	CustomersFavoritesReducers,
	CustomersReducers,
	OrdersReducers,
	PaymentReducers,
	ProductsAttachmentsReducers,
	ProductsReviewsReducers,
	SeoReducers,
	UserReducers,
} from '@kakadu-dev/base-frontend-components'
import AuthReducers from './auth/reducer'
import EmailReducer from './email/reducer'
import StaticPagesReducer from './staticPages/reducer'
import CertificateForm from './certificatesForm/reducer'
import CartReducers from './cart/reducer'
import DiscountCardReducer from './discountCard/reducer'
import ProductsReducers from './products/reducer'

const allReducers = {
	auth:                AuthReducers,
	user:                UserReducers,
	cities:              CitiesReducers,
	countries:           CountriesReducers,
	customers:           CustomersReducers,
	products:            ProductsReducers,
	categories:          CategoriesReducers,
	orders:              OrdersReducers,
	cart:                CartReducers,
	productsReviews:     ProductsReviewsReducers,
	productsAttachments: ProductsAttachmentsReducers,
	payment:             PaymentReducers,
	customersAddresses:  CustomersAddressesReducers,
	customersFavorites:  CustomersFavoritesReducers,
	seo:                 SeoReducers,
	appeals:             AppealsReducers,
	email:               EmailReducer,
	staticPages:         StaticPagesReducer,
	certificateForm:     CertificateForm,
	discountCard:        DiscountCardReducer,
}

export {
	allReducers,
}
