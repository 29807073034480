import { PageHeading } from 'components/global'
import WordsCloud from 'components/social/TagCloud'
import { data } from 'components/social/TagCloud/data'
import React, { useState } from 'react'
import {
	Col,
	Container,
	Row,
} from 'reactstrap'
import styles from './styles.scss'

/**
 * Render social page
 *
 * @return {*}
 * @constructor
 */
export const Social = () => {
	const [wordsList, setWordsList] = useState([])

	const maxWordsLength = 10

	const toggle = (word) => {
		if (wordsList.length === maxWordsLength && !wordsList.includes(word)) {
			return
		}
		if (wordsList.includes(word)) {
			wordsList.splice(wordsList.indexOf(word), 1)
			setWordsList([...wordsList])

			return
		}
		setWordsList([...wordsList, word])
	}

	return (
		<section className={styles.social}>
			<PageHeading
				textCenter
				title='Выберите теги, которые Вам интересны'
			/>
			<Container>
				<Row>
					<Col xs={9} className={styles.cloud}>
						<WordsCloud
							toggle={toggle}
							words={data}
							arr={wordsList}
							count={wordsList.length}
						/>
					</Col>
					<Col xs={3}>
						<div className={styles.wordsWrapper}>
							<div className={styles.header}>
								{'Список ваших предпочтений'}
							</div>
							<div className={styles.list}>
								{wordsList.map(word =>
									<div
										role="button"
										tabIndex={0}
										className={styles.word}
										key={word}
										onClick={() => toggle(word)}
									>
										{word}
									</div>)}
							</div>
							<div className={styles.footer}>
								<button type="button" className={styles.sendBtn}>
									{'Сохранить'}
								</button>
								<div className={styles.counter}>
									<span>{'Выбрано'}</span>
									<span className={styles.value}>{wordsList.length}</span>
									<span>{'из 10'}</span>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}
