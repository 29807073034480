import Icon from 'assets/icomoon/Icon'
import img from 'assets/images/payment/cards.png'
import { social } from 'containers/Home/Footer/data'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.scss'
import { injectIntl } from 'react-intl'

/**
 * Render social
 *
 * @return {*}
 * @constructor
 */
export const Social = ({ intl }) => (
	<div className={styles.social}>
		<p>{intl.messages['general.social.title']}</p>
		<div>
			{social.map(item => (
				<a key={item.link} href={item.link} target='_blank' rel="noopener noreferrer">
					<Icon icon={item.icon} size={25} />
				</a>
			))}
		</div>
		{
			intl.locale === 'be' ? null :
				<>
					<p>Программа лояльности</p>
					<Link to="/buyers/b-loyalty-program" className={styles.imageLink}>
						<img src={img} alt="loyalty" />
					</Link>
				</>
		}
	</div>
)

export default injectIntl(Social)
