import React, {
	useCallback,
} from 'react'
import PropTypes from 'prop-types'
import {
	Checkbox as CheckBox,
	FormControlLabel,
} from '@material-ui/core'
import './styles.css'

export const Checkbox = ({ id, title, value, handler }) => {

	const onChange = useCallback((event) => {
		handler(id, event.target.checked )
	}, [id, handler])

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<FormControlLabel
				control={
					<CheckBox
						id={id}
						name={id}
						checked={value}
						onChange={onChange}
						classes={{
							root: 'custom-checkbox-root',
							colorSecondary: 'custom-colorSecondary',
							checked: 'custom-checked',
						}}
					/>}
				label={title}
				labelPlacement="end"
				classes={{
					root: 'control-label-root',
				}}
			/>
		</div>
	)
}

Checkbox.propTypes = {
	id:    PropTypes.string,
	value: PropTypes.bool,
}
Checkbox.defaultProps = {
	id:    'undefined',
	value: false,
}
