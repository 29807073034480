export const userMenuLinks = [
	{
		id:    1,
		title: 'Личный кабинет',
		href:  '/user/profile',
	},
	{
		id:    2,
		title: 'Избранное',
		href:  '/user/favorite',
	},
	{
		id:    3,
		title: 'История заказов',
		href:  '/user/history',
	},
	{
		id:    4,
		title: 'Активация дисконтной карты',
		href:  '/user/discount',
	},
]
