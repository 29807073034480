import { takeLatest } from 'redux-saga/effects'
import SagasHelper from '@kakadu-dev/base-frontend-components/lib/helpers/Redux/SagasHelper'
import SagasHelperLists from './SagasHelperLists'
import { PRODUCTS_ACTIONS, ProductsApi } from './index'

/**
 * Create product
 */
function* create(action) {
	yield SagasHelper.action(action, ProductsApi.create)
}

/**
 * Get product
 */
function* view(action) {
	yield SagasHelper.action(action, ProductsApi.view)
}

/**
 * Get product by barcode
 */
function* viewBarcode(action) {
	yield SagasHelper.action(action, ProductsApi.viewBarcode)
}

/**
 * Get products
 */
function* list(action) {
	yield SagasHelper.action(action, ProductsApi.list)
}

function* search(action) {
	yield SagasHelperLists.listsAction(action, ProductsApi.list)
}

/**
 * Update product
 */
function* update(action) {
	yield SagasHelper.action(action, ProductsApi.update)
}

/**
 * Delete product
 */
function* deleteModel(action) {
	yield SagasHelper.action(action, ProductsApi.delete)
}

/**
 * Add product to stock
 */
function* addToStock(action) {
	yield SagasHelper.action(action, ProductsApi.addToStock)
}

/**
 * Upload products attachments
 */
function* batchUpload(action) {
	yield SagasHelper.action(action, ProductsApi.batchUpload)
}

/**
 * Get list from categories
 */
function* pageLists(action) {
	yield SagasHelper.listsAction(action, ProductsApi.list)
}

/**
 * Get products list by categories
 */
function* byCategories(action) {
	yield SagasHelper.action(action, ProductsApi.byCategories, null, null, null)
}

export default [
	takeLatest(PRODUCTS_ACTIONS.CREATE, create),
	takeLatest(PRODUCTS_ACTIONS.VIEW, view),
	takeLatest(PRODUCTS_ACTIONS.VIEW_BARCODE, viewBarcode),
	takeLatest(PRODUCTS_ACTIONS.LIST, list),
	takeLatest(PRODUCTS_ACTIONS.BY_CATEGORIES, byCategories),
	takeLatest(PRODUCTS_ACTIONS.SEARCH, list),
	takeLatest(PRODUCTS_ACTIONS.SEARCH_SUB, search),
	takeLatest(PRODUCTS_ACTIONS.UPDATE, update),
	takeLatest(PRODUCTS_ACTIONS.DELETE, deleteModel),
	takeLatest(PRODUCTS_ACTIONS.ADD_TO_STOCK, addToStock),
	takeLatest(PRODUCTS_ACTIONS.BATCH_UPLOAD, batchUpload),
	takeLatest(PRODUCTS_ACTIONS.HOME_PAGE_LISTS, pageLists),
	takeLatest(PRODUCTS_ACTIONS.CATEGORIES_PAGE_LISTS, pageLists),
]
