import SearchQuery from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider/SearchQuery'
import { Notification } from 'helpers/Notification'

/**
 * Get search query params if exist
 *
 * @param {object} params
 *
 * @return {object}
 */
const getSearchQueryParams = (params) => {
	return (params instanceof SearchQuery)
		? params.getReduxRequestParams()
		: params
}

/**
 * Show error messages when request failed
 *
 * @return {function(*): function(*=): *}
 * @constructor
 */
const Notifications = () => next => action => {
	const { type, params, meta } = action

	if (type.startsWith('REQUEST_ERROR')) {
		// let { error: { message } }                                 = getSearchQueryParams(params)
		const { error: { messageData }, disableErrorFlashMessage } = getSearchQueryParams(params)

		if (disableErrorFlashMessage === true
			|| (typeof disableErrorFlashMessage === 'function' && disableErrorFlashMessage(params))
		) {
			return next(action)
		}

		// Check response status
		if (messageData) {
			const { status } = messageData

			switch (status) {
				case 404:
				case 500:
				case 400:
				case 403:
				case 429:
					break
				default:
					// Skip other statuses
					return next(action)
			}
		}

		// if (message) {
		// 	if (message === 'Failed to fetch') {
		// 		message = 'Подключение к интернету отсутствует'
		// 	}
		//
		// 	Notification.add({ title: 'Ошибка', message })
		// }
	} else if (type.indexOf('/FETCH_OFFLINE_MODE') !== -1) {
		if (meta && meta.retry) {
			Notification.add({
				title:   'Подключение к интернету отсутствует',
				message: 'Ваш запрос, будет обработан после подключения к интернету',
				type:    'warning',
			})
		}
	}

	return next(action)
}

export {
	Notifications,
}
