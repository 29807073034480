import {
	DispatchSelector as DefaultDispatchSelector,
	StateSelector as DefaultStateSelector,
} from '@kakadu-dev/base-frontend-components'
import { AuthStateSelectors, AuthActions } from 'reduxm/modules/auth'
import { EmailStateSelectors, EmailActions } from './email'
import { StaticPagesStateSelectors, StaticPagesActions } from './staticPages'
import { CertificatesFormStateSelectors, CertificatesFormActions } from './certificatesForm'
import { CartStateSelectors, CartActions } from './cart'
import { DiscountCardSelectors, DiscountCardActions } from './discountCard'
import { ProductsStateSelectors, ProductsActions } from './products'
import { StylistFormActions } from './stylistForm'

export const StateSelector = {
	appeals:             DefaultStateSelector.appeals,
	auth:                AuthStateSelectors,
	user:                DefaultStateSelector.user,
	countries:           DefaultStateSelector.countries,
	customers:           DefaultStateSelector.customers,
	customersAddresses:  DefaultStateSelector.customersAddresses,
	customersFavorites:  DefaultStateSelector.customersFavorites,
	orders:              DefaultStateSelector.orders,
	products:            ProductsStateSelectors,
	productsReviews:     DefaultStateSelector.productsReviews,
	productsAttachments: DefaultStateSelector.productsAttachments,
	cities:              DefaultStateSelector.cities,
	categories:          DefaultStateSelector.categories,
	cart:                CartStateSelectors,
	payment:             DefaultStateSelector.payment,
	settings:            DefaultStateSelector.settings,
	seo:                 DefaultStateSelector.seo,
	email:               EmailStateSelectors,
	staticPages:         StaticPagesStateSelectors,
	certificateForm:     CertificatesFormStateSelectors,
	discountCard:        DiscountCardSelectors,
}

export const DispatchSelector = {
	appeals:             DefaultDispatchSelector.appeals,
	auth:                AuthActions,
	user:                DefaultDispatchSelector.user,
	countries:           DefaultDispatchSelector.countries,
	customers:           DefaultDispatchSelector.customers,
	customersAddresses:  DefaultDispatchSelector.customersAddresses,
	customersFavorites:  DefaultDispatchSelector.customersFavorites,
	orders:              DefaultDispatchSelector.orders,
	products:            ProductsActions,
	productsReviews:     DefaultDispatchSelector.productsReviews,
	productsAttachments: DefaultDispatchSelector.productsAttachments,
	cities:              DefaultDispatchSelector.cities,
	categories:          DefaultDispatchSelector.categories,
	cart:                CartActions,
	payment:             DefaultDispatchSelector.payment,
	settings:            DefaultDispatchSelector.settings,
	seo:                 DefaultDispatchSelector.seo,
	email:               EmailActions,
	staticPages:         StaticPagesActions,
	certificateForm:     CertificatesFormActions,
	discountCard:        DiscountCardActions,
	stylistForm:         StylistFormActions,
}
