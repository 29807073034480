import Payment from 'payment'

export const inputs = [
	{ id: 'number', data: 'cardNumber', placeholder: 'Номер карты', xs: 8 },
	{ id: 'cvc', data: 'cvv', placeholder: 'CVC', xs: 4 },
	{ id: 'name', data: 'name', placeholder: 'Имя владельца карты', xs: 6 },
	{ id: 'expiryMonth', data: 'expDateMonth', placeholder: 'Месяц', xs: 3 },
	{ id: 'expiryYear', data: 'expDateYear', placeholder: 'Год', xs: 3 },
]

function clearNumber(value = '')
{
	return value.replace(/\D+/g, '')
}

function clearString(value = '')
{
	return value.replace(/\d+/g, '')
}

export function formatCreditCardNumber(value)
{
	if (!value) {
		return value
	}

	const clearValue = clearNumber(value)
	const nextValue  = `${clearValue.slice(0, 4)} ${clearValue.slice(
		4,
		8,
	)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`

	return nextValue.trim()
}

export function formatCVC(value, prevValue, allValues = {})
{
	const clearValue = clearNumber(value)
	let maxLength    = 4

	if (allValues.number) {
		const issuer = Payment.fns.cardType(allValues.number)
		maxLength    = issuer === 'amex' ? 4 : 3
	}

	return clearValue.slice(0, maxLength)
}

export function formatExpirationDate(value)
{
	const clearValue = clearNumber(value)

	return clearValue.slice(0, 2)
}

export function formatFormData(data)
{
	return Object.keys(data).map(d => `${d}: ${data[d]}`)
}

export function formatName(value)
{
	return clearString(value)
}
