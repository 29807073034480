import { Slide } from 'components/home/HomeSlider/Slides'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import styles from './styles.scss'

/**
 * Render home slider
 *
 * @return {*}
 * @constructor
 */
export const HomeSlider = ({ bannersState }) => {

	const banners = useMemo(() => bannersState?.result?.list ?? [], [bannersState])

	return (
		<>
			{
				(banners.length > 0) &&
				<section className={styles.slider}>
					<div className={styles.container}>
						<Carousel
							showArrows
							showThumbs={false}
							showStatus={false}
							showIndicators
							infiniteLoop
							swipeable
							autoPlay
						>
							{banners.map(item => <Slide key={item.id} image={item.image} linkTo={item.url} />)}
						</Carousel>
					</div>
				</section>
			}
		</>
	)
}

HomeSlider.propTypes = {
	bannersState: PropTypes.object,
}
HomeSlider.defaultProps = {
	bannersState: {},
}
