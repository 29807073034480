import { NormalizeApi } from '@kakadu-dev/base-frontend-components/lib/modules/normalizeApi'

const STATIC_PAGES_ENDPOINT = 'static-pages'

export const StaticPagesApi = NormalizeApi({
	pages:        `${STATIC_PAGES_ENDPOINT}.pages.list`,
	news:        `${STATIC_PAGES_ENDPOINT}.news.list`,
	stocks: `${STATIC_PAGES_ENDPOINT}.stocks.list`,
	banners: `${STATIC_PAGES_ENDPOINT}.banners.list`,
})
