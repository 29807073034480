import { connect } from 'react-redux'
import ProductCard from './index'
import {
	mapDispatchToProps,
	mapStateSelector,
} from './index.props'

const ProductCardStore = connect(
	mapStateSelector,
	mapDispatchToProps,
)(ProductCard)

export { ProductCardStore }
