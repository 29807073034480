import {
	DispatchSelector,
	StateSelector,
} from '@kakadu-dev/base-frontend-components'

const mapStatesToProps = (state) => ({
	userState:        StateSelector.user.getUser(state),
})

const mapDispatchToProps = {
	getUser:        DispatchSelector.user.getUser,
}

export {
	mapDispatchToProps,
	mapStatesToProps,
}
