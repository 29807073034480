/**
 * Set overflow option
 *
 * @param {boolean} isOverFlowNeed | true - lock, false - unlock
 */
export const scrollLock = (isOverFlowNeed = false) => {
	const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

	if (isOverFlowNeed) {
		window.scrollTo(0, 0)
		document.body.style.overflow     = 'hidden'
		document.body.style.paddingRight = `${scrollbarWidth}px`
	} else {
		document.body.style.overflow     = ''
		document.body.style.paddingRight = ''
	}
}
