import React from 'react'
import styles from './styles.scss'

/**
 * Render guest loyalty program detail
 *
 *
 * @return {*}
 * @constructor
 */
export const GuestDetail = () => (
	<div className={styles.detailWrapper}>
		<p>
			ОАО «ГУМ» - партнер проекта&nbsp;
			<a href="http://www.minskpass.by/" target="_blank" rel="noopener noreferrer">«Карта гостя» </a>
			, оператором которого является УП «Мингорсправка».
		</p>
		<p>
			Карта гостя– это электронная карта для туристов,
			которая включает в себя электронный транспортный билет,
			предоставляет ее держателю право на льготы при посещении музеев и экскурсий,
			скидки при приобретении товаров и другие выгодные предложения от
			партнеров проекта «Карта гостя».
		</p>
		<p>
			Дисконтная программа «КАРТА ГОСТЯ» - это программа лояльности ГУМа,
			в рамках которой предъявители многофункциональных пластиковых смарт-карт,
			с размещенным на них логотипами «MinskCard» и «Карта гостя»,
			получают скидки при приобретении товаров.
		</p>
		<div className={styles.detailList}>
			<strong>Правила использования КАРТЫ ГОСТЯ в качестве дисконтной карты</strong>
			<ol>
				<li>
					Дисконтная программа «Карта гостя» действует в универмаге в секциях
					непродовольственных товаров (пр.Независимости,21; ул.Ленина 4,6,8) *.
					Объекты общественного питания ОАО «ГУМ» не участвуют в дисконтной программе.
				</li>
				<li>
					Дисконтная карта предъявляется на кассе перед оплатой покупки.
					Скидка предоставляется путем считывания штрих-кода с оборотной стороны карты.
				</li>
				<li>
					Размер скидки - 5%.* Скидка предоставляется при приобретении товаров,
					розничная цена которых свыше 5,0 руб.
				</li>
				<li>
					Дисконтные карты в рамках программы «Карта гостя» могут
					использоваться только в соответствии с&nbsp;
					<a href="http://gum.by/docs/polojenie_karta_gostya.doc" target="_blank" rel="noopener noreferrer">
						Положением об обращении дисконтных карт в ОАО &quot;ГУМ&quot;
					</a>
					.
				</li>
			</ol>
		</div>
		<div className={styles.detailList}>
			<strong>Какие группы товаров не участвуют в дисконтной программе?</strong>
			<ul>
				<strong>Скидка по дисконтной карте не предоставляется:</strong>
				<li>
					на товары, участие которых в акциях ограничено локальными актами ОАО «ГУМ»,
					в том числе продукция ОАО &quot;Минский часовой завод&quot;, а также холодильники,
					морозильники, стиральные машины;
				</li>
				<li>
					на товары, участвующие в специальных, сезонных акциях;
				</li>
				<li>
					на товары по сниженным ценам в результате уценки;
				</li>
				<li>
					на изделия медицинского назначения и медицинскую технику;
				</li>
				<li>
					на услуги, оказываемые в универмаге;
				</li>
				<li>
					на стоимость дисконтных карт ОАО «ГУМ»;
				</li>
				<li>
					при продаже подарочных сертификатов ОАО «ГУМ»;
				</li>
				<li>
					при продаже товаров в кредит;
				</li>
				<li>
					при продаже товаров по картам «Халва», «Smart карта», «Карта покупок», «Магнит»;
				</li>
				<li>
					на конфискованный товар;
				</li>
				<li>
					при покупке товаров юридическими лицами по безналичному расчету;
				</li>
				<strong>Суммирование нескольких видов скидок не производится.</strong>
			</ul>
		</div>
		<p>
			Условия и размеры скидок, другие льготы предоставляемых другими участниками
			проекта «Карта гостя», определяются самостоятельно каждым предприятием.
			С актуальной информацией о всех возможностях использования
			Карты гостя можно ознакомиться на сайте&nbsp;
			<a href="http://www.minskpass.by/" target="_blank" rel="noopener noreferrer">
				http://www.minskpass.by/
			</a>
		</p>
	</div>
)
