import PersistHelper from '@kakadu-dev/base-frontend-components/lib/helpers/Redux/PersistHelper'
import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { STATIC_PAGES_ACTION } from './actionTypes'

const persistConfig = {
	key:       'staticPages',
	whitelist: [
		STATIC_PAGES_ACTION.PAGES_LIST,
		STATIC_PAGES_ACTION.ARTICLES,
		STATIC_PAGES_ACTION.BANNERS,
	],
}

export default PersistHelper.persist(persistConfig,
	RequestActionHelper.createReducerActions(Object.values(STATIC_PAGES_ACTION)))
