import React from 'react'
import ContentLoader from 'react-content-loader'

const FilterPlaceholder = () => (
	<ContentLoader
		height={780}
		width={300}
		speed={1}
		backgroundColor="#e9e9e9"
		foregroundColor="#f5f5f5"
		uniqueKey="filterPlaceholder"
	>
		<rect x="35" y="10" rx="5" ry="5" width="200" height="20" />
		<rect x="35" y="60" rx="5" ry="5" width="150" height="25" />
		<rect x="35" y="110" rx="5" ry="5" width="100" height="20" />
		<rect x="36" y="160" rx="5" ry="5" width="230" height="25" />
		<rect x="35" y="210" rx="5" ry="5" width="150" height="20" />
		<rect x="35" y="250" rx="5" ry="5" width="230" height="25" />
		<rect x="35" y="300" rx="5" ry="5" width="160" height="20" />
		<rect x="35" y="340" rx="5" ry="5" width="230" height="25" />
		<rect x="35" y="390" rx="5" ry="5" width="140" height="20" />
		<rect x="35" y="430" rx="5" ry="5" width="230" height="20" />
		<rect x="35" y="470" rx="5" ry="5" width="205" height="25" />
		<rect x="35" y="510" rx="5" ry="5" width="120" height="20" />
		<rect x="35" y="550" rx="5" ry="5" width="230" height="25" />
	</ContentLoader>
)

export default FilterPlaceholder
