import { Category } from '@kakadu-dev/base-frontend-components'
import { PageHeading } from 'components/global/PageHeading'
import { MainSectionStore } from 'components/market/Home/CategorySection/index.store'
import { SideBar } from 'components/market/Home/Sidebar'
import { META_ENTITY_TYPE } from 'constants'
import { Error } from 'containers/Static/Error'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useMemo,
} from 'react'
import { useParams } from 'react-router'
import {
	Col,
	Container,
	Row,
} from 'reactstrap'
import { MetaService } from 'services/MetaService'
import styles from './styles.scss'

// Enable get meta data
const setMetaInfo = (categoryId, title) => MetaService.setInfo({
	entity:   META_ENTITY_TYPE.CATEGORY,
	entityId: categoryId,
}, { title })

const getCategory = (hierarchy, root) => Category.create(_.find(hierarchy.result, ['alias', root]))

/**
 * Render market home page
 *
 * @param {Object} hierarchicalState
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const Home = ({ hierarchicalState, isFetching }) => {
	const { root } = useParams()

	const category = getCategory(hierarchicalState, root)

	const { id, title } = useMemo(() => category.model, [category])
	const isError       = useMemo(() => !id && !isFetching && hierarchicalState.response,
		[hierarchicalState.response, id, isFetching])

	useEffect(() => {
		if (id && title) {
			setMetaInfo(id, title)
		}
	}, [id, title])

	return !isError &&
		   <>
			   <PageHeading title={title} breadcrumbs />
			   <Container className="my-5">
				   <Row className="d-flex">
					   <Col xs={4} className={styles.sidebar}>
						   <SideBar id={id} hierarchicalState={hierarchicalState} />
					   </Col>
					   <Col xs={12} lg={8}>
						   <MainSectionStore id={id} hierarchicalState={hierarchicalState} />
					   </Col>
				   </Row>
			   </Container>
		   </> || <Error />
}

Home.propTypes = {
	hierarchicalState: PropTypes.object,
	isFetching:        PropTypes.bool,
}

Home.defaultProps = {
	hierarchicalState: {},
	isFetching:        false,
}
