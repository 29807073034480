import Icon from 'assets/icomoon/Icon'
import { elements } from 'components/home/Advantages/data'
import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import styles from './styles.scss'

/**
 * Render advantages
 *
 * @return {*}
 * @constructor
 */
export const Advantages = () => (
	<section className={styles.advantages}>
		<Container>
			<div className={styles.wrapper}>
				{elements.map(element => (
					<Link key={element.link} to={element.link} className={styles.item}>
						<div className={styles.icon}>
							<Icon icon={element.icon} />
						</div>
						<span className={styles.text}>
							{element.text}
						</span>
					</Link>
				))}
			</div>
		</Container>
	</section>
)
