import { BUILD_TARGET } from '@kakadu-dev/base-frontend-components'
import _ from 'lodash'

/**
 * Convert json string to object or return object
 *
 * @param {string|Object} env
 *
 * @return {Object}
 */
const fromStringOrJson = env => {
	if (typeof env === 'string') {
		try {
			return JSON.parse(env)
		} catch (e) {
			// ignore
		}
	}

	return env
}
export const IS_CLIENT             = BUILD_TARGET === 'client'
export const IS_SERVER             = BUILD_TARGET === 'server'
export const IS_PROD               = process.env.REACT_APP_ENV === 'prod'
export const COUNT_OF_PRODUCT      = _.merge(fromStringOrJson(process.env.REACT_APP_COUNT_OF_PRODUCT || {}), {
	HOME:            4,
	PRODUCTS_LIST:   30,
	PRODUCT_PAGE:    4,
	CATEGORIES_LIST: 3,
})
export const HOME_CATEGORIES_IDS   = fromStringOrJson(process.env.REACT_APP_HOME_CATEGORIES_IDS || [])
export const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || null
export const YANDEX_METRICS_ID = parseInt(process.env.REACT_APP_YANDEX_METRICS_ID) || null

export const META_ENTITY_TYPE = {
	PRODUCT:  'product',
	CATEGORY: 'category',
	ARTICLE:  'article',
}

// Change to true for disable SSR, or load faster for development
export const DISABLE_INITIAL_PROPS = process.env.DISABLE_SSR && process.env.DISABLE_SSR === 'true' || IS_CLIENT
