import { connect } from 'react-redux'
import { BasketHome } from './index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'

const HomeStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(BasketHome)

export { HomeStore }
