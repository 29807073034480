import { Lang } from 'components/home/Header/Lang'
import {
	mapDispatchToProps,
	mapStateToProps,
} from 'components/home/Header/Lang/index.props'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

const LangStore = injectIntl(connect(mapStateToProps, mapDispatchToProps)(Lang))

export { LangStore }
