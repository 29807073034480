import { connect } from 'react-redux'
import { BasketItem } from './index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'

const BasketItemStore = connect(
	mapStateToProps,
	mapDispatchToProps
)(BasketItem)

export { BasketItemStore }
