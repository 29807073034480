import { StateSelector } from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	userCity: StateSelector.user.getUserCity(state),
})
const mapDispatchToProps = {}

export {
	mapStateToProps,
	mapDispatchToProps,
}
