import { Slider } from 'components/global/Slider'
import { StaticPageWrapper } from 'components/static/StaticPageWrapper'
import {
	aboutUsImages,
	historyImages,
	tradeHallImages,
	tradeHallImages2,
} from 'containers/Static/History/data'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { MetaService } from 'services/MetaService'
import img1 from '../History/media/other/01.jpg'
import img2 from '../History/media/other/02.jpg'
import styles from './styles.scss'

const title = 'Гісторыя'

const setMetaInfo = () => MetaService.setInfo({}, { title })

export const getYoutubeIframe = src =>
	<div className="my-4 w-100">
		<iframe
			title="gum history"
			width="100%"
			height="520px"
			src={src}
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		/>
	</div>

/**
 * Render history page
 *
 * @return {*}
 * @constructor
 */
export const HistoryBy = () => {
	useEffect(() => {
		setMetaInfo()
	}, [])

	return (
		<StaticPageWrapper title={title} extraBreadcrumbs={[{ title: 'Аб кампаніі', path: '/about-company' }]}>
			<p>
				<strong>ГУМ</strong>
				{' '}
				— першы найбуйнейшы ўніверсальны магазін г. Мінска. Размешчаны ў самым
				цэнтры сталіцы, на скрыжаванні галоўнай магістралі – пр. Незалежнасці і вул.
				Леніна Упершыню расчыніў свае дзверы для пакупнікоў і гасцей
				{' '}
				<strong>5 лістапада 1951 года.</strong>
			</p>
			<br />
			<p>
				<strong>ГУМ</strong>
				{' '}
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				— славутасць Беларусі. Будынак універмага з'яўляецца помнікам
				архітэктуры і горадабудаўніцтва ХХ стагоддзя.
			</p>
			<Slider images={aboutUsImages} />
			<p>
				На сіметрычна вырашаным фасадзе ўнівермага, вылучаны тры арачныя праёмы,
				якія ўтвараюць парадны ўваход, арыентаваны на пр.Незалежнасці.
				Архітэктурна-мастацкім рашэннем будынка прадугледжана наяўнасць
				вялікіх зашклёных праёмаў і складанага дэкору — шматлікіх франтончыкаў,
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				ляпніны, уставак, геральдычных паяскоў. У інтэр'еры таксама шырока
				выкарыстаны дэкаратыўныя элементы класічнай спадчыны – ляпныя дэталі, мармур,
				хрусталь, незвычайныя вітражы, шырокія лесвіцы і раскошныя калоны.
				<br />
				Ва ўнівермагу штодня абслугоўваюцца больш за 20 тыс.пакупнікоў.
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				Тут прадаюцца тавары для ўсёй сям'і і на ўсе выпадкі жыцця: для дзяцей
				і дарослых, для дома і дачы, для спорту і адпачынку.
			</p>
			<Slider images={tradeHallImages} />
			<p>
				У гандлёвых залах прадстаўлены шырокі асартымент тавараў вядомых беларускіх
				і замежных брэндаў.
			</p>
			<Slider images={tradeHallImages2} />
			<p>
				Мы пастаянна ўдасканальваем метады гандлю,
				ажыццяўляем пошук патрэбных пакупніку тавараў і
				прафесійна іх прадаём, таму што нам дарагі кожны пакупнік і кожны
				партнёр па гандлі.
			</p>
			<br />
			<p>
				Да паслуг пакупнікоў ва ўнівермагу  -
				{' '}
				<Link to="/about-company/commercial-network/cn-canteen-cafeteria">кафэтэрый і сталовая</Link>
				, шэраг платных і бясплатных
				{' '}
				<Link to="/buyers/b-services">дадатковых паслуг</Link>
				.
			</p>
			<br />
			<p>
				У 1997 годзе дзяржаўны ўніверсальны магазін быў ператвораны ў адкрытае акцыянернае таварыства “ГУМ”.
			</p>
			<br />
			<p>
				У цяперашні час гандлёвая сетка ААТ “ГУМ” – гэта не толькі ўнівермаг, размешчаны па праспекце Незалежнасці,
				21, але і гандлёвыя залы па вул. Леніна (Будынкі 4, 6 і 8), а таксама пяць харчовых магазінаў
				{' '}
				<Link to="/about-company/commercial-network/cn-shops-generous">
					“Шчодры” ў Цэнтральным раёне г. Мінска.
				</Link>
				.
			</p>
			<br />
			<h1>АБ ГІСТОРЫІ СТВАРЭННЯ ГУМа</h1>
			<p>
				Гісторыя першага дзяржаўнага ўніверсальнага магазіна пачалася ў 1934 годзе.
				ГУМ адкрыўся ў горадзе Мінску 28 мая ў манументальным будынку былога Польскага банка,
				які стаяў на скрыжаванні Савецкай і Камсамольскай вуліц (цяперашняга пр. Незалежнасці і вул. Камсамольскай).
				Пасля Вялікай Айчыннай вайны ГУМ у свой ранейшы будынак не вярнуўся,
				бо вуліца Савецкая была амаль цалкам разбурана, і яе вырашылі спланаваць нанова.
			</p>
			<div className={styles.image}>
				<img src={img1} alt="history-1" />
			</div>
			<p>
				Рашэнне аб будаўніцтве новага ўніверсальнага магазіна было прынята
				26 ліпеня 1945 года ў Крамлі намеснікам старшыні Саўнаркама СССР Анастасам Мікаянам.
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				Універмаг стаў першым аб'ектам зноў створанага архітэктурнага цэнтра г. Мінска.
				Архітэктарамі праекта былі прызначаны Раман Гегарт і Леў Мелегі.
				У сваёй працы яны абапіраліся на шэдэўры італьянскай архітэктуры –
				базіліку Паладыяна ў венецыянскім горадзе Вічэнца і бібліятэку Марчыяна
			</p>
			<div className={styles.image}>
				<img src={img2} alt="history-2" />
			</div>
			<p>
				5 лістапада 1951 года Мінскі ГУМ сустрэў сваіх першых наведвальнікаў
				у новым будынку агульнай плошчай 6 891 м2, у тым ліку гандлёвай – 3 561 м2.
			</p>
			<br />
			<p>
				Гэта было сапраўднае свята горада. Для пасляваенных людзей гэта быў не магазін, а палац гандлю.
				У магазіне панавала святочная атмасфера, ля ўвахода стаяў швейцар, ззялі люстэркі ...
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				Інтэр'ер будынка дзівіў вока абывацеля. Усё гэта імгненна ператварыла хол універмага-палаца
				ў галоўнае месца сустрэч закаханых.
			</p>
			<Slider images={historyImages} />
			<p>
				Гандлёвае абсталяванне было выканана з каштоўных парод дрэва,
				столі ўпрыгожвалі шыкоўныя люстры з лямпамі дзённага святла,
				на вокнах віселі шаўковыя “міністэрскія” гардзіны, падлогі былі паркетнымі.
				Усюды паліраваны дуб, бронза, шоўк…
			</p>
			<br />
			<p>
				На паверхах знаходзіліся аўтаматы, якія за сімвалічную плату выдавалі порцыю адэкалону,
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				апырскваючы ім пакупніка. У той дзень упершыню на прылаўках з'явіліся імпартныя тавары з Чэхаславакіі,
				Польшчы, ГДР і Францыі. Нідзе не было такога багацця тавараў, як ва ўнівермагу.
				Велізарным попытам карысталася бытавая тэхніка (халадзільнікі, тэлевізары і пральныя машынкі).
				Наведвальнікаў абслугоўвалі 460 прадаўцоў, 30 з іх на той момант прайшлі стажыроўку
				ў Ленінградскім Гандлёвым Доме.
			</p>
			{getYoutubeIframe('https://www.youtube.com/embed/67poKtWOxzE')}
			<p>
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				Перыядам паступальнага развіцця ГУМа з'явіліся 1960–1970-я гады.
				Асаблівая ўвага надавалася ўдасканаленню гандлёвага працэсу.
			</p>
			<br />
			<p>
				У 1969 годзе Мінскі ГУМ увайшоў у лік першых савецкіх магазінаў,
				якія ўкаранілі ў сябе метад самаабслугоўвання.
			</p>
			<br />
			<p>
				У 1973 г. ГУМ пачаў сам рыхтаваць кадры. Універмаг першым у горадзе арганізаваў школу-магазін,
				стаў адным з родапачынальнікаў гэтай практыкі ў сістэме савецкага гандлю.
			</p>
			<br />
			<p>
				У 1976 годзе арганізаваны гандаль па спажывецкіх комплексах: “Тавары для жанчын”,
				“Тавары для мужчын”, “Тавары для дзяцей” і інш. Ва ўнівермагу аказвалася больш за 30 дадатковых паслуг,
				уключаючы раскрой тканін і дробную падгонку адзення, працавалі ашчадныя касы,
				аддзяленне сувязі. Вопыт Мінскага ГУМа пачаў шырока ўкараняцца ў СССР.
			</p>
			<br />
			<p>
				У 1985 ў поўнай меры пачалі ўжываць аўтаматызаваную сістэму ўліку тавараў.
				У гэтыя гады ГУМ – гэта прыкладна 5% у агульным тавараабароце горада.
			</p>
			<br />
			<p>
				У 1997 годзе дзяржаўны ўніверсальны магазін быў ператвораны ў адкрытае акцыянернае таварыства “ГУМ”.
			</p>
			<br />
			<p>
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				ГУМ на працягу ўсёй сваёй дзейнасці не проста гандлёвы аб'ект, а магазін-наватар.
			</p>
			<br />
			<p>
				Мы першыя сярод універмагаў увялі падарункавыя сертыфікаты, дысконтныя карты.
				І з часам маем намер удасканальваць сваю сістэму кліенцкай лаяльнасці “Клуб пастаянных пакупнікоў ГУМа”.
				Мінскі ГУМ быў і застаецца вядучым прадпрыемствам гандлю Рэспублікі Беларусь.
			</p>
		</StaticPageWrapper>
	)
}
