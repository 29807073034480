import { Category } from '@kakadu-dev/base-frontend-components'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { HiddenNavItems } from 'components/home/Header/Navigation/HiddenNavItems'
import { NavItem } from 'components/home/Header/Navigation/NavItem'
import CategoriesPlaceholder from 'components/utils/placeholders/categories'
import * as PropTypes from 'prop-types'
import React, {
	useEffect,
	useMemo,
	useState,
} from 'react'
import { injectIntl } from 'react-intl'
import { byMenu } from './data'
import styles from './styles.scss'

/**
 * Render desktop navigation menu
 *
 * @param {Function} showBackdrop
 * @param {Object} hierarchicalState
 * @param {Object} intl
 *
 * @return {*}
 * @constructor
 */
export const Navigation = ({ showBackdrop, hierarchicalState, intl }) => {
	const [visibleItems, setVisibleItems] = useState(9)

	const getHiddenItemsCount = () => {
		const width = window.innerWidth

		if (width > 1280 || width <= 767) {
			setVisibleItems(9)
		} else
		if (width < 1280 && width > 990) {
			setVisibleItems(5)
		} else if (width < 991 && width > 767) {
			setVisibleItems(3)
		}
	}

	useEffect(() => {
		getHiddenItemsCount()
		window.addEventListener('resize', getHiddenItemsCount)

		return () => window.removeEventListener('resize', getHiddenItemsCount)
	})

	const hierarchical = useMemo(() => {
		if(intl.locale === 'be'){
			return byMenu
		}
		return hierarchicalState
	}, [intl])

	const categories = Category.createList(hierarchical)

	const visibleCategories = categories.slice(0, visibleItems)
	const hiddenCategories  = categories.slice(visibleItems)

	return (
		!StateService.create(hierarchicalState).isFetching() &&
		<nav className={styles.container}>
			{visibleCategories.map((category, idx, array) => (
				<NavItem
					key={category.getId()}
					category={category}
					hierarchical={hierarchical}
					setIsBackdrop={showBackdrop}
					isLeftRender={Math.ceil(array.length / 2) < idx}
				/>
			))}
			<HiddenNavItems
				setIsBackdrop={showBackdrop}
				items={hiddenCategories}
				hierarchical={hierarchical}
			/>
		</nav> || <CategoriesPlaceholder />
	)
}

Navigation.propTypes = {
	hierarchicalState: PropTypes.object,
	showBackdrop:      PropTypes.func,
	intl:              PropTypes.object,
}

Navigation.defaultProps = {
	hierarchicalState: {},
	intl:              {},
	showBackdrop:      () => null,
}

export default injectIntl(Navigation)
