import {
	Category,
	Product,
} from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { ArrowForwardIosRounded as Arrow } from '@material-ui/icons'
import Icon from 'assets/icomoon/Icon'
import { Loading } from 'components/global'
import { OverflowScrollBarWidth } from 'helpers/OverflowScrollBarWidth'
import _ from 'lodash'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link } from 'react-router-dom'
import {
	Container,
	Spinner,
} from 'reactstrap'
import { HierarchicalService } from 'services/HierarchicalService'
import { SearchResultEmpty } from './SearchResult/SearchResultEmpty'
import { SearchResultItem } from './SearchResult/SearchResultItem'
import styles from './styles.scss'
import { Pagination } from 'components/market/ProductsList/Pagination'


class Search extends Component
{
	state = {
		searchString:     '',
		activeCategoryId: 1,
		isSearchOpen:     false,
		preFetching:      false,
		categoriesIsOpen: false,
		categoriesId:     null,
	}

	componentDidUpdate()
	{
		const { isSearchOpen } = this.state

		if (isSearchOpen) {
			document.addEventListener('click', this.outsideClick)
		} else {
			document.removeEventListener('click', this.outsideClick)
		}
	}

	/**
	 * On change search input value
	 *
	 * @param {Event} event
	 *
	 * @return {void}
	 */
	onChangeSearch = event => this.setState({ searchString: event.target.value }, this.fetchModel)

	/**
	 * Get current category
	 *
	 * @return {undefined}
	 *
	 */
	getCurrentCategory = () => {
		const { getCategory }          = this.props
		const { activeCategoryId: id } = this.state

		const searchQuery = DataProvider
			.buildQuery()
			.addBody({
				query: { expands: ['childrenCategories', 'parentCategories'] },
				id,
			})
			.setSuccessCallback((res) => {
				const category = Category.create(res)
				const categoriesArr = category.getLevel() === 'two' ?
					category?.model?.childrenCategories : [{ id: category.getId() }]

				const categoriesIds = categoriesArr[0].id !== 1 ? categoriesArr : null

				this.setState({ categoriesId: categoriesIds }, this.fetchModel)
			})


		getCategory(searchQuery)
	}

	/**
	 * Get products
	 *
	 * @return {undefined}
	 */


	getProducts = _.debounce((page) => {
		this.setState({ preFetching: false })
		const { searchProducts, setProducts, searchProductsSub, searchCategory } = this.props
		const { searchString, categoriesId }  = this.state

		if (!searchString) {
			setProducts(DataProvider.getDefaultState())

			return
		}

		const searchQueryProductsSub = (categoryId) => DataProvider
			.buildQuery()
			.addBody({

				extraFilter:
					{
						categoryId,
						// isAvailable: 1,
					},

				query: {
					expands: ['categories.category.allParents', 'minPrices'],
					filter:  {
						title: { like: `%${searchString}%` },
						isRemoved: false
					},
					page,
					perPage: 20,
				},
			})
			.setSuccessCallback(() => this.setState({ preFetching: false }))


		if (categoriesId ) {
			this.setState({ preFetching: true })
			searchProductsSub(categoriesId.map(category => searchQueryProductsSub(category.id)))

		} else {

			this.setState({ preFetching: true })
			const searchQuery = DataProvider
				.buildQuery()
				.addBody({

					extraFilter:
						{
							// isAvailable: true,
						},

					query: {
						expands: ['categories.category.allParents', 'minPrices'],
						filter:  {
							title: { like: `%${searchString}%` },
							isRemoved: false
						},
						page,
						perPage: 20,
					},
				})
				.setSuccessCallback(() => this.setState({ preFetching: false }))
			searchProducts(searchQuery)
		}

		const searchQuery = DataProvider
			.buildQuery()
			.addBody({

				query: {
					expands: ['parentCategories'],
					filter:  {
						title: { like: `%${searchString}%` },
					},
					perPage: 20,
				},
			})

		searchCategory(searchQuery)

	}, 500)

	/**
	 * Get products list
	 *
	 * The solution for correct animation of search panel
	 *
	 * @return {undefined}
	 */
	fetchModel = (page = 1) => {
		const {searchString} = this.state
		if(searchString.length > 1) {
			this.getProducts(page)
		}
	}

	/**
	 * Open search
	 *
	 * @return {undefined}
	 */
	openSearch = () => {
		const { showBackdrop } = this.props

		this.setState({ isSearchOpen: true }, () => {
			this.getCurrentCategory()
			showBackdrop(true)
		})
	}

	/**
	 * Close search
	 *
	 * @return {undefined}
	 */
	closeSearch = () => {
		const { showBackdrop } = this.props

		this.setState({
			isSearchOpen: false,
		})

		showBackdrop(false)
	}

	outsideClick = event => {
		if (event.target?.className?.startsWith?.('global_root')) {
			this.closeSearch()
		}
	}

	/**
	 * Toggle subcategory panel
	 *
	 * @return {void}
	 */
	toggleSubCategory = () => this.setState(prevState => ({ categoriesIsOpen: !prevState.categoriesIsOpen }))

	/**
	 * Choose different category
	 *
	 * @param {number} activeCategoryId
	 *
	 * @return {undefined}
	 */
	switchCategory = activeCategoryId => {
		this.setState({ activeCategoryId }, () => {
			this.toggleSubCategory()
			this.getCurrentCategory()
		})
	}

	/**
	 * Get search text
	 *
	 * @return {string}
	 */
	getSearchText = () => {
		const { searchString, preFetching } = this.state
		const { intl }                      = this.props

		if (searchString !== '' && !preFetching) {
			return `${intl.messages['general.search.empty']}...`
		}

		if (searchString === '') {
			return `${intl.messages['general.search.start']}...`
		}

		return ''
	}

	/**
	 * Render filters navigation
	 *
	 * @param {Category} category
	 *
	 * @return {*}
	 */

	renderFiltersNavigation = category => {
		const { categoriesIsOpen } = this.state
		const { isMobile, categoryState }         = this.props

		const categoryService = StateService.create(categoryState)

		return (
			<div className={styles.navigation}>
				<div className={isMobile ? styles.container : 'container'}>
					<ul className={[styles.all, categoriesIsOpen ? styles.all_categories : ''].join(' ')}>
						<div
							role="button"
							tabIndex="0"
							className={styles.button}
							onClick={this.toggleSubCategory}
						>
							<p>Раздел</p>
							<div className={styles.arrow}>
								<Icon icon="mobile-menu-arrow" size={12} color="#ffffff" />
							</div>
						</div>

						<ul className={styles.categories}>
							{category.getChildCategories().map(item => (
								<li
									key={item.getId()}
									role="presentation"
									className={styles.category}
									onClick={() => this.switchCategory(item.getId())}
								>
									{item.getTitle()}
								</li>
							))}

							{category.getParentCategories()?.length > 0 && (
								<li
									role="presentation"
									className={[styles.category, styles.back].join(' ')}
									onClick={() => this.switchCategory(category.getParentCategories()?.[0]?.getId())}
								>
									Вернуться
								</li>
							)}
						</ul>

						<ul className={styles.modules}>
							{!categoryService.isFetching() ?
								<li
									key={category.getId()}
									role="presentation"
									className={[
										styles.module,
										styles.module_active,
									].join(' ')}
									onClick={this.toggleSubCategory}
								>
									{category.getTitle()}
								</li> :
								<Spinner className={styles.spinner} color="success" />}
						</ul>
					</ul>
				</div>
			</div>
		)
	}

	/**
	 * Render search input
	 *
	 * @return {*}
	 */
	renderSearchInput = () => {
		const { searchString } = this.state
		const { isMobile }     = this.props

		return (
			<div className={[styles.box, isMobile ? styles.container : 'container'].join(' ')}>
				<div className={[styles.icon, styles.icon_opened].join(' ')}>
					<Icon icon="ic-search-alt" size={18} />
				</div>
				<input
					className={styles.input}
					ref={input => input && input.focus()}
					value={searchString}
					onChange={this.onChangeSearch}
				/>
				<div
					className={styles.close}
					onClick={this.closeSearch}
					role="button"
					tabIndex="0"
				>
					<Icon icon="close" size={28} />
				</div>
			</div>
		)
	}

	render()
	{
		const { productsState, categoryState, intl, searchCategoryState } = this.props
		const { isSearchOpen, preFetching }          = this.state

		const productsService = StateService.create(productsState)
		const category        = Category.create(categoryState)
		const categoriesList  = Category.createList(searchCategoryState)

		return (
			<>
				<OverflowScrollBarWidth isOverflowed={isSearchOpen} />

				<div
					className={styles.search}
					onClick={this.openSearch}
					role="button"
					tabIndex="0"
				>
					<p>{`${intl?.messages['general.search']} ...`}</p>
					<div className={[styles.icon, styles.icon_closed].join(' ')}>
						<Icon icon="ic-search-alt" size={18} />
					</div>
				</div>

				<div className={[styles.block, isSearchOpen ? styles.block_opened : ''].join(' ')}>
					{this.renderSearchInput()}
					{this.renderFiltersNavigation(category)}
					<Container className={styles.results}>
						{categoriesList.map(category => (
							<div className={styles.categoriesList} key={category.getId()}>
							<Arrow />
								<Link
									to={HierarchicalService.getInstance().getCategoryLink(category.getId())}
									className={styles.categoriesLink}
									onClick={this.closeSearch}
								>
									{category.getTitle()}
								</Link>
							</div>

						))}

						<PerfectScrollbar className={styles.wrapper}>
							{preFetching ? <Loading large /> :
								(!productsService.isEmptyList() && Product.map(productsService.getList(), product => (
									<SearchResultItem
										key={product.getId()}
										product={product}
										onClickLink={this.closeSearch}
									/>
								))) ||

								<SearchResultEmpty text={this.getSearchText()} />
							}
							{!preFetching &&
							 (<div className={styles.pagination}>
								<Pagination
									service={productsService}
									goToPage={page => this.fetchModel(page)}
								/>
							</div>)}
						</PerfectScrollbar>
					</Container>
				</div>
			</>
		)
	}
}

Search.propTypes = {
	getCategory:      PropTypes.func,
	getProducts:      PropTypes.func,
	setProducts:      PropTypes.func,
	showBackdrop:     PropTypes.func,
	getProductsLists: PropTypes.func,
	categoryState:    PropTypes.object,
	productsState:    PropTypes.object,
	intl:             PropTypes.object,
	isMobile:         PropTypes.bool,
}

Search.defaultProps = {
	getCategory:      () => null,
	getProducts:      () => null,
	setProducts:      () => null,
	getProductsLists: () => null,
	showBackdrop:     () => null,
	categoryState:    {},
	productsState:    {},
	intl:             {},
	isMobile:         false,
}

export { Search }
