import * as PropTypes from 'prop-types'
import React from 'react'

import styles from './tabs.scss'

const Tab = props => {
	const {
			  activeTab,
			  id,
			  title,
			  tabClass,
			  tabActiveClass,
			  onClick,
		  } = props

	let className

	if (activeTab !== id) {
		className = [
			styles.item,
			tabClass,
		].join(' ')
	} else {
		className = [
			styles.item,
			tabClass,
			styles.active,
			tabActiveClass,
		].join(' ')
	}

	return (
		<div
			role='button'
			tabIndex='0'
			className={className}
			onClick={() => onClick(id)}
			onKeyDown={() => onClick(id)}
		>
			{title}
		</div>
	)
}

Tab.propTypes = {
	activeTab:      PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	id:             PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	title:          PropTypes.string.isRequired,
	onClick:        PropTypes.func.isRequired,
	tabClass:       PropTypes.string.isRequired,
	tabActiveClass: PropTypes.string.isRequired,
}

export { Tab }
