import { Customer } from '@kakadu-dev/base-frontend-components'
import {
	countFormatter,
	ReviewForm,
	reviewOptions,
} from 'components/global'
import { Button } from 'components/global/Button'

import styles from 'components/market/ProductPage/ProductReviews/styles.scss'
import * as PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
	Col,
	Collapse,
	Row,
} from 'reactstrap'

/**
 * Render reviews
 *
 * @param {Node} children
 * @param {number} countOfReviews
 * @param {Object} productState
 * @param {Object} userState
 * @param {function} updateReviews
 * @param {Object} innerRef
 *
 * @return {*}
 * @constructor
 */

const ProductReviews = ({
	children,
	countOfReviews,
	productState,
	userState,
	updateReviews,
	innerRef,
}) => {
	const [isOpen, setIsOpen] = useState(true)
	const customer            = Customer.create(userState)

	const toggleIsOpen = () => setIsOpen(prevState => !prevState)

	return (
		<div className={styles.reviews} ref={innerRef}>
			<h2 className={styles.title}>Отзывы</h2>
			<Collapse isOpen={isOpen}>
				<div className={styles.wrapper}>
					<div className={styles.header}>
						<p className={[styles.count, !countOfReviews && styles.count_no].join(' ')}>
							{countOfReviews ? countFormatter(countOfReviews, reviewOptions)
								: 'Оставьте первый отзыв!'}
						</p>
						{customer.getId() > 0 ?
							<Button
								onClick={toggleIsOpen}
								onKeyDown={toggleIsOpen}
								title='Оставить отзыв'
								color={'green'}
							/> :
							<div className={styles.guestBlock}>
								{'Чтобы оставить отзыв войдите в свой аккаунт!'}
								<Link name='link-to-sign-in' to='/sign-in' className={styles.link}>
									Войти
								</Link>

							</div>}
					</div>
					<Row>
						<Col xs="12" lg="11" className={styles.review_items}>
							{children}
						</Col>
					</Row>
				</div>
			</Collapse>

			<Collapse isOpen={!isOpen}>
				<ReviewForm
					updateReviews={updateReviews}
					productState={productState}
					userState={userState}
					close={toggleIsOpen}
				/>
			</Collapse>

		</div>
	)
}

ProductReviews.propTypes = {
	children:       PropTypes.node.isRequired,
	countOfReviews: PropTypes.number,
	productState:   PropTypes.object,
	updateReviews:  PropTypes.func,
	userState:      PropTypes.object,
	innerRef:       PropTypes.object,
}

ProductReviews.defaultProps = {
	countOfReviews: 0,
	productState:   {},
	updateReviews:  () => null,
	userState:      {},
	innerRef:       {},
}

export { ProductReviews }
