import { MetaData } from '@kakadu-dev/base-frontend-components'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

export const SeoMetaData = ({ metaState, defaultMeta, urlInfo }) => {
	const metaData = MetaData.create(metaState)

	const title       = metaData.getTitle() || defaultMeta?.title
	const description = metaData.getDescription() || defaultMeta?.description
	const keywords = Array.isArray(metaData.getKeywords()) ? metaData.getKeywords().join(', ') :
	 (defaultMeta?.keywords ?? []).join(', ')
	const canonical   = metaData.getCanonical() || defaultMeta?.canonical
	const domain      = process.env.REACT_APP_DOMAIN

	return (
		<Helmet>
			<title>{title}</title>
			{description && <meta name="description" content={description} />}
			{keywords && <meta name="keywords" content={keywords} />}
			{title && <meta property="og:title" content={title} />}
			<meta property="og:url" content={`${domain}${urlInfo.url}`} />
			<meta name="og:image" content={`${domain}/static/media/logo.80c85d2e.png`} />
			<meta name="og:image:secure_url" content={`${domain}/static/media/logo.80c85d2e.png`} />
			{canonical && <link rel="canonical" href={canonical} />}
		</Helmet>
	)
}

SeoMetaData.propTypes = {
	metaState:   PropTypes.object,
	defaultMeta: PropTypes.object,
	urlInfo:     PropTypes.object,
}

SeoMetaData.defaultProps = {
	metaState:   {},
	defaultMeta: {},
	urlInfo:     {},
}
