import { Slider } from 'components/global/Slider'
import { StaticPageWrapper } from 'components/static/StaticPageWrapper'
import {
	aboutUsImages,
	historyImages,
	tradeHallImages,
	tradeHallImages2,
} from 'containers/Static/History/data'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { MetaService } from 'services/MetaService'
import img1 from './media/other/01.jpg'
import img2 from './media/other/02.jpg'
import styles from './styles.scss'

const title = 'История'

const setMetaInfo = () => MetaService.setInfo({}, { title })

export const getYoutubeIframe = src =>
	<div className="my-4 w-100">
		<iframe
			title="gum history"
			width="100%"
			height="520px"
			src={src}
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
		/>
	</div>

/**
 * Render history page
 *
 * @return {*}
 * @constructor
 */
export const HistoryPage = () => {
	useEffect(() => {
		setMetaInfo()
	}, [])

	return (
		<StaticPageWrapper title={title} extraBreadcrumbs={[{ title: 'О компании', path: '/about-company' }]}>
			<p>
				<strong>ГУМ</strong>
				{' '}
				— первый крупнейший универсальный магазин г. Минска.
				Расположен в самом центре столицы, на пересечении главной магистрали —
				пр. Независимости и ул. Ленина. Впервые распахнул свои двери для
				покупателей и гостей
				{' '}
				<strong>5 ноября 1951 года.</strong>
			</p>
			<br />
			<p>
				<strong>ГУМ</strong>
				{' '}
				— достопримечательность Беларуси. Здание универмага является
				памятником архитектуры и градостроительства ХХ века.
			</p>
			<Slider images={aboutUsImages} />
			<p>
				На симметрично решённом фасаде универмага, выделены три арочных проёма,
				которые образуют парадный вход, ориентированный на пр. Независимости.
				Архитектурно-художественным решением здания предусмотрено наличие
				больших остекленных проемов и сложного декора
				—
				многочисленных
				фронтончиков, лепнины, вставок, геральдических поясков, В интерьере также
				широко использованы декоративные элементы классического наследия -
				лепные детали, мрамор, хрусталь, необычные витражи, широкие лестницы и
				роскошные колонны.
				<br />
				В универмаге ежедневно обслуживаются более 20 тыс. покупателей. Здесь
				продаются товары для всей семьи и на все случаи жизни: для детей и взрослых,
				для дома и дачи, для спорта и отдыха.
			</p>
			<Slider images={tradeHallImages} />
			<p>
				В торговых залах представлен широкий ассортимент товаров известных
				белорусских и иностранных брендов.
			</p>
			<Slider images={tradeHallImages2} />
			<p>
				Мы постоянно совершенствуем методы торговли, осуществляем поиск нужных
				покупателю товаров и профессионально их продаем, потому что нам дорог
				каждый покупатель и каждый партнер по торговле.
			</p>
			<br />
			<p>
				К услугам покупателей в универмаге -
				{' '}
				<Link to="/about-company/commercial-network/cn-canteen-cafeteria">кафетерий и столовая</Link>
				, ряд платных и бесплатных
				{' '}
				<Link to="/buyers/b-services">дополнительных услуг</Link>
				.
			</p>
			<br />
			<p>
				В 1997 году государственный универсальный магазин был преобразован в
				Открытое акционерное общество «ГУМ».
			</p>
			<br />
			<p>
				В настоящее время торговая сеть ОАО «ГУМ» это не только универмаг,
				расположенный по проспекту Независимости, 21, но и торговые залы по ул.
				Ленина (строения 4,6 и 8), а также пять продовольственных магазинов
				{' '}
				<Link to="/about-company/commercial-network/cn-shops-generous">
					«Щедрый» в Центральном районе г. Минска
				</Link>
				.
			</p>
			<br />
			<h1>ОБ ИСТОРИИ СОЗДАНИЯ ГУМа</h1>
			<p>
				История первого государственного универсального магазина
				началась в 1934 году. ГУМ открылся в городе Минске 28 мая в
				монументальном здании бывшего Польского банка, который стоял
				на пересечении Советской и Комсомольской улиц (нынешнего пр.
				Независимости и ул. Комсомольской).
				После Великой Отечественной войны ГУМ в свое прежнее здание не вернулся,
				поскольку улица Советская была почти полностью разрушена и ее решили
				спланировать заново.
			</p>
			<div className={styles.image}>
				<img src={img1} alt="history-1" />
			</div>
			<p>
				Решение о строительстве нового универсального магазина было принято 26
				июля 1945 года в Кремле зампредом Совнаркома СССР Анастасом Микояном..
				Универмаг стал первым объектом вновь создаваемого архитектурного центра
				г. Минска. Архитекторами проекта были назначены Роман Гегарт и Лев
				Мелеги. В своей работе они полагались на шедевры итальянской
				архитектуры — базилику Палладиана в венецианском городе Виченца
				и библиотеку Марчиана.
			</p>
			<div className={styles.image}>
				<img src={img2} alt="history-2" />
			</div>
			<p>
				5 ноября 1951 года Минский ГУМ встретил своих первых посетителей в
				новом здании, общей площадью 6 891м2, в том числе торговой – 3 561 м2.
			</p>
			<br />
			<p>
				Это был настоящий праздник города. Для послевоенных людей это был не
				магазин, а дворец торговли. В магазине царила праздничная атмосфера, у входа
				стоял швейцар, сияли зеркала… Интерьер здания поражал глаз обывателя. Все
				это мгновенно превратило холл универмага-дворца в главное место встреч
				влюбленных.
			</p>
			<Slider images={historyImages} />
			<p>
				Торговое оборудование было выполнено из ценных пород дерева, потолки
				украшали шикарные люстры с лампами дневного света, на окнах висели
				шелковые «министерские» гардины, полы были паркетными. Всюду
				полированный дуб, бронза, шелк…
			</p>
			<br />
			<p>
				На этажах находились автоматы, которые за символическую плату выдавали
				порцию одеколона, обрызгивая им покупателя. В тот день впервые на
				прилавках появились импортные товары из Чехословакии, Польши, ГДР и
				Франции. Нигде не было такого обилия товаров как в универмаге.
				Огромным спросом пользовалась бытовая техника (холодильники, телевизоры
				и стиральные машинки). Посетителей обслуживали 460 продавцов, 30 из них
				на тот момент прошли стажировку в Ленинградском Торговом Доме.
			</p>
			{getYoutubeIframe('https://www.youtube.com/embed/67poKtWOxzE')}
			<p>
				Периодом поступательного развития ГУМа явились 1960-1970-е годы. Особое
				внимание уделялось совершенствованию торгового процесса.
			</p>
			<br />
			<p>
				В 1969 году Минский ГУМ вошел в число первых советских магазинов,
				внедривших у себя метод самообслуживания.
			</p>
			<br />
			<p>
				В 1973 г. ГУМ начал сам готовить кадры. Универмаг первым в городе
				организовал школу-магазин, стал одним из родоначальников этой практики в
				системе советской торговли.
			</p>
			<br />
			<p>
				В 1976 году организована торговля по потребительским комплексам: «Товары
				для женщин», «Товары для мужчин», «Товары для детей» и др. В универмаге
				оказывалось более 30 дополнительных услуг, включая раскрой тканей и
				мелкую подгонку одежды, работали сберегательные кассы, отделение связи.
				Опыт Минского ГУМа начал широко внедряться в СССР.
			</p>
			<br />
			<p>
				В 1985 в полной мере начали применять автоматизированную систему учета
				товаров. В эти годы ГУМ – это примерно 5% в общем товарообороте города.
			</p>
			<br />
			<p>
				В 1997 году государственный универсальный магазин был
				преобразован в Открытое акционерное общество «ГУМ».
			</p>
			<br />
			<p>
				ГУМ на протяжении всей своей деятельности не просто торговый объект, а
				магазин-новатор.
			</p>
			<br />
			<p>
				Мы первые среди универмагов ввели подарочные сертификаты, дисконтные
				карты. И со временем намерены совершенствовать свою систему клиентской
				лояльности «Клуб постоянных покупателей ГУМа».
				Минский ГУМ был и остается ведущим предприятием торговли Республики
				Беларусь.
			</p>
		</StaticPageWrapper>
	)
}
