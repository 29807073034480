import {
	// About,
	// Advertising,
	// BookOfComments,
	DeliveryPage,
	// DepartmentsAndServices,
	// EquipmentSale,
	// ExchangeRefund,
	Faq,
	// GiftCertificate,
	Help,
	HistoryPage,
	HomeStore,
	// InstallmentPlanCredit,
	// Leadership,
	// LoyaltyProgram,
	OurLife,
	// OurProduction,
	// PersonalReception,
	// Purchases,
	// Regulations,
	// Shareholders,
	SignInStore,
	SiteMapStore,
	Social,
	// Suppliers,
	// TaxFree,
	// Tenants,
	// TradingNetwork,
	// WiFI,
	// HowOrder,
} from 'containers'
// import { AboutBy } from 'containers/Static/AboutBy'
// import { CitizenAppealsInStore } from 'containers/Static/ElectronicAppeals/Citizen/index.store'
// import { IndividualsAppealsInStore } from 'containers/Static/ElectronicAppeals/Individuals/index.store'
import { GiftCertificatesInStore } from 'containers/Static/GiftCertificates/index.store'
import { EveningWithStylistInStore } from 'containers/Static/EveningWithStylist/index.store'
import { ContactsInStore } from 'containers/Static/Contacts/index.store'
import { ConRequisitesInStore } from 'containers/Static/ConRequisites/index.store'
import { HistoryBy } from 'containers/Static/HistoryBy'
// import { TradingNetworkBy } from 'containers/Static/TradingNetworkBy'
import { TrapStocks } from 'containers/Static/TrapStocks'
import wrappers from 'routing/wrappers'

export const staticPages = [
	{
		path:      '/',
		exact:     true,
		component: wrappers(HomeStore),
	},
	{
		path:      '/sign-in',
		exact:     true,
		component: wrappers(SignInStore),
	},
	// TODO change to appeal
	{
		path:      '/help',
		exact:     true,
		component: wrappers(Help),
	},
	{
		path:      '/social',
		exact:     true,
		component: wrappers(Social),
	},
	{
		path:      '/buyers/b-shipping-and-payment',
		exact:     true,
		component: wrappers(DeliveryPage),
	},
	{
		path:      '/buyers/vesperam-cum-stylist',
		exact:     true,
		component: wrappers(EveningWithStylistInStore),
	},
	{
		path:      '/faq',
		exact:     true,
		component: wrappers(Faq),
	},
	{
		path:      '/sitemap',
		exact:     true,
		component: wrappers(SiteMapStore),
	},
	{
		path:      '/contacts',
		exact:     true,
		component: wrappers(ContactsInStore),
	},
	{
		path:      '/by/contacts',
		exact:     true,
		component: wrappers(ContactsInStore),
	},
	// {
	// 	path:      '/about-company',
	// 	exact:     true,
	// 	component: wrappers(About),
	// },
	// {
	// 	path:      '/by/about-company',
	// 	exact:     true,
	// 	component: wrappers(AboutBy),
	// },

	// {
	// 	path:      '/about-company/ac-shareholders',
	// 	exact:     true,
	// 	component: wrappers(Shareholders),
	// },
	// {
	// 	path:      '/about-company/ac-vacancies',
	// 	exact:     true,
	// 	component: wrappers(Vacancies),
	// },
	// {
	// 	path:      '/cooperation/coo-for-tenants',
	// 	exact:     true,
	// 	component: wrappers(Tenants),
	// },
	// {
	// 	path:      '/for-business/fb-by-bank-transfer',
	// 	exact:     true,
	// 	component: wrappers(CashlessPayments),
	// },
	// {
	// 	path:      '/appeals/a-appeals-of-citizens',
	// 	exact:     true,
	// 	component: wrappers(CitizenAppealsInStore),
	// },
	// {
	// 	path:      '/by/appeals/a-appeals-of-citizens',
	// 	exact:     true,
	// 	component: wrappers(CitizenAppealsInStore),
	// },
	// {
	// 	path:      '/appeals/a-appeals-legal-individuals',
	// 	exact:     true,
	// 	component: wrappers(IndividualsAppealsInStore),
	// },
	// {
	// 	path:      '/by/appeals/a-appeals-legal-individuals',
	// 	exact:     true,
	// 	component: wrappers(IndividualsAppealsInStore),
	// },
	// {
	// 	path:      '/appeals/a-book-of-comments-and-suggestions',
	// 	exact:     true,
	// 	component: wrappers(BookOfComments),
	// },
	// {
	// 	path:      '/cooperation/coo-purchases',
	// 	exact:     true,
	// 	component: wrappers(Purchases),
	// },
	// {
	// 	path:      '/appeals/a-personal-reception',
	// 	exact:     true,
	// 	component: wrappers(PersonalReception),
	// },
	// {
	// 	path:      '/about-company/ac-leadership',
	// 	exact:     true,
	// 	component: wrappers(Leadership),
	// },
	{
		path:      '/about-company/ac-our-life-old',
		exact:     true,
		component: wrappers(OurLife),
	},
	// {
	// 	path:      '/cooperation/coo-suppliers',
	// 	exact:     true,
	// 	component: wrappers(Suppliers),
	// },
	// {
	// 	path:      '/buyers/b-installment-plan-credit',
	// 	exact:     true,
	// 	component: wrappers(InstallmentPlanCredit),
	// },
	// {
	// 	path:      '/buyers/b-gift-certificate',
	// 	exact:     true,
	// 	component: wrappers(GiftCertificate),
	// },
	// {
	// 	path:      '/cooperation/coo-advertising',
	// 	exact:     true,
	// 	component: wrappers(Advertising),
	// },
	// {
	// 	path:      '/buyers/b-loyalty-program',
	// 	exact:     true,
	// 	component: wrappers(LoyaltyProgram),
	// },
	// {
	// 	path:      '/anti-corruption',
	// 	exact:     true,
	// 	component: wrappers(AntiCorruption),
	// },
	{
		path:      '/contacts/сon-requisites',
		exact:     true,
		component: wrappers(ConRequisitesInStore),
	},
	{
		path:      '/by/contacts/сon-requisites',
		exact:     true,
		component: wrappers(ConRequisitesInStore),
	},
	// {
	// 	path:      '/tax-free',
	// 	exact:     true,
	// 	component: wrappers(TaxFree),
	// },
	// {
	// 	path:      '/about-company/commercial-network/:alias?',
	// 	exact:     true,
	// 	component: wrappers(TradingNetwork),
	// },
	// {
	// 	path:      '/by/about-company/commercial-network/:alias?',
	// 	exact:     true,
	// 	component: wrappers(TradingNetworkBy),
	// },
	// {
	// 	path:      '/exchange-and-refund',
	// 	exact:     true,
	// 	component: wrappers(ExchangeRefund),
	// },
	{
		path:      '/about-company/ac-history',
		exact:     true,
		component: wrappers(HistoryPage),
	},
	{
		path:      '/by/about-company/ac-history',
		exact:     true,
		component: wrappers(HistoryBy),
	},
	// {
	// 	path:      '/cooperation/coo-wholesale-buyers',
	// 	exact:     true,
	// 	component: wrappers(OurProduction),
	// },
	// {
	// 	path:      '/contacts/con-departments-and-services',
	// 	exact:     true,
	// 	component: wrappers(DepartmentsAndServices),
	// },
	// {
	// 	path:      '/cooperation/coo-sale-of-equipment',
	// 	exact:     true,
	// 	component: wrappers(EquipmentSale),
	// },
	// {
	// 	path:      '/regulations',
	// 	exact:     true,
	// 	component: wrappers(Regulations),
	// },
	// {
	// 	path:      '/stocks/about',
	// 	exact:     true,
	// 	component: wrappers(PromoAbout),
	// },
	// {
	// 	path:      '/free-wi-fi',
	// 	exact:     true,
	// 	component: wrappers(WiFI),
	// },
	{
		path:      '/for-business/fb-gift-certificates',
		exact:     true,
		component: wrappers(GiftCertificatesInStore),
	},
	// {
	// 	path:      '/buyers/b-services',
	// 	exact:     false,
	// 	component: wrappers(Services),
	// },
	{
		path:      '/stocks',
		exact:     false,
		component: wrappers(TrapStocks),
	},
	// {
	// 	path:      '/buyers/b-shipping',
	// 	exact:     false,
	// 	component: wrappers(AllDelivery),
	// },
	// {
	// 	path:      '/buyers/b-payment',
	// 	exact:     false,
	// 	component: wrappers(PaymentInfo),
	// },
	// {
	// 	path:      '/kak-zakazat',
	// 	exact:     false,
	// 	component: wrappers(HowOrder),
	// }

]
