import styles from 'components/Form/fields/City/SearchList/styles.scss'
import { BlankButton } from 'components/global/BlankButton'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render search result
 * @param {Array} list
 * @param {Function} action
 *
 * @return {*}
 * @constructor
 */
export const SearchResult = ({ list, action }) => (
	<div className={styles.searchList}>
		{list.map(item => (
			<BlankButton
				key={item.getId()}
				className={styles.searchItem}
				onClick={() => action(item)}
			>
				{item.getTitle()}
			</BlankButton>
		))}
	</div>
)

SearchResult.propTypes = {
	action: PropTypes.func,
	list:   PropTypes.array,
}

SearchResult.defaultProps = {
	action: () => null,
	list:   [],
}
