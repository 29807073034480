import React from 'react'
import ContentLoader from 'react-content-loader'

const TitlePlaceholder = () => (
	<ContentLoader
		height={50}
		width={400}
		speed={1}
		backgroundColor="#e9e9e9"
		foregroundColor="#f5f5f5"
		uniqueKey="titlePlaceholder"
	>
		<rect x="56" y="20" rx="5" ry="5" width="324" height="25" />
		<circle cx="27" cy="32" r="15" />
	</ContentLoader>
)

export default TitlePlaceholder
