import appLocaleData from 'react-intl/locale-data/be'
import byMessages from '../locales/by_BY'

const ByLang = {
	messages: {
		...byMessages,
	},
	locale:   'be',
	data:     appLocaleData,
}
export default ByLang
