import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { BasketPreview } from './index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'

const BasketPreviewStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectIntl(BasketPreview))

export { BasketPreviewStore }
