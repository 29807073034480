import { CartModel } from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { Footer } from 'components/basket/BasketAside/Footer'
import { PriceWrapper } from 'components/basket/BasketAside/PriceWrapper'
import { Button } from 'components/global/Button'
import { DottedLine } from 'components/global/DottedLine'
import * as PropTypes from 'prop-types'
import React, {
	useCallback,
	useMemo,
} from 'react'
import { useHistory } from 'react-router-dom'
import StickyBox from 'react-sticky-box'
import { height } from 'styles/geometry'
import styles from './styles.scss'

// TODO get data from backend
const freeShippingFrom = 150000

const redirectUrl = `${process.env.REACT_APP_DOMAIN}/basket/order-created`

const createOrderQuery = (attributes, redirect) => {
	return DataProvider
		.buildQuery()
		.addBody(attributes)
		.setSuccessCallback(response => {
			const { result: { model: { id }, url } } = response || null
			if (url)
				window.location.assign(url)
			else
				redirect(`/basket/order-created/${id}`)
		})
}

const checkForm = attributes => {
	let isSuccess = true
	const newParams = {}
	const { data, params } = attributes

	for(const [key, obj] of Object.entries(params)){
		const { error, required } = obj
		if(required)
		{
			if(key === 'cityId'){
				if(data[key] === 0){
					newParams[key] = {error: true, required}
					isSuccess = false
				}
			}
			if(key === 'phone'){
				if(data[key].length < 12){
					newParams[key] = {error: true, required}
					isSuccess = false
				}
			}
			else if(data[key].length === 0){
				newParams[key] = {error: true, required}
				isSuccess = false
			}
		}
		else newParams[key] = {error, required}
	}
	if (data.paymentMethod === 'online') data.returnUrl = redirectUrl
	return { success: isSuccess, data, params: newParams}
}

/**
 * Render basket aside block
 *
 * @param {Object} cartListState
 * @param {Object} attributes
 * @param {string} attributes.pointsMethod
 * @param {Function} createOrder
 * @param {Function} errorFields
 * @param {Object} checkoutData
 *
 * @return {*}
 * @constructor
 */
export const BasketAside = ({
	checkoutData,
	attributes,
	cartListState,
	createOrder,
	errorFields,
}) => {
	const { push, location: { pathname } } = useHistory()

	const totalDelivery = checkoutData?.model?.totalDelivery

	const { deliveryMethod } = attributes?.data ?? {}

	const cart = CartModel.create(cartListState)

	const total          = cart.getTotal()
	const originalTotal  = cart.getOriginalTotal()
	const discountPoints = checkoutData.getDiscountPoints?.() ?? 0

	const isDeliveryFree = useMemo(() => total >= freeShippingFrom, [total])

	const isBasketPage = useMemo(() => pathname === '/basket',
		[pathname])

	const finalDeliveryPrice = useMemo(() => {
		if(isDeliveryFree) return 0

		switch (deliveryMethod) {
			case 'pickup': return totalDelivery?.pickup
			case 'courierRB': return totalDelivery?.courierRB
			case 'courierMinsk': return totalDelivery?.courierMinsk
			case 'postRB': return totalDelivery?.postRB
			case 'postMinsk': return totalDelivery?.postMinsk
			default: return null
		}

	}, [deliveryMethod, isDeliveryFree, totalDelivery])

	const sendOrder = useCallback(() => createOrder(createOrderQuery(attributes?.data, push)),
		[attributes, createOrder, push])

	const buttonAction = useCallback(() => {
		if (isBasketPage) {
			push('/basket/checkout')
		}
		else {
			const { success, data, params } = checkForm(attributes)
			if(success) sendOrder()
			else errorFields({ success, data, params })
		}
	},
	[isBasketPage, push, sendOrder, errorFields, attributes])

	const buttonTitle = useMemo(() => isBasketPage ? 'Оформить' : 'Завершить заказ',
		[isBasketPage])

	return (
		<StickyBox offsetTop={height.headerFixedHeight} offsetBottom={40}>
			<div className={styles.container}>
				<span className={styles.title}>Итого</span>
				<DottedLine />
				<PriceWrapper title="Сумма заказа" price={originalTotal} />
				{deliveryMethod &&
				 <PriceWrapper title="Доставка" price={finalDeliveryPrice} />}
				<div className={styles.sale}>
					<PriceWrapper title="Скидка за баллы" price={discountPoints} />
				</div>
				<PriceWrapper title="К оплате" price={finalDeliveryPrice + originalTotal} final />
				<Button
					title={buttonTitle}
					onClick={buttonAction}
					style={styles.button}
					disabled={isBasketPage && !cart.getCount()}
					type='submit'
				/>
				<Footer />
			</div>
		</StickyBox>
	)
}

BasketAside.propTypes = {
	attributes:    PropTypes.object,
	cartListState: PropTypes.object,
	checkoutData:  PropTypes.object,
	createOrder:   PropTypes.func,
	errorFields:   PropTypes.func,
}

BasketAside.defaultProps = {
	attributes:    {},
	cartListState: {},
	checkoutData:  {},
	createOrder:   () => null,
	errorFields:   () => null,
}
