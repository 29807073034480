import Collapse from '@material-ui/core/Collapse'
import { BlankButton } from 'components/global/BlankButton'
import React, {
	useCallback,
	useState,
} from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../../assets/icomoon/Icon'

import styles from './styles.scss'

/**
 * Render Collapse info component
 *
 * @param {object} info
 *
 * @return {*}
 * @constructor
 */
export const CollapseInfoCard = ({ info }) => {
	const [isActiveDetail, setIsActiveDetail] = useState(false)
	const [isActivePromo, setIsActivePromo] = useState(false)

	const onClickDetail = useCallback(() => {
		setIsActiveDetail(prevState => !prevState)
	}, [])
	const onClickPromo = useCallback(() => {
		setIsActivePromo(prevState => !prevState)
	}, [])

	return (
		<div className={styles.infoCardWrapper}>
			<div className={styles.infoCardContent}>
				<div className={styles.infoCardImage}>
					<img src={info?.img} alt="" />
				</div>
				<div className={styles.infoCardDescriptionBlock}>
					<div className={styles.infoCardTitle}>
						{info?.title}
					</div>
					<ul className={styles.infoCardDescription}>
						{/* eslint-disable-next-line react/no-array-index-key */}
						{info?.description.map((desc, index) => <li key={index}>{desc}</li>)}
					</ul>
					<div className={styles.infoCardShowMoreWrapper}>
						{info?.detail &&
						 <div role="button" tabIndex={0} onClick={onClickDetail} className={styles.infoCard}>
							 <span>{info.detailTitle}</span>
							 <BlankButton
								 className={[styles.handler, isActiveDetail && styles.active].join(' ')}
							 >
								 <Icon icon="mobile-menu-arrow" size={20} color="#535353" />
							 </BlankButton>
						 </div>}
						{info?.promo &&
						 <div role="button" tabIndex={0} onClick={onClickPromo} className={styles.infoCard}>
							 <span>{info.promoTitle}</span>
							 <BlankButton
								 className={[styles.handler, isActiveDetail && styles.active].join(' ')}
							 >
								 <Icon icon="mobile-menu-arrow" size={20} color="#535353" />
							 </BlankButton>
						 </div>}
					</div>
				</div>
			</div>
			<Collapse in={isActiveDetail}>
				{info?.detail}
			</Collapse>
			<Collapse in={isActivePromo}>
				{info?.promo}
			</Collapse>
		</div>
	)
}

CollapseInfoCard.propTypes    = {
	info: PropTypes.object,
}
CollapseInfoCard.defaultProps = {
	info: {},
}
