import { NormalizeApi } from '@kakadu-dev/base-frontend-components/lib/modules/normalizeApi'
const BASE_ENDPOINT = 'base.rpc.cart';
export const CartApi = NormalizeApi({
	view: `${BASE_ENDPOINT}.view`,
	list: `${BASE_ENDPOINT}.index`,
	create: `${BASE_ENDPOINT}.create`,
	update: `${BASE_ENDPOINT}.update`,
	delete: `${BASE_ENDPOINT}.delete`,
	deleteAll: `${BASE_ENDPOINT}.delete-all`,
	checkout: `${BASE_ENDPOINT}.checkout`,
	checkoutTerminal: `${BASE_ENDPOINT}.checkout-terminal`,
	createOrder: `${BASE_ENDPOINT}.create-order`,
	createOrderTerminal: `${BASE_ENDPOINT}.create-order-terminal`,
	startSessionTerminal: `${BASE_ENDPOINT}.start-session-terminal`
})
