import { CategoriesList } from 'containers/Market/CategoriesList/index'
import { connect } from 'react-redux'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'

const CategoriesListStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CategoriesList)

export { CategoriesListStore }
