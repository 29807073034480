import styles from 'components/basket/BasketDelivery/styles.scss'
import React from 'react'
import PropTypes from 'prop-types'

/**
 * ]Redner info block for basket delivery
 *
 * @return {*}
 * @constructor
 */
export const Info = ({ text }) => (
	<div className={styles.info}>
		{text}
	</div>
)
Info.propTypes = {
	text: PropTypes.string,
}
Info.defaultProps = {
	text: '',
}
