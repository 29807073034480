import { CitySelect } from 'components/home/Header/CitySelect/index'
import { connect } from 'react-redux'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'

const CitySelectStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CitySelect)

export { CitySelectStore }


