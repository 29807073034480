import {
	DispatchSelector,
} from 'reduxm/modules/selectors'

const mapDispatchToProps = {
	createAppeals: DispatchSelector.certificateForm.create,
}

export {
	mapDispatchToProps,
}
