import { MaterialIcon } from 'components/global/MaterialIcon'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'
import styles from './styles.scss'

export const CatalogSection = ({ title, category, categoryLink, subsectionLink, children, fetching }) => (
	<Col xs="12" className={styles.section}>
		{!fetching &&
		 <div className={styles.heading}>
			 <div className={styles.box}>
				 <h2 className={styles.title}>{title}</h2>
				 <Link to={categoryLink} className={styles.link}>
					 <p className={styles.description}>Смотреть всё</p>
					 <div className={styles.icon}>
						 <MaterialIcon icon="keyboard_arrow_right" />
					 </div>
				 </Link>
			 </div>
			 {category &&
			  <Link to={subsectionLink} className={styles.link_category}>
				  {category}
			  </Link>}
		 </div>}
		{children}
	</Col>
)

CatalogSection.propTypes = {
	category:       PropTypes.string,
	categoryLink:   PropTypes.string,
	children:       PropTypes.node.isRequired,
	fetching:       PropTypes.bool,
	subsectionLink: PropTypes.string,
	title:          PropTypes.string,
}

CatalogSection.defaultProps = {
	category:       '',
	categoryLink:   '/category',
	fetching:       false,
	subsectionLink: 'category',
	title:          '',
}
