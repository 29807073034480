import { Category } from '@kakadu-dev/base-frontend-components'
import { ArrowForwardIosRounded as Arrow } from '@material-ui/icons'
import { AdministrativeProcedures } from 'components/market/Home/Sidebar/AdministrativeProcedures'
import { Subcategories } from 'components/market/Home/Sidebar/Subcategories'
import { getIcon } from 'components/market/Home/Sidebar/utils'
import _ from 'lodash'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { HierarchicalService } from 'services/HierarchicalService'
import styles from './styles.scss'

const getArrow = (isLeft = false) => <Arrow className={[styles.arrow, isLeft && styles.left].join(' ')} />

const getTitle = (category, isLeft, isProducts = false) => {
	const isChildrenExist = !!category.getChildrens()

	return (
		<>
			{isChildrenExist && isLeft && getArrow(true)}
			<div className="d-flex align-items-center">
				{isProducts && <img className={styles.icon} src={getIcon(category.getAlias())} alt="category" />}
				<span className={styles.linkText}>{category.getTitle()}</span>
			</div>
			{isChildrenExist && !isLeft && getArrow()}
		</>
	)
}

const noWay = ['a-appeals']

/**
 * Render desktop menu sidebar
 *
 * @param {object} hierarchicalState
 * @param {number} id
 * @param {Function} close
 * @param {boolean} isLeftRender
 *
 * @return {*}
 * @constructor
 */
export const SideBar = ({ hierarchicalState, id, close, isLeftRender }) => {
	const menu = _.find(hierarchicalState.result, ['id', id])

	HierarchicalService.getInstance().setCategories(hierarchicalState.result)

	return (
		<ul className="position-relative">
			{Category.createList(menu?.childrens ?? []).map(category => (
				<li key={category.getAlias()} className={styles.category}>
					<Link
						to={HierarchicalService.getInstance().getCategoryLink(category.getId())}
						onClick={close}
						className={[
							styles.link,
							noWay.includes(category.getAlias()) && styles.prevented
						].join(' ')}
					>
						{getTitle(category, isLeftRender, menu?.alias === 'products')}
					</Link>
					<Subcategories
						close={close}
						category={category}
						isLeftRender={isLeftRender}
					/>
				</li>
			))}
		</ul>
	)
}

SideBar.propTypes = {
	close:             PropTypes.func,
	hierarchicalState: PropTypes.object,
	id:                PropTypes.number,
	isLeftRender:      PropTypes.bool,
}

SideBar.defaultProps = {
	close:             () => null,
	hierarchicalState: {},
	id:                0,
	isLeftRender:      false,
}
