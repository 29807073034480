import * as PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'

const TooltipText = ({ id, text, placement, children }) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<div className="d-inline-block">
			<div
				className="cursor-help text-dashed"
				id={id}
			>
				{children}
			</div>
			<Tooltip
				placement={placement}
				isOpen={isOpen}
				target={id}
				toggle={() => setIsOpen(!isOpen)}
			>
				{text}
			</Tooltip>
		</div>
	)
}

TooltipText.propTypes = {
	id:        PropTypes.string.isRequired,
	text:      PropTypes.string.isRequired,
	placement: PropTypes.string,
	children:  PropTypes.node.isRequired,
}

TooltipText.defaultProps = {
	placement: 'right',
}

export { TooltipText }
