import { connect } from 'react-redux'
import { SignIn } from './index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'

const SignInStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(SignIn)

export { SignInStore }


