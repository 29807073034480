import {
	Customer,
	Order,
} from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { Collapse } from 'components/global/Collapse'
import { Price } from 'components/global/Price'
import { Header } from 'containers/User/Header'
import styles from 'containers/User/Orders/styles.scss'
import { getTime } from 'helpers/TimeHelper'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useState,
} from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { HierarchicalService } from 'services/HierarchicalService'

const getOrdersQuery = () => DataProvider.buildQuery()
	.addBody({
		query: {
			expands: ['products.product.categories.category.allParents',
				'city',
				'deliveryAddress'],
		},
	}).addOrderBy('id', '-')

const infoItem = (title, description) => (
	<div className={styles.infoItem}>
		<div>{title}</div>
		<div>{description}</div>
	</div>
)

const getPrice = price => <Price value={price} />

const orderStatuses = {
	new: 'Новый',
	notFinished: 'Новый',
	processed: 'В обработке',
	collected: 'Скомплектован',
	notDelivered: 'Передан на доставку',
	delivered: 'Доставлен',
	canceled: 'Oтменен',
}

/**
 * Render users orders
 *
 * @param {Object} userState
 * @param {Object} ordersState
 * @param {Function} getOrders
 *
 * @return {*}
 * @constructor
 */
export const Orders = ({ userState, ordersState, getOrders }) => {
	const [activeOrder, setActiveOrder] = useState(null)

	useEffect(() => {
		getOrders(getOrdersQuery())
	}, [getOrders])

	const orders = Order.createList(ordersState)

	return (
		<section>
			<Header
				title={orders.length ? 'История заказов' : 'Тут будет отображена история заказов'}
				userName={Customer.create(userState)?.getFullName?.()}
			/>
			<article>
				{orders.map(order => {
					const orderId       = order?.getId?.()
					const orderProducts = order?.getProducts?.()
					// const address       = order?.getDeliveryAddress?.()
					const isActive      = activeOrder === orderId

					return (
						<div id={orderId} key={orderId} className={styles.wrapper}>
							<div className={styles.header}>
								<div className={styles.number}>
									{`Заказ №${orderId}`}
								</div>
								<Button
									className={styles.moreInfoBtn}
									color="primary"
									onClick={() => setActiveOrder(activeOrder === orderId ? null : orderId)}
								>
									{isActive ? 'Скрыть' : 'Подробности'}
								</Button>
								<div className={[styles.status,
									order.getStatus() !== 'delivered' && styles.notCompleted].join(' ')}
								>
									{`${orderStatuses[order.getStatus()] || '-'}`}
								</div>
							</div>
							<Collapse className={styles.body} isActive={isActive}>
								{orderProducts.map(orderProduct => {
									const product = orderProduct.getProduct()
									const purchasePrice = orderProduct.model.purchasePrice
									const productLink = HierarchicalService.getProductLink(product)
									return (
										<div key={`${orderId}${product.getId()}`} className={styles.product}>
											<div className={styles.image}>
												<img src={product.getPreview()} alt={product.getAlias()} />
											</div>
											<div className={styles.info}>
												<div className={styles.links}>
													<Link
														className={styles.title}
														to={productLink}
													>
														{product.getTitle()}
													</Link>
													<Link className={styles.review} to={`${productLink}#reviews`}>Оставить отзыв о товаре</Link>
												</div>
												<div>
													<div className={styles.priceWrapper}>
														<div className={styles.count}>
															<div>{`${orderProduct.getCount()} шт.`}</div>
															<div>{getPrice(purchasePrice)}</div>
														</div>
														<div className={styles.price}>{getPrice(orderProduct.getPrice())}</div>
													</div>
												</div>
											</div>
										</div>
									)
								})}
							</Collapse>
							<div className={styles.footer}>
								<div className={styles.addressInfo}>
									{infoItem('Город:', order.getCity().getTitle())}
									{/* {address && infoItem('Адрес:', address)} */}
									{infoItem('Оформлен:', getTime(order.getCreatedAt() * 1000))}
									{infoItem('Оплата:', order.getPaymentMethod(true))}
								</div>
								<div className={styles.priceInfo}>
									{infoItem(`Товары (${orderProducts.length}):`,
										getPrice(order.getPrice() - order.getDeliveryPrice()))}
									{infoItem('Доставка', getPrice(order.getDeliveryPrice()))}
									{infoItem('Итого', getPrice(order.getPrice()))}
								</div>
							</div>
						</div>
					)
				})}
			</article>
		</section>
	)
}

Orders.propTypes = {
	getOrders:   PropTypes.func,
	ordersState: PropTypes.object,
	userState:   PropTypes.object,
}

Orders.defaultProps = {
	getOrders:   () => null,
	ordersState: {},
	userState:   {},
}
