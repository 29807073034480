import { Collapse } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { getHtml } from 'helpers/htmlHelper'
import styles from './style.scss'


/**
 * Render collapse link
 *
 * @param {Object} node
 *
 * @return {*}
 * @constructor
 */
export const CollapseLink = ({ node }) => {
	const [isOpen, setIsOpen] = useState(false)
	const elements = getHtml(node)
	return (
		<>
			<span
				className={styles.title}
				role="button"
				tabIndex={0}
				onClick={() => setIsOpen(prevState => !prevState)}
			>
				{elements['collapse-title'] || null}
			</span>
			<Collapse in={isOpen}>
				{elements['collapse-description'] || null}
			</Collapse>
		</>
	)
}
CollapseLink.propTypes    = {
	node: PropTypes.object,
}
CollapseLink.defaultProps = {
	node: {},
}
