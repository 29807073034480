import { Info } from 'components/basket/BasketDelivery/Info'
import {
	RadioBtn,
} from 'components/global'
import { DottedLine } from 'components/global/DottedLine'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Collapse } from 'reactstrap'
import styles from './styles.scss'

const deliveryText = {
	pickupMinsk: 'Пункт самовывоза находится в г. Минске по адресу пр-т Независимости, 21 (универмаг). Обращаться в инфоцентр универмага.',
	pickupZhodino: 'Пункт самовывоза находится в г. Жодино по адресу ул. 8 Марта, 1',
}

/**
 * Render basket delivery
 *
 * @param {Object} methods
 * @param {string} currentMethod
 * @param {Function} toggle
 *
 * @return {*}
 * @constructor
 */
export const DeliveryMethod = ({ methods, currentMethod, toggle }) => (
	<>
		<div className={styles.box}>
			<DottedLine />
			<p className={styles.title}>Способ доставки</p>
			<div className={styles.method}>
				{methods.map(method => (
					<RadioBtn
						key={method.value}
						id={method.value}
						name='deliveryMethod'
						label={method.label}
						onChange={toggle}
						checked={method.value === currentMethod}
					/>
				))}
			</div>
		</div>
		<Collapse isOpen={(currentMethod === 'pickupMinsk' || currentMethod === 'pickupZhodino')}>
			<div className={styles.variant}>
				<Info text={deliveryText[currentMethod]} />
			</div>
		</Collapse>
	</>
)

DeliveryMethod.propTypes = {
	currentMethod: PropTypes.string,
	methods:       PropTypes.array,
	toggle:        PropTypes.func,
}

DeliveryMethod.defaultProps = {
	currentMethod: '',
	methods:       [],
	toggle:        () => null,
}
