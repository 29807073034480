import React from 'react'
import styles from './styles.scss'

/**
 * Render dotted line
 *
 * @return {*}
 * @constructor
 */
export const DottedLine = () => <div className={styles.dotted} />
