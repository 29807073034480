import Icon from 'assets/icomoon/Icon'
import { BlankButton } from 'components/global/BlankButton'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render controller for sidebar
 *
 * @param {boolean} isActive
 * @param {Function} setGroupIds
 * @param {Array} ids
 *
 * @return {*}
 * @constructor
 */
export const Controller = ({ isActive, action, ids }) =>
	isActive &&
	<div className={styles.controller}>
		<BlankButton onClick={() => action([])} className={styles.btn}>
			<Icon icon="minus-square" size={16} />
			Скрыть
		</BlankButton>
		<BlankButton onClick={() => action(ids)} className={styles.btn}>
			<Icon icon="plus-square" size={16} />
			Показать
		</BlankButton>
	</div>

Controller.propTypes = {
	action:   PropTypes.func,
	ids:      PropTypes.array,
	isActive: PropTypes.bool,
}

Controller.defaultProps = {
	action:   () => null,
	ids:      [],
	isActive: false,
}
