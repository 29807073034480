export const giftItems = [
	{
		id:      1,
		title:   'Подарочек',
		text:    'При покупке от 10 руб.',
		checked: true,
	},
	{
		id:    2,
		title: 'Подарок',
		text:  'При покупке от 100 руб.',
	},
]
