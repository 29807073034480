import { ElectronicAppealsForm } from 'components/static/ElectronicAppealsForm/index'
import { connect } from 'react-redux'
import {
	mapDispatchToProps,
} from './index.props'

const ElectronicAppealsFormInStore = connect(
	null,
	mapDispatchToProps,
)(ElectronicAppealsForm)

export {ElectronicAppealsFormInStore}
