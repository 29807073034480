/* eslint-disable array-callback-return */
import { Category } from '@kakadu-dev/base-frontend-components'
import _ from 'lodash'

/**
 * Hierarchical service
 */
export class HierarchicalService
{
	/**
	 * @type {HierarchicalService}
	 */
	static myInstance = null

	/**
	 * @type {object}
	 */
	categories = {}

	/**
	 * Get instance
	 *
	 * @return {HierarchicalService}
	 */
	static getInstance() {
		if (this.myInstance === null) {
			this.myInstance = new HierarchicalService()
		}

		return this.myInstance
	}

	/**
	 * Format category
	 *
	 * @param {Object} category
	 * @param {number} level
	 * @param {Object|undefined} parentCategory
	 *
	 * @return {Object}
	 *
	 * @private
	 */
	static formatCategory = (category, level, parentCategory) => ({
		[category.id]: {
			id:    Number(category.id),
			alias: category.alias,
			title: category.title,
			level,
			...(parentCategory && { parentId: Number(parentCategory.id) }),
		},
	})

	/**
	 * Set categories
	 *
	 * @param {Array.<Object>} categories
	 *
	 * @return {undefined}
	 */
	setCategories(categories) {
		let result = {}

		if (!categories) {
			return
		}

		(categories.map ? categories : Object.values(categories)).map(category => {
			if (category.childrens && category.childrens.length > 0) {
				result = { ...result, ...HierarchicalService.formatCategory(category, 1) }

				category.childrens.map(secondCategory => {
					result = { ...result, ...HierarchicalService.formatCategory(secondCategory, 2, category) }

					if (secondCategory.childrens) {
						if (Array.isArray(secondCategory.childrens)) {
							secondCategory.childrens.map(thirdCategory => {
								result = { ...result, ...HierarchicalService.formatCategory(thirdCategory, 3, secondCategory) }
							})
						} else {
							const { childrens: thirdCategory, groups } = secondCategory

							groups.map(group => {
								group.categoriesIds.map(categoryId => {
									if (thirdCategory[categoryId]) {
										result = {
											...result, ...HierarchicalService
												.formatCategory(thirdCategory[categoryId], 3, secondCategory),
										}
									}
								})
							})
						}
					}
				})
			}
		})
		this.categories = result
	}

	/**
	 * Get link with product model expands
	 *
	 * @param {Product} product
	 * @param {number} id
	 *
	 * Is required set expands for Product model:
	   .addExpands('categories', 'categories.category')
	 * or for CartProduct model:
	   .addExpands('product', 'product.categories', 'product.categories.category')
	 *
	 * @return {string}
	 */
	static getProductLink = (product, id) => {
		const aliases  = []
		const rawModel = product?.getCategoriesEntities?.()?.getRawModel?.() ?? []

		const primaryCategory = rawModel.length ? rawModel.find(category => category.isPrimary)?.category : {}

		const mainCategory = Category.create(primaryCategory || {})

		mainCategory.getAllParents().forEach(category => {
			if (category.level === 'one') {
				aliases[0] = category.alias
			}
			if (category.level === 'two') {
				aliases[1] = category.alias
			}
		})

		aliases.push(mainCategory.getAlias())

		aliases.push(id ? `${product?.getAlias?.()}-${id}` : product?.getLinkPath?.())

		return `/${aliases.join('/')}`
	}

	/**
	 * Get link with category id
	 *
	 * @param {number} categoryId
	 *
	 * @return {string}
	 */
	getCategoryLink = categoryId => {
		const categories = this.getCategories(categoryId)
		const aliasArray = []

		categories.map(category => {
			if (category) {
				aliasArray.push(category.alias)
			}
		})

		if (categories.length === 0) {
			return ''
		}

		return `/${aliasArray.join('/')}`
	}

	/**
	 * Get all categories for current id
	 *
	 * @param {number} categoryId
	 *
	 * @return {Array.<Object>}
	 * @private
	 */
	getCategories = categoryId => {
		const result = []

		const category = _.find(this.categories, ['id', categoryId])

		result.push(category)

		if (category && category.parentId) {
			const parentCategory = _.find(this.categories, ['id', category.parentId])

			result.push(parentCategory)

			if (parentCategory && parentCategory.parentId) {
				const subParentCategory = _.find(this.categories, ['id', parentCategory.parentId])

				result.push(subParentCategory)
			}
		}

		return _.sortBy(result, 'level')
	}

	/**
	 * Get breadcrumbs for current page
	 *
	 * @param {number} categoryId
	 * @param {string|null} productTitle
	 *
	 * @return {Array.<Object>}
	 */
	getBreadcrumbs = (categoryId, productTitle = null) => {
		const start = [{ title: 'Главная', path: '/' }]
		const end   = [{ title: productTitle, path: '' }]

		const categories = Category.createList(this.getCategories(categoryId))

		categories.map(category => {
			category && start.push(
				{ title: category.getTitle(), path: this.getCategoryLink(category.getId()) },
			)
		})

		return productTitle ? [...start, ...end] : start
	}
}
