import Icon from 'assets/icomoon/Icon'
import { BlankButton } from 'components/global/BlankButton'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render store item
 *
 * @param {boolean} isActive
 * @param {Object} store
 * @param {Function} action
 *
 * @return {*}
 * @constructor
 */
export const StoreItem = ({ isActive, store, action }) => (
	<div key={store.geometry[0]} className={[styles.address, isActive && styles.active].join(' ')}>
		<BlankButton onClick={action}>
			{isActive && <Icon icon='location1' color="00a675" size={16} />}
			{`${store.balloonContentHeader} ${store.balloonContent} ${store.address}`}
		</BlankButton>
		<div className={styles.phones}>
			{store.balloonContentFooter.join(' ')}
		</div>
	</div>
)

StoreItem.propTypes = {
	action:   PropTypes.func,
	isActive: PropTypes.bool,
	store:    PropTypes.object,
}

StoreItem.defaultProps = {
	action:   () => null,
	isActive: false,
	store:    {},
}
