import CityField from 'components/Form/fields/City/index.store'
import { PhoneNumber } from 'components/Form/fields/PhoneNumber'
import React from 'react'
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/material.css'

export const userContactsFields = [
	{ id: 'firstName', title: 'Имя', },
	{ id: 'lastName', title: 'Фамилия', },
	{ id: 'phone', title: 'Телефон', component:  <PhoneNumber />},
	{ id: 'email', title: 'E-mail' },
	{ id: 'comment', title: 'Комментарий' },
]

export const userAddressFields = [
	{ id: 'cityId', title: 'Город', component: <CityField /> },
	{ id: 'street', title: 'Улица', },
	{ id: 'house', title: 'Дом', },
	{ id: 'block', title: 'Корпус' },
	{ id: 'apartment', title: 'Квартира' },
]
