import MaterialInput from '@material-ui/core/Input'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useState,
} from 'react'
import InputMask from 'react-input-mask'
import styles from './styles.scss'

/**
 * Render phone input
 *
 * @param {number} value
 * @param {Function} handler
 * @param {Object} field
 *
 * @return {*}
 * @constructor
 */
export const PhoneInput = ({ value, handler, field }) => {
	const [isActive, setIsActive] = useState(false)

	const { id, title } = field

	const onChange = useCallback(({ target: { value: nextValue } }) => handler(id, nextValue),
		[handler, id])

	return (
		<div
			onFocus={() => setIsActive(true)}
			onBlur={() => setIsActive(false)}
			className={[styles.wrapper, isActive && styles.active].join(' ')}
		>
			<span className={styles.label}>{title}</span>
			<InputMask
				formatChars={{ a: '(2|3|4)', b: '(3|5|9|4)', '9': '[0-9]' }}
				mask="+375 (ab) 999-99-99"
				maskChar="_"
				value={value}
				onChange={onChange}
				placeholder="+375"
			>
				{(inputProps) => <MaterialInput {...inputProps} type="tel" disableUnderline />}
			</InputMask>
		</div>
	)
}

PhoneInput.propTypes = {
	field:   PropTypes.object,
	handler: PropTypes.func,
	value:   PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

PhoneInput.defaultProps = {
	field:   {},
	handler: () => null,
	value:   null,
}
