import PropTypes from 'prop-types'
import React, {
	useMemo,
	useRef,
	useState,
} from 'react'
import styles from './styles.scss'

const initialRangeState        = { min: '', max: '' }
/**
 * Render number filter block
 *
 * @param {PropertyType} propertyType
 * @param {function} toggle
 *
 * @return {*}
 * @constructor
 */
export const FilterNumberBlock = ({ propertyType, toggle }) => {
	const [alias, name]            = useMemo(() => [propertyType.getAlias(), propertyType.getName()],
		[propertyType])
	const [defaultMin, defaultMax] = useMemo(() => [propertyType.getMinRange(), propertyType.getMaxRange()],
		[propertyType])

	const [showHint, setShowHint] = useState(false)
	const [range, setRange]       = useState(initialRangeState)

	const minRangeRef = useRef()
	const maxRangeRef = useRef()

	const showError = () => {
		setShowHint(true)

		setTimeout(() => setShowHint(false), 3000)
	}

	const getCurrentValue = value => {
		let newValue = value

		setShowHint(false)

		if (Number.isNaN(Number(value))) {
			showError()
		}

		if (value[0] === '0' && value.length === 1) {
			newValue += '.'
		}

		if (value > defaultMax) {
			newValue = defaultMax
			showError()
		}

		if (value < 0) {
			newValue = 0
			showError()
		}

		return newValue
	}

	const onChange = ({ target: { value } }, isMax = false) => {
		const { min: prevMin, max: prevMax } = range

		const min = getCurrentValue(isMax ? prevMin : value)
		const max = getCurrentValue(isMax ? value : prevMax)

		setRange({ min, max })
		toggle(null, {
			value:    `${name}: от ${min} до ${max}`,
			alias,
			type:     'number',
			id:       { 'between': [+min, +max] },
			callback: () => setRange(initialRangeState),
		})
	}

	return (
		<>
			<p className={styles.title}>{name}</p>
			<div className={styles.range}>
				<div className={[styles.incorrect, showHint && styles.incorrect_active].join(' ')}>
					<p>Введите число в диапазоне</p>
					<p>{`от ${range.min} до ${range.max}`}</p>
				</div>
				<input
					type="text"
					className={styles.min}
					onChange={onChange}
					value={range.min}
					ref={minRangeRef}
					placeholder={defaultMin}
				/>
				<input
					type="text"
					className={styles.max}
					onChange={event => onChange(event, true)}
					value={range.max}
					ref={maxRangeRef}
					placeholder={defaultMax}
				/>
			</div>
		</>
	)
}

FilterNumberBlock.propTypes = {
	propertyType: PropTypes.object,
	toggle:       PropTypes.func,
}

FilterNumberBlock.defaultProps = {
	propertyType: {},
	toggle:       () => null,
}
