import sales from 'assets/images/product-card/percentage.png'
import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.scss'

export const ProductCardSale = ({ sale }) => {

	return (
		<div className={styles.sale}>
			<img src={sales} alt="sales" />
			<div className={styles.saleText}>{`-${sale}%`}</div>
		</div>
	)
}

ProductCardSale.propTypes = {
	sale: PropTypes.number,
}
ProductCardSale.defaultProps = {
	sale: 0,
}
