import depDirector from 'assets/images/static-pages/managment/dep-director.png'
import depDirector1 from 'assets/images/static-pages/managment/dep-director1.jpg'
import director from 'assets/images/static-pages/managment/director.jpg'
import noPhoto from 'assets/images/static-pages/managment/no-photo.png'
import struct from 'assets/images/static-pages/managment/struct.png'
import { UncontrolledCollapse } from 'components/global/CollapseText'
import { StaticPageWrapper } from 'components/static/StaticPageWrapper'
import React, { useEffect } from 'react'
import { MetaService } from 'services/MetaService'
import styles from './styles.scss'

const title = 'Руководство'

const setMetaInfo = () => MetaService.setInfo({}, { title })

const managers = [
	{
		name:         'Крепак Александр Петрович',
		image:        director,
		phone:        '+375(17)323-15-29',
		fax:          '+375(17)257-22-03',
		position:     'Директор открытого акционерного общества «ГУМ»',
		receiptTime:  'Среда с 14:00 до 16:00, каб. 28',
		receiptPhone: '+375(17)323-15-29',
	},
	{
		name:         'Гвоздева Татьяна Николаевна',
		image:        depDirector,
		phone:        '+375(17)347-31-37',
		fax:          '+375(17)257-22-03',
		position:     'Первый заместитель директора открытого акционерного общества «ГУМ»',
		receiptTime:  'Вторник с 14:00 до 16:00, каб. 28',
		receiptPhone: '+375(17)323-15-29',
	},
	{
		name:         'Ульянцева Наталья Николаевна',
		image:        depDirector1,
		phone:        '+375(17)326-55-29',
		position:     'Заместитель директора открытого акционерного общества «ГУМ»',
		receiptTime:  'Среда с 14:00 до 16:00, каб. 10',
		receiptPhone: '+375(17)323-45-14',
	},
]

/**
 * Render purchases page
 *
 * @return {*}
 * @constructor
 */
export const Leadership = () => {
	useEffect(() => {
		setMetaInfo()
	}, [])

	return (
		<StaticPageWrapper title={title} extraBreadcrumbs={[{ title: 'О компании', path: '/about-company' }]}>
			<section className={styles.managers}>
				{managers.map(manager => (
					<div key={manager.name} className={styles.managerCard}>
						<img className={styles.image} src={manager.image || noPhoto} alt="manager" />
						<div className={styles.info}>
							<div>
								<strong>
									{manager.name}
								</strong>
							</div>
							<div>{manager.position}</div>
							<div>
								<p>
									Телефон:
									<a className="ml-2" href={`tel:${manager.phone}`}>
										{manager.phone}
									</a>
								</p>
								{manager.fax &&
								 <p>
									 Факс:
									 <a className="ml-2" href={`tel:${manager.fax}`}>
										 {manager.fax}
									 </a>
								 </p>}
							</div>
							<div>
								{'Личный прием граждан: '}
								<br />
								{manager.receiptTime}
								<br />
								<br />
								{'Tел. для записи:'}
								<a className="ml-2" href={`tel:${manager.receiptPhone}`}>
									{manager.receiptPhone}
								</a>
							</div>
						</div>
					</div>
				))}
				<br />
				<div>
					<UncontrolledCollapse title="Организационная структура ОАО «ГУМ»">
						<img src={struct} alt="" />
					</UncontrolledCollapse>
				</div>
			</section>
		</StaticPageWrapper>
	)
}
