import { connect } from 'react-redux'
import { ProductOffer } from 'components/market/ProductPage/ProductOffer/index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from 'components/market/ProductPage/ProductOffer/index.props'

const ProductOfferStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProductOffer)

export { ProductOfferStore }
