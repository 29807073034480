import { ArrowButton } from 'components/slider'
import * as PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-slick'
import styles from './styles.scss'

const getInitialSettings = numberOfSlides => ({
	infinite:       true,
	speed:          500,
	slidesToShow:   numberOfSlides > 4 ? 4 : numberOfSlides,
	slidesToScroll: 1,
	nextArrow:      <ArrowButton styles={styles} />,
	prevArrow:      <ArrowButton styles={styles} prev />,
	responsive:     [
		{
			breakpoint: 1200,
			settings:   {
				slidesToShow: numberOfSlides > 3 ? 3 : numberOfSlides,
			},
		},
		{
			breakpoint: 991,
			settings:   {
				slidesToShow: numberOfSlides > 2 ? 2 : numberOfSlides,
			},
		},
		{
			breakpoint: 767,
			settings:   {
				slidesToShow: 1,
			},
		},
		{
			breakpoint: 400,
			settings:   {
				slidesToShow: 1,
			},
		},
	],
})

/**
 * Render products slider
 *
 * @param {string} title
 * @param {object} settings
 * @param {[Node]} children
 *
 * @return {*}
 * @constructor
 */
export const ProductsSlider = ({ title, settings, children }) => (
	<section className={styles.slider}>
		{title && <p className={styles.title}>{title}</p>}
		<div className={styles.container}>
			<Slider {...{ ...getInitialSettings(children.length), ...settings }}>
				{children}
			</Slider>
		</div>
	</section>
)


ProductsSlider.propTypes = {
	title:    PropTypes.string,
	children: PropTypes.node.isRequired,
	settings: PropTypes.object,
}

ProductsSlider.defaultProps = {
	title:    '',
	settings: {},
}
