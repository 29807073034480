import { Customer } from '@kakadu-dev/base-frontend-components'
import female from 'assets/images/reviews/female.png'
import male from 'assets/images/reviews/male.png'
import styles from 'components/market/ProductPage/ProductReviewItems/Avatar/styles.scss'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render user photo
 *
 * @param {Customer} user
 */
export const Index = ({ user }) => {
	const image        = user.getSettings().getImage()
	const defaultImage = user.getGender() === 'female' ? female : male

	return (
		<div className={styles.avatar}>
			<img src={image || defaultImage} alt="user-avatar" />
		</div>
	)
}

Index.propTypes = {
	user: PropTypes.instanceOf(Customer),
}

Index.defaultProps = {
	user: Customer.create({}),
}
