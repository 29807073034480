
module.exports = {
	'general.basket': 'Кошык',
	'general.entrance': 'Увайсці',
	'general.search': 'Пошук',
	'general.search.start': 'Пачніце пошук',
	'general.search.empty': 'Мы нічога не знайшлі',
	'general.store.map': 'Карта крам',
	'general.sunday': 'Нядзеля',
	'general.subscribe.button': 'Падпісацца',
	'general.subscribe.input': 'Калі ласка, увядзіце e-mail',
	'general.subscribe.title': 'Падпішыцеся на рассылку і будзьце ў курсе падзей!',
	'general.subscribe.success': 'Дзякуй за падпіску!',
	'general.social.title': 'Мы у сацыяльных сетках',
	'general.developers.title': 'Распрацоўка сайта',
	'general.breadCrumbs.title': 'Галоўная ',
	'general.administrative.procedures': 'Пералік адміністрацыйных працэдур',
	'general.about.title': 'Пра нас',
	'general.about.read.more': 'Чытаць больш',
	'general.about.introduction':
		'ГУМ — першы найбуйнейшы універсальны магазін г. Мінска. Упершыню расчыніў свае дзверы для ' +
		'пакупнікоў і гасцей 5 лістапада 1951 года.\n' +
		'ГУМ-славутасць Беларусі. Будынак' +
		' універмага з\'яўляецца помнікам архітэктуры і горадабудаўніцтва ХХ стагоддзя.',


	'registration.data': 'Адкрытае акцыянернае таварыства «ГУМ», УНП 100063737, 220030, Рэспубліка Беларусь,' +
						 ' г. Мінск, пр -т Незалежнасці, 21. Інтэрнэт - крама www.gum.by зарэгістравана ў ' +
						 'гандлёвым рэестры Рэспублікі Беларусь 25.03.2015, рэгістрацыйны № 226598.',
	'registration.certificate': 'Пасведчанне аб дзяржаўнай рэгістрацыі №100063737 выдадзена 26.04.2000 ' +
								'рашэннем Мінскага Гарвыканкама № 456.'
}
