import { StateSelector } from '@kakadu-dev/base-frontend-components'
import { connect } from 'react-redux'
import { DispatchSelector } from 'reduxm/modules/selectors'
import { BasketAside } from './index'

const mapStateToProps = state => ({
	cartListState:    StateSelector.cart.list(state),
	createOrderState: StateSelector.cart.createOrder(state),
})

const mapDispatchToProps = {
	createOrder: DispatchSelector.cart.createOrder,
}

export const BasketAsideStore = connect(mapStateToProps, mapDispatchToProps)(BasketAside)
