import FilterPlaceholder from 'components/utils/placeholders/filter-block'
import PropTypes from 'prop-types'
import React from 'react'
import { FilterBooleanBlock } from './FilterBooleanBlock'
import { FilterNumberBlock } from './FilterNumberBlock'
import { FilterProgress } from './FilterProgress'
import { FilterStringBlock } from './FilterStringBlock'
import styles from './styles.scss'

/**
 * Render filters section
 *
 * @param {CategoryFilters} list
 * @param {Object} productsService
 * @param {boolean} fetching
 * @param {function} toggle
 *
 * @return {*}
 * @constructor
 */
export const FiltersList = ({ list, productsService, fetching, toggle }) => (
	!fetching ?
		<div className={styles.filter}>
			{list.getFilters().map(propertyType => {
				const filterProps = {
					key: propertyType.getId(),
					propertyType,
					toggle,
				}

				switch (propertyType.getFilterType()) {
					case 'number':
						return <FilterNumberBlock {...filterProps} />
					case 'string':
						return <FilterStringBlock {...filterProps} />
					case 'boolean':
						return <FilterBooleanBlock {...filterProps} />
					default:
						return null
				}
			})}

			<FilterProgress
				searching={productsService.isFetching()}
				countOfProducts={productsService.getPagination().getTotalItems()}
			/>
		</div> : <FilterPlaceholder />
)

FiltersList.propTypes = {
	list:            PropTypes.object,
	productsService: PropTypes.object,
	fetching:        PropTypes.bool,
	toggle:          PropTypes.func,
}

FiltersList.defaultProps = {
	list:            {},
	productsService: {},
	fetching:        false,
	toggle:          () => null,
}
