import { Category } from '@kakadu-dev/base-frontend-components'
import Icon from 'assets/icomoon/Icon'
import { BlankButton } from 'components/global/BlankButton'
import styles from 'containers/Static/SiteMap/styles.scss'
import * as PropTypes from 'prop-types'
import React, {
	useMemo,
	useState,
} from 'react'
import { Link } from 'react-router-dom'
import { Collapse } from 'reactstrap'
import { HierarchicalService } from 'services/HierarchicalService'

/**
 * Render sitemap collapse item
 *
 * @param {Category} category
 *
 * @return {*}
 * @constructor
 */
export const SiteMapDataItem = ({ category }) => {
	const [isOpen, setIsOpen] = useState(false)

	const subCategories = useMemo(() => {
		const list = category.getChildrens()

		if (list) {
			return Category.createList(Array.isArray(list) ? list : Object.values(list))
		}
	}, [category])

	const link = HierarchicalService.getInstance().getCategoryLink(category.getId())

	return subCategories ?
		<>
			<li className={styles.item}>
				<BlankButton
					className={styles.icon}
					onClick={() => setIsOpen(!isOpen)}
				>
					<Icon icon={isOpen ? 'minus-square' : 'plus-square'} size={14} />
				</BlankButton>
				<Link className={styles.link} to={link}>
					{category.getTitle()}
				</Link>
			</li>

			<Collapse isOpen={isOpen}>
				<ul className={styles.sublist}>
					{subCategories.map(subItem => (
						<li key={subItem.getId()} className={styles.item}>
							<Link
								className={styles.link}
								to={HierarchicalService.getInstance().getCategoryLink(subItem.getId())}
							>
								{subItem.getTitle()}
							</Link>
						</li>
					))}
				</ul>
			</Collapse>
		</> :
		<li className={styles.item}>
			<Link
				className={[styles.link, styles.empty].join(' ')}
				to={link}
			>
				{category.getTitle()}
			</Link>
		</li>
}

SiteMapDataItem.propTypes = {
	category: PropTypes.object,
}

SiteMapDataItem.defaultProps = {
	category: {},
}
