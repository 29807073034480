/* eslint-disable no-underscore-dangle */
import {
	After,
	ensureReady,
	getSerializedData,
} from '@jaredpalmer/after'
import {
	GOOGLE_TAG_MANAGER_ID,
	YANDEX_METRICS_ID,
	IS_PROD,
} from 'constants'
import Layout from 'containers/Layout/index.store'
import React from 'react'
import { hydrate } from 'react-dom'
import TagManager from 'react-gtm-module'
import ym, { YMInitializer } from 'react-yandex-metrika'
import { Provider } from 'react-redux'
import {
	BrowserRouter,
	Router,
} from 'react-router-dom'
import { configureStore } from 'reduxm/store'
import routes from 'routing'
import { history } from 'routing/history'
import * as serviceWorker from './serviceWorker'
import './styles/styles-for-loading'

const preloadedState = getSerializedData('__PRELOADED_STATE__')
// const preloadedState = window.__PRELOADED_STATE__
// delete window.__PRELOADED_STATE__

const store = configureStore(preloadedState)

if (GOOGLE_TAG_MANAGER_ID) {
	const tagManagerArgs = {
		gtmId: GOOGLE_TAG_MANAGER_ID,
		events: {
			sendUserInfo: 'userInfo'
		},
		dataLayer: {
			userId: '001',
			userProject: 'project'
		},
		dataLayerName: 'PageDataLayer'
	}
	TagManager.initialize(tagManagerArgs)
}

history.listen((location, action) => {
	if (IS_PROD) {
		if(action === 'PUSH') {
			const { pathname } = location

			const tagManagerArgs = {
				dataLayer: {
					userId: '001',
					userProject: 'project',
					page: pathname,
				},
				dataLayerName: 'PageDataLayer'
			}

			TagManager.dataLayer(tagManagerArgs)

			if(YANDEX_METRICS_ID) {
				try {
					ym('hit', pathname)
				}
				catch (e) {
					console.error('yandex metrics', 'not available')
				}
			}
		}
	}
})

function renderApp()
{
	ensureReady(routes).then(data =>
		hydrate(
			<>
				<BrowserRouter>
					<Provider store={store}>
						<Layout>
							<After data={data} routes={routes} store={store} />
						</Layout>
					</Provider>
				</BrowserRouter>
				<YMInitializer
					accounts={[YANDEX_METRICS_ID]}
					options={{
						clickmap: true,
						trackLinks: true,
						accurateTrackBounce: true,
						webvisor: true,
						triggerEvent: true,
					}}
					version="2"
				/>
			</>,
			document.getElementById('root'),
		),
	)
}

renderApp()

// If you want your site to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if (module.hot) {
	module.hot.accept('./routing', renderApp)
}
