import {
	CartModel,
	CartProduct,
} from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import Icon from 'assets/icomoon/Icon'
import { Button } from 'components/global/Button'
import { Price } from 'components/global/Price'
import { useStopScrolling } from 'components/global/useStopScrolling'
import _ from 'lodash'
import * as PropTypes from 'prop-types'
import React, {
	useEffect,
	useState,
} from 'react'
import AnimateHeight from 'react-animate-height'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { PreviewCartProduct } from './PreviewCartProduct'
import styles from './styles.scss'

const cartQuery = DataProvider
	.buildQuery()
	.addBody({
		query: {
			expands: [
				'offer.option.properties',
				'product.categories.category.allParents',
				'minPrice',
			],
		},
	})

/**
 * Render basket preview
 */
export const BasketPreview = ({
	deleteCartProduct,
	getCartList,
	isMobile,
	showBackdrop,
	deleteCartProductState,
	cartListState,
	intl,
}) => {
	const [previewIsOpen, setPreviewIsOpen] = useState(false)

	// eslint-disable-next-line react-hooks/rules-of-hooks
	!isMobile && useStopScrolling(previewIsOpen)

	useEffect(() => {
		getCartList(cartQuery)
	}, [getCartList])

	const removeProduct = _.throttle(id => deleteCartProduct({ id }),
		500, { leading: true, trailing: false })

	const cartListService  = StateService.create(cartListState)
	const cartList         = CartProduct.createList(cartListState)
	const fetchingOnDelete = StateService.create(deleteCartProductState).isFetching()
	const cart             = CartModel.create(cartListState)

	const cartTotal = cart.getTotal()
	const cartCount = cart.getCount()

	return (
		<div
			className={styles.basket}
			onMouseEnter={() => {
				setPreviewIsOpen(!previewIsOpen)
				showBackdrop()
			}}
			onMouseLeave={() => {
				setPreviewIsOpen(!previewIsOpen)
				showBackdrop(false)
			}}
		>
			<Link to='/basket'>
				<Icon icon={cartCount > 0 ? 'cart' : 'empty-cart'} size={24} />
				{cartCount > 0 && <div className={styles.badge}>{cartCount}</div>}
				{!isMobile && <p>{intl.messages['general.basket']}</p>}
			</Link>

			{!isMobile && (
				<AnimateHeight
					className={styles.preview}
					duration={500}
					height={previewIsOpen ? 'auto' : '0'}
					animateOpacity
				>
					<PerfectScrollbar className={styles.content}>
						{(cartList || []).map(product => (
							<PreviewCartProduct
								key={product.getId()}
								product={product}
								fetchingOnDelete={fetchingOnDelete}
								removeProduct={removeProduct}
							/>
						))}
					</PerfectScrollbar>

					<div className={styles.footer}>
						{cartList.length &&
						 <>
							 <div className={styles.total}>
								 <span>Итого:</span>
								 {cartListService.isFetching() ?
									 <span className="ml-2">
										 <Spinner color="success" />
									 </span> :
									 <Price value={cartTotal} />}
							 </div>

							 <Link to="/basket" className={styles.link}>
								 <Button title="Перейти в корзину" color="green" />
							 </Link>
						 </> ||
						 cartListService.isFetching() && <Spinner color="success" /> ||
						 <>
							 <div className={styles.empty_basket}>
								 Корзина пуста
							 </div>

							 <Link to={'/products'} className={styles.link}>
								 <Button title="Перейти в каталог" color="green" />
							 </Link>
						 </>
						}
					</div>
				</AnimateHeight>
			)}
		</div>
	)
}

BasketPreview.propTypes = {
	isMobile:               PropTypes.bool.isRequired,
	cartListState:          PropTypes.object,
	deleteCartProductState: PropTypes.object,
	intl:                   PropTypes.object,
	showBackdrop:           PropTypes.func,
	getCartList:            PropTypes.func,
	deleteCartProduct:      PropTypes.func,
}

BasketPreview.defaultProps = {
	cartListState:          {},
	deleteCartProductState: {},
	intl:                   {},
	showBackdrop:           () => null,
	getCartList:            () => null,
	deleteCartProduct:      () => null,
}
