import { Customer } from '@kakadu-dev/base-frontend-components'
import Icon from 'assets/icomoon/Icon'
import * as PropTypes from 'prop-types'
import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react'
import { injectIntl } from 'react-intl'
import {
	Link,
	useHistory,
} from 'react-router-dom'
import { DesktopMenuStore } from './DesktopMenu/index.store'
import { MobileMenuStore } from './MobileMenu/index.store'
import styles from './styles.scss'

/**
 * Render login block
 *
 * @param {Customer} userState
 * @param {boolean} isMobile
 * @param {function} closeBurgerMenu
 * @param {boolean} burgerMenuIsOpen
 *
 * @returns {*}
 * @constructor
 */
// eslint-disable-next-line react/prop-types
export const Login = injectIntl(forwardRef(({ userState, isMobile, closeBurgerMenu, burgerMenuIsOpen, intl }, ref) => {
	const [isOpen, setIsOpen] = useState(false)
	const history             = useHistory()
	useEffect(() => {
		if (burgerMenuIsOpen) {
			closeBurgerMenu()
		}
	}, [isOpen])

	useImperativeHandle(ref, () => ({
		close: () => {
			if (isOpen) {
				setIsOpen(false)
			}
		},
	}))

	const customer        = Customer.create(userState)
	const settings        = customer.getSettings()

	const isCustomer = customer.getRole() === 'user' // TODO заменить на хелпер

	const name = useMemo(() => customer.getFirstName() || customer.getLastName() || 'Пользователь',
		[customer])

	return (
		<>
			<div
				className={styles.wrapper}
				onMouseEnter={() => setIsOpen(true)}
				onMouseLeave={() => setIsOpen(false)}
			>
				<div className={styles.toggle}>
					{isCustomer ? (
						<div
							className={styles.toggle}
							onClick={() => history.push('/user/profile')}
							role='button'
							tabIndex='0'
						>
							{settings.getImage() ?
								<div
									className={styles.avatarIcon}
									style={{ backgroundImage: `url(${settings.getImage()})` }}
								/>
								: <Icon icon='account' size={24} />
							}
							<div className={styles.name}>
								{name}
							</div>
						</div>
					) : (
						<Link to='/sign-in' className={styles.toggle}>
							<Icon icon='account' size={24} />
							{/* eslint-disable-next-line react/prop-types */}
							{!isMobile && <p className={styles.name}>{intl.messages['general.entrance']}</p>}
						</Link>
					)}
				</div>

				{isCustomer && (
					!isMobile
						? <DesktopMenuStore
							isOpen={isOpen}
							toggle={() => setIsOpen(!isOpen)}
						/>
						: <MobileMenuStore
							isOpen={isOpen}
							toggle={() => setIsOpen(!isOpen)}
							customer={customer}
						/>
				)}
			</div>
		</>
	)
}))

Login.propTypes = {
	userState:        PropTypes.object,
	intl:             PropTypes.object,
	isMobile:         PropTypes.bool,
	burgerMenuIsOpen: PropTypes.bool,
	closeBurgerMenu:  PropTypes.func,
}

Login.defaultProps = {
	userState:        {},
	intl:             {},
	isMobile:         false,
	burgerMenuIsOpen: false,
	closeBurgerMenu:  () => null,
}
