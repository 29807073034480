import Icon from 'assets/icomoon/Icon'
import { BlankButton } from 'components/global/BlankButton'
import { userMenuLinks } from 'components/home/Header/Login/data'
import * as PropTypes from 'prop-types'
import React, {
	useCallback,
	useState,
} from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { history } from 'routing/history'
import styles from '../styles.scss'

/**
 * Render desktop layout
 *
 * @param {boolean} isOpen
 * @param {function} toggle
 * @param {function} logOut
 *
 * @returns {*}
 * @constructor
 */
export const DesktopMenu = ({ isOpen, toggle, logOut }) => {
	const [isClicked, setIsClicked] = useState(false)

	const logOutAndRedirect = useCallback(() => {
		setIsClicked(true)

		logOut()
		history.push('/')
	}, [logOut])

	return (
		<div className={[styles.menu, !isOpen && styles.menu_hidden].join(' ')}>
			{userMenuLinks.map(link => (
				<Link
					key={link.id}
					className={styles.item}
					to={link.href}
					onClick={toggle}
				>
					{link.title}
				</Link>
			))}
			<div className={styles.divider} />
			<BlankButton
				disabled={isClicked}
				className={[styles.item, styles.btn, isClicked && styles.opacity].join(' ')}
				onClick={logOutAndRedirect}
			>
				<Icon icon="exit" size="18" color="#888" className={styles.icon} />
				{isClicked &&
				 <div className={styles.spinner}>
					 <Spinner type="grow" color="secondary" />
				 </div>}
				Выход
			</BlankButton>
		</div>
	)
}

DesktopMenu.propTypes = {
	isOpen: PropTypes.bool,
	toggle: PropTypes.func,
	logOut: PropTypes.func,
}

DesktopMenu.defaultProps = {
	isOpen: false,
	toggle: () => null,
	logOut: () => null,
}
