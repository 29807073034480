import { City } from '@kakadu-dev/base-frontend-components'
import ComponentHelper from '@kakadu-dev/base-frontend-helpers/helpers/ComponentHelper'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import SSRComponentHelper from '@kakadu-dev/base-frontend-helpers/helpers/SSRComponentHelper'
import { SeoMetaData } from 'components/global'
import {
	DISABLE_INITIAL_PROPS,
	IS_PROD,
} from 'constants'
import { HeaderStore } from 'containers'
import Footer from 'containers/Home/Footer'
import { MailSubscribeStore } from 'containers/MailSubscribe/index.store'
import ru from 'date-fns/locale/ru'
import ErrorHelper from 'helpers/ErrorHelper'
import AppLocale from 'lang'
import moment from 'moment'
import * as PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { registerLocale } from 'react-datepicker'
import { IntlProvider } from 'react-intl'
import ReactNotification from 'react-notifications-component'
import { HierarchicalService } from 'services/HierarchicalService'
import { MetaService } from 'services/MetaService'
import '../../styles/fonts'
import styles from '../../styles/global.scss'
import { mapDispatchToProps } from './index.props'

moment.updateLocale('ru', require('moment/locale/ru'))

registerLocale('ru', ru)

const menuQuery = () => DataProvider
	.buildQuery()
	.cacheResponse(3600, IS_PROD)

const userQuery = () => DataProvider
	.buildQuery()
// .addBody({ query: { expands: ['city.country', 'settings'] } }, false)

const pageQuery = () => DataProvider
	.buildQuery()
	.addExtraFilter('isActive', true)
	.cacheResponse(3600, IS_PROD)

const articlesQuery = () => DataProvider
	.buildQuery()
	.addOrderBy('createdAt', '-')
	.addExtraFilter('isActive', true)
	.cacheResponse(3600, IS_PROD)

const bannersQuery = () => DataProvider
	.buildQuery()
	.addOrderBy('createdAt', '-')
	.addExtraFilter('isActive', true)
	.cacheResponse(3600, IS_PROD)

const cityQuery = () => DataProvider.buildQuery().cacheResponse(3600, IS_PROD)

/**
 * App layout
 */
const Layout = ({
	userCityState,
	menuState,
	getUser,
	getUserCity,
	getMenu,
	getMeta,
	children,
	metaState,
	getPages,
	getArticles,
	getBanners,
	intlState,
	isShowPanel,
	siteBackground,
	isImg,
	isFontNormal,
	isKerningNormal,
	staticPagesState,
	bannersState,
	articlesState,
}) => {
	const [isBackdropNeeded, setBackdropNeeded] = useState(false)
	const [urlInfo, setUrlInfo]                 = useState(MetaService.getUrlInfo())
	const [isMobileSearch, setIsMobileSearch]   = useState(false)
	const [isMobile, setIsMobile]               = useState(false)

	const checkIsMobile = useCallback(() => {
		if (window.innerWidth <= 769 && !isMobile || window.innerWidth > 769 && isMobile) {
			setIsMobile(!isMobile)
		}
	}, [isMobile])

	useEffect(() => {
		HierarchicalService.getInstance().setCategories(menuState.result)
	}, [menuState])

	useEffect(() => {
		checkIsMobile()
		window.addEventListener('resize', checkIsMobile)

		return () => {
			window.removeEventListener('resize', checkIsMobile)
		}
	}, [checkIsMobile])

	const city = City.create(userCityState)
	const cityId = useMemo(() => city.getId(), [city])

	const currentLocale = useMemo(() => {
		return intlState.locale
	}, [intlState])

	useEffect(() => {
		// Get user one more time on client side (firebase messages, tokens and etc.)
		getUser(userQuery())

		ComponentHelper.fetchIfNotExist(userCityState, getUserCity, cityQuery())
		ComponentHelper.fetchIfNotExist(menuState, getMenu, menuQuery())
		MetaService.subscribe(url => setUrlInfo(url))

		return () => MetaService.unsubscribe()
	}, [getMenu, getUser, getUserCity])

	// Get meta data for pages
	useEffect(() => {
		const metaSearch = MetaService.getMetaSearch({ cityId })

		// if (!city.getId() || !metaSearch) {
		// 	return
		// }

		if (urlInfo.isFirstRun) {
			// First run
			ComponentHelper.fetchIfNotExist(metaState, getMeta, metaSearch)
		} else {
			// Navigate between pages
			getMeta(metaSearch)
		}
	}, [urlInfo, cityId, getMeta])

	useEffect(() => {
		// Get pages, articles, banners
		getPages(pageQuery())
		getArticles(articlesQuery())
		getBanners(bannersQuery())
		// ComponentHelper.fetchIfNotExist(staticPagesState, getPages, pageQuery(), true)
		// ComponentHelper.fetchIfNotExist(articlesState, getArticles, articlesQuery())
		// ComponentHelper.fetchIfNotExist(bannersState, getBanners, bannersQuery(), true)

	}, [getPages, getArticles, getBanners])

	const changeBackground = useCallback(background => {
		switch (background)
		{
			case 'white': return `${styles.backgroundWhite}`
			case 'black': return `${styles.backgroundBlack}`
			case 'blue': return `${styles.backgroundBlue}`
			default: return `${styles.backgroundWhite}`
		}
	}, [])

	return (
		<div className={[
			styles.root,
			isBackdropNeeded && styles.backdrop,
			!isImg && styles.hideImg,
			!isFontNormal && styles.fontBig,
			!isKerningNormal && styles.kerningBig].join(' ')}
		>
			<IntlProvider locale={AppLocale[currentLocale].locale} messages={AppLocale[currentLocale].messages}>
				<>
					<SeoMetaData
						metaState={metaState}
						defaultMeta={MetaService.getDefaultMeta()}
						urlInfo={urlInfo}
					/>

					<ReactNotification />

					<div className={siteBackground && changeBackground(siteBackground)}>
						<HeaderStore
							showBackdrop={setBackdropNeeded}
							setIsMobileSearch={setIsMobileSearch}
							isMobileSearch={isMobileSearch}
							isMobile={isMobile}
						/>

						<main
							className={`${styles.main}
							${isMobileSearch? styles.mainMobile: ''}
							${isShowPanel && styles.mainPanel}
							${siteBackground && changeBackground(siteBackground)}
							${!isKerningNormal && styles.kerningBig}`}
						>
							{children}
						</main>

						<MailSubscribeStore />
						<Footer />
					</div>
				</>
			</IntlProvider>
		</div>
	)
}

/**
 * SSR get initial state
 *
 * @type {Promise<Function>}
 */
Layout.getInitialProps = SSRComponentHelper.initialProps(async (dispatchers, { match }) => {
	const { getUser, getUserCity, getMenu, getMeta, getPages, getBanners, getArticles } = dispatchers

	try {
		await Promise.all([
			getUser(userQuery()),
			getMenu(menuQuery()),
			getPages(pageQuery()),
			getBanners(bannersQuery()),
			// getArticles(articlesQuery()),
		])
	}
	catch (e) {
		ErrorHelper.log(e)
	}

	if(match)
	{
		MetaService.setUrlInfo({ url: match?.url ?? '/error' })

		let city
		try {
			city = await Promise.all([getUserCity(cityQuery())])
		} catch (e) {
			ErrorHelper.log(e)
		}

		const metaSearch = MetaService.getMetaSearch({
			cityId: city?.result?.id ?? null,
		})

		try {
			await Promise.all([
				...([metaSearch ? getMeta(metaSearch) : true]),
			])
		} catch (e) {
			ErrorHelper.log(e)
		}
	}
}, mapDispatchToProps, DISABLE_INITIAL_PROPS)

Layout.propTypes = {
	children:         PropTypes.node.isRequired,
	getMenu:          PropTypes.func,
	getUser:          PropTypes.func,
	getUserCity:      PropTypes.func,
	getMeta:          PropTypes.func,
	menuState:        PropTypes.object,
	userCityState:    PropTypes.object,
	metaState:        PropTypes.object,
	intlState:        PropTypes.object,
	getPages:         PropTypes.func,
	getArticles:      PropTypes.func,
	getBanners:       PropTypes.func,
	siteBackground:   PropTypes.string,
	isImg:            PropTypes.bool,
	isFontNormal:     PropTypes.bool,
	isKerningNormal:  PropTypes.bool,
	isShowPanel:      PropTypes.bool,
	staticPagesState: PropTypes.object,
	bannersState:     PropTypes.object,
	articlesState:    PropTypes.object,
}

Layout.defaultProps = {
	getMenu:         () => null,
	getUser:         () => null,
	getUserCity:     () => null,
	getMeta:         () => null,
	metaState:       {},
	menuState:       {},
	userCityState:   {},
	intlState:       {},
	getPages:        () => null,
	getArticles:     () => null,
	getBanners:      () => null,
	siteBackground:  '',
	isImg:           true,
	isFontNormal:    true,
	isKerningNormal: true,
	isShowPanel:     false,
	staticPagesState: {},
	bannersState:     {},
	articlesState:    {},
}

export default Layout
