import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'

/**
 * Get isFetching from states
 *
 * @param {Object} args
 *
 * @return {boolean}
 */
export const isFetching = (...args) => {
	if (args.length > 1) {
		return !!args.map(arg => StateService.create(arg).isFetching()).filter(element => element).length
	}
	return StateService.create(args[0]).isFetching()
}
