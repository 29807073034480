/**
 * Get correct time
 *
 * @param {Date|number|string} object
 *
 * @returns {string}
 */
export const getTime = object => {
	const inst = typeof object === 'object' ? object : new Date(object)

	const toString = (array, separator) => array.map(value => value > 9 ? value : `0${value}`).join(separator)

	const date = toString([inst.getDate(), inst.getMonth() + 1, inst.getFullYear()], '.')
	const time = toString([inst.getHours(), inst.getMinutes()], ':')

	return [date, time].join(' в ')
}
