import { INTL_ACTIONS } from 'reduxm/intl/actionTypes'

const INIT_STATE = {
	locale:        'ru',
	// defaultLocale: 'ru',
	// currency:      'RUB',
	// messages:      {},
}

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case INTL_ACTIONS.SET_LANG:
			return Object.assign({}, state, {
				locale: action.payload,
			})

		default:
			return { ...state }
	}
}
