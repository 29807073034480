import { StateSelector } from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	updateCartProductState: StateSelector.cart.update(state),
	deleteCartProductState: StateSelector.cart.delete(state),
})
const mapDispatchToProps = {}

export {
	mapStateToProps,
	mapDispatchToProps,
}
