import { FaqBlock } from 'components/static/faq'
import Question from 'components/static/faq/Question'
import { StaticPageWrapper } from 'components/static/StaticPageWrapper'
import { blockQuestions } from 'containers/Static/Faq/data'

import styles from 'containers/Static/Faq/styles.scss'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
	Col,
	Row,
} from 'reactstrap'
import { MetaService } from 'services/MetaService'

const title = 'Вопросы и ответы'

const setMetaInfo = () => MetaService.setInfo({}, { title })

/**
 * Render faq
 *
 * @return {*}
 * @constructor
 */
export const Faq = () => {
	useEffect(() => {
		setMetaInfo()
	}, [])

	return (
		<StaticPageWrapper title={title}>
			<Row className={styles.wrapper}>
				<Col lg="7">
					<div className={styles.border}>
						<form action="" className={styles.form}>
							<input
								className={styles.input}
								placeholder='Введите вопрос...'
								type='text'
							/>
						</form>
					</div>
					<div className={styles.border}>
						<div className={styles.content}>
							{blockQuestions.map(block =>
								<FaqBlock key={block.id} title={block.title}>
									{block.questions.map(question =>
										<Question key={question.id} question={question} />,
									)}
								</FaqBlock>,
							)}
						</div>
						<p className={styles.title}>
							<Link to='/'>{'Если вы не нашли ответ, задайте вопрос нам!'}</Link>
						</p>
					</div>
				</Col>
				<Col lg="4">
					<div className={styles.border}>
						<ul className={styles.categories}>
							{blockQuestions.map(block =>
								<li key={block.id} className={styles.category}>
									<Link to='/' className={styles.link}>
										{block.title}
									</Link>
								</li>,
							)}
						</ul>
					</div>
				</Col>
			</Row>
		</StaticPageWrapper>
	)
}
