/* eslint-disable react-hooks/exhaustive-deps */
import {
	Customer,
} from '@kakadu-dev/base-frontend-components'
import { BaseHelper } from '@kakadu-dev/base-frontend-helpers/helpers/BaseHelper'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import more from 'assets/images/product-card/ellipsis.svg'
import { StarRating } from 'components/global'
import { Price } from 'components/global/Price'
import ProductCardFooter from 'components/market/ProductCard/ProductCardFooter'
import { ProductCardSale } from 'components/market/ProductCard/ProductCardSale'
import { ProductFavoritesStore } from 'components/market/ProductPage/ProductFavorites/index.store'
import { Product } from 'model/Product'
import * as PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'
import { HierarchicalService } from 'services/HierarchicalService'
import styles from './styles.scss'

const preventLink = event => event.preventDefault()

const initialState = Product.create({})

/**
 * Render product card
 *
 * @param {string} link
 * @param {Product} product
 * @param {Function} addToCart
 * @param {Object} colProps
 * @param {Function} getProduct
 * @param {boolean} isFooterNeeded
 * @param {Object} userState
 *
 * @return {*}
 * @constructor
 */
const ProductCard = ({
	link,
	product,
	addToCart,
	colProps,
	getProduct,
	isFooterNeeded,
	userState,
}) => {
	const [productModel, setProductModel] = useState(initialState)
	const [isFetching, setIsFetching]     = useState(false)
	const [isActive, setIsActive]         = useState(false)

	const discountPercent = useMemo(() =>
		productModel.getOffers()[0]?.getRawModel?.()?.params?.discountPercent || 0, [productModel])

	useEffect(() => {
		if (product.isExist()) {
			setProductModel(product)
		}
		return () => setProductModel(null)
	}, [product.getId(), product.isFavorite()])

	const changeProduct = id => {
		setIsFetching(true)

		const searchQuery = DataProvider
			.buildQuery()
			.addBody({
				query: {
					expands: [
						'categories.category.allParents',
						'minPrices',
						'properties',
						'categories',
						'options',
						'isFavorite',
						'offers'
					],
				},
				id,
			})
			.setSuccessCallback(response => {
				setProductModel(Product.create(response))
				setIsFetching(false)
			})

		getProduct(searchQuery)
	}

	const generatedLink = useCallback(() => HierarchicalService.getProductLink(productModel), [productModel])

	const getIsPromo = () => {
		const minPrices = productModel.getMinPrices()
		return minPrices && minPrices[0] && minPrices[0].isPromo || false
	}

	const getOriginalPrice = () => {
		const minPrices = productModel.getMinPrices()
		return minPrices && minPrices[0] && minPrices[0].originalPrice || 0
	}

	const isNotInStock = () => 	Boolean(productModel.model.isNotInStock)

	return productModel && (
		<Col
			className={styles.col}
			onMouseEnter={() => setIsActive(true)}
			onMouseLeave={() => setIsActive(false)}
			{...(colProps !== null && {
				...ProductCard.defaultProps.colProps,
				...colProps,
			})}
		>
			<div
				className={[styles.wrapper, isActive && styles.wrapper_active,
					isActive && !isFooterNeeded && styles.noFooter].join(' ')}
			>
				{!isNotInStock() &&
				 getIsPromo() &&
				 <ProductCardSale sale={discountPercent} />}

				<div className={styles.rating}>
					<StarRating initialRating={productModel.getRating()} readonly />
					<div className={styles.productInfo}>
						<ProductFavoritesStore
							productModel={productModel}
							userId={Customer.create(userState).getId()}
						/>
					</div>
				</div>

				<Link to={link || generatedLink} onClick={isFetching ? preventLink : null}>
					<div className={styles.imgWrapper}>
						<img
							className={[styles.img, isFetching && styles.fetching].join(' ')}
							src={productModel.getPreview()}
							alt="product"
						/>
					</div>
				</Link>
				<div className={styles.title_price_wrapper}>
					<div className={styles.title}>
						{productModel.getTitle()}
					</div>
					{isNotInStock()?
						<span className={styles.real}>{'Нет в наличии'}</span>:
						<div className={styles.price}>
							<span className={[getIsPromo() ? styles.promo : styles.real].join(' ')}>
								<Price value={productModel.getPrice()} />
							</span>
							{getIsPromo() &&
							 <span className={styles.old}>
								 <Price value={getOriginalPrice()} />
							 </span>}
						</div>
					}
				</div>
			</div>

			{isFooterNeeded &&
			 <>
				 {BaseHelper.isMobileDevice() &&
				  <div className={styles.btnWrapper}>
					  <button
						  type="button"
						  className={[styles.toggleBtn, isActive ? styles.less : styles.more].join(' ')}
						  onClick={() => setIsActive(!isActive)}
					  >
						  <img className={styles.moreBtn} src={more} alt="more" />
					  </button>
				  </div>}

				 <ProductCardFooter
					 isMobile={BaseHelper.isMobileDevice()}
					 isActive={isActive}
					 isFetching={isFetching}
					 setIsFetching={setIsFetching}
					 addToCart={addToCart}
					 productModel={productModel}
					 changeProduct={changeProduct}
				 />
			 </>}
		</Col>
	)
}

ProductCard.propTypes = {
	link:           PropTypes.string,
	product:        PropTypes.object,
	addToCart:      PropTypes.func,
	colProps:       PropTypes.object,
	getProduct:     PropTypes.func,
	isFooterNeeded: PropTypes.bool,
	userState:      PropTypes.object,
}

ProductCard.defaultProps = {
	link:           '',
	product:        {},
	addToCart:      () => null,
	colProps:       { xs: 12, md: 6, lg: 4 },
	getProduct:     () => null,
	isFooterNeeded: true,
	userState:      {},
}

export default ProductCard
