
export const byMenu = {
	isFetching: false,
	result: [
		{
			id: 354,
			title: "Аб кампаніі",
			alias: "by/about-company",
			childrens: [
				{
					id: 355,
					title: "Кіраўніцтва",
					alias: "ac-leadership"
				},
				{
					id: 349,
					title: "Гандлёвая сетка",
					alias: "commercial-network"
				},
				{
					id: 358,
					title: "Гісторыя",
					alias: "ac-history"
				},
			]
		},
		{
			id: 359,
			title: "Вакансіі",
			alias: "by/about-company/ac-vacancies"
		},
		{
			id: 380,
			title: "Зварот",
			alias: "by/appeals",
			childrens: [
				{
					id: 467,
					title: "Электронныя звароты",
					alias: "electronicae-appellationes"
				},
				{
					id: 384,
					title: "Асабісты прыём грамадзян і юр.асоб",
					alias: "a-personal-reception"
				},
				{
					id: 385,
					title: "Пералік адміністрацыйных працэдур",
					alias: "a-list-of-administrative-procedures"
				},
				{
					id: 386,
					title: "Кніга заўваг і прапаноў",
					alias: "a-book-of-comments-and-suggestions"
				}
			]
		},
		{
			id: 387,
			title: "Кантакты",
			alias: "by/contacts",
			childrens: [
				{
					id: 388,
					title: "Рэквізіты",
					alias: "сon-requisites"
				},
				{
					id: 389,
					title: "Аддзелы і службы",
					alias: "con-departments-and-services"
				}
			]
		}
	]
}
