import {
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	staticPagesState:  StateSelector.staticPages.pages(state),
	hierarchicalState: StateSelector.categories.getHierarchical(state)
})
const mapDispatchToProps = {
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
