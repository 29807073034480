import { BlankButton } from 'components/global/BlankButton'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render firebase auth component
 *
 * @param {string} action
 * @param {string} icon
 *
 * @return {*}
 * @constructor
 */
export const FirebaseAuth = ({ action, icon }) => (
	<BlankButton
		onClick={action}
		className={styles.link}
	>
		<img src={icon} alt="auth-icon" />
	</BlankButton>
)

FirebaseAuth.propTypes = {
	icon:     PropTypes.string,
	action: PropTypes.func,
}

FirebaseAuth.defaultProps = {
	icon:     '',
	action: () => null,
}
