import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import Icon from 'assets/icomoon/Icon'
import { BlankButton } from 'components/global/BlankButton'
import {
	BasketPreviewStore,
	Burger,
	CitySelectStore,
	Login,
	Logo,
	MobileNavigation,
	SearchStore,
	Telephone,
} from 'components/home/Header'
import { LangStore } from 'components/home/Header/Lang/index.store'
import Navigation from 'components/home/Header/Navigation'
import Contacts from 'components/home/Header/Contacts'
import StoreMap from 'components/home/Header/StoreMap'
import styles from 'components/home/Header/styles.scss'
import { VisionPanelStore } from 'components/visionPanel/index.store'

import * as PropTypes from 'prop-types'
import React, {
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react'

import { useLocation } from 'react-router'

/**
 * Render site header
 */
export const Header = ({
	showBackdrop,
	userState,
	hierarchicalState,
	countriesState,
	getCountries,
	userCity,
	intl,
	setIsMobileSearch,
	isMobileSearch,
	isMobile,
	isShowPanel,
	siteBackground,
}) => {

	const [isOpenModal, setIsOpenCityModal]       = useState(false)
	const [burgerMenuIsOpen, setBurgerMenuIsOpen] = useState(false)
	// const [isMobileSearch, setIsMobileSearch]     = useState(false)

	const loginMenu    = useRef()
	const { pathname } = useLocation()

	const locale = useMemo(() => intl.locale, [intl.locale])

	const isHome = useMemo(() => pathname === '/', [pathname])

	const toggleModal = useCallback(() => {
		if (userCity) {
			setIsOpenCityModal(!isOpenModal)

			if (!countriesState.result?.list?.length) {
				getCountries(DataProvider.buildQuery())
			}
		}
	}, [countriesState.result?.list?.length, getCountries, isOpenModal, userCity])

	const toggleBurgerMenu = useCallback(() => {
		setBurgerMenuIsOpen(!burgerMenuIsOpen)
	}, [burgerMenuIsOpen])

	const renderDesktop = useCallback(componentsProps => (
		<div className={styles.container}>
			<Logo {...componentsProps.logo} />
			<div className={styles.content}>
				<div className={styles.wrapper}>
					<StoreMap {...componentsProps.storeMap} />
					<Contacts />
					<SearchStore {...componentsProps.search} />
					<Telephone {...componentsProps.telephone} />
					<LangStore  />
					{locale === 'be' ?
						null :
						<>
							<BasketPreviewStore {...componentsProps.basket} />
							<Login {...componentsProps.login} />
						</>}
				</div>
				<div className={styles.nav}>
					<Navigation {...{ ...componentsProps.navigation, ...componentsProps.desktopNav }} />
				</div>
			</div>
		</div>
	), [locale])

	const renderMobile = useCallback(componentsProps => (
		<>
			<div className={styles.wrapper}>
				<BasketPreviewStore {...componentsProps.basket} />
				<Login {...componentsProps.login} />
				<Logo {...componentsProps.logo} />
				<BlankButton onClick={() => setIsMobileSearch(!isMobileSearch)}>
					<Icon icon="ic-search-alt" size={25} />
				</BlankButton>
				<BlankButton className={styles.box} onClick={toggleBurgerMenu}>
					<Burger isOpen={burgerMenuIsOpen} />
				</BlankButton>
			</div>
			<div className={[styles.search, isMobile && styles.mobile, isMobileSearch && styles.open].join(' ')}>
				<SearchStore {...componentsProps.search} />
			</div>
			<MobileNavigation {...componentsProps.navigation}>
				<CitySelectStore {...componentsProps.citySelect} />
				<Telephone {...componentsProps.telephone} />
			</MobileNavigation>
		</>
	), [burgerMenuIsOpen, isMobile, isMobileSearch, toggleBurgerMenu])

	const componentsProps = useMemo(() => ({
		storeMap:   { showBackdrop },
		basket:     { isMobile, ...(!isMobile && showBackdrop) },
		telephone:  { isMobile },
		logo:       { isHome },
		navigation: {
			hierarchicalState,
			isOpen:    burgerMenuIsOpen,
			closeMenu: toggleBurgerMenu,
			isMobile,
		},
		citySelect: {
			isMobile,
			isOpenModal,
			toggleModal,
		},
		search:     {
			showBackdrop,
			isMobile,
			mobileMenuIsOpen: burgerMenuIsOpen,
			isMobileSearch,
		},
		login:      {
			isMobile,
			ref:             loginMenu,
			userState,
			closeBurgerMenu: () => setBurgerMenuIsOpen(false),
			burgerMenuIsOpen,
		},
		desktopNav: {
			showBackdrop,
			hierarchicalState,
		},
	}), [burgerMenuIsOpen, hierarchicalState, isHome, isMobile, isMobileSearch, isOpenModal,
		showBackdrop, toggleBurgerMenu, toggleModal, userState])

	const getComponent = !isMobile ? renderDesktop : renderMobile

	const changeBackground = (background) => {
		if (background === 'white') return `${styles.backgroundWhite}`

		if (background === 'black')	return `${styles.backgroundBlack}`

		if (background === 'blue') 	return `${styles.backgroundBlue}`
		return `${styles.backgroundWhite}`
	}

	return (
		<header className={`${styles.header} ${siteBackground && changeBackground(siteBackground)}`}>
			{/* <div className={styles.note}> */}
			{/*	Уважаемые клиенты! Интернет – магазин работает, доступны покупки онлайн! Сайт запущен в тестовом режиме. */}
			{/* </div> */}
			{isShowPanel && <VisionPanelStore />}
			{getComponent(componentsProps)}
		</header>
	)
}

Header.defaultProps = {
	countriesState:    {},
	userCity:          {},
	userState:         {},
	hierarchicalState: {},
	showBackdrop:      () => null,
	getCountries:      () => null,
	setIsMobileSearch: () => null,
	intl:              {},
	isMobileSearch:    false,
	isShowPanel:       false,
	siteBackground:       '',
}

Header.propTypes = {
	countriesState:    PropTypes.object,
	userCity:          PropTypes.object,
	userState:         PropTypes.object,
	hierarchicalState: PropTypes.object,
	showBackdrop:      PropTypes.func,
	getCountries:      PropTypes.func,
	setIsMobileSearch: PropTypes.func,
	intl:              PropTypes.object,
	isMobileSearch:    PropTypes.bool,
	isShowPanel:       PropTypes.bool,
	siteBackground:    PropTypes.string,
	isMobile:          PropTypes.bool.isRequired,
}
