import MIcon from '@material/react-material-icon'
import * as PropTypes from 'prop-types'
import React from 'react'

/**
 * Render material icon
 *
 * @param {string} icon
 * @param {number} size
 * @param {string} color
 *
 * Library: https://github.com/material-components/material-components-web-react/tree/master/packages/material-icon
 * Icons: https://material.io/resources/icons/?style=baseline
 *
 * @returns {*}
 * @constructor
 */
const MaterialIcon = ({ icon, size, color, className }) => {
	return (
		<MIcon
			className={className}
			icon={icon}
			style={{
				color,
				fontSize:   size,
				lineHeight: 1.3,
			}}
		/>
	)
}

MaterialIcon.propTypes = {
	className: PropTypes.string,
	color:     PropTypes.string,
	icon:      PropTypes.string.isRequired,
	size:      PropTypes.number,
}

MaterialIcon.defaultProps = {
	className: '',
	color:     'inherit',
	size:      15,
}

export { MaterialIcon }
