import { DispatchSelector } from 'reduxm/modules/selectors'

const mapStateToProps    = () => ({})
const mapDispatchToProps = {
	logOut: DispatchSelector.auth.logOut,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
