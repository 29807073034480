import {
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	articlesState: StateSelector.staticPages.articles(state),
})
const mapDispatchToProps = {
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
