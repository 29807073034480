import React from 'react'
import { Spinner } from 'reactstrap'
import styles from './styles.scss'

/**
 * Render phone auth container
 *
 * @return {*}
 * @constructor
 */
export const PhoneAuth = () => (
	<div className={styles.phoneAuth}>
		<p className={styles.title}>Или войдите по номеру телефона:</p>
		<Spinner id="loader" color="success" className={styles.spinner} />
		<div id="phone-container" />
	</div>
)
