import Icon from 'assets/icomoon/Icon'
import { appendScriptToHead } from 'components/global/AppendScriptToHead'
import { Backdrop } from 'components/global/Backdrop'
import { BlankButton } from 'components/global/BlankButton'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import { useStopScrolling } from 'components/global/useStopScrolling'
import {
	config,
	stores,
} from 'components/home/Header/StoreMap/data'
import { StoreItem } from 'components/home/Header/StoreMap/StoreItem'
import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import styles from './styles.scss'

let myMap

// ToDo optimized load map (now more than 1 seconds)
/**
 * Render store map
 *
 * @return {*}
 * @constructor
 */
const StoreMap = ({ intl }) => {
	const [isActive, setIsActive]       = useState(false)
	const [isOpen, setIsOpen]           = useState(false)
	const [activeStore, setActiveStore] = useState(null)
	const [places, setPlaces]           = useState([])

	const mapRef = useRef()

	const open = useCallback(() => {
		setIsActive(true)
		setIsOpen(true)
	}, [])

	const close = useCallback(() => {
		setIsActive(false)
		setTimeout(() => setIsOpen(false), 500)
	}, [])

	useCheckOutsideClick(mapRef, isActive, close, isActive)
	useStopScrolling(isActive)

	const init = useCallback(() => {
		const { state, options, icon } = config

		window.ymaps.ready(() => {
			myMap = new window.ymaps.Map('map', state, options)

			const getPlace = ({ geometry, address, ...balloonProps }) => new window
				.ymaps
				.Placemark(geometry, balloonProps, icon)

			const nextPlaces = stores.map(store => {
				const place = getPlace(store)

				myMap.geoObjects.add(place)

				return place
			})

			setPlaces(nextPlaces)
		})
	}, [])

	useEffect(() => {
		const { url, apiKey } = config

		appendScriptToHead(`${url}${apiKey}`)
	}, [])

	useEffect(() => {
		if (isActive) {
			init()
		} else {
			setActiveStore(null)
		}
	}, [init, isActive])

	const storeAction = useCallback((geometry, idx) => {
		myMap.setCenter(geometry, 16, { checkZoomRange: true })
			.then(() => {
				places[idx].balloon.open()
				setActiveStore(idx)
			})

	}, [places])


	return (
		<article className={styles.container}>
			<Backdrop isActive={isOpen} />
			<BlankButton
				className={styles.toggleBtn}
				onClick={open}
				title={intl.messages['general.store.map']}
			/>
			{isOpen &&
			 <section className={[styles.section, isActive ? styles.active : styles.hidden].join(' ')} ref={mapRef}>
				 <BlankButton onClick={close} className={styles.closeBtn}>
					 <Icon icon="close" size={25} color="#999" />
				 </BlankButton>
				 <aside className={styles.addresses}>
					 {stores.map((store, idx) => (
						 <StoreItem
							 key={store.geometry[0]}
							 isActive={idx === activeStore}
							 store={store}
							 action={() => storeAction(store.geometry, idx)}
						 />
					 ))}
				 </aside>
				 <div id="map" className={styles.map} />
			 </section>}
		</article>
	)
}

StoreMap.propTypes = {
	intl: PropTypes.object,
}
StoreMap.defaultProps = {
	intl: {},
}

export default injectIntl(StoreMap)
