import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { history } from 'routing/history'

/**
 * Meta service
 */
export class MetaService
{
	/**
	 * @private
	 * @type {function|null}
	 */
	static listner = null

	/**
	 * @private
	 * @type {Object|null}
	 */
	static metaInfo = null

	/**
	 * @private
	 * @type {Object|null}
	 */
	static urlInfo = { url: history.location.pathname, isFirstRun: true }

	/**
	 * @private
	 * @type {Object|null} for detect changes in metaInfo and urlInfo
	 */
	static changedValues = { metaInfo: false, urlInfo: false }

	/**
	 * @private
	 * @type {function|null}
	 */
	static historyListener = null

	/**
	 * @private
	 * @type {Object} default meta data
	 */
	static defaultMeta = {}

	/**
	 * Set meta info
	 *
	 * @param {Object} params
	 * @param {Object} defaultMeta
	 *
	 * @return {undefined}
	 */
	static setInfo(params = {}, defaultMeta = {})
	{
		// Run only after change url
		if (!this.changedValues.urlInfo && !this.urlInfo.isFirstRun) {
			return
		}

		this.metaInfo    = params
		this.defaultMeta = defaultMeta

		if (this.urlInfo?.isFirstRun === false) {
			this.changedValues.metaInfo = true
		}

		this.runListener()
	}

	/**
	 * Get default meta
	 *
	 * @return {Object}
	 */
	static getDefaultMeta()
	{
		return this.defaultMeta
	}

	/**
	 * Get meta info
	 *
	 * @param {Object} params pass url and cityId
	 *
	 * @return {Object|null}
	 */
	static getMetaSearch(params = {})
	{
		if (this.metaInfo === null) {
			return null
		}

		return DataProvider
			.buildQuery()
			.addBody({
				url: this.urlInfo?.url ?? null,
				...this.metaInfo,
				...params,
			})
	}

	/**
	 * Set url info
	 *
	 * @param {Object} params
	 *
	 * @return {undefined}
	 */
	static setUrlInfo(params)
	{
		this.urlInfo = {
			url:        '/',
			isFirstRun: true,
			...params,
		}
	}

	/**
	 * Get url info
	 *
	 * @return {Object|null}
	 */
	static getUrlInfo()
	{
		return this.urlInfo
	}

	/**
	 * Subscribe to listen meta info changes
	 *
	 * @param {function} callback
	 *
	 * @return {Object}
	 */
	static subscribe(callback)
	{
		history.listen(data => {
			this.urlInfo     = { url: data.pathname, isFirstRun: false }
			this.defaultMeta = {}

			this.changedValues.urlInfo = true
		})

		this.listner = callback
	}

	/**
	 * Unsubscribe meta changes
	 *
	 * @return {undefined}
	 */
	static unsubscribe()
	{
		if (this.historyListener !== null) {
			this.historyListener()
		}

		this.listner = null
	}

	/**
	 * Run listener callback
	 *
	 * @return {undefined}
	 * @private
	 */
	static runListener()
	{
		const { urlInfo, metaInfo } = this.changedValues

		if (!urlInfo || !metaInfo) {
			return
		}

		this.changedValues = { metaInfo: false, urlInfo: false }

		this.listner(this.urlInfo, this.metaInfo)
	}
}
