import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

/**
 * Redirect /stocks to /stocks/s-in-the-hum
 *
 * @return {*}
 * @constructor
 */
export const TrapStocks = () => {
	const history = useHistory()
	useEffect(() => history.push('/stocks/s-in-the-hum'), [history])
	return (
		<>
		</>
	)
}
