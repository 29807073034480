import styles from 'components/global/styles.scss'
import * as PropTypes from 'prop-types'
import React from 'react'

const Cover = ({ description, classLabel, children }) => {

	return (
		<div className={styles.field}>
			<p className={[styles.description, classLabel].join(' ')}>
				{description}
			</p>
			{children}
			<p className={styles.error}>
				Необходимо заполнить «{description}».
			</p>
		</div>
	)
}

Cover.propTypes = {
	description: PropTypes.string.isRequired,
	classLabel:  PropTypes.string,
	children:    PropTypes.node.isRequired,
}

Cover.defaultProps = {
	classLabel: '',
}

export { Cover }
