import {
	Customer,
	CustomerSettings,
} from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { BirthdayField } from 'components/Form/fields/Birthday'
import { Checkbox } from 'components/Form/fields/Checkbox'
import { PhoneNumber } from 'components/Form/fields/PhoneNumber'
import { Button } from 'components/global/Button'
import { InputField } from 'components/global/InputField'
import { Notification } from 'helpers/Notification'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import styles from './styles.scss'


const getInitialState = (discount, user) => {
	const userSettings = CustomerSettings.create(user.getSettings().getRawModel())
	const discountBirthday = discount?.birthday?.split('T')[0]

	return {
		data: {
			cardNumber: discount?.cardNumber || '', // max 13 numbers
			lastName:   discount?.lastName   || user.getLastName()      || '',
			firstName:  discount?.firstName  || user.getFirstName()     || '',
			middleName: discount?.middleName || user.getMiddleName()    || '',
			gender:     discount?.gender     || user.getGender(),
			birthDay:   discountBirthday     || user.getBirthday(),
			phone:      discount?.phone      || userSettings.getPhone(),
			email:      discount?.email      || userSettings.getEmail() || '',
			city:       discount?.city       || '',
			consent:    discount?.consent    || true,
		},
		params: {
			cardNumber: {
				error: false,
				required: true,
			},
			lastName: {
				error: false,
				required: true,
			},
			firstName: {
				error: false,
				required: true,
			},
			middleName: {
				error: false,
				required: false,
			},
			gender: {
				error: false,
				required: true,
			},
			birthDay: {
				error: false,
				required: true,
			},
			phone: {
				error: false,
				required: true,
			},
			email: {
				error: false,
				required: true,
			},
			city: {
				error: false,
				required: true,
			},
			consent: {
				error: false,
				required: false,
			},
		}
	}
}


const options = [
	{ label: 'Женский', value: 'female' },
	{ label: 'Мужской', value: 'male' },
]

const forms = [
	{ id: 'cardNumber', title: 'Номер дисконтной карты', inputProps:{ pattern: '[0-9]{13}' }, required: true },
	{ id: 'lastName', title: 'Фамилия', inputProps:{ pattern: '[A-Za-zА-Яа-яЁё]{2,}' }, required: true },
	{ id: 'firstName', title: 'Имя', inputProps:{ pattern: '[A-Za-zА-Яа-яЁё]{2,}' }, required: true, },
	{ id: 'middleName', title: 'Отчество', required: false, },
	{ id: 'gender', title: 'Пол', type: 'select', required: true, options },
	{ id: 'birthDay', title: 'День рождения', inputProps:{ pattern: '[0-9]{4}\\-[0-9]{2}\\-[0-9]{2}' }, required: true, component: <BirthdayField /> },
	{ id: 'phone', title: 'Телефон', inputProps:{ pattern: '\\+[0-9]{1,3}\\s+\\([0-9]{2,3}\\)\\s[0-9\\s]{9,11}' }, required: true, component:  <PhoneNumber />},
	{ id: 'email', title: 'E-mail', inputProps:{ pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$' }, required: false, validationType: 'email' },
	{ id: 'city', title: 'Город/населенный пункт', inputProps:{ pattern: '[A-Za-zА-Яа-яЁё]{2,}' }, required: true },
	{
		id: 'consent',
		title: 'Согласен на получение SMS - сообщений, Viber - сообщений, E-mail - сообщений об акциях, услугах, программах ОАО "ГУМ".',
		type: 'checkbox'
	}
]

const confirmRulesTitle = 'С правилами участия в Дисконтной программе ознакомлен и согласен. Дисконтную карту получил.'
const confirmDataTitle = 'Разрешаю обрабатывать, хранить и использовать мои персональные данные в целях реализации Дисконтной программы ОАО "ГУМ".'

// ToDo add Dispatcher
export const Discount = ({ userState, discountCardState, createDiscountCard }) => {
	const [fieldsData, setFieldsData] = useState({})
	const [confirmRules, setConfirmRules] = useState(true)
	const [confirmData, setConfirmData] = useState(true)
	const user = useMemo(() => Customer.create(userState), [userState])
	const discount = useMemo(() => discountCardState?.result, [discountCardState])

	useEffect(() => {
		setFieldsData(getInitialState(discount, user))
	}, [user, discount])

	const inputHandler = useCallback((event, value) => {
		const { name } = event?.target ?? 'undefined'

		let nextAttributes
		let nextError

		if(name === 'gender'){
			nextAttributes = { [name]: event.target.value }
		}
		else{
			if (value !== undefined) {
				nextAttributes = { [event]: value }
				nextError = { [event]: { ...fieldsData?.params[event], error: false} }
			} else {
				nextAttributes = { [name]: event.target.value }
				nextError = { [name]:{ ...fieldsData?.params[name], error: false} }
			}
		}

		setFieldsData({
			data: { ...fieldsData.data, ...nextAttributes },
			params: { ...fieldsData.params, ...nextError}
		})
	}, [fieldsData])

	const onBlur = useCallback((event) => {
		const { name, validity: { valid } } = event?.target ?? 'undefined'
		let nextAttributes

		nextAttributes = { error: !valid }

		setFieldsData({
			data: { ...fieldsData.data },
			params: { ...fieldsData.params, [name]:{ ...fieldsData.params[name], ...nextAttributes } }
		})
	}, [fieldsData])

	const submit = useCallback(e => {
		e.preventDefault()
		const searchQuery = DataProvider.buildQuery()
			.addBody({ ...fieldsData.data }, true)
			.setSuccessCallback(() => {
				Notification.add({
					title:   'Успех',
					message: 'Дисконтная карта успешно зарегистрирована',
					type:    'success',
				})
			})
			.setErrorCallback(() => {
				Notification.add({
					title:   'Ошибка',
					message: 'Дисконтная карта не была зарегистрирована',
					type:    'danger',
				})
			})

		createDiscountCard(searchQuery)
	}, [fieldsData, createDiscountCard])

	return (
		<div className={styles.row}>
			{/*<Spinner isFetching={isFetching} block />*/}
			<div className={styles.title}>{'Активация дисконтной карты'}</div>
			<form onSubmit={submit}>
				<div className={styles.forms}>
					{
						forms.map(field => (
							field.type === 'checkbox' ?
								<Checkbox
									key={field.id}
									id={field.id}
									title={field.title}
									handler={inputHandler}
									value={fieldsData?.data?.[field.id] ?? false}
								/> :
								<InputField
									key={field.id}
									onChange={inputHandler}
									onBlur={onBlur}
									value={fieldsData?.data?.[field.id] ?? ''}
									field={field}
									groupClassName={styles[field.id]}
									required={field.required}
									error={fieldsData?.params?.[field.id]?.error}
									inputProps={field.inputProps}
								/>
						))
					}
					<Checkbox
						title={confirmDataTitle}
						handler={() => setConfirmData(prevState => !prevState)}
						value={confirmData}
					/>
					<Checkbox
						title={confirmRulesTitle}
						handler={() => setConfirmRules(prevState => !prevState)}
						value={confirmRules}
					/>
				</div>
				<Button
					type="submit"
					title="Отправить"
					//onClick={submit}
					style={[styles.submit, confirmData && confirmRules && styles.submitActive].join(' ')}
					color={confirmData && confirmRules ? 'green' : 'gray'}
				/>
			</form>
		</div>
	)
}
