import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render blank button
 *
 * @param {string} className
 * @param {Object} children
 * @param {string} title
 * @param {Object} innerRef
 * @param {Object} props
 *
 * @return {*}
 * @constructor
 */
export const BlankButton = ({
	className,
	children,
	title,
	innerRef,
	...props
}) => (
	<button
		type="button"
		className={[styles.button, className].join(' ')}
		ref={innerRef}
		{...props}
	>
		{title}
		{children}
	</button>
)

BlankButton.propTypes = {
	children:  PropTypes.node,
	className: PropTypes.string,
	innerRef:  PropTypes.object,
	title:     PropTypes.string,
}

BlankButton.defaultProps = {
	children:  null,
	className: '',
	innerRef:  null,
	title:     '',
}
