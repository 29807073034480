import PropTypes from 'prop-types'
import React, { Component } from 'react'
import TagCloud from 'react-tag-cloud'
import styles from './styles.scss'

/**
 * Render word cloud
 *
 * @param {Array} words
 * @param {function} toggle
 * @param {number} count
 * @param {Array} arr
 *
 * @return {*}
 * @constructor
 */
class WordsCloud extends Component
{
	shouldComponentUpdate(nextProps)
	{
		const { count }            = this.props
		const { count: nextCount } = nextProps

		return count !== nextCount
	}

	render()
	{
		const { words, toggle, arr } = this.props

		return (
			<div className={styles.outer}>
				<TagCloud
					className={styles.wrapper}
					style={{
						fontFamily: 'sans-serif',
						fontSize:   () => Math.round(Math.random() * 30) + 10,
						padding:    7,
					}}
				>
					{words.map(word =>
						<div
							role="button"
							tabIndex={0}
							key={word.id}
							className={[styles.word,
								arr.includes(word.text) ? styles.active : ''].join(' ')}
							onClick={() => toggle(word.text)}>
							{word.text}
						</div>)}
				</TagCloud>
			</div>
		)

	}

}

WordsCloud.propTypes = {
	arr:    PropTypes.array,
	count:  PropTypes.number,
	toggle: PropTypes.func,
	words:  PropTypes.array,
}

WordsCloud.defaultProps = {
	arr:    [],
	count:  0,
	toggle: () => null,
	words:  [],
}
export default WordsCloud

