import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	userState: StateSelector.user.getUser(state),
	discountCardState: StateSelector.discountCard.view(state),
})
const mapDispatchToProps = {
	createDiscountCard: DispatchSelector.discountCard.create,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
