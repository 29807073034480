import { SagasHelper } from '@kakadu-dev/base-frontend-components'
import {
	takeLatest,
} from 'redux-saga/effects'
import { EMAIL_ACTION } from './actionTypes'
import { EmailApi } from './api'

/**
 * Email subscribe
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{properties, type}>|CallEffect|PutEffect<{type}>>}
 */
function* subscribe(action) {
	yield SagasHelper.defaultAction(action, EmailApi.subscribe)
}

export default [
	takeLatest(EMAIL_ACTION.SUBSCRIBE, subscribe),
]
