import { Component } from 'react'
import { store } from 'react-notifications-component'

/**
 * Notification service
 *
 * https://github.com/teodosii/react-notifications-component
 */
export class Notification extends Component
{


	/**
	 * Default params
	 *
	 * @type {object}
	 */
	// eslint-disable-next-line react/sort-comp
	static defaultParams = {
		title:        '',
		message:      '',
		type:         'danger',
		insert:       'bottom',
		container:    'top-right',
		animationIn:  ['animated', 'fadeIn'],
		animationOut: ['animated', 'fadeOut'],
		dismiss:      {
			duration:         5000,
			pauseOnHover:     true,
			waitForAnimation: true,
		},
	}

	/**
	 * Get result params
	 *
	 * @param {object} params
	 *
	 * @return {object}
	 */
	static getParams(params)
	{
		return {
			...this.defaultParams,
			...params,
		}
	}

	/**
	 * Notification types
	 *
	 * @type {object}
	 */
	static types = {
		success: 'success',
		warning: 'warning',
		info:    'info',
		danger:  'danger',
	}

	/**
	 * Add new notification
	 *
	 * @param {object} params
	 *
	 * @return {undefined}
	 */
	static add(params)
	{
		store.addNotification(this.getParams(params))
	}
}
