import {
	useCallback,
	useEffect,
} from 'react'

/**
 * Hook to check outside clicks
 *
 * @param {Object} ref
 * @param {boolean} active
 * @param {function} setActive
 * @param {boolean} condition
 */
export const useCheckOutsideClick = (ref, active, setActive, condition = true) => {
	const checkOutsideClick = useCallback(event => {
		if (!ref.current?.contains?.(event.target) && active) {
			setActive(false)
		}
	}, [ref, setActive, active])

	useEffect(() => {
		if (condition) {
			document.addEventListener('mousedown', checkOutsideClick)
		}

		return () => document.removeEventListener('mousedown', checkOutsideClick)
	}, [checkOutsideClick, condition])
}
