export const config = {
	apiKey:  '0965da21-c6d0-448a-a3b0-4232a43c9906',
	url:     'https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=',
	state:   {
		center:   [53.908875, 27.574714],
		zoom:     12,
		controls: ['searchControl', 'zoomControl'],
	},
	options: { searchControlProvider: 'yandex#search' },
	icon:    { preset: 'islands#dotIcon', iconColor: '#48bc00' },
}


export const stores = [
	{
		geometry:             [53.900491, 27.557960],
		balloonContentHeader: 'ГУМ',
		balloonContent:       'Универмаг',
		balloonContentFooter: ['+375 (17) 323 10 48', '+375 (29) 304 50 45'],
		address:              'пр. Независимости, 21, Минск',
	},
	{
		geometry:             [53.901255, 27.557866],
		balloonContentHeader: 'ГУМ',
		balloonContent:       'Отдел «Обувь», «Mattioli»',
		balloonContentFooter: ['+375 (17) 323 10 48', '+375 (29) 304 50 45'],
		address:              'ул.Ленина,8, Минск',
	},
	{
		geometry:             [53.901297, 27.557492],
		balloonContentHeader: 'ГУМ',
		balloonContent:       'Отдел косметики «H&B»',
		balloonContentFooter: ['+375 (17) 323 10 48', '+375 (29) 304 50 45'],
		address:              'ул. Ленина,6, Минск',
	},
	{
		geometry:             [53.901711, 27.556899],
		balloonContentHeader: 'ГУМ',
		balloonContent:       'Отдел трикотажа «Mark Formelle»',
		balloonContentFooter: ['+375 (17) 323 10 48', '+375 (29) 304 50 45'],
		address:              'ул. Ленина,4, Минск',
	},
	{
		geometry:             [53.931901, 27.562837],
		balloonContentHeader: 'Продовольственный магазин',
		balloonContent:       '«Щедрый» №4',
		balloonContentFooter: ['+375 (17) 364 26 52'],
		address:              'ул. Орловская. 11, Минск',
	},
	{
		geometry:             [53.929198, 27.563691],
		balloonContentHeader: 'Продовольственный магазин',
		balloonContent:       '«Щедрый» №5',
		balloonContentFooter: ['+375 (17) 233 32 86'],
		address:              'ул. Каховская, 47, Минск',
	},
	{
		geometry:             [53.909142, 27.573707],
		balloonContentHeader: 'Продовольственный магазин',
		balloonContent:       '«Щедрый» №10',
		balloonContentFooter: ['+375 (17) 355 45 83'],
		address:              'пр. Независимости, 35, Минск',
	},
]
