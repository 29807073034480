import { VISION_PANEL_ACTIONS } from 'reduxm/modules/visionPanel/actionTypes'

export const setIsShow = (payload) => ({ type: VISION_PANEL_ACTIONS.IS_SHOW_PANEL, payload })

export const setSiteBackground = (payload) => ({ type: VISION_PANEL_ACTIONS.SITE_BACKGROUND, payload })

export const setShowImg = (payload) => ({ type: VISION_PANEL_ACTIONS.IS_IMG, payload })

export const setFontSize = (payload) => ({ type: VISION_PANEL_ACTIONS.SET_FONT_SIZE, payload })

export const setFontKerning = (payload) =>  ({ type: VISION_PANEL_ACTIONS.SET_FONT_KERNING,	payload })
