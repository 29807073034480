import styles from 'containers/Static/SiteMap/styles.scss'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

export const SiteMapBlock = ({ title, href, children }) => (
	<div className={styles.block}>
		<Link to={href} className={[styles.link, styles.header].join(' ')}>
			{title}
		</Link>
		<ul className={styles.list}>
			{children}
		</ul>
	</div>
)

SiteMapBlock.propTypes = {
	title:    PropTypes.string.isRequired,
	href:     PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
}
