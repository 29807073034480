export const data = [
	{
		id: 1,
		name: 'company',
		title: 'Полное наименование юридического лица / Поўнае найменне юрыдычнай асобы:',
		type: 'text',
	},
	{
		id: 2,
		name: 'bankDetails',
		title: 'Банковские реквизиты / Банкаўскія рэквізіты:',
		type: 'text',
	},
	{
		id: 3,
		name: 'email',
		title: 'Электронная почта / электронная пошта:',
		type: 'email',
	},
	{
		id: 4,
		name: 'phone',
		title: 'Телефон / тэлефон:',
		type: 'text',
	},
	{
		id: 5,
		name: 'name',
		title: 'Фамилия, имя, отчество / Прозвiшча, iмя, iмя па бацьку:',
		type: 'text',
	},
]

export const dataTextarea =
	{
		id: 13,
		name: 'message',
		title: 'Дополнительная информация / Дадатковая інфармацыя',
		type: 'textarea',
	}

