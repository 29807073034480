import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Collapse } from 'reactstrap'
import { UserAddressForm } from './UserAddressForm/AddressForm'
import { UserContactsForm } from './UserContactsForm'

/**
 * Render user data forms
 *
 * @param {Object} attributes
 * @param {Function} onChange
 * @param {Function} onBlur
 *
 * @return {*}
 * @constructor
 */
export const UserDataForms = ({ attributes, onChange, onBlur }) => {

	const isOpen = useMemo(() => {
		return (attributes?.data?.deliveryMethod === 'courierRB') ||
				(attributes?.data?.deliveryMethod ==='courierMinsk')
	}, [attributes])

	return (
		<div>
			<Collapse isOpen={isOpen}>
				<UserAddressForm
					attributes={attributes}
					onChange={onChange}
					onBlur={onBlur}
				/>
			</Collapse>
			<UserContactsForm
				attributes={attributes}
				onChange={onChange}
				onBlur={onBlur}
			/>
		</div>
	)
}

UserDataForms.propTypes = {
	attributes: PropTypes.object,
	onChange:    PropTypes.func,
	onBlur: PropTypes.func,
}

UserDataForms.defaultProps = {
	attributes: {},
	onChange:    () => null,
	onBlur: () => null,
}
