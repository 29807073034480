import { isFetching as _ } from 'helpers/FetchingHelper'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const { products: { byCategories } } = StateSelector

const mapStateToProps    = state => ({
	isFetching:        _(byCategories(state)),
	hierarchicalState: StateSelector.categories.getHierarchical(state),
	productsState:     byCategories(state),
})
const mapDispatchToProps = {
	getProducts: DispatchSelector.products.byCategories,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
