import * as PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import styles from './styles.scss'

/**
 * Render data block
 *
 * @param {string} title
 * @param {string} link
 * @param {Object} children
 *
 * @return {*}
 * @constructor
 */
export const DataBlock = ({ title, link, children }) => (
	<Container>
		<h1 className={styles.title}>{title}</h1>
		<div className={styles.box}>
			{link &&
			 <Link to={link} className={styles.link}>
				 Смотреть все
			 </Link>}
		</div>
		<div className={styles.wrapper}>
			{children}
		</div>
	</Container>
)

DataBlock.propTypes = {
	title:    PropTypes.string,
	children: PropTypes.node.isRequired,
	link:     PropTypes.string,
}

DataBlock.defaultProps = {
	link:  '',
	title: '',
}
