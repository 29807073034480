import {
	DispatchSelector,
} from 'reduxm/modules/selectors'

const mapDispatchToProps = {
	createForm: DispatchSelector.stylistForm.create,
}

export {
	mapDispatchToProps,
}
