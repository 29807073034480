import { Cancel as Error } from '@material-ui/icons'
import { BlankButton } from 'components/global/BlankButton'
import React from 'react'
import { useHistory } from 'react-router'
import styles from './styles.scss'

export const ErrorOrder = () => {

	const { push } = useHistory()

	return (
		<div className={styles.container}>
			<div className={styles.orderWrapper}>
				<div className={styles.icon}>
					<Error />
				</div>
				<div className={styles.header}>Платеж не прошёл</div>
				<div className={styles.title}>При обработке платежа произошла ошибка.</div>
				<div className={styles.orderInfo}>
					Повторите попытку или выберите другой способ оплаты
				</div>
				<BlankButton
					onClick={() => push('/basket')}
					title="Вернуться в корзину"
					className={styles.toMainBtn}
				/>
			</div>
		</div>
	)
}
