import { CartProduct } from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { BasketHeader } from 'components/basket'
import { BasketAsideStore } from 'components/basket/BasketAside/index.props'
import { BasketItemStore } from 'components/basket/BasketItem/index.store'
import _ from 'lodash'
import * as PropTypes from 'prop-types'
import React, {
	useEffect,
} from 'react'
import { Link } from 'react-router-dom'
import {
	Col,
	Container,
	Row,
} from 'reactstrap'
import { MetaService } from 'services/MetaService'
import styles from './styles.scss'

// Enable get meta data
const setMetaInfo = () => MetaService.setInfo({}, { title: 'Корзина' })

const expands = [
	'offer.option.properties',
	'product.categories.category.allParents',
	'minPrice',
]

const getCartListQuery = () => DataProvider
	.buildQuery()
	.addBody({ query: { expands } })

const getRemoveItemQuery = id => DataProvider
	.buildQuery()
	.addBody({ id })

const getUpdateCountQuery = (id, count) => DataProvider
	.buildQuery()
	.addBody({
		query: { expands },
		count,
		id,
	})

/**
 * Render basket home
 *
 * @param {Function} getCartList
 * @param {Function} deleteCartProduct
 * @param {Function} updateCartProduct
 * @param {Object} cartListState
 *
 * @return {*}
 * @constructor
 */
export const BasketHome = ({
	getCartList,
	deleteCartProduct,
	updateCartProduct,
	cartListState,
}) => {
	useEffect(() => {
		setMetaInfo()
		getCartList(getCartListQuery())
	}, [getCartList])

	const deleteCartItem = _.throttle(id => deleteCartProduct(getRemoveItemQuery(id)),
		500, { leading: true, trailing: false })

	const cartProducts = CartProduct.createList(cartListState)

	return (
		<section className={styles.basket}>
			<Container>
				<Row>
					<Col xs="12" lg="8" className={styles.container}>
						<BasketHeader title='Корзина' />
						{cartProducts?.length ?
							cartProducts.map(product => (
								<BasketItemStore
									key={product.getId()}
									cartProduct={product}
									updateProductCount={(id, count) => updateCartProduct(getUpdateCountQuery(id, count))}
									deleteCartProduct={deleteCartItem}
								/>
							)) :
							<div className={styles.emptyBasket}>
								<div>Ваша корзина пуста</div>
								<div>
									<span>Перейдите в</span>
									<Link to="/products">каталог</Link>
									<span>и добавьте товары.</span>
								</div>
							</div>}
					</Col>
					<Col xs="12" lg="4">
						<BasketAsideStore />
					</Col>
				</Row>
			</Container>
		</section>
	)
}

BasketHome.propTypes = {
	cartListState:     PropTypes.object,
	deleteCartProduct: PropTypes.func,
	getCartList:       PropTypes.func,
	updateCartProduct: PropTypes.func,
}

BasketHome.defaultProps = {
	cartListState:     {},
	deleteCartProduct: () => null,
	getCartList:       () => null,
	updateCartProduct: () => null,
}
