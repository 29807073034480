import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import SSRComponentHelper from '@kakadu-dev/base-frontend-helpers/helpers/SSRComponentHelper'
import { Pages } from 'components/pages'
import styles from 'containers/Static/Error/styles.scss'
import ErrorHelper from 'helpers/ErrorHelper'
import React, {
	useEffect,
	useMemo,
} from 'react'
import { useLocation } from 'react-router'
import { MetaService } from 'services/MetaService'
import * as PropTypes from 'prop-types'
import { mapDispatchToProps } from './index.props'

// Enable get meta data
const setMetaInfo = title => MetaService.setInfo({}, { title })


const searchQueryPages = DataProvider.buildQuery().cacheResponse(3600, true)

/**
 * Render 404 error component
 *
 * @return {*}
 * @constructor
 */
export const Error = ({ staticPagesState, hierarchicalState, getMeta, getPages }) => {

	const { pathname } = useLocation()

	const hierarchical = useMemo(() => hierarchicalState.result, [hierarchicalState])

	const page = useMemo(() => {
		const list = staticPagesState?.result?.list || []
		return list.find((element) => element.alias === pathname)
	}, [staticPagesState, pathname])

	useEffect(() => {
		getPages(searchQueryPages)
	}, [getPages])

	useEffect(() => {
		if(page){
			setMetaInfo(page.title)
		}
		else setMetaInfo('Ошибка')
	}, [pathname, page])

	useEffect(() => {
		const metaSearch = MetaService.getMetaSearch()
		getMeta(metaSearch)
	}, [getMeta])

	return (
		<>
			{
				page ?
					<Pages page={page} hierarchical={hierarchical} /> :
					<section className={styles.container}>
						<div className={styles.image}>
							ГУМ
						</div>
						<div className={styles.title}>
							Упс! Эта страница не может быть найдена.
						</div>
						<div className={styles.description}>
							<p>Похоже, что был неправильно набран адрес, или такой страницы больше не существует.</p>
						</div>
					</section>
			}
		</>
	)
}

Error.getInitialProps = SSRComponentHelper.initialProps(async (dispatchers, { match }) => {
	if(match) {
		const { getMeta, getPages } = dispatchers

		MetaService.setUrlInfo({ url: match?.url ?? '/error' })
		const metaSearch = MetaService.getMetaSearch()

		try {
			await Promise.all([
				getPages(searchQueryPages),
				getMeta(metaSearch)
			])
		}
		catch (e){
			ErrorHelper.log(e)
		}
	}
}, mapDispatchToProps)

Error.propTypes = {
	staticPagesState:  PropTypes.object,
	hierarchicalState: PropTypes.object,
	getMeta:           PropTypes.func,
	getPages:          PropTypes.func,
}
Error.defaultProps = {
	staticPagesState:  {},
	hierarchicalState: {},
	getMeta:           () => null,
	getPages:          () => null,
}
