import { PageHeading } from 'components/global/PageHeading'
import PropTypes from 'prop-types'
import React from 'react'
import { Container } from 'reactstrap'
import './style.css'
import styles from './styles.scss'

/**
 * Render wrapper for static page
 *
 * @param {string} title
 * @param {Node} children
 * @param {Array} extraBreadcrumbs
 * @param {string} className
 *
 * @return {*}
 * @constructor
 */
export const StaticPageWrapper = ({ title, children, extraBreadcrumbs, className }) => (
	<Container className={[styles.container, className].join(' ')}>
		<PageHeading
			title={title}
			extraBreadcrumbs={extraBreadcrumbs}
			breadcrumbs
		/>
		{children}
	</Container>
)

StaticPageWrapper.propTypes = {
	children:         PropTypes.node,
	extraBreadcrumbs: PropTypes.array,
	title:            PropTypes.string,
	className:        PropTypes.string,
}

StaticPageWrapper.defaultProps = {
	children:         null,
	extraBreadcrumbs: [],
	title:            '',
	className:        '',
}
