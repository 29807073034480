import PropTypes from 'prop-types'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import styles from './styles.scss'
import 'react-phone-input-2/lib/material.css'
import './styles.css'


/**
 * Render phone number field
 *
 * @param {Object} field
 * @param {number} value
 * @param {Function} handler
 * @param {Function} onBlur
 * @param {boolean} required
 * @param {boolean} error
 *
 * @return {*}
 * @constructor
 */
export const PhoneNumber = ({ field, value, handler, onBlur, required, error, inputProps }) => {
	const { id, title } = field

	const onChange = phone => {
		const event = {
			target: {
				name: id,
				value: phone.startsWith('+') ? phone : `+${phone}`,
				required,
			}
		}
		handler(event)
	}

	return (
		<div className={styles.container}>
			<PhoneInput
				specialLabel={title}
				country='by'
				onChange={onChange}
				containerClass="MuiFormControl-root MuiTextField-root"
				inputClass={[error ? 'Mui-inputError': 'Mui-input', styles.field].join(' ')}
				inputProps={{...inputProps, required: true, name: id}}
				value={value}
				onBlur={onBlur}
				prefix="+"
			/>
		</div>
	)
}

PhoneNumber.propTypes = {
	field:           PropTypes.object,
	handler:         PropTypes.func,
	onBlur:          PropTypes.func,
	value:           PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	required:        PropTypes.bool,
	error:           PropTypes.bool,
}
PhoneNumber.defaultProps = {
	field:           {},
	handler:         () => null,
	onBlur:          () => null,
	value:           '',
	required:        false,
	error:           false,
}
