import {
	DispatchSelector,
	StateSelector,
} from '@kakadu-dev/base-frontend-components'

const mapStateSelector = (state) => ({
	userState:      StateSelector.user.getUser(state),
	addToCartState: StateSelector.cart.create(state),
})

const mapDispatchToProps = {
	addToCart:  DispatchSelector.cart.create,
	getProduct: DispatchSelector.products.view,
}

export {
	mapStateSelector,
	mapDispatchToProps,
}
