import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps = state => ({
	userState:     StateSelector.user.getUser(state),
	cardAddState:  StateSelector.payment.create(state),
	cardListState: StateSelector.payment.list(state),
})

const mapDispatchToProps = {
	getCardList:   DispatchSelector.payment.list,
	createPayment: DispatchSelector.payment.create,
}

export {
	mapDispatchToProps,
	mapStateToProps,
}
