import { SagasHelper } from '@kakadu-dev/base-frontend-components'
import { takeLatest } from 'redux-saga/effects'
import { FORM_ACTIONS } from './actionTypes'
import { CertificateFormApi } from './api'

/**
 * Create certificateForm
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{countries, type}>|CallEffect|PutEffect<{type}>>}
 */
function* create(action)
{
	yield SagasHelper.action(action, CertificateFormApi.create)
}

export default [
	takeLatest(FORM_ACTIONS.CREATE, create),
]
