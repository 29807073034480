import image from 'assets/images/market/product.jpg'
import { GoodsItemFooter } from 'components/global/GoodsItemFooter'
import { StarRating } from 'components/global/StarRating'
import * as PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'

import styles from './styles.scss'

class GoodsItem extends Component
{
	state = {
		footerIsOpen: false,
		footerHeight: 0,
		width:        window.innerWidth,
	}

	componentDidMount()
	{
		window.addEventListener('resize', this.updateDimensions)
	}

	componentWillUnmount()
	{
		window.removeEventListener('resize', this.updateDimensions)
	}

	updateDimensions = () => {
		this.setState({
			width: window.innerWidth,
		})
	}

	onHover = () => {
		const { footerIsOpen, footerHeight } = this.state

		this.setState({
			footerIsOpen: !footerIsOpen,
			footerHeight: footerHeight === 'auto' ? '0' : 'auto',
		})

	}

	render()
	{
		const {
				  xs,
				  md,
				  disableAnimation,
				  title,
				  linkTo,
				  rating,
				  isSale,
			  } = this.props
		const {
				  footerIsOpen,
				  width,

			  } = this.state

		const isMobile = width <= 767

		return (
			<Col xs={xs} md={md} className={styles.wrapper}>
				<div
					onMouseEnter={this.onHover}
					onMouseLeave={this.onHover}
					className={[
						styles.item,
						isSale ? styles.item_sale : '',
						styles.item_closed,
						footerIsOpen && !disableAnimation && !isMobile ? styles.item_open : '',
					].join(' ')}
				>
					<Link to={linkTo} className={styles.box}>

						<div className={styles.head}>
							<div className={styles.rating}>
								<StarRating initialRating={rating} readonly />
							</div>
							<p className={styles.sale}>
								{'Скидка'}
							</p>
						</div>

						<div className={styles.image}>
							<img src={image} alt="product" />
						</div>

						<p className={styles.title}>
							{title}
						</p>

						<div className={styles.price}>
							<p className={styles.price_real}>
								от 84.50 руб.
							</p>
							<p className={styles.price_sale}>
								от 70.50 руб.
							</p>
						</div>

					</Link>

					{!isMobile && (
						<GoodsItemFooter
							isOpen={footerIsOpen}
							disableAnimation={disableAnimation}
						/>
					)}

				</div>
			</Col>
		)
	}
}

GoodsItem.propTypes = {
	xs:               PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	md:               PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	disableAnimation: PropTypes.bool,
	title:            PropTypes.string,
	linkTo:           PropTypes.string,
	rating:           PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	isSale:           PropTypes.bool,
}

GoodsItem.defaultProps = {
	xs:               6,
	md:               4,
	disableAnimation: false,
	title:            '',
	linkTo:           '',
	rating:           5,
	isSale:           false,
}

export { GoodsItem }
