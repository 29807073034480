import * as PropTypes from 'prop-types'
import React from 'react'
import Rating from 'react-rating'
import styles from './styles.scss'
import {
	getEmptyStar,
	getFullStar,
} from './symbols'

/**
 * Get rate text
 *
 * @param {number} rate
 *
 * @return {string}
 */
const rateToString = rate => {
	if (rate >= 1 && rate < 2) {
		return 'Ужасно'
	}
	if (rate >= 2 && rate < 3) {
		return 'Плохо'
	}
	if (rate >= 3 && rate < 4) {
		return 'Нормально'
	}
	if (rate >= 4 && rate < 5) {
		return 'Хорошо'
	}

	return 'Отлично!'
}

/**
 * Render star rating component
 *
 * @param {number} initialRating
 * @param {number} size
 * @param {boolean} showRateString
 * @param {Object} newProps
 *
 * @return {*}
 * @constructor
 */
const StarRating = ({ initialRating, size, showRateString, prefix, changeValue, readonly, ...newProps }) => {
	return (
		<div className={showRateString ? styles.rateWrapper : ''}>
			{prefix && <div className={styles.prefix}>{prefix}</div>}
			<Rating
				readonly={readonly}
				onClick={changeValue}
				fullSymbol={getFullStar(size)}
				emptySymbol={getEmptyStar(size)}
				initialRating={initialRating}
				{...newProps}
			/>

			{showRateString && (
				<div className={styles.rateString}>
					{rateToString(initialRating)}
				</div>
			)}
		</div>
	)
}

StarRating.propTypes = {
	changeValue:    PropTypes.func,
	initialRating:  PropTypes.number.isRequired,
	newProps:       PropTypes.object,
	prefix:         PropTypes.string,
	readonly:       PropTypes.bool,
	showPrefix:     PropTypes.bool,
	showRateString: PropTypes.bool,
	size:           PropTypes.number,
}

StarRating.defaultProps = {
	changeValue:    () => null,
	newProps:       {},
	prefix:         '',
	readonly:       true,
	showPrefix:     false,
	showRateString: false,
	size:           14,
}

export { StarRating }
