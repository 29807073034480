import { Customer } from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { appendScriptToHead } from 'components/global/AppendScriptToHead'
import { scrollLock } from 'components/global/scrollLock'
import { Backdrop } from 'components/user/Backdrop'
import { LoaderWithTitle } from 'components/user/PaymentLoader'
import { UserAddCardError } from 'components/user/UserAddCardError'
import { UserAddCardForm } from 'components/user/UserAddCardForm'
import { UserAddCardHiddenForm } from 'components/user/UserAddCardHiddenForm'
import { Header } from 'containers/User/Header'
import {
	formatCreditCardNumber,
	formatCVC,
	formatExpirationDate,
	formatName,
} from 'containers/User/Payment/utils'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'

const cloudPaymentsScript = 'https://widget.cloudpayments.ru/bundles/checkout'
const serverURL           = 'https://pay-zooqi.kakadu.bz/v1/site/post-secure?redirect_url='
const redirectURL         = 'http://localhost:3000/user/payment-add'
const paymentKey          = process.env.REACT_APP_PAYMENT_KEY || 'test_api_10001000000100000100001'

const initialState = {
	number:      '',
	name:        '',
	expiryMonth: '',
	expiryYear:  '',
	cvc:         '',
	issuer:      '',
	focused:     '',
	isValid:     false,
	isSubmit:    false,
	isPressed:   false,
	error:       false,
}

/**
 * Render payment form
 */
export class Payment extends Component
{
	state = initialState

	componentDidMount()
	{
		appendScriptToHead(cloudPaymentsScript, this.addPay)			// Append payment script to <head> of HTML
		this.onRefresh()											// After loading the script initialize it with callback
	}

	componentDidUpdate()
	{
		const { isSubmit, isPressed } = this.state

		if (isSubmit || isPressed) {
			scrollLock(true)
		} else {
			scrollLock()
		}
	}

	/**
	 * Refresh card list
	 *
	 * @return {undefined}
	 */
	onRefresh = () => {
		const { getCardList } = this.props
		getCardList(DataProvider.buildQuery())
	}

	/**
	 * Initialize payment service
	 *
	 * @param {number} publicId
	 */
	addPay = () => {
		this.checkout = new window.cp.Checkout(paymentKey,
			document.getElementById('paymentFormSample'))
	}

	/**
	 * Show error and reset state
	 *
	 * @return {number}
	 */
	showError = () => setTimeout(() => {
		this.setState(initialState, () => {
			setTimeout(() => this.setState({ error: true }), 600)

			setTimeout(() => this.setState({ error: false }), 4500)
		})
	}, 1000)

	/**
	 * Check is valid data
	 *
	 * @param issuer
	 * @param (bolean) isValid
	 *
	 * @return {undefined}
	 */
	handleCallback = ({ issuer }, isValid) => isValid && this.setState({ issuer })

	/**
	 * Handle inputs focus
	 *
	 * @param {Object} target
	 *
	 * @return {undefined}
	 */
	handleInputFocus = ({ target }) => {
		if (target.id === 'expiryMonth' || target.id === 'expiryYear') {
			this.setState({ focused: 'expiry' })
		} else {
			this.setState({ focused: target.id })
		}
	}

	/**
	 * Handle onchange events (focus, value, check is valid)
	 *
	 * @param {Object} target
	 *
	 * @return {undefined}
	 */
	handleInputChange = ({ target: { value, id } }) => {
		const { isValid } = this.state
		let nextValue

		if (id === 'number') {
			nextValue = formatCreditCardNumber(value)

			if (value.length === 16 && this.mainFormRefs.cvc.current.value.length < 3) {
				this.mainFormRefs.cvc.current.focus()
			}
		}

		if (id === 'expiryMonth' || id === 'expiryYear') {
			nextValue = formatExpirationDate(value)

			if (id === 'expiryMonth' && value.length === 2) {
				this.mainFormRefs.year.current.focus()
			}
		}

		if (id === 'cvc') {
			nextValue = formatCVC(value)
			if (value.length === 3) {
				this.mainFormRefs.name.current.focus()
			}
		}

		if (id === 'name') {
			nextValue = formatName(value)
		}

		this.setState({ [id]: nextValue }, () => {
			const { name, number, expiryMonth, expiryYear, cvc } = this.state

			if (name.length > 4 && number.length >= 19 && expiryMonth.length === 2 && expiryYear.length === 2 &&
				cvc.length >= 3) {
				this.setState({ isValid: true }, () => {
					if (id !== 'name') {
						this.hiddenFormRefs.submitBtn.current.focus()
					}
				})
			} else if (isValid === true) {
				this.setState({ isValid: false })
			}
		})
	}

	/**
	 * Send request to the server
	 *
	 * @param { string } url
	 * @param { string } transaction_id
	 * @param { string } token
	 * @param { string } redirURL
	 */
	sendRequest = (url, transaction_id, token, redirURL) => {
		this.hiddenFormRefs.PaReq.current.value          = token
		this.hiddenFormRefs.MD.current.value             = transaction_id
		this.hiddenFormRefs.TermUrl.current.value        = `${serverURL}${redirURL}`
		this.hiddenFormRefs.secure3DSForm.current.action = url
		this.hiddenFormRefs.secure3DSForm.current.submit()
	}

	/**
	 * Add card
	 *
	 * @return {undefined}
	 */
	addCard = (crypto) => {
		const { createPayment }                 = this.props
		const { name, expiryMonth, expiryYear } = this.state

		const data = {
			card_encrypt_packet: crypto,
			card_holder:         name,
			card_expired:        `${expiryMonth}${expiryYear}`,
			isEncrypt:           true,
		}

		const searchQuery = DataProvider
			.buildQuery()
			.addBody(data)
			.setSuccessCallback(response => {
				const { result: { token, transaction_id, url } } = response

				this.sendRequest(url, transaction_id, token, redirectURL)
			})
			.setErrorCallback(this.showError)

		createPayment(searchQuery)
	}

	/**
	 * Create cryptogram if data is valid
	 *
	 * @return {undefined}
	 */
	createCryptogram = (event) => {
		const { isValid, isPressed } = this.state

		if (isPressed) {
			this.setState({ isPressed: false })
			return
		}
		event.preventDefault()

		this.setState({ isSubmit: true, isPressed: true }, () => {
			if (!isValid) {
				setTimeout(() => {
					this.setState({ isSubmit: false })
					this.hiddenFormRefs.submitBtn.current.click()
				}, 2000)
			}

			if (isValid) {
				const result = this.checkout.createCryptogramPacket()

				if (result.success) {
					this.addCard(result.packet)
				} else {
					for (const msgName in result.messages) {
						alert(result.messages[msgName])
					}
				}
			}
		})
	}

	hiddenFormRefs = {
		form:          React.createRef(),
		PaReq:         React.createRef(),
		MD:            React.createRef(),
		TermUrl:       React.createRef(),
		secure3DSForm: React.createRef(),
		submitBtn:     React.createRef(),
	}

	mainFormRefs = {
		number: React.createRef(),
		cvc:    React.createRef(),
		name:   React.createRef(),
		month:  React.createRef(),
		year:   React.createRef(),

	}

	render()
	{
		const {
				  name,
				  number,
				  expiryMonth,
				  expiryYear, cvc,
				  focused,
				  issuer,
				  isSubmit,
				  error,
			  }             = this.state
		const { userState } = this.props

		const functionForMainForm = {
			createCryptogram:  this.createCryptogram,
			handleInputFocus:  this.handleInputFocus,
			handleInputChange: this.handleInputChange,
		}

		const user = Customer.create(userState)

		return (
			<div id="PaymentForm">
				<Header title="Платежные данные" userName={user.getFullName()} />
				<Backdrop isActive={error}>
					<UserAddCardError isStart={error} />
				</Backdrop>

				<Backdrop isActive={isSubmit}>
					<LoaderWithTitle title="Проверка введенных данных..." />
				</Backdrop>
				<Cards
					number={number}
					cvc={cvc}
					name={name}
					expiry={`${expiryMonth}${expiryYear}`}
					locale={{ valid: 'месяц/год', name: 'Ваше имя' }}
					placeholders={{ name: 'Ваше имя' }}
					focused={focused}
					callback={this.handleCallback}
					acceptedCards={['visa', 'mastercard', 'maestro']}
				/>
				<UserAddCardForm
					hiddenFormRefs={this.hiddenFormRefs}
					formRefs={this.mainFormRefs}
					functionForMainForm={functionForMainForm}
					issuer={issuer}
				/>
				<UserAddCardHiddenForm formRefs={this.hiddenFormRefs} />
			</div>
		)
	}
}

Payment.propTypes = {
	createPayment: PropTypes.func,
	getCardList:   PropTypes.func,
	userState:     PropTypes.object,
}

Payment.defaultProps = {
	createPayment: () => null,
	getCardList:   () => null,
	userState:     {},
}
