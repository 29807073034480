import { navBlocks } from 'containers/Home/Footer/data'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.scss'
import { injectIntl } from 'react-intl'

/**
 * Render nav blocks
 *
 * @return {*[]}
 * @constructor
 */
export const NavBlocks = ({ intl }) => (
	navBlocks[intl.locale].map(block => (
		<div key={block.id} className={styles.navBlock}>
			<p>{block.title}</p>
			{block.navLinks.map(link => (
				<div key={link.title}>
					{link.to.startsWith('http') ?
						<a
							href={link.to}
							download
						>
							{link.title}
						</a> :
						<Link to={link.to} download>
							{link.title}
						</Link>
					}
				</div>
			))}
		</div>
	))
)

export default injectIntl(NavBlocks)
