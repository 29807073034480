import { setLang } from 'reduxm/intl/action'
import { setIsShow } from 'reduxm/modules/visionPanel/action'

const mapStateToProps = state => ({
	intlState:     state.intl,
	isShowPanel:   state.visionPanel.isShow,
})

const mapDispatchToProps = {
	changeLang: setLang,
	setIsShow,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
