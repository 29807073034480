import Icon from 'assets/icomoon/Icon'
import { BlankButton } from 'components/global/BlankButton'
import styles from 'components/global/CollapseText/styles.scss'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

/**
 * Render collapse text
 *
 * @param {Node} children
 * @param {string} title
 *
 * @return {*}
 * @constructor
 */
export const UncontrolledCollapse = ({ title, children }) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<div>
			<span className={styles.title}>{title}</span>
			<BlankButton
				onClick={() => setIsOpen(!isOpen)}
				className={[styles.handler, isOpen && styles.active].join(' ')}
			>
				<Icon icon="mobile-menu-arrow" size={20} color="#535353" />
			</BlankButton>
			<div className={[styles.wrapper, isOpen && styles.active].join(' ')}>
				<div className={[styles.opacity, isOpen && styles.disabled].join(' ')}>
					<div className={styles.content}>
						{children}
					</div>
				</div>
			</div>
		</div>
	)
}

UncontrolledCollapse.propTypes = {
	children: PropTypes.node,
	title:    PropTypes.string,
}

UncontrolledCollapse.defaultProps = {
	children: null,
	title:    '',
}
