import { connect } from 'react-redux'
import { ProductsList } from './index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'

const ProductsListStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProductsList)

export { ProductsListStore }
