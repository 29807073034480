import {
	DispatchSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps = () => ({
})

const mapDispatchToProps = {
	subscribe: DispatchSelector.email.subscribe,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
