export const PRODUCTS_ACTIONS = {
	VIEW:                  'PRODUCTS_VIEW',
	VIEW_BARCODE:          'PRODUCTS_VIEW_BARCODE',
	LIST:                  'PRODUCTS_LIST',
	BY_CATEGORIES:         'PRODUCTS_BY_CATEGORIES',
	HOME_PAGE_LISTS:       'PRODUCTS_HOME_PAGE_LISTS',
	CATEGORIES_PAGE_LISTS: 'PRODUCTS_CATEGORIES_PAGE_LISTS',
	SEARCH:                'PRODUCTS_SEARCH',
	SEARCH_SUB:            'PRODUCTS_SEARCH_SUB',
	CREATE:                'PRODUCTS_CREATE',
	UPDATE:                'PRODUCTS_UPDATE',
	DELETE:                'PRODUCTS_DELETE',
	ADD_TO_STOCK:          'PRODUCTS_ADD_TO_STOCK',
	BATCH_UPLOAD:          'PRODUCTS_BATCH_UPLOAD'
}
