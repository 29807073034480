import { useSelector } from 'react-redux'
import { StateSelector } from 'reduxm/modules/selectors'
import { HierarchicalService } from 'services/HierarchicalService'

const mapStateToProps = state => ({
	hierarchical: StateSelector.categories.getHierarchical(state),
})

/**
 * Hook to get breadcrumbs
 *
 * @param {number} categoryId
 * @param {string|null} productTitle
 *
 * @returns {Array<Object>}
 */
export const useBreadcrumbs = (categoryId, productTitle = null) => {
	useSelector(mapStateToProps)

	return HierarchicalService.getInstance().getBreadcrumbs(categoryId, productTitle)
}
