import { connect } from 'react-redux'
import { Favorites } from './index'
import {
	mapDispatchToProps,
	mapStatesToProps,
} from './index.props'

export const FavoritesPageStore = connect(
	mapStatesToProps,
	mapDispatchToProps)
(Favorites)
