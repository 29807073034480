import { SagasHelper } from '@kakadu-dev/base-frontend-components'
import { takeLatest } from 'redux-saga/effects'
import { DISCOUNT_CARD_ACTIONS } from './actionTypes'
import { DiscountCardApi } from './api'

/**
 * Create discount card
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{countries, type}>|CallEffect|PutEffect<{type}>>}
 */
function* create(action)
{
	yield SagasHelper.action(action, DiscountCardApi.create)
}

/**
 * View discount card
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{countries, type}>|CallEffect|PutEffect<{type}>>}
 */
function* view(action)
{
	yield SagasHelper.action(action, DiscountCardApi.view)
}

export default [
	takeLatest(DISCOUNT_CARD_ACTIONS.CREATE, create),
	takeLatest(DISCOUNT_CARD_ACTIONS.VIEW, view),
]
