import {
	PropertyGroup,
	PropertyType,
} from '@kakadu-dev/base-frontend-components'
import * as PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render product properties
 *
 * @param {Product} product
 *
 * @return {*}
 * @constructor
 */
const ProductProperties = ({ product }) => {
	const properties = product.getProperties()

	return (
		<div className={styles.properties}>
			<h2 className={styles.title}>{'Характеристики'}</h2>
			{properties &&
			 PropertyGroup.map(properties, propGroup => {
				 return (
					 <div key={propGroup.getId()}>
						 <div className={styles.prop_group_title}>
							 {propGroup.getTitle()}
						 </div>

						 <div className={styles.wrapper}>
							 {PropertyType.map(propGroup.getRawModel().items, propType => (
								 <div
									 key={propType.getAlias()}
									 className={styles.row}
								 >
									 <p className={styles.type}>
										 {`${propType.getName()}:`}
									 </p>

									 <div className={styles.total}>
										 {propType.getRawModel().items.map(prop => (
											 <div
												 key={prop.id}
												 className={styles.link}
											 >
												 {prop.value}
											 </div>
										 ))}
									 </div>
								 </div>
							 ))}
						 </div>
					 </div>
				 )
			 })}
		</div>
	)
}

ProductProperties.propTypes = {
	product: PropTypes.object.isRequired,
}

export { ProductProperties }
