import React from 'react'
import styles from './styles.scss'

export const ProductDescription = ({ info }) => {
	return (
		<div className={styles.description}>
			<h2 className={styles.title}>
				{'Информация о товаре'}
			</h2>
			<div className={styles.content}>
				<div dangerouslySetInnerHTML={{ __html: info }} />
			</div>
		</div>
	)
}

