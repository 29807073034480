import Icon from 'assets/icomoon/Icon'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styles from './styles.scss'

/**
 * Render filter string list
 *
 * @param {Array} list
 * @param {string} alias
 * @param {Function} handler
 * @param {string} className
 *
 * @returns {*}
 * @constructor
 */
export const FilterStringList = ({ list, alias, handler, className }) => {
	const getData = useCallback((item, ref) => ({
		alias,
		id:    item.pId,
		value: item.value,
		type:  'string',
		ref,
	}), [alias])

	return (
		list.map(item => {
			let ref
			const key = `${item.pId}${item.alias}`

			return (
				<div key={key} className={className}>
					<label
						htmlFor={key}
						className={styles.item}
						onChange={event => handler(event, getData(item, ref))}
					>
						<input
							ref={r => ref = r}
							className={styles.input}
							type="checkbox"
							id={key}
						/>
						<div className={styles.icon}>
							<Icon icon='catalog-checkbox' color={'#4e4c4c'} size={15} />
						</div>
						<p className={styles.link}>{item.value}</p>
					</label>
				</div>
			)
		})
	)
}

FilterStringList.propTypes = {
	alias:     PropTypes.string,
	className: PropTypes.string,
	handler:   PropTypes.func,
	list:      PropTypes.array,
}

FilterStringList.defaultProps = {
	alias:     '',
	className: '',
	handler:   () => null,
	list:      [],
}
