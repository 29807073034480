import appLocaleData from 'react-intl/locale-data/ru'
import ruMessages from '../locales/ru_RU'

const RuLang = {
	messages: {
		...ruMessages,
	},
	locale:   'ru-RU',
	data:     appLocaleData,
}
export default RuLang
