import {
	AppealsSagas,
	CategoriesSagas,
	CitiesSagas,
	CountriesSagas,
	CustomersAddressesSagas,
	CustomersFavoritesSagas,
	CustomersSagas,
	OrdersSagas,
	PaymentSagas,
	ProductsAttachmentsSagas,
	ProductsReviewsSagas,
	SeoSagas,
	SettingsSagas,
	UserSagas,
} from '@kakadu-dev/base-frontend-components'
import AuthSagas from './auth/sagas'
import EmailSagas from './email/sagas'
import StaticPagesSagas from './staticPages/sagas'
import CertificateForm from './certificatesForm/sagas'
import CartSagas from './cart/sagas'
import DiscountCardSagas from './discountCard/sagas'
import ProductsSagas from './products/sagas'
import StylistForm from './stylistForm/sagas'

export default [
	...AuthSagas,
	...UserSagas,
	...CitiesSagas,
	...CountriesSagas,
	...CustomersSagas,
	...CustomersAddressesSagas,
	...CustomersFavoritesSagas,
	...CategoriesSagas,
	...ProductsSagas,
	...CartSagas,
	...ProductsReviewsSagas,
	...ProductsAttachmentsSagas,
	...PaymentSagas,
	...SettingsSagas,
	...SeoSagas,
	...OrdersSagas,
	...AppealsSagas,
	...EmailSagas,
	...CertificateForm,
	...StaticPagesSagas,
	...DiscountCardSagas,
	...StylistForm,
]
