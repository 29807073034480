import { CheckCircleRounded as Success } from '@material-ui/icons'
import { BlankButton } from 'components/global/BlankButton'
import { getTime } from 'helpers/TimeHelper'
import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import styles from './styles.scss'

export const SuccessOrder = ({ id }) => {

	const { push } = useHistory()

	return (
		<div className={styles.container}>
			<div className={styles.orderWrapper}>
				<div className={styles.successIcon}>
					<Success />
				</div>
				<div className={styles.header}>Заказ оформлен успешно</div>
				<div className={styles.title}>Спасибо за покупку!</div>
				<div className={styles.orderInfo}>
					Номер заказа:
					<span>{id}</span>
					<br />
					Оформлен:
					<span>{getTime(new Date())}</span>
				</div>
				<BlankButton
					onClick={() => push('/')}
					title="Перейти на главную"
					className={styles.toMainBtn}
				/>
			</div>
		</div>
	)
}

SuccessOrder.propTypes = {
	id: PropTypes.number,
}
SuccessOrder.defaultProps = {
	id: null,
}
