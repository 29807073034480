import {
	DispatchSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps = () => ({
})

const mapDispatchToProps = {
	getCustomerAddress: DispatchSelector.customersAddresses.list,
	getDiscountCard:    DispatchSelector.discountCard.view,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
