import {
	DispatchSelector,
	StateSelector,
} from '@kakadu-dev/base-frontend-components'

const mapStateToProps    = state => ({
	cartListState:          StateSelector.cart.list(state),
	deleteCartProductState: StateSelector.cart.delete(state),
})
const mapDispatchToProps = {
	getCartList:       DispatchSelector.cart.getList,
	deleteCartProduct: DispatchSelector.cart.delete,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
