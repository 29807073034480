import { connect } from 'react-redux'
import { ConRequisites } from './index'
import {
	mapDispatchToProps,
	mapStateToProps
} from './index.props'

const ConRequisitesInStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ConRequisites)

export { ConRequisitesInStore }
