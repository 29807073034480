/* eslint-disable jsx-a11y/anchor-has-content */
import {
	Customer,
	PaymentCard,
} from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import mastercard from 'assets/images/payment/mastercard.svg'
import visa from 'assets/images/payment/visa.svg'
import { BlankButton } from 'components/global/BlankButton'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useMemo,
	useRef,
} from 'react'
import styles from './styles.scss'

const cardIcons   = { visa, mastercard }
const redirectUrl = `${process.env.REACT_APP_DOMAIN}/basket/checkout`

const setMainQuery = id => DataProvider.buildQuery().addBody({ id })
const addCardQuery = (user_id, redirect_url) => DataProvider.buildQuery().addBody({ user_id, redirect_url })

/**
 * Render list of user cards
 *
 * @param {Object} attributes
 * @param {Object} cardsState
 * @param {Function} getCards
 * @param {Function} addCard
 * @param {Function} setMainCard
 * @param {Object} userState
 * @param {Object} addCardState
 *
 * @return {*}
 * @constructor
 */
export const CardSelection = ({
	attributes,
	cardsState,
	getCards,
	addCard,
	setMainCard,
	userState,
	addCardState,
}) => {
	const linkRef = useRef()

	const cards = PaymentCard.createList(cardsState)
	const user  = Customer.create(userState)

	const userId     = useMemo(() => user.getId(), [user])
	const addCardUrl = useMemo(() => addCardState.result, [addCardState])

	useEffect(() => {
		getCards(addCardQuery(userId, redirectUrl))
	}, [getCards, userId])

	useEffect(() => {
		if (addCardUrl) {
			linkRef.current.click()
		}
	}, [addCardUrl])

	return (
		attributes.paymentMethod === 'online' &&
		<div className={styles.container}>
			<a ref={linkRef} href={addCardUrl} />
			{cards.map(card => (
				<BlankButton
					key={card.getId()}
					onClick={() => setMainCard(setMainQuery(card.getId()))}
					className={[styles.card, card.isPrimary() && styles.active].join(' ')}
				>
					<img src={cardIcons[card.getType()]} alt="card" />
					<div>{card.getCardNumber()}</div>
					<div className={styles.title}>{card.getTitle()}</div>
				</BlankButton>
			))}
			<BlankButton
				title="Добавить карту"
				className={styles.addCardBtn}
				onClick={() => addCard(addCardQuery(userId, redirectUrl))}
			/>
		</div>
	)
}

CardSelection.propTypes = {
	addCard:      PropTypes.func,
	addCardState: PropTypes.object,
	attributes:   PropTypes.object,
	cardsState:   PropTypes.object,
	getCards:     PropTypes.func,
	setMainCard:  PropTypes.func,
	userState:    PropTypes.object,
}

CardSelection.defaultProps = {
	addCard:      () => null,
	addCardState: {},
	attributes:   {},
	cardsState:   {},
	getCards:     () => null,
	setMainCard:  () => null,
	userState:    {},
}
