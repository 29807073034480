import { OnlineGiftCertificatesForm } from 'components/static/onlineGiftCertificatesForm/index'
import { connect } from 'react-redux'
import {
	mapDispatchToProps,
} from './index.props'

const OnlineGiftCertificatesFormStore = connect(
	null,
	mapDispatchToProps,
)(OnlineGiftCertificatesForm)

export {OnlineGiftCertificatesFormStore}
