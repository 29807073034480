import { articlePages } from 'routing/Article'
import { basketPages } from 'routing/Basket'
import { errorPage } from 'routing/Error'
import { marketPages } from 'routing/Market'
import { staticPages } from 'routing/Static'
import { userPages } from 'routing/User'

export default [
	...basketPages,
	...marketPages,
	...articlePages,
	...staticPages,
	...userPages,
	...errorPage,
]
