import { MaterialIcon } from 'components/global/MaterialIcon'
import * as PropTypes from 'prop-types'
import React from 'react'

const ArrowButton = ({ onClick, iconSize, prev, styles }) => {
	const icon = prev ? 'keyboard_arrow_left' : 'keyboard_arrow_right'

	const className = prev
		? [styles.arrow, styles.arrow_prev].join(' ')
		: [styles.arrow, styles.arrow_next].join(' ')

	return (
		<div
			role='button'
			tabIndex='0'
			onClick={onClick}
			onKeyDown={onClick}
			className={className}
		>
			<MaterialIcon icon={icon} size={iconSize} />
		</div>
	)
}

ArrowButton.propTypes = {
	onClick:  PropTypes.func,
	iconSize: PropTypes.number,
	prev:     PropTypes.bool,
	styles:   PropTypes.object.isRequired,
}

ArrowButton.defaultProps = {
	onClick:  () => null,
	iconSize: 32,
	prev:     false,
}

export { ArrowButton }
