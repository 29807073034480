import PropTypes from 'prop-types'
import React, {
	useCallback,
	useMemo,
} from 'react'
import styles from './styles.scss'

/**
 * Render boolean filter block
 *
 * @param {PropertyType} propertyType
 * @param {function} toggle
 *
 * @return {*}
 * @constructor
 */
export const FilterBooleanBlock = ({ propertyType, toggle }) => {
	const [alias, name] = useMemo(() => [propertyType.getAlias(), propertyType.getName()], [propertyType])

	const onChange = useCallback(({ value, pId }, ref) => toggle(null, {
		alias,
		value: `${name}: ${value}`,
		ref,
		id:    [pId],
		type:  'boolean',
	}), [alias, name, toggle])

	return (
		<>
			<p className={styles.title}>
				{propertyType.getName()}
			</p>
			<div className={styles.row}>
				{propertyType.getRawModel().items.map(property => {
					const key = `${property.pId}${property.alias}`
					let ref

					return (
						<div key={key} className={styles.label_wrapper}>
							<label
								className={styles.label}
								htmlFor={key}
							>
								<input
									id={key}
									onChange={() => onChange(property, ref)}
									name={alias}
									className={styles.input}
									ref={r => ref = r}
									type="radio"
								/>

								<div className={styles.count}>
									{property.value}
								</div>
							</label>
						</div>
					)
				})}
			</div>
		</>
	)
}

FilterBooleanBlock.propTypes = {
	propertyType: PropTypes.object,
	toggle:       () => null,
}

FilterBooleanBlock.defaultProps = {
	propertyType: {},
	toggle:       () => null,
}
