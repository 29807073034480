import React from 'react'
import styles from './styles.scss'

/**
 * Render gum loyalty program detail
 *
 *
 * @return {*}
 * @constructor
 */
export const GumDetail = () => (
	<div className={styles.detailWrapper}>
		<div className={styles.detailHeader}>
			<h3>Работает с 27 сентября 2010 года*</h3>
		</div>
		<div className={styles.detailList}>
			<strong>Как получить дисконтную карту ОАО «ГУМ»?</strong>
			<ul>
				<li>
					Дисконтная карта выдается после уплаты ее стоимости (3 руб. 00 коп.) на основании
					заполненной «Анкеты» и обязательной регистрации в общей базе данных владельцев карт.
					<p>Обращаться: Инфоцентр универмага, г. Минск, пр. Независимости,21 (1 этаж)</p>
				</li>
			</ul>
		</div>
		<div className={styles.detailList}>
			<strong>Как использовать дисконтную карту?</strong>
			<ul>
				<li>
					Дисконтная карта предъявляется владельцем на кассе перед оплатой покупки.
					Все суммы покупок, начиная с первой, заносятся на счет дисконтной карты покупателей
					и являются его
					<strong> историей покупок</strong>
					. Исключение составляют покупки акционного
					и уцененного товара, а также товара, приобретаемого в кредит и по картам рассрочки.
				</li>
				<li>
					Скидка предоставляется на товары, розничная цена которых
					<strong> (за 1 шт., 1 м.) </strong>
					свыше 5,0 руб.
				</li>
				<li>
					Определены три уровня скидок. Размер предоставляемой скидки от розничной цены
					соответствует следующим суммам накоплений:
				</li>
			</ul>
			<table className={styles.detailTable}>
				<thead>
					<tr>
						<th>номер уровня</th>
						<th>накопленная сумма покупок, руб.</th>
						<th>размер скидки</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Первый</td>
						<td>100 руб. 00 коп. - 499 руб.99 коп.</td>
						<td>3%</td>
					</tr>
					<tr>
						<td>Второй</td>
						<td>500 руб. 00 коп. – 1 499 руб 99 коп.</td>
						<td>5%</td>
					</tr>
					<tr>
						<td>Третий</td>
						<td>1 500 руб. 00 коп. и выше</td>
						<td>7%</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div className={styles.detailList}>
			<strong>Где действует дисконтная программа?</strong>
			<ul>
				<li>
					Дисконтная программа действует в универмаге в секциях непродовольственных
					товаров (пр.Независимости,21; ул.Ленина 4,6,8).
				</li>
			</ul>
		</div>
		<div className={styles.detailCardImg}>
			<img src={require('./media/gum_card1.jpg')} alt="" />
			<img src={require('./media/gum_card2.jpg')} alt="" />
		</div>
		<div className={styles.detailList}>
			<strong>Какие группы товаров не участвуют в дисконтной программе?</strong>
			<ul>
				<strong>Скидка по дисконтной карте не предоставляется:</strong>
				<li>
					на товары, участие которых в акциях ограничено локальными актами ОАО «ГУМ»,
					в том числе продукция ОАО &quot;Минский часовой завод&quot;, а также холодильники,
					морозильники, стиральные машины;
				</li>
				<li>
					на товары, участвующие в специальных, сезонных акциях;
				</li>
				<li>
					на товары по сниженным ценам в результате уценки;
				</li>
				<li>
					на изделия медицинского назначения и медицинскую технику;
				</li>
				<li>
					на услуги, оказываемые в универмаге;
				</li>
				<li>
					на стоимость дисконтных карт ОАО «ГУМ»;
				</li>
				<li>
					при продаже подарочных сертификатов ОАО «ГУМ»;
				</li>
				<li>
					при продаже товаров в кредит;
				</li>
				<li>
					при продаже товаров по картам «Халва», «Smart карта», «Карта покупок», «Магнит»;
				</li>
				<li>
					на конфискованный товар;
				</li>
				<li>
					при покупке товаров юридическими лицами по безналичному расчету;
				</li>
				<strong>Суммирование нескольких видов скидок не производится.</strong>
			</ul>
		</div>
		<p>
			<strong>Подробности: </strong>
			в Инфоцентре, у работников торгового зала, по тел. 8017-323-10-48, 8029-3045-045
		</p>
		<p>
			<a href={require('./media/poldisc.doc')} target="_blank" rel="noopener noreferrer">
				Положение о дисконтной программе ОАО &quot;ГУМ&quot; &quot;Клуб постоянных покупателей&quot;
			</a>
		</p>
		<p>
			<a href={require('./media/anketad.doc')} target="_blank" rel="noopener noreferrer">
				Анкета владельца дисконтной карты
			</a>
		</p>
		<p>
			* Дисконтные карты старого образца (выданные до 01.09.2010)
			– не действительны и к обслуживанию не принимаются.
		</p>
	</div>
)
