import PropTypes from 'prop-types'
import React from 'react'
import { Spinner } from 'reactstrap'
import styles from './styles.scss'

/**
 * Render button
 *
 * @param {string} title
 * @param {boolean} fetching
 * @param {'green'|'black'|'light'|'grey'} color
 * @param {string} style
 * @param {string} type
 * @param {Object} innerRef
 * @param {Object} children
 * @param {boolean} disabled
 * @param {Object} props
 *
 * @return {*}
 * @constructor
 */
export const Button = ({
	title,
	fetching,
	type,
	color,
	style,
	children,
	disabled,
	innerRef,
	...props
}) => (
	// eslint-disable-next-line react/button-has-type
	<button
		type={type}
		className={[styles.button, styles[color], style, disabled && styles.disabled].join(' ')}
		ref={innerRef}
		disabled={fetching || disabled}
		{...props}
	>
		{children || title}
		{fetching &&
		 <div className={styles.spinner}>
			 <Spinner type="grow" color="light" />
		 </div>}
	</button>
)

Button.propTypes = {
	children: PropTypes.node,
	color:    PropTypes.string,
	disabled: PropTypes.bool,
	fetching: PropTypes.bool,
	innerRef: PropTypes.object,
	style:    PropTypes.string,
	title:    PropTypes.string,
	type:     PropTypes.string,
}

Button.defaultProps = {
	children: null,
	color:    'green',
	disabled: false,
	fetching: false,
	innerRef: null,
	style:    '',
	title:    '',
	type:     'button',
}
