import ComponentHelper from '@kakadu-dev/base-frontend-helpers/helpers/ComponentHelper'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import SSRComponentHelper from '@kakadu-dev/base-frontend-helpers/helpers/SSRComponentHelper'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { PageHeading } from 'components/global'
import {
	About,
	Advantages,
	Brands,
} from 'components/home'
import { HomeSliderInStore } from 'components/home/HomeSlider/index.store'
import { NewsInStore } from 'components/home/News/index.store'
import ProductsBlock from 'components/home/ProductsBlock'
import ErrorHelper from 'helpers/ErrorHelper'
import * as PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { MetaService } from 'services/MetaService'
import {
	COUNT_OF_PRODUCT,
	DISABLE_INITIAL_PROPS,
	HOME_CATEGORIES_IDS,
	IS_PROD,
} from './constants'
import { mapDispatchToProps } from './index.props'
import styles from './styles.scss'

const getProductsQuery = categoryId => DataProvider
	.buildQuery()
	.addBody({
		extraFilter: { categoryId, isAvailable: true },
		query:       {
			expands: ['categories.category.allParents', 'minPrices', 'options', 'isFavorite', 'offers'],
			filter: { isRemoved: false },
			page:    1,
			perPage: COUNT_OF_PRODUCT.HOME,
		},
	}, true)
	.cacheResponse(60 * 5, IS_PROD)
// Enable get meta data
const setMetaInfo      = () => MetaService.setInfo({}, { title: 'Главная' })

const Home = ({
	getProducts,
	productsSate,
	productsSaleState,
	getProductsSale,
	intl,
	isShowPanel,
}) => {
	const productsListsService = StateService.create(productsSate)
	const productsLists        = productsListsService.getList()
	const productsSaleService = StateService.create(productsSaleState)
	const productsSale = productsSaleService.getList()

	const { locale } = intl

	useEffect(() => {
		setMetaInfo()

		const searchQuery = DataProvider.buildQuery().addBody({
			extraFilter: { hasDiscount: 1 },
			query:       {
				expands: ['categories.category.allParents', 'minPrices', 'options', 'isFavorite', 'offers'],
				filter: { isRemoved: false },
				page:    1,
				perPage: COUNT_OF_PRODUCT.HOME,
			},
		}, true)
		getProductsSale(searchQuery)

		if (HOME_CATEGORIES_IDS?.length) {
			const searchQueries = HOME_CATEGORIES_IDS.map(categoryId => getProductsQuery(categoryId))

			ComponentHelper.fetchIfNotExist(productsSate, getProducts, searchQueries)
		}
	}, [getProducts, getProductsSale])

	return (
		<div className={styles.background}>
			<PageHeading hideMainTitle fetching={false} />
			{
				locale === 'be' ? null :
					<>
						{!isShowPanel && <HomeSliderInStore />}
						<Advantages />
						<NewsInStore />
						<ProductsBlock
							isFetching={productsListsService.isFetching() || productsSaleService.isFetching()}
							productsLists={productsLists}
							productsSale={productsSale}
						/>
						<Brands />
					</>
			}
			{!isShowPanel && <About intl={intl} />}
		</div>
	)
}

Home.getInitialProps = SSRComponentHelper.initialProps(async dispatchers => {
	setMetaInfo()

	if (HOME_CATEGORIES_IDS?.length) {
		const { getProducts } = dispatchers
		const searchQueries   = HOME_CATEGORIES_IDS.map(categoryId => getProductsQuery(categoryId))

		try {
			await getProducts(searchQueries)
		} catch (e) {
			ErrorHelper.log(e)
		}
	}

}, mapDispatchToProps, DISABLE_INITIAL_PROPS)

Home.propTypes = {
	getProducts:       PropTypes.func,
	productsSate:      PropTypes.object,
	productsSaleState: PropTypes.object,
	getProductsSale:   PropTypes.func,
	intl:              PropTypes.object,
	isShowPanel:       PropTypes.bool,
}

Home.defaultProps = {
	getProducts:       () => null,
	productsSate:      {},
	productsSaleState: {},
	getProductsSale:   () => null,
	intl:              {},
	isShowPanel:       false,
}

export default Home
