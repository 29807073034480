import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps = state => ({
	userState:        StateSelector.user.getUser(state),
	settingsState:    StateSelector.user.getUserSettings(state),
	addressListState: StateSelector.customersAddresses.list(state),
	checkoutState:    StateSelector.cart.checkout(state),
})

const mapDispatchToProps = {
	getCustomerAddress: DispatchSelector.customersAddresses.list,
	checkout:           DispatchSelector.cart.checkout,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
