import { BreadCrumbs } from 'components/global/BreadCrumbs'
import BreadPlaceholder from 'components/utils/placeholders/breadcrumbs'
import TitlePlaceholder from 'components/utils/placeholders/title'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Container } from 'reactstrap'
import styles from './heading.scss'

export const PageHeading = ({
	title,
	breadcrumbs,
	textCenter,
	hideMainTitle,
	fetching,
	extraBreadcrumbs,
}) => (
	<Container>
		{breadcrumbs && !fetching &&
		 <BreadCrumbs
			 data={breadcrumbs}
			 extraBreadcrumbs={extraBreadcrumbs}
			 title={title}
		 />}

		{!hideMainTitle && title && !fetching &&
		 <h1 className={[styles.title, textCenter ? styles.title_center : ''].join(' ')}>
			 {title}
		 </h1> || <>
			 {!hideMainTitle &&
			  <div className={styles.placeholder}>
				  <BreadPlaceholder />
			  </div>}
			 <div className={hideMainTitle ? styles.none : styles.title_placeholder}>
				 <TitlePlaceholder />
			 </div>
		 </>}
	</Container>
)

PageHeading.propTypes = {
	breadcrumbs:      PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
	fetching:         PropTypes.bool,
	hideMainTitle:    PropTypes.bool,
	extraBreadcrumbs: PropTypes.array,
	textCenter:       PropTypes.bool,
	title:            PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

PageHeading.defaultProps = {
	breadcrumbs:      false,
	fetching:         false,
	hideMainTitle:    false,
	extraBreadcrumbs: [],
	textCenter:       false,
	title:            '',
}
