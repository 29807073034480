import { Slide } from 'components/home/HomeSlider/Slides'
import PropTypes from 'prop-types'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import { images } from './data'
import styles from './styles.scss'

/**
 * Render about page
 *
 * @return {*}
 * @constructor
 */
export const About = ({intl}) => (
	<section className={styles.wrapper}>
		<h1 className={styles.title}>{intl.messages['general.about.title']}</h1>
		<div className={styles.banner}>
			<Carousel
				showThumbs={false}
				showStatus={false}
				showIndicators={false}
				infiniteLoop
				swipeable
				autoPlay
			>
				{images.map(image => <Slide key={image.id} image={image.image} linkTo={image.link} />)}
			</Carousel>
		</div>
		<Container className={styles.content}>
			{intl.messages['general.about.introduction']}
			<br />
			<Link to={intl.locale === 'be' ?
				"by/about-company/ac-history" :
				"/about-company/ac-history"}
			>
				{intl.messages['general.about.read.more']}
			</Link>
		</Container>
	</section>
)

About.propTypes = {
	intl: PropTypes.object,
}

About.defaultProps = {
	intl: {},
}
