import PropTypes from 'prop-types'
import React, {
	useRef,
	useState,
} from 'react'
import styles from './styles.scss'

/**
 * Render number filter block
 *
 * @param {function} toggle
 * @param range
 * @param priceHandle
 * @return {*}
 * @constructor
 */
export const FilterPriceBlock = ({ toggle, range, priceHandle }) => {

	const [showHint, setShowHint] = useState(false)

	const minRangeRef = useRef()
	const maxRangeRef = useRef()

	const showError = () => {
		setShowHint(true)

		setTimeout(() => setShowHint(false), 3000)
	}

	const getCurrentValue = value => {
		let newValue = value

		setShowHint(false)

		if (Number.isNaN(Number(value))) {
			showError()
		}

		if (value[0] === '0' && value.length === 1) {
			newValue += '.'
		}

		if (value < 0) {
			newValue = 0
			showError()
		}

		if (range.min > range.max) {
			showError()
		}

		return newValue
	}

	const onChange = ({ target: { value } }, isMax = false) => {
		const { min: prevMin, max: prevMax } = range

		const min = getCurrentValue(isMax ? prevMin : value)
		const max = getCurrentValue(isMax ? value : prevMax)

		priceHandle({ min, max })
		toggle(null, {
			value:    `По цене: от ${min} до ${max}`,
			alias:    'price',
			type:     'price',
			id:       { 'between': [+min, +max] },
			price:    { min: +min, max: +max },
			callback: () => priceHandle({min: '', max: ''}),
		})
	}

	return (
		<>
			<div className={styles.wrapper}>
				<p className={styles.title}>{'По цене'}</p>
				<div className={styles.range}>
					<div className={[styles.incorrect, showHint && styles.incorrect_active].join(' ')}>
						<p>Введите число в диапазоне</p>
						<p>{`от ${range.min} до ${range.max}`}</p>
					</div>
					<input
						type="text"
						className={styles.min}
						onChange={onChange}
						value={range.min}
						ref={minRangeRef}
						placeholder={'0.00'}
					/>
					<input
						type="text"
						className={styles.max}
						onChange={event => onChange(event, true)}
						value={range.max}
						ref={maxRangeRef}
						placeholder={'0.00'}
					/>
				</div>
			</div>
		</>
	)
}

FilterPriceBlock.propTypes = {
	toggle:       PropTypes.func,
	priceHandle:  PropTypes.func,
	range:        PropTypes.object,
}

FilterPriceBlock.defaultProps = {
	range:        {},
	toggle:       () => null,
	priceHandle:  () => null,
}
