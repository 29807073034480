import { connect } from 'react-redux'
import Home from './index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'
import { injectIntl } from 'react-intl'

export const HomeStore = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home))

