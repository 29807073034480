export const CART_ACTION = {
	LIST: 'LIST_CART_PRODUCT',
	VIEW: 'VIEW_CART_PRODUCT',
	UPDATE: 'UPDATE_CART_PRODUCT',
	CREATE: 'CREATE_CART_PRODUCT',
	DELETE: 'DELETE_CART_PRODUCT',
	DELETE_ALL: 'DELETE_ALL_CART_PRODUCT',
	CHECKOUT: 'CART_CHECKOUT',
	CHECKOUT_TERMINAL: 'CART_CHECKOUT_TERMINAL',
	CREATE_ORDER: 'CART_CREATE_ORDER',
	CREATE_ORDER_TERRMINAL: 'CART_CREATE_ORDER_TERRMINAL',
	START_SESSION_TERMINAL: 'CART_START_SESSION_TERMINAL'
}
