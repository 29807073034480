import { useEffect } from 'react'

const disableScroll = () => {
	const scrollTop  = window.pageYOffset || document.documentElement.scrollTop
	const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

	window.onscroll = () => window.scrollTo(scrollLeft, scrollTop)
}

const enableScroll = () => window.onscroll = () => null

/**
 * Hook to block scrolling
 */
export const useStopScrolling = (isBlockNeeded = true) => {
	useEffect(() => {
		if (isBlockNeeded) {
			disableScroll()
		} else {
			enableScroll()
		}
	}, [isBlockNeeded])
}
