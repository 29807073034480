import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render backdrop
 *
 * @param {boolean} isActive
 * @param {Node} children
 *
 * @return {*}
 * @constructor
 */
export const Backdrop = ({ isActive, children }) => (
	<div className={[styles.checking, isActive && styles.active].join(' ')}>
		<div className={styles.children}>
			{children}
		</div>
	</div>
)

Backdrop.propTypes = {
	children: PropTypes.element,
	isActive: PropTypes.bool,
}

Backdrop.defaultProps = {
	children: null,
	isActive: false,
}
