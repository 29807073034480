import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import Icon from 'assets/icomoon/Icon'
import PropTypes from 'prop-types'
import { BlankButton } from 'components/global/BlankButton'
import React, {
	useCallback,
	useState,
} from 'react'
import { Container } from 'reactstrap'
import styles from './styles.scss'

// eslint-disable-next-line max-len
const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})]?$)/i)

/**
 * Render mail subscribe
 *
 * @return {*}
 * @constructor
 */
export const MailSubscribe = ({ subscribe, intl }) => {
	const [email, setEmail] = useState('')
	const [error, setError] = useState(false)
	const [success, setSuccess] = useState(false)

	const onChangeEmail = useCallback(({target: { value }}) => {
		setEmail(value)
		setError(false)
	}, [])

	const onSubmit = useCallback(event => {
		event.preventDefault()
		if (!pattern.test(email)) {
			setError(true)
		}
		else {
			const searchQuery = DataProvider.buildQuery()
				.addBody({email}, true)
				.setSuccessCallback(() => setSuccess(true))
			subscribe(searchQuery)
		}

	}, [email, subscribe])

	return (
		<section
			className={[
				styles.subscribe,
				success && styles.subscribe_success,
			].join(' ')}
		>
			<Container className={styles.wrapper}>
				<div className={styles.icon}>
					<Icon icon='envelope' color="#247c69" />
				</div>
				<p className={styles.text}>{intl.messages['general.subscribe.title']}</p>
				<p className={[styles.text, styles.text_success].join(' ')}>
					{intl.messages['general.subscribe.success']}
				</p>
				<form onSubmit={onSubmit} className={styles.form}>
					<input
						type="text"
						className={[styles.input, error && styles.error].join(' ')}
						placeholder={intl.messages['general.subscribe.input']}
						onChange={onChangeEmail}
					/>
					<BlankButton
						type="submit"
						title={intl.messages['general.subscribe.button']}
						className={styles.button}
					/>
				</form>
			</Container>
		</section>
	)
}

MailSubscribe.propTypes = {
	subscribe: PropTypes.func,
	intl:      PropTypes.object,
}

MailSubscribe.defaultProps = {
	subscribe: () => null,
	intl:      {},
}

