import React from 'react'
import styles from './styles.scss'

/**
 * Render detail Dabrabit bank credit
 *
 *
 * @return {*}
 * @constructor
 */
export const DabrabitDetail = () => (
	<div className={styles.detailWrapper}>
		<div className={styles.detailListTitle}>Условия приобретения товаров в рассрочку:</div>
		<div className={styles.detailList}>
			<div className={styles.detailListTitle}>Срок рассрочки:</div>
			<ul>
				<li>
					<strong>4 месяца </strong>
					- при покупке
					<strong> непродовольственных товаров на любую сумму.</strong>
				</li>
			</ul>
		</div>
		<div className={styles.detailList}>
			<div className={styles.detailListTitle}>«Смарт карта» не принимается к оплате:</div>
			<ul>
				<li>при продаже подарочных сертификатов ОАО «ГУМ»,</li>
				<li>при продаже дисконтных карт ОАО «ГУМ»,</li>
				<li>при оплате взносов по кредиту ОАО «ГУМ»,</li>
				<li>при продаже конфискованного товара,</li>
				<li>за услуги, оказываемые ОАО «ГУМ».</li>
			</ul>
		</div>
		<p>
			<strong>Скидки </strong>
			на товары при покупке по «Смарт карта»
			<strong> не предоставляются</strong>
			, за исключением специальных акций, в рамках которых предусмотрено предоставление скидок.
		</p>
		<p>
			Товары, приобретаемые по карте «Смарт карта»,
			<strong> не участвуют в дисконтных программах </strong>
			, действующих в ОАО «ГУМ».
		</p>
		<div className={styles.detailList}>
			<div className={styles.detailListTitle}>Адреса магазинов:</div>
			<ul>
				<li>универмаг (пр.Независимости,21),</li>
				<li>салон обуви (ул.Ленина,8),</li>
				<li>салон трикотажа «Mark Formelle» (ул.Ленина,4).</li>
			</ul>
		</div>
	</div>
)
