import { connect } from 'react-redux'
import { Contacts } from './index'
import {
	mapDispatchToProps,
	mapStateToProps
} from './index.props'

const ContactsInStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Contacts)

export { ContactsInStore }
