import { DottedLine } from 'components/global/DottedLine'
import { RadioBtn } from 'components/global/RadioInput'
import * as PropTypes from 'prop-types'
import React from 'react'
import styles from '../BasketDelivery/styles.scss'

/**
 * Render basket payment method
 *
 * @param {Array} methods
 * @param {string} currentMethod
 * @param {Function} toggle
 *
 * @return {*}
 * @constructor
 */
export const PaymentMethod = ({ methods, currentMethod, toggle }) => (
	<div className={styles.box}>
		<DottedLine />
		<p className={styles.title}>Способ оплаты</p>
		<div className={styles.method}>
			{methods.map(method => (
				<RadioBtn
					key={method.value}
					id={method.value}
					name='paymentMethod'
					label={method.label}
					onChange={toggle}
					checked={method.value === currentMethod}
				/>
			))}
		</div>
		<DottedLine />
	</div>
)

PaymentMethod.propTypes = {
	currentMethod: PropTypes.string,
	methods:       PropTypes.array,
	toggle:        PropTypes.func,
}

PaymentMethod.defaultProps = {
	currentMethod: '',
	methods:       [],
	toggle:        () => null,
}
