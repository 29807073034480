import { ArticleCategoryInStore } from 'containers/Article/ArticleCategory/index.store'
import { ArticleItemInStore } from 'containers/Article/ArticleItem/index.store'
import { ArticleListInStore } from 'containers/Article/ArticleList/index.store'
import { ErrorInStore } from 'containers/Static/Error/index.store'
import wrappers from 'routing/wrappers'

const articles = ['stocks', 'buyers', 'for-business'].join('|')
const categories = ['s-in-the-hum', 'ac-news', 's-in-shops-generous', 'fb-shares'].join('|')

export const articlePages = [
	{
		path:      '/stocks/about',
		exact:     true,
		component: wrappers(ErrorInStore),
	},
	{
		path:      `/:article(${articles})/:category(${categories})`,
		exact:     true,
		component: wrappers(ArticleListInStore),
	},
	{
		path:       `/:article(${articles})/:category(${categories})/:type`,
		exact:     true,
		component: wrappers(ArticleCategoryInStore),
	},
	{
		path:      `/:article(${articles})/:category(${categories})/:type/:id`,
		exact:     true,
		component: wrappers(ArticleItemInStore),
	},
]
