export const data = [
	{
		name:  '10_BYN',
		count: 0,
		title: 'Сертификат номиналом 10 рублей',
	},
	{
		name:  '15_BYN',
		count: 0,
		title: 'Сертификат номиналом 15 рублей',
	},
	{
		name:  '20_BYN',
		count: 0,
		title: 'Сертификат номиналом 20 рублей',
	},
	{
		name:  '30_BYN',
		count: 0,
		title: 'Сертификат номиналом 30 рублей',
	},
	{
		name:  '50_BYN',
		count: 0,
		title: 'Сертификат номиналом 50 рублей',
	},
	{
		name:  '100_BYN',
		count: 0,
		title: 'Сертификат номиналом 100 рублей',
	},
	{
		name:  '200_BYN',
		count: 0,
		title: 'Сертификат номиналом 200 рублей',
	},
]
