import { UseTimer } from 'components/global/UseTimer'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render error if payment data is invalid or server problem
 *
 * @return {*}
 * @constructor
 */
export const UserAddCardError = ({ isStart }) => {
	const seconds = UseTimer(isStart, 4)

	return (
		<div className={styles.error}>
			<div>Операция завершилась с ошибкой</div>
			<div>Пожалуйста, повторите попытку через...</div>
			{seconds}
		</div>
	)
}

UserAddCardError.propTypes = {
	isStart: PropTypes.bool,
}

UserAddCardError.defaultProps = {
	isStart: false,
}
