import { CollaplsePoint } from 'components/market/ProductsList/Pagination/CollapsePoint'
import { SimplePoint } from 'components/market/ProductsList/Pagination/SimplePoint'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.scss'

/**
 * Get pagination props
 *
 * @param service
 * @returns {{canPreviousPage: boolean, totalPages: *, canNextPage: boolean, isFetching: *, currentPage: *}}
 */
const getDataFromService = service => {
	const pagination = service.getPagination()

	const currentPage = pagination.getCurrentPage()
	const totalPages  = pagination.getPageCount()

	return {
		currentPage,
		totalPages,
		canNextPage:     currentPage < totalPages,
		canPreviousPage: currentPage > 1,
		isFetching:      service.isFetching,
	}
}
/**
 * Render pagination
 *
 * @param {number} currentPage
 * @param {Object} service
 *
 * @return {*}
 * @constructor
 */
export const Pagination  = ({ goToPage, service }) => {
	const { currentPage, totalPages, canNextPage, canPreviousPage, fetching } = getDataFromService(service)

	/**
	 * Generate array of page points
	 *
	 * @return {Array}
	 */
	const getPoints = () => {
		const totalPoints = []

		for (let i = 1; i <= totalPages; i += 1) {
			totalPoints.push(i)
		}

		if (totalPages > 6) {
			return [1, 2, 3, totalPoints, totalPages - 2, totalPages - 1, totalPages]
		}

		return totalPoints
	}

	return (
		!fetching && totalPages > 1 &&
		<div className={styles.wrapper}>
			<div
				tabIndex={0}
				role={'button'}
				className={[styles.pageItem, styles.prev,
					!canPreviousPage && styles.disabled].join(' ')}
				onClick={() => canPreviousPage && goToPage(currentPage - 1)}
			/>

			{getPoints().map(point => {
				if (typeof point === 'number') {
					return <SimplePoint
						key={point}
						currentPage={currentPage}
						goToPage={goToPage}
						point={point}
					/>
				}

				return <CollaplsePoint
					key={'collapse-point'}
					currentPage={currentPage}
					goToPage={goToPage}
					points={point}
				/>
			})}

			<div
				tabIndex={0}
				role={'button'}
				className={[styles.pageItem, styles.next,
					!canNextPage && styles.disabled].join(' ')}
				onClick={() => canNextPage && goToPage(currentPage + 1)}
			/>

		</div>
	)
}

Pagination.propTypes = {
	service:  PropTypes.object,
	goToPage: PropTypes.func,
}

Pagination.defaultProps = {
	service:  {},
	goToPage: () => null,
}
