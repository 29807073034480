import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import {
	call,
	put,
} from 'redux-saga/effects'
import { StaticPagesApi } from './api'

export default class SagasHelpersArticles {

	static *defaultAction(action) {
		const { payload, type } = action
		const { request, success, error } = RequestActionHelper.getAllActions(type)
		const searchQuery = DataProvider.getSearchQuery(payload)

		try {
			yield put(request(searchQuery))
			const responseStocks = yield call(StaticPagesApi.stocks, searchQuery)
			const responseNews = yield call(StaticPagesApi.news, searchQuery)
			if(!responseStocks.error && !responseNews.error) {
				const response = responseNews
				const { result: newsList } = responseNews
				const { result: stocksList } = responseStocks

				response.result = {
					buyers: {
						'ac-news': {
							event: [],
							collection: []
						},
					},
					stocks: {
						's-in-the-hum': {
							regular: [],
							sale: [],
							gifts: [],
						},
						's-in-shops-generous': {
							generous: [],
						},
					},
					'for-business': {
						'fb-shares': {
							business: [],
						},
					},
				}

				// eslint-disable-next-line array-callback-return
				newsList.map(item => {
					switch(item.groupId){
						case 1:
							response.result.buyers['ac-news'].event.push(item)
							break
						case 2:
							response.result.buyers['ac-news'].collection.push(item)
							break
						default:
					}
				})

				// eslint-disable-next-line array-callback-return
				stocksList.map(item => {
					switch(item.groupId){
						case 3:
							switch (item.typeId){
								case 6:
									response.result.stocks['s-in-the-hum'].regular.push(item)
									break
								case 7:
									response.result.stocks['s-in-the-hum'].sale.push(item)
									break
								case 8:
									response.result.stocks['s-in-the-hum'].gifts.push(item)
									break
								default:
									break
							}

							break
						case 4:
							response.result.stocks['s-in-shops-generous'].generous.push(item)
							break
						case 5:
							response.result['for-business']['fb-shares'].business.push(item)
							break
						default:
							break
					}
				})

				yield put(success(DataProvider.handleResponse(response), searchQuery))
				searchQuery.runSuccessCallback(response)
			}
		}
		catch (e) {
			yield put(error(searchQuery.addReduxRequestParams({
				error: e
			})))
			searchQuery.runErrorCallback(e)
		}
		searchQuery.runCallback()
	}
}
