import { Product } from 'model/Product'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { ProductCardStore } from 'components/market/ProductCard/index.store'
import { ProductCardPlaceholder } from 'components/utils/placeholders/product-card'
import PropTypes from 'prop-types'
import React from 'react'
import { Row } from 'reactstrap'
import styles from './styles.scss'

/**
 * Render products
 *
 * @param {boolean} isFetching
 * @param {Object} productsState
 *
 * @returns {*}
 * @constructor
 */
export const Products = ({ isFetching, productsState }) => {
	const productsService = StateService.create(productsState)
	const productsList    = Product.createList(productsState)

	return (
		!isFetching && productsService.isEmptyList() &&
		<div className={styles.empty}>
			<p>По вашему запросу ничего не найдено.</p>
			<p>Попробуйте изменить критерии поиска...</p>
		</div> ||
		<Row className={styles.wrapper}>
			{!isFetching && productsList.length ? productsList.map(product => (
				<ProductCardStore key={product.getId()} product={product} />
			)) : <ProductCardPlaceholder count={6} />}
		</Row>
	)
}

Products.propTypes = {
	isFetching:    PropTypes.bool,
	productsState: PropTypes.object,
}

Products.defaultProps = {
	isFetching:    false,
	productsState: {},
}
