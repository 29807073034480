import { connect } from 'react-redux'
import { FavoritesList } from './index'
import {
	mapDispatchToProps,
	mapStatesToProps,
} from './index.props'

export const FavoritesListStore = connect(
	mapStatesToProps,
	mapDispatchToProps)
(FavoritesList)
