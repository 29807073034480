import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import _ from 'lodash'
import * as PropTypes from 'prop-types'
import {
	Children,
	cloneElement,
	forwardRef,
	useImperativeHandle,
	useRef,
} from 'react'
import { connect } from 'react-redux'

/**
 * Upload form logo
 *
 * @param {Array.<object>} refs
 * @param {BaseForm} form
 * @param {object} response
 * @param {function} onSuccess
 *
 * @return {undefined}
 */
const submitImages = (refs, form, response, onSuccess) => {
	const uploading = refs.current[0].getImagesInputs().map(async dropzone => {
		if (form.isNew()) {
			await dropzone
				.props
				.djsConfig
				.updatePostUrl(null, form.modelClass.create(response).primaryKey())
		}

		const promise = new Promise((resolve) => {
			if (dropzone.dropzone.getAcceptedFiles().length === 0) {
				resolve()
			}

			dropzone.dropzone.on('queuecomplete', () => {
				setTimeout(() => resolve(), 500)
			})
			dropzone.dropzone.processQueue()
		})

		return promise
	})

	if (uploading && uploading.length > 0) {
		Promise.all(uploading).then(() => onSuccess())
	} else {
		onSuccess()
	}
}

/**
 * Send equals form in one request
 *
 * @param {object} refs
 * @param {function} dispatch
 * @param {function} onSuccess
 *
 * @return {Promise}
 */
const submitEqualForms = (refs, dispatch) => {
	return new Promise((resolve, reject) => {
		let values = {}

		refs.current.map(form => {
			values = {
				...values,
				...form.getValues(true),
			}
			return true
		})

		const form         = refs.current[0].getFormInstance()
		const formDispatch = form.getFormDispatcher()

		if (!formDispatch) {
			return reject()
		}

		const searchQuery = DataProvider
			.buildQuery()
			.addBody(values)
			.setSuccessCallback(response => {
				submitImages(refs, form, response, resolve)
			})
			.setErrorCallback(() => {
				reject()
			})

		dispatch(formDispatch(
			form.isNew() ? searchQuery : {
				id: form.model.primaryKey(),
				searchQuery,
			},
		))

		return null
	})
}

/**
 * Send different form
 *
 * @param {object} refs
 * @param {function} dispatch
 * @param {function} onSuccess
 *
 * @return {boolean}
 */
const submitDifferentForms = (refs, dispatch) => {
	return refs.current.map(ref => {
		return submitEqualForms({
			current: [ref],
		}, dispatch)
	})
}

/**
 * Submit form(s)
 *
 * @param {object} refs
 * @param {function} customSubmit
 * @param {function} dispatch
 * @param {function} onSuccess
 * @param {boolean} isEqualForms
 *
 * @return {boolean}
 */
const submitForm = _.throttle((refs, customSubmit, dispatch, onSuccess, isEqualForms) => {
	if (typeof customSubmit === 'function') {
		if (!customSubmit()) {
			return false
		}
	}

	if (!refs || !refs.current || refs.current.length === 0) {
		return false
	}

	const validation = refs.current.some(form => !form.validate())

	if (validation) {
		return false
	}

	let promises = []

	if (isEqualForms) {
		promises = [submitEqualForms(refs, dispatch)]
	} else {
		promises = submitDifferentForms(refs, dispatch)
	}

	return Promise
		.all(promises)
		.then(() => {
			if (typeof onSuccess === 'function') {
				onSuccess()
			}
		})
		.catch(() => ({}))
}, 1000, { leading: true, trailing: false })

/**
 * Form wrapper with state
 *
 * @param {object} children
 * @param {function} onSubmit
 * @param {function} onSuccess
 * @param {object} state
 * @param {function} dispatch
 * @param {function|object} scrollView
 * @param {boolean} isEqualForms
 * @param {function} submitOnChange
 *
 * @param {function} ref
 *
 * @constructor
 */
const IndependentForm = forwardRef((props, ref) => {
	const {
			  children,
			  onSubmit,
			  onSuccess,
			  state,
			  dispatch,
			  scrollView,
			  isEqualForms,
			  submitOnChange,
		  }         = props
	const formsRefs = useRef([])

	useImperativeHandle(ref, () => ({
		submit: () => submitForm(formsRefs, onSubmit, dispatch, onSuccess, isEqualForms),
	}))

	return (
		Children.map(children, (child, index) => {
			const { ref: existRef, props: { form, requestState } } = child

			if (existRef) {
				formsRefs.current[index] = existRef
			}

			const reqState = form.getFormStateSelector()

			return cloneElement(child, {
				...child.props,
				scrollView,
				...{
					...(!existRef ? {
						ref: r => {
							formsRefs.current[index] = r
						},
					} : {}),
					...(!requestState || !requestState.fetching ? {
						requestState: reqState && reqState(state) || {},
					} : {}),
					...(submitOnChange ? {
						onChangeValue: () => submitForm(formsRefs, onSubmit, dispatch, onSuccess, isEqualForms),
					} : {}),
				},
			})
		})
	)
})

IndependentForm.propTypes = {
	children:       PropTypes.node.isRequired,
	onSubmit:       PropTypes.func,
	onSuccess:      PropTypes.func,
	scrollView:     PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	state:          PropTypes.object,
	dispatch:       PropTypes.func,
	isEqualForms:   PropTypes.bool,
	submitOnChange: PropTypes.bool,
}

IndependentForm.defaultProps = {
	onSuccess:      () => null,
	onSubmit:       () => true,
	state:          {},
	dispatch:       () => null,
	scrollView:     () => null,
	isEqualForms:   true,
	submitOnChange: false,
}

// eslint-disable-next-line react-redux/mapStateToProps-no-store
const mapStateToProps      = state => ({ state })
// eslint-disable-next-line react-redux/prefer-separate-component-file
const IndependentFormStore = connect(mapStateToProps, null, null, { forwardRef: true })(IndependentForm)

export {
	IndependentForm,
	IndependentFormStore,
}
