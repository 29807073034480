/* eslint-disable react-hooks/exhaustive-deps */
import { Customer } from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
// import fbIcon from 'assets/images/auth/fb.svg'
import googleIcon from 'assets/images/auth/google.svg'
import { PageHeading } from 'components/global/PageHeading'
import { FirebaseAuth } from 'components/signIn/FirebaseAuth'
import 'components/signIn/FirebaseUI/style.css'
import { PhoneAuth } from 'components/signIn/PhoneAuth'
import { Terms } from 'components/signIn/Terms'
// import { VkAuth } from 'components/signIn/VkAuth'
import { Backdrop } from 'components/user/Backdrop'
import { LoaderWithTitle } from 'components/user/PaymentLoader'
import {
	// FacebookProvider,
	GoogleProvider,
} from 'configs/authProviders'
import firebase, { getFirebaseuiConfig } from 'configs/firebase'
import { IS_CLIENT } from 'constants'
import { redirectUrl } from 'containers/SignIn/data'
import Fingerprint2 from 'fingerprintjs2'
import * as PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { Container } from 'reactstrap'
import styles from './styles.scss'

let firebaseui = {}

if (IS_CLIENT && firebase.apps.length) {
	firebaseui = require('../../components/signIn/FirebaseUI/firebaseui')
}

// const vkAuth = (request, param, firebaseObj) => {
// 	Fingerprint2.get({}, async components => {
// 		const result = await components.map((item) => item.value)
// 		const finger = await Fingerprint2.x64hash128(result.join(''), 31)
//
// 		if (finger) {
// 			request(finger, param, firebaseObj)
// 		}
// 	})
// }

const getFingerPrint = (provider, token, additionalParams, realProvider, action, callback) => {
	Fingerprint2.get({}, async components => {
		const result = await components.map((item) => item.value)
		const finger = await Fingerprint2.x64hash128(result.join(''), 31)

		if (finger) {
			const searchQuery = DataProvider
				.buildQuery()
				.addBody({ provider, token, params: { browserId: finger, ...additionalParams } })
				.addCustomParams({ realProvider })
				.setCallback(callback)

			action(searchQuery)
		}
	})
}

const init = (userId, signIn, redirect, successCallback) => {
	// if (userId > 0) {
	// 	redirect()
	// }

	if (firebaseui.auth) {
		const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth())
		ui.start('#phone-container', getFirebaseuiConfig(successCallback, 'loader'))

		const url           = window.location.href
		const paramPosition = url.indexOf('code=')

		if (paramPosition === -1) {
			firebase.auth().onAuthStateChanged(async customer => {
				console.log('customer', customer)
				if (customer) {
					const token = await customer.getIdToken()
					getFingerPrint('firebase', token, {}, customer.providerData[0]?.providerId, signIn, redirect)
				}
			})
		} else {
			successCallback()

			getFingerPrint('vkontakte', url.substr(paramPosition + 5), { redirectUrl }, signIn, redirect)
		}
	}
}

export const SignIn = ({ userState, history, signIn }) => {
	const [isLoginFetching, setIsLoginFetching] = useState(false)

	const redirect = useCallback(() => {
		setIsLoginFetching(false)
		history.push('/user/profile')
	}, [history])

	useEffect(() => {
		init(Customer.create(userState).getId(), signIn, redirect, () => setIsLoginFetching(true))
	}, [])

	// const facebookAuth = useCallback(() => {
	// 	setIsLoginFetching(true)
	//
	// 	firebase.auth().signInWithPopup(FacebookProvider).catch(() => setIsLoginFetching(false))
	// }, [])

	const googleAuth = useCallback(() => {
		setIsLoginFetching(true)

		firebase.auth().signInWithPopup(GoogleProvider).catch(() => setIsLoginFetching(false))
	}, [])

	const isAppsExist = useMemo(() => firebase.apps.length, [])

	return (
		<Container className={styles.signIn}>
			<PageHeading
				title="Авторизация"
				breadcrumbs
			/>
			<Backdrop isActive={isLoginFetching}>
				<LoaderWithTitle title="Минуту, пожалуйста..." />
			</Backdrop>
			<div className={styles.wrapper}>
				<p className={styles.header}>Войдите с помощью</p>
				{isAppsExist &&
				 <div className={styles.social}>
					 <FirebaseAuth action={googleAuth} icon={googleIcon} />
					 {/*<FirebaseAuth action={facebookAuth} icon={fbIcon} />*/}
					 {/*<VkAuth onClick={() => setIsLoginFetching(true)} onKeyPress={vkAuth} />*/}
				 </div>}
				<PhoneAuth />
				<Terms />
			</div>
		</Container>
	)
}

SignIn.propTypes = {
	history:   PropTypes.object,
	userState: PropTypes.object,
	signIn:    PropTypes.func,
}

SignIn.defaultProps = {
	history:   {},
	userState: {},
	signIn:    () => null,
}
