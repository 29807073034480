import { PageHeading } from 'components/global/PageHeading'
import { CollapseLink } from 'components/static/CollapseLink'
import styles from 'containers/Article/ArticleItem/styles.scss'
import {
	categories,
	types,
	breadcrumbs
} from 'containers/Article/db'
import React, {
	useEffect,
	useMemo,
} from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useParams } from 'react-router'
import { Container } from 'reactstrap'
import { MetaService } from 'services/MetaService'
import PropTypes from 'prop-types'

const setMetaInfo = title => MetaService.setInfo({}, { title })

const transform = (node, index) => {
	const { attribs, type, name } = node
	if (type === 'tag' && name === 'section') {
		switch (attribs.class) {

			case 'collapse-wrapper':
				return <CollapseLink key={index} node={node} />

			default:
				break
		}
	}
}

/**
 * Render promo detail page
 *
 * @return {*}
 * @constructor
 */
export const ArticleItem = ({ articlesState }) => {
	const { id, category, type, article } = useParams()

	const item = useMemo(() =>
		(articlesState?.result?.[article]?.[category]?.[type] ?? []).find(i => +i.id === +id) ?? null,
	[articlesState, article, category, type, id])

	const title = useMemo(() => item?.title ?? '', [item])

	useEffect(() => {
		setMetaInfo(title)
	}, [title])

	const breadCrumbs = useMemo(() => [
		breadcrumbs[article],
		{ title: categories[category], path: `/${article}/${category}` },
		{ title: types[type], path: `/${article}/${category}/${type}` },
	], [article, category, type])

	return (
		<section className={styles.promo}>
			<PageHeading
				extraBreadcrumbs={breadCrumbs}
				title={title}
				breadcrumbs
			/>
			<Container>
				<div
					className={[styles.promoDetailedWrapper, 'ck-content'].join(' ')}
					// dangerouslySetInnerHTML={{ __html: item?.body }}
				>
					{ ReactHtmlParser(item?.body, { transform }) }
				</div>
			</Container>
		</section>
	)
}

ArticleItem.propTypes = {
	articlesState: PropTypes.object,
}
ArticleItem.defaultProps = {
	articlesState: {},
}
