import { convertNodeToElement } from 'react-html-parser'

const getHtml = node => {
	const { children } = node
	const result = {}
	children.map(el => {
		result[el.attribs.class] = convertNodeToElement(el)
	})
	return result
}


export {
	getHtml,
}
