import { BlankButton } from 'components/global/BlankButton'
import { removeFilter } from 'containers/Market/ProductsList/utils'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

/**
 * Render selected filters list
 *
 * @param {Array} list
 * @param {Function} toggle
 *
 * @returns {*}
 * @constructor
 */
export const SelectedFilters = ({ list, toggle }) => (
	<div className={styles.filtersList}>
		{list.map(filter => (
			<BlankButton
				key={filter.id}
				className={styles.filter_item}
				onClick={event => {
					toggle(event, filter, true)
					removeFilter(filter.ref || filter.callback)
				}}
			>
				{filter.value}
			</BlankButton>
		))}
	</div>
)

SelectedFilters.propTypes = {
	list:   PropTypes.array,
	toggle: PropTypes.func,
}

SelectedFilters.defaultProps = {
	list:   [],
	toggle: () => null,
}
