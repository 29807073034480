export const data = [
	{
		text: 'Молоко',
		id:   1,
	},
	{
		text: 'Хлеб',
		id:   2,
	},
	{
		text: 'Колбаса',
		id:   3,
	},
	{
		text: 'Канцелярия',
		id:   4,
	},
	{
		text: 'Доставка',
		id:   5,
	},
	{
		text: 'Статьи',
		id:   6,
	},
	{
		text: 'Бонусы',
		id:   7,
	},
	{
		text: 'Сыр',
		id:   8,
	},
	{
		text: 'Чеддер',
		id:   9,
	},
	{
		text: 'Бананы',
		id:   10,
	},
	{
		text: 'Соус',
		id:   11,
	},
	{
		text: 'Икра',
		id:   12,
	},
	{
		text: 'Кофе',
		id:   13,
	},
	{
		text: 'Пельмени',
		id:   14,
	},
	{
		text: 'Автомобили',
		id:   15,
	},
	{
		text: 'Кефир',
		id:   16,
	},
	{
		text: 'Белый',
		id:   17,
	},
	{
		text: 'Серый',
		id:   18,
	},
	{
		text: 'Желтый',
		id:   19,
	},
	{
		text: 'Решение',
		id:   20,
	},
	{
		text: 'Рис',
		id:   21,
	},
	{
		text: 'Паста',
		id:   22,
	},
	{
		text: 'Италия',
		id:   23,
	},
	{
		text: 'Листья',
		id:   24,
	},
	{
		text: 'Морепродукты',
		id:   25,
	},
	{
		text: 'Лосось',
		id:   26,
	},
	{
		text: 'Креветки',
		id:   27,
	},
	{
		text: 'Ветчина',
		id:   28,
	},
	{
		text: 'Морковь',
		id:   29,
	},
	{
		text: 'Салаты',
		id:   30,
	},
	{
		text: 'Ризотто',
		id:   31,
	},
	{
		text: 'Горох',
		id:   32,
	},
	{
		text: 'Закуски',
		id:   33,
	},
	{
		text: 'Огурец',
		id:   34,
	},
	{
		text: 'Травы',
		id:   35,
	},
	{
		text: 'Лимон',
		id:   36,
	},
	{
		text: 'Апельсин',
		id:   37,
	},
	{
		text: 'Цитрусы',
		id:   38,
	},
	{
		text: 'Яйца',
		id:   39,
	},
	{
		text: 'Белок',
		id:   40,
	},
	{
		text: 'Гриль',
		id:   41,
	},
	{
		text: 'Пицца',
		id:   42,
	},
	{
		text: 'Тесто',
		id:   43,
	},
	{
		text: 'Мука',
		id:   44,
	},
	{
		text: 'Чеснок',
		id:   45,
	},
	{
		text: 'Пирог',
		id:   46,
	},
	{
		text: 'Шпинат',
		id:   47,
	},
	{
		text: 'Орехи',
		id:   48,
	},
	{
		text: 'Приправа',
		id:   49,
	},
	{
		text: 'Сыпучие',
		id:   50,
	},
	{
		text: 'Паприка',
		id:   51,
	},
	{
		text: 'Масло',
		id:   52,
	},
	{
		text: 'Томаты',
		id:   53,
	},
	{
		text: 'Черри',
		id:   54,
	},
	{
		text: 'Рыба',
		id:   55,
	},
	{
		text: 'Вишня',
		id:   56,
	},
	{
		text: 'Карри',
		id:   57,
	},
	{
		text: 'Лук',
		id:   58,
	},
	{
		text: 'Перец',
		id:   59,
	},
	{
		text: 'Баклажан',
		id:   60,
	},
	{
		text: 'Здоровье',
		id:   61,
	},
	{
		text: 'Альтернатива',
		id:   62,
	},
	{
		text: 'Питание',
		id:   63,
	},
	{
		text: 'Зрение',
		id:   64,
	},
	{
		text: 'Спорт',
		id:   65,
	},
	{
		text: 'Красота',
		id:   66,
	},
	{
		text: 'Сухофрукты',
		id:   67,
	},
	{
		text: 'Цыплята',
		id:   68,
	},
	{
		text: 'Бекон',
		id:   69,
	},
]
