import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

export const mapStateToProps = state => ({
	userState:          StateSelector.user.getUser(state),
	productsSate:       StateSelector.products.homePageLists(state),
	productsSaleState:  StateSelector.products.list(state),
	isShowPanel:        state.visionPanel.isShow,
})

export const mapDispatchToProps = {
	getProducts:     DispatchSelector.products.homePageLists,
	getProductsSale: DispatchSelector.products.list,
}
