import { UncontrolledCollapse } from 'components/global/CollapseText'
import { StaticPageWrapper } from 'components/static/StaticPageWrapper'
import React, { useEffect } from 'react'
import { MetaService } from 'services/MetaService'

const title = 'Наша жизнь'

const setMetaInfo = () => MetaService.setInfo({}, { title })

/**
 * Render purchases page
 *
 * @return {*}
 * @constructor
 */
export const OurLife = () => {
	useEffect(() => {
		setMetaInfo()
	}, [])

	return (
		<StaticPageWrapper title="Наша жизнь" extraBreadcrumbs={[{ title: 'О компании', path: '/about-company' }]}>
			<UncontrolledCollapse
				title="28 января 2021 года состоялась встреча председателя Постоянной комиссии Совета Республики
				Национального собрания Республики Беларусь по экономике"
			>
				<div className="list_text">
					<p>
						28 января 2021 года состоялась встреча председателя Постоянной комиссии Совета Республики
						Национального собрания Республики Беларусь по экономике, бюджету и финансам
						Рунец Татьяны Аркадьевны с трудовым коллективом ОАО «ГУМ».
						На встрече присутствовал Глава администрации Центрального района г.Минска
						Петруша Дмитрий Викторович.
					</p>
					<p>
						В ходе встречи Татьяна Аркадьевна проинформировала собравшихся об итогах
						социально-экономического развития Республики Беларусь за 2020 год и о приоритетах социально-
						экономического развития Республики Беларусь на 2021–2025 годы. Также была доведена
						информация о проведении 6 Всебелорусского народного собрания, которое состоится в
						Республике Беларусь 11-12 февраля 2021 года.
					</p>
					<p>
						В своем выступлении Татьяна Аркадьевна отметила, что, несмотря на внешние вызовы и ограничения,
						нам удалось сохранить внутреннюю стабильность и не допустить обвала экономики.
						Главные достижения – это рост уровня жизни населения, сохранения безопасности и
						суверенитета страны, высокие результаты в области экономики.
					</p>
					<p>
						Встреча прошла в формате диалога, в ходе которого каждый участник мог задать
						интересующие его вопросы. На все поступившие в ходе встречи вопросы были даны ответы.
					</p>
					<div>
						<img src={require('./media/101-1.jpg')} alt="" />
						<img src={require('./media/101-2.jpg')} alt="" />
						<img src={require('./media/101-3.jpg')} alt="" />
						<img src={require('./media/101-4.jpg')} alt="" />
						<img src={require('./media/101-5.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="4 ноября 2020 года состоялся праздничный концерт «В кругу друзей» для работников ОАО «ГУМ»,
				посвященный 69 – летию со дня открытия универмага."
			>
				<div className="list_text">
					<p>
						С приветственным словом к работникам обратился директор ОАО «ГУМ» Крепак Александр Петрович,
						который поблагодарил всех присутствующих за неоценимый вклад в развитие
						и становление универмага, профессионализм, преданность профессии, пожелал здоровья,
						добра, тепла и уюта, любви и заботы близких людей.
					</p>
					<p>
						В праздничном мероприятии принял участие первый заместитель министра антимонопольного
						регулирования и торговли Республики Беларусь Вежновец Иван Валерьевич.
					</p>
					<p>
						После официального открытия начался праздничный концерт.
						Артисты (участники художественной самодеятельности) выступили с душевными
						праздничными номерами, которые стали подарком для всех работников предприятия.
						В их исполнении звучали знаменитые песни, красивые стихи и самые теплые, добрые и
						лучшие пожелания всем работникам универмага!
					</p>
					<p>
						Концерт получился ярким, красочным и подарил незабываемые эмоции настоящего праздника.
					</p>
					<div>
						<img src={require('./media/100-1.jpg')} alt="" />
						<img src={require('./media/100-2.jpg')} alt="" />
					</div>
					<p>
						5 ноября 2020 года, в честь 69-летия со дня открытия универмага, состоялось
						музыкально – развлекательное мероприятие для посетителей универмага с
						участием артистов художественной самодеятельности ОАО «ГУМ».
					</p>
					<p>
						Программа мероприятия включала в себя яркие запоминающиеся музыкальные номера,
						интересные факты из истории универмага и развлекательные моменты.
					</p>
					<p>
						Мероприятие прошло в непринужденной обстановке и веселой атмосфере.
					</p>
					<p>
						Все присутствующие получили заряд бодрости, хорошее настроение и положительные эмоции!
					</p>
					<div>
						<img src={require('./media/100-3.jpg')} alt="" />
						<img src={require('./media/100-4.jpg')} alt="" />
						<img src={require('./media/100-5.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="14 октября 2020 года в актовом зале универмага состоялся праздничный концерт,
				посвященный Дню матери."
			>
				<div className="list_text">
					<p>
						МАМА. Слово, которое сопровождает нас с самого рождения и живет в самой глубине
						сердца и души каждого человека! Мама - это наша сила, неиссякаемый источник добра,
						нравственности и благородства!
					</p>
					<p>
						Сегодня теплые слова и пожелания получают те, кто дарит жизнь, любит,
						воспитывает и направляет по жизни. И что может быть лучше в этот день,
						если не поздравления от самых любимых и родных ….
					</p>
					<p>
						14 октября 2020 года в актовом зале универмага состоялся праздничный концерт, посвященный Дню матери.
					</p>
					<p>
						Трогательное до глубины души мероприятие было наполнено теплыми словами
						благодарности за подаренную жизнь, поздравлениями и пожеланиями,
						любящими улыбками деток и подарками для тех, кого мы с нежностью называем «мама».
					</p>
					<p>
						На протяжении праздничного концерта гостей мероприятия ждали вокальные и
						хореографические номера в исполнении детей и внуков наших сотрудников.
					</p>
					<p>
						В зрительном зале стоя аплодировали юным талантам. А это значит, что концерт
						прошел при полном аншлаге, оставив яркие воспоминания в памяти у всех присутствующих.
						По улыбающимся в зале лицам мам и бабушек было видно, что юные артисты смогли подарить
						им массу радостных и незабываемых моментов и хорошее настроение. Никто из ребят не
						ушел домой без сладкого угощения и подарков.
					</p>
					<p>
						Огромное спасибо всем участникам концерта и дорогим мамочкам, которые не остались в стороне,
						и пришли на концерт, посвященный их празднику!
					</p>
					<div>
						<img src={require('./media/51.jpg')} alt="" />
						<img src={require('./media/52.jpg')} alt="" />
						<img src={require('./media/53.jpg')} alt="" />
						<img src={require('./media/54.jpg')} alt="" />
						<img src={require('./media/55.jpg')} alt="" />
						<img src={require('./media/56.jpg')} alt="" />
						<img src={require('./media/57.jpg')} alt="" />
						<img src={require('./media/58.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="30 сентября 2020 года в ОАО «ГУМ» состоялась выставка - конкурс среди работников
				организации «Гумовские ДОЖИНКИ!»."
			>
				<div className="list_text">
					<p>
						30 сентября 2020 года в ОАО «ГУМ» состоялась выставка - конкурс среди
						работников организации «Гумовские ДОЖИНКИ!».
					</p>
					<p>
						Основной целью проведения творческого конкурса является развитие эстетической
						и духовно- нравственной культуры, реализация творческого потенциала,
						развитие корпоративной культуры, мотивации,
						организация активного досуга работников организации.
					</p>
					<p>
						В творчестве столько открытий, идей – только успевай воплощать да зрителей радовать!
					</p>
					<p>
						Участники конкурса продемонстрировали выращенные своими руками овощи, фрукты и цветы,
						а также блюда и поделки из них. На конкурсе были представлены работы разных направлений:
						цветочная (овощная) композиция, овощ на оригинальность, ценный рецепт,
						декоративно-прикладное искусство из природного материала и многое другое.
						Дары осени прямо с грядок попали на конкурсные столы.
					</p>
					<p>
						Конкурсная комиссия, под руководством директора ОАО «ГУМ» Крепака Александра Петровича,
						объективно оценивала каждую номинацию, с учетом гармоничности цветовой гаммы,
						сочетаемости материалов, степени сложности и мастерства, оригинальности,
						лаконичности в оформлении, художественного уровня.
					</p>
					<p>
						Члены жюри оценивали конкурсантов в следующих номинациях:
						<br/>
						«Чудо – овощ, чудо – фрукт»
						<br/>
						«Чудо заморское»
						<br/>
						«Домашние разносолы»
						<br/>
						«Осенняя (фруктовая, овощная, цветочная) композиция»
						<br/>
						«Рецепт от хозяюшки»
						<br/>
						«Карвинг»
						<br/>
						«Мини - сад»
						<br/>
						«Фабрика здоровья».
					</p>
					<p>
						Комиссии предстоит очень сложный отбор победителей. Все номинанты - мастера с большой буквы.
					</p>
					<p>
						Мы гордимся талантами и достижениями наших работников!
					</p>
					<p>
						Итоги конкурса будут подведены 14 октября 2020 года в 15.00 в актовом зале универмага.
					</p>
					<p>
						От души желаем крепкого здоровья, творческого полета и как можно больше желания
						творить и радовать своими работами.
						<br/>
						Благодарим за добро и тепло сердец.
						<br/>
						Помните - инициативный человек может изменить мир!
					</p>
					<div>

					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="26 сентября 2020 года была организована экскурсия работников и ветеранов
				ОАО «ГУМ» по маршруту Минск-Поставы-Камаи-Лучаи-Голубые озера."
			>
				<div className="list_text">
					<div>
						<img src={require('./media/81.jpg')} alt="" />
						<img src={require('./media/82.jpg')} alt="" />
						<img src={require('./media/83.jpg')} alt="" />
						<img src={require('./media/84.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="16 сентября 2020 года был организован выезд молодых специалистов и
				рабочей молодёжи в загородный клуб «Фестивальный», где были проведены
				культурно-массовые и спортивные мероприятия."
			>
				<div className="list_text">
					<p>
						В мероприятии приняли участие молодые работники, которые
						проявили себя за время работы на предприятии.
					</p>
					<div>
						<img src={require('./media/71.jpg')} alt="" />
						<img src={require('./media/72.jpg')} alt="" />
						<img src={require('./media/74.jpg')} alt="" />
						<img src={require('./media/75.jpg')} alt="" />
						<img src={require('./media/76.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="В Центральном районе г.Минска состоялся районный смотр –
				конкурс на лучшее благоустройство, содержание и оформление объектов
				«Лучшая скамейка -- 2020»."
			>
				<div className="list_text">
					<p>
						В Центральном районе г.Минска состоялся районный смотр – конкурс на лучшее
						благоустройство, содержание и оформление объектов «Лучшая скамейка -- 2020».
					</p>
					<p>
						Цель смотра – конкурса – широкое вовлечение коллективов предприятий и организаций
						в работы по благоустройству, повышение эстетического и архитектурно – художественного
						облика Центрального района г.Минска.
					</p>
					<p>
						Коллектив ОАО «ГУМ» принял участие в смотре – конкурсе «Лучшая скамейка -- 2020».
					</p>
					<div>
						<img src={require('./media/60.jpg')} alt="" />
						<img src={require('./media/61.jpg')} alt="" />
						<img src={require('./media/62.jpg')} alt="" />
						<img src={require('./media/63.jpg')} alt="" />
						<img src={require('./media/64.jpg')} alt="" />
						<img src={require('./media/65.jpg')} alt="" />
						<img src={require('./media/66.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="5 августа 2020 года в актовом зале универмага состоялась встреча заместителя председателя
				Мингорисполкома Трущенко Юрия Владимировича с трудовым коллективом ОАО «ГУМ»."
			>
				<div className="list_text">
					<p>
						5 августа 2020 года в актовом зале универмага состоялась встреча заместителя председателя
						Мингорисполкома Трущенко Юрия Владимировича с трудовым коллективом ОАО «ГУМ».
					</p>
					<p>
						В ходе встречи с трудовым коллективом были разъяснены актуальные вопросы
						социально-экономического развития г.Минска, планы городских властей по
						застройке и благоустройству столицы, возможности и перспективы дальнейшего развития
						предприятий.
					</p>
					<p>
						Трудовой коллектив выразил слова благодарности за проведенную встречу.
					</p>
					<div><img src={require('./media/70.jpg')} alt="" /></div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="24 июля 2020 года в актовом зале универмага состоялся праздничный
				концерт «С праздником, работники Торговли!»."
			>
				<div className="list_text">
					<p>
						24 июля 2020 года в актовом зале универмага состоялся праздничный концерт
						«С праздником, работники Торговли!». Ежегодно этот праздник отмечается в
						последнее воскресенье июля.
					</p>
					<p>
						Приветственное слово для работников трудового коллектива прозвучало из
						уст директора ОАО «ГУМ» Крепака Александра Петровича.
					</p>
					<p>
						– В преддверии праздника хочу поблагодарить каждого работника за огромный вклад
						в развитие отрасли, города и страны. Торговля – одна из немногих отраслей экономики,
						которая выполняет доведенные правительством показатели, продолжает развиваться и радовать
						потребителей широким ассортиментом товаров высокого качества. Мы с вами – сплоченная,
						сильная команда. Крепкого здоровья вам и вашим близким, успехов, профессионального роста,
						достойной заработной платы, улыбок покупателей! Ведь это самый главный и важный подарок
						для нас. В канун профессионального праздника хочется пожелать коллективу финансовой
						устойчивости и стабильности.
					</p>
					<p>
						Александр Петрович также подчеркнул, что работать в торговле очень сложно потому,
						что, приходя на работу, нужно оставить все свои проблемы за порогом предприятия.
						Нужно быть всегда в хорошем настроении, внимательным, честным и профессиональным.
					</p>
					<p>
						Слова благодарности работникам ОАО «ГУМ» и поздравления в их адрес прозвучали от
						заместителя начальника ГУ «Главное управление потребительского рынка Мингорисполкома»
						Натычко Юрия Михайловича и председателя Минской городской организации Белорусского
						профессионального союза работников торговли, потребительской кооперации и предпринимательства
						Кулибаба Алексея Александровича.
					</p>
					<p>
						Приветственные слова сопровождались вручением почетных наград.
					</p>
					<p>
						Подарком же для всех приглашенных стал праздничный концерт!!!
					</p>
					<div>
						<img src={require('./media/41.jpg')} alt="" />
						<img src={require('./media/43.jpg')} alt="" />
						<img src={require('./media/44.jpg')} alt="" />
						<img src={require('./media/45.jpg')} alt="" />
						<img src={require('./media/46.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="17 июля 2020 года в актовом зале универмага состоялась информационная
				встреча депутата Палаты представителей Национального собрания Республики Беларусь Светланы Анатольевны
				Любецкой с трудовым коллективом ОАО «ГУМ»."
			>
				<div className="list_text">
					<p>
						17 июля 2020 года в актовом зале универмага состоялась информационная
						встреча депутата Палаты представителей Национального собрания Республики
						Беларусь Светланы Анатольевны Любецкой с трудовым коллективом ОАО «ГУМ».
					</p>
					<p>
						В своем выступлении Светлана Анатольевна отметила, что уделяет большое
						внимание работе с обращениями граждан, поэтому очень часто бывает в трудовых коллективах.
					</p>
					<p>
						Это эффективная форма общения с людьми, когда ты не просто встречаешься с
						работниками того или иного предприятия, но и видишь, как оно работает, как
						выполняются доведенные показатели, решаются социальные вопросы в коллективе,
						какие здесь условия труда и заработная плата, как работает руководство и местная
						власть, насколько она отзывчиво и конструктивно реагирует на запросы общества и
						людей.
					</p>
					<p>
						В ходе встречи обсуждались самые разные вопросы, характерные для сферы торговли,
						в том числе в области трудового законодательства, а также болезненные – о хищении товаров,
						«потребительском экстремизме».
					</p>
					<p>
						В этом свете остановились на работе Парламента над проектами Кодекса Республики
						Беларусь об административные правонарушениях и Процессуально-исполнительного кодекса
						Республики Беларусь об административных правонарушениях.
					</p>
					<p>
						В стране уделяется особое внимание вопросу обеспечения стабильности законодательства
						и повышения качества принимаемых законов. Грамотное использование как уже существующих,
						так появляющихся механизмов правотворчества позволит вывести процесс подготовки
						законопроектов на новый уровень и обеспечить стабильность нашей национальной системы
						законодательства, -- отметила Светлана Анатольевна.
					</p>
					<p>
						Трудовой коллектив выразил слова благодарности за проведенную встречу.
					</p>
					<div>
						<img src={require('./media/34.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="3 июля 2020 года в Минске состоялась
				торжественная церемония возложения цветов на площади Победы."
			>
				<div className="list_text">
					<p>
						3 июля 2020 года в Минске состоялась торжественная церемония возложения цветов на площади Победы.
					</p>
					<p>
						Делегация Центрального района города Минска во главе с руководством
						администрации района приняла участие в патриотическом шествии «Беларусь помнит!».
					</p>
					<p>
						В праздничной колонне делегации шествовали ветераны, депутаты Палаты
						представителей Национального собрания Республики Беларусь и Минского городского
						Совета депутатов, представители общественных организаций и политических партий,
						трудовые коллективы предприятий, организаций и учреждений района.
					</p>
					<p>
						На площади Победы состоялась церемония возложения венков и цветов к подножию
						монумента Победы, делегация Центрального района также возложила цветы от имени жителей и
						трудящихся района. Присутствующие почтили память погибших в годы Великой Отечественной
						войны минутой молчания.
					</p>
					<p>
						Желаем нашей родной Беларуси мира и процветания.
					</p>
					<p>
						Белорусскому народу, всем нам - здоровья, счастья и благополучия.
					</p>
					<p>
						С праздником вас, дорогие друзья!
					</p>
					<p>
						С Днем Независимости!
					</p>
					<div><img src={require('./media/33.jpg')} alt="" /></div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="Коллектив ОАО «ГУМ» выражает искреннюю благодарность медицинским работникам за их труд!!!"
			>
				<div className="list_text">
					<p>
						В это непростое время как никогда важно поддержать тех, кто ежедневно и самоотверженно
						заботится о здоровье людей, работает в больницах и поликлиниках, занимается профилактикой,
						лечением и диагностикой коронавирусной инфекции.
					</p>
					<p>
						Именно медики сейчас оказались на переднем крае общемировой борьбы с опасным, более того,
						невероятно коварным противником. Без того напряжённые будни врачей сегодня превратились в
						настоящую битву. И цена слишком высока, а риск для каждого просто огромен. Но никто не намерен
						отступать.
					</p>
					<p>

						17 апреля, в честь православного праздника Пасхи, представители трудового коллектива ОАО
						«ГУМ» вручили пасхальные куличи сотрудникам УЗ «2-я городская клиническая больница» и УЗ
						«3-я городская клиническая больница им. Е.В.Клумова».

					</p>
					<p>
						Коллектив ОАО «ГУМ» выражает искреннюю благодарность медицинским работникам за их труд!!!
					</p>
					<p>
						Поздравляем всех сотрудников учреждений здравоохранения с наступающим праздником Пасхи!
					</p>
					<p>
						Поэтому еще раз – спасибо вам, дорогие доктора, фельдшеры, медсестры, водители скорых –
						все, кто сегодня, сейчас помогает справиться с вирусом.
					</p>
					<div>
						<img src={require('./media/1994925263645843_130.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="19 марта 2020 года в актовом зале универмага прошел Единый день информирования."
			>
				<div className="list_text">
					<p>
						19 марта 2020 года в актовом зале универмага прошел Единый день информирования.
					</p>
					<p>

						<ins>Тема Дня информирования</ins>
						: «Социально-экономическое состояние регионов и перспективы их развития».

					</p>
					<p>
						Устойчивое региональное развитие — одно из приоритетных направлений деятельности
						руководства страны. Такое пристальное внимание к политике регионального развития неслучайно,
						т.к. от него зависит благополучие всей республики в целом и каждого гражданина в отдельности.
						Помимо повышения доходов населения целями социально-экономического развития регионов
						является улучшение качества образования, здравоохранения и т. д.
					</p>
					<p>
						Коллективу организации была представлена информация о социально-экономическом состоянии
						регионов, динамике валового регионального продукта в 2019 году касательно промышленности,
						сельского хозяйства, жилищного строительства, торговли, инвестиционной деятельности,
						предпринимательской и деловой активности, а также о перспективах развития регионов.
					</p>
					<p>
						В рамках проведения Единого дня информирования была организована встреча трудового коллектива
						с и.о. председателя Центральной районной организации Белорусского общества Красного Креста
						Штанюк Еленой Николаевной.
					</p>
					<p>
						Трудовой коллектив выразил слова благодарности за проведенную встречу.
					</p>
					<div>
						<img src={require('./media/1994925263645843_130.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="6 марта 2020 года, в преддверии Международного женского дня, для представительниц прекрасной
				половины человечества в актовом зале универмага состоялся большой праздничный концерт."
			>
				<div className="list_text">
					<p>
						Международный женский день — это праздник весны и внимания к женщине, когда представители
						сильной половины человечества могут еще раз порадовать своих любимых и родных женщин
						подарками и заботой.
					</p>
					<p>
						Именно весной, когда пробуждается природа, мы празднуем 8 Марта – праздник наших
						замечательных женщин и самой жизни. Все знают, что сила жизни в любви: любви к детям,
						к родным и близким, к своему делу. И именно женщине сила любви дана в полной мере!!!
					</p>
					<p>
						6 марта 2020 года, в преддверии Международного женского дня, для представительниц прекрасной
						половины человечества в актовом зале универмага состоялся большой праздничный концерт.
					</p>
					<p>
						Весь праздник был признанием в любви женщинам, со сцены не раз звучали слова благодарности,
						поздравлений и пожеланий, адресованных тем, кто наполняет нашу жизнь своим теплом, красотой
						и заботой. Все, кому сегодня посчастливилось побывать на праздничном концерте, получили заряд
						радости, любви и весеннего настроения.
					</p>
					<div>
						<img src={require('./media/24.jpg')} alt="" />
						<img src={require('./media/25.jpg')} alt="" />
						<img src={require('./media/26.jpg')} alt="" />
						<img src={require('./media/27.jpg')} alt="" />
						<img src={require('./media/28.jpg')} alt="" />
						<img src={require('./media/29.jpg')} alt="" />
						<img src={require('./media/30.jpg')} alt="" />
						<img src={require('./media/32.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="26 февраля 2020 года в актовом зале универмага состоялся кулинарный
				конкурс среди работников организации «Пироги – ватрушки, плюшки да пампушки»."
			>
				<div className="list_text">
					<p>
						26 февраля 2020 года, в преддверии 8 Марта – Международного женского дня,
						в актовом зале универмага состоялся кулинарный конкурс среди работников организации
						«Пироги – ватрушки, плюшки да пампушки».
					</p>
					<p>
						Основной целью проведения кулинарного конкурса является развитие эстетической и духовно-
						нравственной культуры, реализация творческого потенциала, развитие корпоративной культуры,
						мотивации, организация активного досуга работников организации.
					</p>
					<p>
						Конкурсная комиссия, под руководством заместителя директора по идеологической, кадровой
						работе и социальным вопросам Ульянцевой Натальи Николаевны, объективно оценивала каждую
						номинацию с учетом вкусовых качеств, сочетаемости продуктов, степени сложности и мастерства,
						оригинальности, лаконичности в оформлении, художественного уровня презентации блюд.
					</p>
					<p>
						Члены жюри оценивали конкурсантов в следующих номинациях:
					</p>
					<ul>
						<li>«Самое оригинальное оформление пирога»</li>
						<li>«Самое оригинальное оформление булочек»</li>
						<li>«Самый вкусный пирог с начинкой»</li>
						<li>«Самый «сложный» пирог»</li>
						<li>«Фантазия на тему плюшки да ватрушки»</li>
						<li>«Самые аппетитные рогалики»</li>
						<li>«За сохранение белорусских кулинарных традиций»</li>
						<li>«Самый широкий ассортимент выпечки»</li>
						<li>«Мои любимые булочки»</li>
						<li>«Самый молодой участник конкурса» и др.</li>
					</ul>
					<p>
						Комиссии предстоит очень сложный отбор победителей. Все номинанты - мастера с большой буквы.
					</p>
					<p>
						Мы гордимся талантами и достижениями наших работников!
					</p>
					<p>
						Итоги конкурса будут подведены 04 марта 2020 года в 14.00 в актовом зале универмага.
					</p>
					<p>
						Мы искренне хотим поблагодарить за участие в конкурсе всех наших работников.
					</p>
					<p>
						От души желаем Вам крепкого здоровья, творческого полета и как можно больше
						желания творить и радовать своими работами!!!
					</p>
					<div>
						<img src={require('./media/8.jpg')} alt="" />
						<img src={require('./media/9.jpg')} alt="" />
						<img src={require('./media/10.jpg')} alt="" />
						<img src={require('./media/11.jpg')} alt="" />
						<img src={require('./media/12.jpg')} alt="" />
						<img src={require('./media/13.jpg')} alt="" />
						<img src={require('./media/14.jpg')} alt="" />
						<img src={require('./media/15.jpg')} alt="" />
						<img src={require('./media/16.jpg')} alt="" />
						<img src={require('./media/17.jpg')} alt="" />
						<img src={require('./media/18.jpg')} alt="" />
						<img src={require('./media/19.jpg')} alt="" />
						<img src={require('./media/20.jpg')} alt="" />
						<img src={require('./media/21.jpg')} alt="" />
						<img src={require('./media/22.jpg')} alt="" />
						<img src={require('./media/23.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="20 февраля 2020 года в актовом зале универмага прошел Единый день информирования."
			>
				<div className=" list_text">
					<p>
						20 февраля 2020 года в актовом зале универмага прошел Единый день информирования.
					</p>
					<p>

						<ins>Тема Дня информирования</ins>
						: «Транспортный комплекс Республики Беларусь: состояние и перспективы развития».

					</p>
					<p>
						В рамках проведения Единого дня информирования была организована встреча
						трудового коллектива с начальником управления жилищной политики Мингорисполкома
						Лукашевич Еленой Леонидовной и начальником отдела жилищной политики администрации
						Центрального района г.Минска Сиваковой – Трубечковой Натальей Сергеевной.
					</p>
					<p>
						Запланированные встречи по традиции прошли в рамках дружеской атмосферы и открытого диалога.
					</p>
					<div>
						<img src={require('./media/5.jpg')} alt="" />
						<img src={require('./media/6.jpg')} alt="" />
						<img src={require('./media/7.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="19 февраля 2020 года в актовом зале универмага состоялось собрание трудового коллектива."
			>
				<div className=" list_text">
					<p>
						19 февраля 2020 года в актовом зале универмага состоялось собрание трудового коллектива,
						на котором подвели итоги конкурса «Лучший работник ОАО «ГУМ» по итогам января месяца.
					</p>
					<p>

						В соответствии с Положением ОАО «ГУМ» о порядке и условиях проведения конкурса на звание
						«Лучший работник», протоколом Комиссии по качеству труда от 04.02.2020, по согласованию с
						первичной профсоюзной организацией Лучшими работниками признаны:Полянина Оксана Николаевна
						– продавец–консультант 7 разряда секции № 108 «Обувь»Жуковская Наталия Евгеньевна – кладовщик
						5 разряда склада № 4 «Белье, чулочно – носочные изделия»Макарова Вероника Сергеевна – кондитер
						5 разряда столовойЧерник Владимир Ростиславович -- грузчик магазина № 3 «Щедрый».

					</p>
					<p>
						Директор ОАО «ГУМ» Приступа Светлана Николаевна поздравила победителей конкурса и
						поблагодарила работников, которые по праву заслужили уважение за свой честный и благородный
						труд.
					</p>
					<p>
						Поздравляем!!!
					</p>
					<div>
						<img src={require('./media/3.jpg')} alt="" />
						<img src={require('./media/4.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="14 февраля 2020 года состоялось музыкально – развлекательное мероприятие «День Святого Валентина»."
			>
				<div className="list_text">
					<p>
						14 февраля 2020 года состоялось музыкально – развлекательное мероприятие «День Святого
						Валентина» для гостей универмага с участием артистов художественной самодеятельности ОАО
						«ГУМ».
					</p>
					<p>
						<strong>День Святого Валентина – день</strong>
						, когда можно проявить свои самые нежные чувства любому человеку: родному или просто
						знакомому, к которому испытываешь симпатию. Не случайно символом дня Святого
						Валентина является сердечко -- «валентинка».
					</p>
					<p>
						Посетители универмага познакомились с историей возникновения праздника
						Святого Валентина, узнали о традициях, которые существуют в разных странах в этот день.
					</p>
					<p>
						Мероприятие прошло в непринужденной и веселой атмосфере.
					</p>
					<div>
						<img src={require('./media/1.jpg')} alt="" />
						<img src={require('./media/2.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse title="19 декабря 2019 года в актовом зале универмага прошел Единый день информирования.">
				<div className="list_text">
					<p>
						<ins>Тема Дня информирования:</ins>
						«Традиции суверенной Беларуси».
					</p>
					<p>
						Почти 30 лет Республика Беларусь существует на политической карте мира. За эти годы наша
						страна состоялась как суверенная и независимая держава. Была принята Конституция страны –
						основа белорусской государственности, введен институт президентства как гарант мира,
						стабильности и безопасности, выстроена четкая и слаженная система органов государственной
						власти.
					</p>
					<p>
						Современная Республика Беларусь уверенно идет собственным путем развития. И сегодня у нас
						есть главное – мирная, стабильная, красивая и независимая страна. Есть героическая история,
						которой мы гордимся, богатое культурное, духовное наследие, традиции.
					</p>
					<p>
						Президент Республики Беларусь А.Г.Лукашенко в ходе ежегодного Послания к белорусскому
						народу и Национальному собранию Республики Беларусь подчеркнул: «Сегодня, когда извне
						навязываются некие усредненные ценности, порождающие безвкусицу, бездуховность, для нас очень
						важно показать это богатство, сохранить свои идеалы, чувство собственного достоинства,
						а значит – сохранить силу страны, ее независимость. Мы должны сделать все для того, чтобы
						сберечь и приумножить наше культурное наследие, воспитать на нем новые поколения».
					</p>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="14 декабря 2019 года в актовом зале универмага для детей сотрудников ОАО «ГУМ»
				состоялось новогоднее музыкально-театрализованное представление «Новогодние приключения
				Маши и Вити»."
			>
				<div className="list_text">
					<p>
						14 декабря 2019 года в актовом зале универмага для детей сотрудников ОАО «ГУМ» состоялось
						новогоднее музыкально-театрализованное представление «Новогодние приключения Маши и Вити».

					</p>

					<p>
						В мероприятии приняли участие ребята театральной студии «Созвездие» Центра дополнительного
						образования детей и молодежи «Светоч». Художественный руководитель – Анастасия Ашихмина.

					</p>
					<p>
						Перед маленькими зрителями развернулось красочное представление с участием Деда Мороза,
						Снегурочки и других сказочных персонажей, которые очень порадовали не только детей, но и их
						родителей.

					</p>
					<p>
						Ребята весело наблюдали за новогодней сказкой и сами с удовольствием принимали участие в
						занимательным играх и веселых конкурсах, предложенных волшебными персонажами. Все без
						исключения поддались обаянию сказочных персонажей.

					</p>
					<p>
						Яркие новогодние декорации, масса красочных и необычных световых эффектов, весёлая музыкальная
						программа и танцы возле ёлки, а также современный и необычный сценарий всего торжества ожидал
						всех гостей мероприятия.

					</p>
					<p>
						Праздник получился искренним и по-домашнему теплым.

					</p>
					<div>
						<img src={require('./media/1(1).jpg')} alt="" />
						<img src={require('./media/2(1).jpg')} alt="" />
						<img src={require('./media/3(1).jpg')} alt="" />
						<img src={require('./media/4(1).jpg')} alt="" />
						<img src={require('./media/5(1).jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="5 ноября 2019 года, в честь 68-летия со дня открытия универмага,
			состоялось музыкально – развлекательное мероприятие для посетителей универмага с участием артистов
			 художественной самодеятельности ОАО «ГУМ»."
			>
				<div className="list_text">
					<p>
						5 ноября 2019 года, в честь 68-летия со дня открытия универмага, состоялось
						музыкально – развлекательное мероприятие для посетителей универмага с участием
						артистов художественной самодеятельности ОАО «ГУМ».

					</p>
					<p>
						Программа мероприятия включала в себя яркие запоминающиеся музыкальные номера,
						интересные факты из истории универмага и развлекательные моменты.

					</p>
					<p>
						Мероприятие прошло в непринужденной обстановке и веселой атмосфере.
					</p>
					<p>
						Все присутствующие получили заряд бодрости, хорошее настроение и положительные эмоции!
					</p>
					<div>
						<img src={require('./media/6(1).jpg')} alt="" />
						<img src={require('./media/7(1).jpg')} alt="" />
						<img src={require('./media/8(1).jpg')} alt="" />
						<img src={require('./media/9(1).jpg')} alt="" />
						<img src={require('./media/10(1).jpg')} alt="" />
						<img src={require('./media/11(1).jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="4 ноября 2019 года состоялся праздничный концерт «В кругу друзей» для работников
				ОАО «ГУМ», посвященный 68 – летию со дня открытия универмага."
			>
				<div className="list_text">
					<p>
						4 ноября 2019 года состоялся праздничный концерт «В кругу друзей» для работников ОАО «ГУМ»,
						посвященный 68 – летию со дня открытия универмага.

					</p>
					<p>
						С приветственным словом к работникам обратилась директор ОАО «ГУМ» Приступа Светлана
						Николаевна, которая поблагодарила всех присутствующих за неоценимый вклад в развитие и
						становление универмага, профессионализм, преданность профессии, пожелала здоровья, добра,
						тепла и уюта, любви и заботы близких людей.

					</p>
					<p>
						В праздничном мероприятии приняли участие заместитель начальника ГУ «Главное управление
						потребительского рынка Мингорисполкома» Натычко Юрий Михайлович и заместитель главы
						администрации Центрального района города Минска Евсеев Александр Владимирович.

					</p>
					<p>
						После официального открытия начался праздничный концерт. Артисты (участники художественной
						самодеятельности) выступили с душевными праздничными номерами, которые стали подарком для
						всех работников предприятия. В их исполнении звучали знаменитые песни, красивые стихи и самые
						теплые, добрые и лучшие пожелания всем работникам универмага!

					</p>
					<p>
						Концерт получился ярким, красочным и подарил незабываемые эмоции настоящего праздника.
					</p>
					<div>
						<img src={require('./media/1(2).jpg')} alt="" />
						<img src={require('./media/2(2).jpg')} alt="" />
						<img src={require('./media/3(2).jpg')} alt="" />
						<img src={require('./media/4(2).jpg')} alt="" />
						<img src={require('./media/5(2).jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="23 октября 2019 года в актовом зале универмага состоялась отчетно – выборная
				профсоюзная конференция первичной профсоюзной организации открытого акционерного общества «ГУМ»."
			>
				<div className="list_text">
					<p>
						23 октября 2019 года в актовом зале универмага состоялась отчетно – выборная профсоюзная
						конференция первичной профсоюзной организации открытого акционерного общества «ГУМ» на
						которой присутствовало 106 делегатов.
					</p>
					<p>
						В ходе конференции были рассмотрены актуальные вопросы деятельности первичной организации
						по обеспечению трудовых прав и интересов работников, развитию социального партнерства,
						поддержанию безопасных условий труда, пропаганде здорового образа жизни.
					</p>
					<p>
						В профсоюзной конференции приняли участие: Кулибаба Алексей Александрович, председатель
						Минской городской организации Белорусского профсоюза работников торговли, потребительской
						кооперации и предпринимательства и Блажевская Светлана Сергеевна, председатель Центрального
						районного города Минска объединения организаций профсоюзов.
					</p>
					<p>
						С отчетным докладом о проделанной работе в период с февраля 2015 года по сентябрь 2019 года
						выступила председатель первичной профсоюзной организации ОАО «ГУМ» Курс Ирина Нарбертовна. Она
						отметила, что за данный период времени был выполнен существенный объем работы по различным
						направлениям в рамках комиссий профсоюзного комитета, начиная от общественного контроля за
						охраной труда, и, заканчивая комиссией по культурно-массовой работе. Проводимая профсоюзной
						организацией многоплановая работа направлена на защиту трудовых и социально- экономических
						прав и законных интересов членов профсоюза. За отчетный период значительная работа проделана
						в плане укрепления материальной базы организации, обучению профсоюзного актива, охране труда,
						развитию инициативы и творчества членов коллектива.
					</p>
					<p>
						Ирина Нарбертовна поблагодарила профсоюзных активистов за работу и подчеркнула, что профсоюзы
						заинтересованы в том, чтобы в каждом трудовом коллективе вырабатывались правильные подходы и
						принимались обдуманные решения, касающиеся социальной защиты работников.
					</p>
					<p>
						Отчет о работе контрольно-ревизионной комиссии сделала Криштофор Ольга Анатольевна,
						председатель ревизионной комиссии.
					</p>
					<p>
						Содокладчиками к основному докладу выступили: Пилецкий Игорь Степанович, ведущий инженер
						по охране труда, Воронцова Наталья Георгиевна, инструктор – методист по физкультурно –
						оздоровительной работе 1 категории.
					</p>
					<p>
						Выступающими была дана высокая оценка работы первичной профсоюзной организации в отчетном
						периоде и предложены новые конструктивные подходы в решении поставленных задач и
						совершенствованию социального партнерства.
					</p>
					<p>
						После выступлений прошли выборы председателя и нового состава первичной профсоюзной
						организации, ревизионной комиссии.
					</p>
					<p>
						Председателем первичной профсоюзной организации ОАО «ГУМ» единогласно открытым
						голосованием вновь избрана Курс Ирина Нарбертовна.
					</p>
					<p>
						В принятом по итогам конференции постановлении работа первичной профсоюзной
						организации была признана удовлетворительной и определены задачи и приоритеты на
						ближайшее будущее.
					</p>
					<p>
						Конференция прошла на высоком организационном уровне.
					</p>
					<div>
						<img src={require('./media/01.jpg')} alt="" />
						<img src={require('./media/02.jpg')} alt="" />
						<img src={require('./media/03.jpg')} alt="" />
						<img src={require('./media/04.jpg')} alt="" />
						<img src={require('./media/05.jpg')} alt="" />
						<img src={require('./media/06.jpg')} alt="" />
						<img src={require('./media/07.jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="14 октября 2019 года в актовом зале универмага состоялся
				праздничный концерт, посвященный Дню матери."
			>
				<div className="list_text">
					<p>
						Как важно вовремя сказать любимому человеку, что вы его любите и цените, как много он для вас
						значит, особенно, если этот человек Ваша мама!
					</p>
					<p>
						Знаете ли вы, что есть специальный день в году, Международный праздник – День мамы!
					</p>
					<p>
						14 октября 2019 года в актовом зале универмага состоялся праздничный концерт, посвященный
						Дню матери. Трогательное до глубины души мероприятие было наполнено теплыми словами
						благодарности за подаренную жизнь, поздравлениями и пожеланиями, любящими улыбками деток и
						подарками для тех, кого мы с нежностью называем «мама».
					</p>
					<p>
						С приветственным словом ко всем присутствующим обратилась Приступа Светлана Николаевна,
						директор ОАО «ГУМ». В своем выступлении она отметила, что есть много прекрасных праздников,
						но нет сердечнее и светлее, чем праздник, посвящённый нашим мамам. С рождения ребёнка,
						первого его шага именно они находятся рядом с ним. Мамы помогают детям идти по жизни,
						поддерживают в минуты слабости, они гордятся их достижениями. Быть мамой – это ежедневный
						труд, требующий терпения и ответственности.
					</p>
					<p>
						На протяжении праздничного концерта гостей мероприятия ждали вокальные и хореографические
						номера в исполнении детей и внуков наших сотрудников.
					</p>
					<p>
						В зрительном зале стоя аплодировали юным талантам. А это значит, что концерт прошел при
						полном аншлаге, оставив яркие воспоминания в памяти у всех присутствующих. По улыбающимся в
						зале лицам мам и бабушек было видно, что юные артисты смогли подарить им массу радостных и
						незабываемых моментов и хорошее настроение.
					</p>
					<img src={require('./media/01(1).jpg')} alt="" />
					<img src={require('./media/02(1).jpg')} alt="" />
					<img src={require('./media/03(1).jpg')} alt="" />
					<img src={require('./media/04(1).jpg')} alt="" />
					<img src={require('./media/05(1).jpg')} alt="" />
					<img src={require('./media/06(1).jpg')} alt="" />
					<img src={require('./media/07(1).jpg')} alt="" />
					<img src={require('./media/08.jpg')} alt="" />
					<img src={require('./media/09.jpg')} alt="" />
					<img src={require('./media/10(2).jpg')} alt="" />
					<img src={require('./media/11(2).jpg')} alt="" />
					<img src={require('./media/12(1).jpg')} alt="" />
					<img src={require('./media/13(1).jpg')} alt="" />
					<img src={require('./media/14(1).jpg')} alt="" />
					<img src={require('./media/15(1).jpg')} alt="" />
					<img src={require('./media/16(1).jpg')} alt="" />

					<p>
						С целью духовно-нравственного воспитания детей и молодёжи, воспитания у подрастающего
						поколения уважительного отношения к женщине-матери, укрепления семейных традиций, в рамках
						мероприятий, посвящённых Дню матери, в ОАО «ГУМ» состоялся творческий конкурс детских работ.
					</p>
					<p>
						В фойе актового зала универмага была организована выставка работ детей и внуков сотрудников
						организации.
					</p>
					<p>

						Детские работы оценивались в трех номинациях:1) конкурс детских рисунков «Это мамочка моя!»2)
						конкурс поделок, приуроченных ко Дню матери3) конкурс презентаций: «Мамы разные нужны, мамы
						всякие важны».

					</p>
					<p>
						В конкурсе приняли участие 18 детей сотрудников организации. На праздничном концерте были
						подведены итоги творческого конкурса. Никто из ребят не ушел домой без дипломов и подарков.
					</p>
					<p>
						В ходе награждения в зале царила праздничная атмосфера.
					</p>
					<img src={require('./media/17(1).jpg')} alt="" />
					<img src={require('./media/18(1).jpg')} alt="" />
					<img src={require('./media/19(1).jpg')} alt="" />
					<img src={require('./media/20(1).jpg')} alt="" />
					<img src={require('./media/21(1).jpg')} alt="" />

					<p>
						{'Первичная профсоюзная организация ОАО "ГУМ" приняла участие в республиканской профсоюзной ' +
						 'акции "Поздравим маму вместе!", посвященной Дню матери.'}
					</p>
					<p>
						Профсоюзный комитет организовал тематическую площадку «Поздравим маму вместе!», где любой
						желающий имел возможность бесплатно получить и заполнить поздравительную открытку, чтобы
						отправить её своей маме. Профсоюзной организацией было изготовлено 160 открыток.
					</p>
					<p>
						Работники организации поблагодарили организаторов за такую замечательную возможность
						выразить слова любви и признательности самому дорогому и любимому человеку — маме. Ведь
						этот светлый праздник — замечательный повод еще раз сказать всем мамам самые нежные слова,
						поблагодарить за любовь и поддержку, терпение и доброту.
					</p>
					<img src={require('./media/22(1).jpg')} alt="" />
					<img src={require('./media/23(1).jpg')} alt="" />
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="30 октября 2019 года в ОАО «ГУМ» состоялась выставка - конкурс среди работников организации
				«Гумовские ДОЖИНКИ!»."
			>
				<div className="list_text">
					<p>
						Основной целью проведения творческого конкурса является развитие эстетической и
						духовно- нравственной культуры, реализация творческого потенциала, развитие корпоративной
						культуры, мотивации, организация активного досуга работников организации.
					</p>
					<p>
						В творчестве столько открытий, идей – только успевай воплощать да зрителей радовать!
					</p>
					<p>
						Участники конкурса продемонстрировали выращенные своими руками овощи, фрукты и цветы,
						а также блюда и поделки из них. На конкурсе были представлены работы разных направлений:
						цветочная (овощная) композиция, овощ на оригинальность, ценный рецепт, декоративно-прикладное
						искусство из природного материала и многое другое. Дары осени прямо с грядок попали на
						конкурсные столы.
					</p>
					<p>
						Конкурсная комиссия, под руководством директора ОАО «ГУМ» Приступа Светланы Николаевны,
						объективно оценивала каждую номинацию, с учетом гармоничности цветовой гаммы, сочетаемости
						материалов, степени сложности и мастерства, оригинальности, лаконичности в оформлении,
						художественного уровня.
					</p>
					<p />
					<ul>
						Члены жюри оценивали конкурсантов в следующих номинациях:
						<li>«Чудо – овощ, чудо – фрукт»</li>
						<li>«Чудо заморское»</li>
						<li>«Домашние разносолы»</li>
						<li>«Осенняя (фруктовая, овощная, цветочная) композиция»</li>
						<li>«Рецепт от хозяюшки»</li>
						<li>«Карвинг»</li>
						<li>«Мини - сад»</li>
						<li>«Фабрика здоровья»</li>
					</ul>
					<p>
						Комиссии предстоит очень сложный отбор победителей. Все номинанты - мастера с большой буквы.
					</p>
					<p>
						Мы гордимся талантами и достижениями наших работников!
					</p>
					<p>
						Итоги конкурса будут подведены 16 октября 2019 года в 14.00 в актовом зале универмага.
					</p>
					<div>
						<img src={require('./media/01(2).jpg')} alt="" />
						<img src={require('./media/02(2).jpg')} alt="" />
						<img src={require('./media/03(2).jpg')} alt="" />
						<img src={require('./media/04(2).jpg')} alt="" />
						<img src={require('./media/05(2).jpg')} alt="" />
						<img src={require('./media/06(2).jpg')} alt="" />
						<img src={require('./media/07(2).jpg')} alt="" />
						<img src={require('./media/08(1).jpg')} alt="" />
						<img src={require('./media/09(1).jpg')} alt="" />
						<img src={require('./media/10(3).jpg')} alt="" />
						<img src={require('./media/11(3).jpg')} alt="" />
						<img src={require('./media/12(2).jpg')} alt="" />
						<img src={require('./media/13(2).jpg')} alt="" />
						<img src={require('./media/14(2).jpg')} alt="" />
						<img src={require('./media/15(2).jpg')} alt="" />
						<img src={require('./media/16(2).jpg')} alt="" />
						<img src={require('./media/17(2).jpg')} alt="" />
						<img src={require('./media/18(2).jpg')} alt="" />
						<img src={require('./media/19(2).jpg')} alt="" />
						<img src={require('./media/20(2).jpg')} alt="" />
						<img src={require('./media/21(2).jpg')} alt="" />
						<img src={require('./media/22(2).jpg')} alt="" />
						<img src={require('./media/23(2).jpg')} alt="" />
						<img src={require('./media/24(1).jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse title="30 августа 2019 года в столовой универмага состоялся детский праздник.">
				<div className="list_text">
					<p>

						<strong>«Первый раз в первый класс!»</strong>

					</p>
					<p>
						Всем нам известно, что август – это последний месяц лета, не только от души дарящий теплые
						солнечные дни, щедрый урожай и ощущение безмятежного счастья, но и горячая пора для родителей,
						чьи дети в первый сентябрьский день сядут за школьные парты. Для них август – время забот,
						тревог и активных сборов в школу. И если для тех родителей, которые уже прошли боевое крещение
						первым звонком, все немного легче и чуть-чуть понятнее, то для тех, чьим детям еще только

						предстоит открыть книгу знаний - более волнительно.
					</p>
					<p>
						Поэтому, чтобы поддержать родителей первоклассников, разделить их тревоги и заботы и дать
						им возможность уделить своим детям немного больше внимания, администрация ОАО «ГУМ» совместно
						с профкомом организовали для детей и их родителей детский праздник «Первый раз в первый класс!».
					</p>
					<p>
						Детский праздник состоялся 30 августа 2019 года в столовой универмага.
					</p>
					<p>
						Праздничному настроению способствовала особая атмосфера.
					</p>
					<p>
						20 мальчиков и девочек в течение двух часов вместе со взрослыми разгадывали загадки, пели,
						танцевали, состязались в ловкости, сноровке и просто веселились.
					</p>
					<p>
						В завершение мероприятия будущих первоклашек ждал сладкий стол, подарки, праздничная
						дискотека и фотография на долгую и добрую память.
					</p>
					<p>
						Поздравляем наших первоклашек и их родителей с новым этапом в их жизни. Желаем крепкого
						здоровья, отличного настроения, успехов в учебе!!!
					</p>
					<div>
						<img src={require('./media/01(3).jpg')} alt="" />
						<img src={require('./media/02(3).jpg')} alt="" />
						<img src={require('./media/03(3).jpg')} alt="" />
						<img src={require('./media/04(3).jpg')} alt="" />
						<img src={require('./media/05(3).jpg')} alt="" />
						<img src={require('./media/06(3).jpg')} alt="" />
						<img src={require('./media/07(3).jpg')} alt="" />
						<img src={require('./media/08(2).jpg')} alt="" />
						<img src={require('./media/09(2).jpg')} alt="" />
						<img src={require('./media/10(4).jpg')} alt="" />
						<img src={require('./media/11(4).jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="15 августа 2019 года в фойе универмага состоялась акция «Веселые старты – ГУМ за ЗОЖ!»."
			>
				<div className="list_text">
					<p>
						Дети и взрослые с нетерпением ждали этот спортивный праздник. Работники ОАО «ГУМ» совместно
						с Ассоциацией «Белорусская федерация футбола» подготовили разнообразные конкурсы и эстафеты.
					</p>
					<p>
						Участники акции продемонстрировали свои физические способности: отжимались, приседали, вращали
						обруч, приседали и, конечно же, играли в перевернутый футбол. Разгорелись настоящие спортивные
						страсти. И взрослые, и дети показали ловкость, выносливость, смекалку.
					</p>
					<p>
						В фойе универмага царил смех, шум и веселье. Все были счастливы!!! А счастливые от восторга
						глаза детей – лучшая награда всем организатором праздника.
					</p>
					<p>
						Все участники акции получили поощрительные призы.
					</p>
					<p>
						Молодцы! Так держать!
					</p>
					<div>
						<iframe
							title="fun-starts"
							width="650"
							height="363"
							src="https://www.youtube.com/embed/j-2EQNw9ONo"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
						<img src={require('./media/01(4).jpg')} alt="" />
						<img src={require('./media/02(4).jpg')} alt="" />
						<img src={require('./media/03(4).jpg')} alt="" />
						<img src={require('./media/04(4).jpg')} alt="" />
						<img src={require('./media/05(4).jpg')} alt="" />
						<img src={require('./media/06(4).jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="24 июля 2019 года на базе загородного клуба «Фестивальный»
			состоялся спортивный праздник – квест – игра «Мега - отрыв» для работников ОАО «ГУМ»."
			>
				<div className=" list_text">
					<p>
						В мероприятии приняли участие молодые работники, которые проявили себя за время работы
						на предприятии.
					</p>
					<p>
						В соревнованиях участвовали 4 команды: «Респираторы», «Оранжевое настроение», «Лесики»,
						«BAD SANTA».
					</p>
					<p>
						Командам были предложены занимательные конкурсы с бегом, прыжками, эстафеты с мячом,
						веревочный городок, где они смогли проявить свои спортивные, умственные способности и
						навыки. Все этапы проходили в напряженной борьбе. Спортивный задор и желание добиться
						победы для своей команды захватывали ребят настолько, что они не замечали происходящего
						вокруг. Все старались изо всех сил прийти к финишу первыми. Проведённый спортивный
						праздник сплотил ребят, никого не оставил равнодушным. Участвуя в квест-игре, ребята
						почувствовали себя настоящим коллективом! Соревнования стали настоящим праздником спорта,
						здоровья и молодости!
					</p>
					<p>
						Все команды были награждены Дипломами, отличным зарядом бодрости и положительными эмоциями.
					</p>
					<p>

						Призовые места распределились следующим образом:Первое место и победитель в номинации «Самая
						эффективная команда» - команда «BAD SANTA».Второе место и победитель в номинации «Самая
						активная команда» - команда «Респираторы».Третье место и победитель в номинации «Самая дружная
						команда» - команда «Оранжевое настроение».Четвертое место и победитель в номинации «Самая
						улетная команда – команда «Лесики».

					</p>
					<p>
						Праздник позволил закалить командный дух, научить ребят доверять друг другу, сделать
						общение неформальным и испытать незабываемые эмоции.
					</p>
					<p>
						Квест - игра прошла в тёплой дружеской атмосфере. Все участники получили отличный
						заряд бодрости и море положительных эмоций.
					</p>
					<div>
						<img src={require('./media/01(5).jpg')} alt="" />
						<img src={require('./media/02(5).jpg')} alt="" />
						<img src={require('./media/03(5).jpg')} alt="" />
						<img src={require('./media/04(5).jpg')} alt="" />
						<img src={require('./media/05(5).jpg')} alt="" />
						<img src={require('./media/06(5).jpg')} alt="" />
						<img src={require('./media/07(4).jpg')} alt="" />
						<img src={require('./media/08(3).jpg')} alt="" />
						<img src={require('./media/09(3).jpg')} alt="" />
						<img src={require('./media/10(5).jpg')} alt="" />
						<img src={require('./media/11(5).jpg')} alt="" />
						<img src={require('./media/12(3).jpg')} alt="" />
						<img src={require('./media/13(3).jpg')} alt="" />
						<img src={require('./media/14(3).jpg')} alt="" />
						<img src={require('./media/15(3).jpg')} alt="" />
						<img src={require('./media/16(3).jpg')} alt="" />
						<img src={require('./media/17(3).jpg')} alt="" />
						<img src={require('./media/18(3).jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="10 июля 2019 года состоялась встреча заместителя председателя Минского
			городского исполнительного комитета Крепака Александра Петровича с трудовым коллективом ОАО «ГУМ»."
			>
				<div className="list_text">
					<p>
						10 июля 2019 года состоялась встреча заместителя председателя Минского городского
						исполнительного комитета Крепака Александра Петровича с трудовым коллективом ОАО «ГУМ».
						На встрече присутствовал начальник ГУ «Главное управление потребительского рынка
						Мингорисполкома» Улезло Андрей Михайлович.
					</p>
					<p>
						<i>
							Цель таких встреч – узнать, какие есть проблемы у того или иного
							коллектива, чем – то ему помочь,
						</i>
						{' '}
						- уточнил, в начале выступления Александр Петрович. –
						<i>
							Поэтому вы можете озвучивать проблемы, задавать интересующие вопросы, а также
							поднимать темы, которые вас волнуют.
						</i>
					</p>
					<p>
						В ходе общения с работниками предприятия Александр Петрович рассказал
						об итогах прошедших II Европейских игр.
					</p>
					<p>
						В своем выступлении он отметил, что II Европейские игры состоялись и прошли
						весьма удачно для команды Беларуси. Несмотря на то, что состязания такого ранга
						у нас проводились впервые, не было допущено ни одного серьезного срыва. И участники,
						и гости II Европейских игр чувствовали себя комфортно. Это касается и организации
						соревнований, и проживания, и питания, и транспортного обслуживания, и других аспектов.
						Для спортсменов и тренеров были созданы очень качественные условия и для соревновательного
						процесса, и для тренировок, медико-восстановительных мероприятий. Во время II Европейских игр
						спортсмены рассыпались в комплиментах организаторам. Особую благодарность они высказывали
						поварам Деревни спортсменов. Каждый день только в основной столовой им предлагали около 250
						блюд на выбор — представлены были белорусская, итальянская, азиатская, средиземноморская
						кухни, вегетарианские и диетические блюда, конфессиональное питание, спортивное питание.
						Как итог - II Европейские игры прошли на высоком организационном уровне.
					</p>
					<p>
						По словам Александра Петровича, международные спортивные организации и национальные комитеты
						стран Европы до сих пор благодарят Беларусь за проведенные Игры, теплый прием и радушие.
					</p>
					<div>
						<img src={require('./media/01(6).jpg')} alt="" />
						<img src={require('./media/02(6).jpg')} alt="" />
						<img src={require('./media/03(6).jpg')} alt="" />
						<img src={require('./media/04(6).jpg')} alt="" />
						<img src={require('./media/05(6).jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse title="6 марта 2019 года в ОАО «ГУМ» состоялся праздничный концерт, посвященный 8 Марта.">
				<div className="list_text">
					<p>
						8 марта – Международный женский день, это время, когда прекрасная половина
						человечества принимает подарки и пожелания в свой адрес.
					</p>
					<p>
						В торжественной части с поздравлением выступил заместитель директора по коммерческой
						работе Ращинский Сергей Андреевич, который от всей души пожелал женщинам универмага
						прекрасного весеннего настроения, творческих и профессиональных успехов и выразил
						слова благодарности за их труд.
					</p>
					<p>
						− Я вижу в этом зале самых успешных и талантливых женщин, тех, кто своим трудом,
						активной жизненной позицией способствуют повышению престижа ОАО «ГУМ».
						Для этого необходим особый женский такт, терпение, напряжение душевных сил –
						те удивительные качества, которых зачастую так не хватает нам, мужчинам.
						Я искренне хочу Вам пожелать, чтобы в душе всегда была весна, а в Ваших семьях были порядок,
						тепло и уют. Пусть любовь согревает Ваши сердца и дарит силы для новых успехов в
						профессиональной сфере, − в поздравительный речи отметил Сергей Андреевич Ращинский.
					</p>
					<p>
						В этот день для милых женщин участники художественной самодеятельности ОАО
						«ГУМ» дарили свои творческие музыкальные подарки. Ярким украшением праздника
						стало выступление юных артистов – детей сотрудников универмага. Их песни
						создали праздничное настроение и теплый микроклимат в зале.
					</p>
					<div>
						<img src={require('./media/01(7).jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="11 января 2019 года в ОАО «ГУМ» состоялся
				праздничный концерт, посвященный Рождеству и Новому Году."
			>
				<div className="list_text">
					<div>
						<img src={require('./media/01(8).jpg')} alt="" />
						<img src={require('./media/02(7).jpg')} alt="" />
					</div>
				</div>
			</UncontrolledCollapse>
			<UncontrolledCollapse
				title="6 ноября 2018 года состоялся праздничный концерт для работников
				ОАО «ГУМ», посвященный 67 – летию со дня открытия универмага."
			>
				<div className="list_text">
					<p>

						В праздничной обстановке коллектив предприятия поздравил помощник руководителя организации –
						Карачун Николай Николаевич, а также гости – председатель Белорусского профессионального союза
						работников торговли Геращенко Оксана Тимофеевна и председатель Минской городской организации
						профсоюза работников торговли Кулибаба Алексей Александрович. Под аплодисменты зала на сцену
						поднялись 78 работников, награжденных: Грамотой Белорусского профсоюза работников торговли,
						Грамотой и Благодарностью ОАО «ГУМ».

					</p>
					<div><img src={require('./media/01(9).jpg')} alt="" /></div>
				</div>
			</UncontrolledCollapse>
		</StaticPageWrapper>
	)
}
