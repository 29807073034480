import * as PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.scss'

const Burger = ({ isOpen }) => {
	return (
		<div className={styles.wrapper}>
			<div className={[
				styles.burger,
				!isOpen ? styles.close : styles.open,
			].join(' ')} />
		</div>
	)
}

Burger.propTypes = {
	isOpen: PropTypes.bool.isRequired,
}

export { Burger }
