import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { AddressForm } from 'components/basket/UserDataForms/UserAddressForm'
import {
	Loading,
	PageHeading,
} from 'components/global'
import { Button } from 'components/global/Button'
import { Notification } from 'helpers/Notification'
import * as PropTypes from 'prop-types'
import React, {
	useEffect,
	useRef,
	useState,
} from 'react'
import {
	Col,
	Container,
	Row,
} from 'reactstrap'
import styles from './styles.scss'

export const DeliveryAddress = props => {
	const { getUserDefaultAddress, addressesListState, history } = props

	useEffect(() => {

		const searchQuery = DataProvider
			.buildQuery()
			.addFilter([
				{ isDefault: true },
			])

		getUserDefaultAddress(searchQuery)
	}, [])

	const [isFetching, setIsFetching] = useState(false)

	/**
	 * Add notification when user success update address
	 *
	 * @return {undefined}
	 */
	const addNotification = () => {
		Notification.add({
			title:   'Отлично!',
			message: 'Информация сохранена',
			type:    'success',
		})

		history.push('/user/profile')
	}

	const formRef          = useRef()
	const addressesService = StateService.create(addressesListState)

	const submit = () => {
		formRef.current.submit()
		setIsFetching(true)
	}

	const title        = 'Редактирование адреса доставки'

	return (
		<section className={styles.background}>
			<PageHeading
				title={title}
				breadcrumbs
				extraBreadcrumbs={[{ title: 'Личный кабинет', path: '/user' }]}
			/>

			<Container>
				<Row className={styles.row}>
					<Col xs="12" lg="6">
						{!addressesService.isFetching() &&
						<AddressForm
							title="Введите новый адрес"
							addressesList={addressesListState}
							formRef={formRef}
							onSuccess={addNotification}
						/>
						|| <Loading large />}
						<div className={styles.button}>
							<Button
								color="green"
								title="Обновить"
								onClick={submit}
								fetching={isFetching}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

DeliveryAddress.propTypes = {
	history:               PropTypes.object,
	addressesListState:    PropTypes.object,
	getUserDefaultAddress: PropTypes.func,
}

DeliveryAddress.defaultProps = {
	history:               {},
	addressesListState:    {},
	getUserDefaultAddress: () => null,
}
