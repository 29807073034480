import { getClientId, TYPE } from '@kakadu-dev/base-frontend-helpers/helpers/Client'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { put, select, takeLatest } from 'redux-saga/effects'
import SagasHelper from '@kakadu-dev/base-frontend-components/lib/helpers/Redux/SagasHelper'
import { DispatchSelector, StateSelector } from '../selectors'
import { UsersStateSelectors } from '@kakadu-dev/base-frontend-components/lib'
import { CartActions } from './actionCreators'
import { CART_ACTION } from './actionTypes'
import { CartApi } from './api'
import CartResponseHandler from './ResponseHandler'

/**
 * Get cart products
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* list(action) {
	yield SagasHelper.action(action, CartApi.list, null, null, function* (response) {
		const {
				  result,
				  error
			  } = response

		if (!error) {
			yield put(CartActions.setList(result))
		}
	})
}

/**
 * Get cart products
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* view(action) {
	yield SagasHelper.action(action, CartApi.view)
}

/**
 * Add product to cart
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* create(action) {
	yield SagasHelper.action(action, CartApi.create, null, function* (searchQuery) {
		if (!searchQuery.getBody().cityId) {
			const user = yield select(UsersStateSelectors.getUser)
			const cityId = user.result?.Settings?.cityId
			searchQuery.addBody({
				cityId
			}, true)
		}
	}, CartResponseHandler.updateListAfterCreate)
}

/**
 * Update cart product
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* update(action) {
	yield SagasHelper.action(action, CartApi.update, null, null, CartResponseHandler.updateListAfterCreate)
}

/**
 * Delete cart products
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* deleteModel(action) {
	yield SagasHelper.action(action, CartApi.delete, null, null, CartResponseHandler.updateListAfterDelete)
}

/**
 * Delete all cart products
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* deleteAll(action) {
	yield SagasHelper.defaultAction(
		action,
		CartApi.deleteAll,
		null,
		null,
		function* (...params) {
			yield SagasHelper.afterClearState(...params, CartActions.setList)
		})
}

/**
 * Get cart checkout info
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* checkout(action) {
	yield SagasHelper.action(action, CartApi.checkout, null, null, DataProvider.handleResponseView)
}

/**
 * Get cart checkout info for terminal
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* checkoutTerminal(action) {
	yield SagasHelper.defaultAction(
		action,
		CartApi.checkoutTerminal,
		null,
		null,
		DataProvider.handleResponseView
	)
}

/**
 * Create order
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* createOrder(action) {
	yield SagasHelper.defaultAction(action, CartApi.createOrder, null, function* () {
		const {
				  result: user
			  } = yield select(StateSelector.user.getUser) // Sign In anonymously if user not exist

		if (!user) {
			const signInAction = DispatchSelector.auth.signIn
			const clientId = yield getClientId()
			const signInQuery = DataProvider.buildQuery().addExpands('settings', 'city').addBody({
				provider: TYPE,
				token: clientId,
				params: {
					browserId: clientId
				}
			})
			yield put(signInAction(signInQuery))
		}
	}, function* (response) {
		if (!response.error) {
			yield put(CartActions.setList({ list: [] }))
		}
	})
}

/**
 * Create order from terminal
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* createOrderTerminal(action) {
	yield SagasHelper.defaultAction(
		action,
		CartApi.createOrderTerminal,
		null,
		null,
		DataProvider.handleResponseView
	)
}

/**
 * Start session terminal
 *
 * @param {object} action
 *
 * @return {IterableIterator<PutEffect<{type, message}>|PutEffect<{user, type}>|CallEffect|PutEffect<{type}>>}
 */
function* startSessionTerminal(action) {
	yield SagasHelper.defaultAction(
		action,
		CartApi.startSessionTerminal,
		null,
		null,
		DataProvider.handleResponseView
	)
}

export default [
	takeLatest(CART_ACTION.LIST, list),
	takeLatest(CART_ACTION.VIEW, view),
	takeLatest(CART_ACTION.CREATE, create),
	takeLatest(CART_ACTION.UPDATE, update),
	takeLatest(CART_ACTION.DELETE, deleteModel),
	takeLatest(CART_ACTION.DELETE_ALL, deleteAll),
	takeLatest(CART_ACTION.CHECKOUT, checkout),
	takeLatest(CART_ACTION.CHECKOUT_TERMINAL, checkoutTerminal),
	takeLatest(CART_ACTION.CREATE_ORDER, createOrder),
	takeLatest(CART_ACTION.CREATE_ORDER_TERRMINAL, createOrderTerminal),
	takeLatest(CART_ACTION.START_SESSION_TERMINAL, startSessionTerminal)
]
