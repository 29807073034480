import {
	DispatchSelector,
	StateSelector,
} from '@kakadu-dev/base-frontend-components'

const mapStatesToProps = (state) => ({
	userState:        StateSelector.user.getUser(state),
	favoritesState:   StateSelector.customersFavorites.list(state),
	productListState: StateSelector.products.list(state),
})

const mapDispatchToProps = {
	getUser:        DispatchSelector.user.getUser,
	getFavorites:   DispatchSelector.customersFavorites.list,
	getProductList: DispatchSelector.products.list,
}

export {
	mapDispatchToProps,
	mapStatesToProps,
}
