import { CollapseInfoCard } from 'components/static/installmentPlanCredit/CollapseInfoCard'
import {
	BelarusDetail,
	BelgaspromDetail,
	CherepahaDetail,
	DabrabitDetail,
	MtbDetail,
	VtbDetail,
} from 'components/static/installmentPlanCredit/detail'
import { KartaFunDetail } from 'components/static/installmentPlanCredit/detail/KartaFun'
import { StaticPageWrapper } from 'components/static/StaticPageWrapper'
import React, { useEffect } from 'react'
import VTBImage from 'assets/images/static-pages/banks/VTB.jpg'
import HalvaImage from 'assets/images/static-pages/banks/Halva.jpg'
import DabrabitImage from 'assets/images/static-pages/banks/DabrabytSmartCard.jpg'
import BelgaspromImage from 'assets/images/static-pages/banks/karta_pokupok.jpg'
import BelarusImage from 'assets/images/static-pages/banks/magnit.jpg'
import CherepahaImage from 'assets/images/static-pages/banks/cherepaha_card.jpg'
import KartaFunImage from 'assets/images/static-pages/banks/kartaFUN.png'
import { MetaService } from 'services/MetaService'
import styles from './styles.scss'

const title = 'Рассрочка, кредит'

const setMetaInfo = () => MetaService.setInfo({}, { title })

const creditArray = [
	{
		key:         0,
		title:       'КРЕДИТ БАНКА ВТБ (Беларусь)',
		img:         VTBImage,
		description: ['ЭКСПРЕСС-КРЕДИТ под 29,9%: cумма кредита от 50 до 10000 руб. со скидкой на товары до 25%',
			'ЭКСПРЕСС-КРЕДИТ. РАССРОЧКА: cумма кредита от 50 до 10000 руб.'],
		detail:      <VtbDetail />,
		detailTitle: 'Подробнее о кредите БАНКА ВТБ (Беларусь)',
		promo:       '',
		promoTitle:  '',
	},
	{
		key:         1,
		title:       'КАРТА РАССРОЧКИ МТБАНКА «ХАЛВА»',
		img:         HalvaImage,
		description: ['1 мес. - на продтовары на любую сумму',
			'4 мес. - на промтовары - до 500 руб.', '5 мес. - на промтовары - свыше 500 руб.'],
		detail:      <MtbDetail />,
		detailTitle: 'Подробнее о карте рассрочки «ХАЛВА»',
		promo:      null,
		promoTitle:  '',
	},
	{
		key:         2,
		title:       'КАРТА РАССРОЧКИ БАНКА ДАБРАБЫТ «СМАРТ КАРТА»',
		img:         DabrabitImage,
		description: ['4 мес. - на промтовары на любую сумму'],
		detail:      <DabrabitDetail />,
		detailTitle: 'Подробнее о карте рассрочки «СМАРТ КАРТА»',
		promo:       null,
		promoTitle:  '',
	},
	{
		key:         3,
		title:       'КАРТА РАССРОЧКИ БЕЛГАЗПРОМБАНКА «КАРТА ПОКУПОК»',
		img:         BelgaspromImage,
		description: ['1 мес. - на продтовары на любую сумму', '4 мес. - на промтовары на любую сумму'],
		detail:      <BelgaspromDetail />,
		detailTitle: 'Подробнее о карте рассрочки «КАРТА ПОКУПОК»',
		promo:       null,
		promoTitle:  '',
	},
	{
		key:         4,
		title:       'КАРТА РАССРОЧКИ БЕЛАРУСБАНКА «МАГНИТ»',
		img:         BelarusImage,
		description: ['1 мес. - на продтовары на любую сумму', '3 мес. - на промтовары на любую сумму'],
		detail:      <BelarusDetail />,
		detailTitle: 'Подробнее о карте рассрочки «МАГНИТ»',
		promo:       null,
		promoTitle:  '',
	},
	{
		key:         5,
		title:       'КАРТА РАССРОЧКИ ЗАО БАНК ВТБ «ЧЕРЕПАХА»',
		img:         CherepahaImage,
		description: ['8 мес. - на промтовары на любую сумму'],
		detail:      <CherepahaDetail />,
		detailTitle: 'Подробнее о карте рассрочки «ЧЕРЕПАХА»',
		promo:       null,
		promoTitle:  '',
	},
	{
		key:         6,
		title:       'КАРТА РАССРОЧКИ ОАО «БПС - СБЕРБАНК» «КартаFUN»',
		img:         KartaFunImage,
		description: ['6 мес. - на промтовары на любую сумму'],
		detail:      <KartaFunDetail />,
		detailTitle: 'Подробнее о карте рассрочки «КартаFUN»',
		promo:       null,
		promoTitle:  '',
	},
]

/**
 * Render installment plan credit page
 *
 *
 * @return {*}
 * @constructor
 */
export const InstallmentPlanCredit = () => {
	useEffect(() => {
		setMetaInfo()
	}, [])

	return (
		<StaticPageWrapper title={'Рассрочка, кредит'} extraBreadcrumbs={[{ title: 'Покупателям', path: '' }]}>
			<section className={styles.sectionWrapper}>
				{
					creditArray.map(item => (<CollapseInfoCard key={item.key} info={item} />))
				}
			</section>
		</StaticPageWrapper>
	)
}
