/**
 * To remove filter blocks
 *
 * @param {object} ref
 *
 * @return {undefined}
 */
export const removeFilter = (ref = {}) => {
	const element = ref

	if (typeof ref === 'function') {
		ref()
	}

	if (element.type !== 'text') {
		element.checked = false
	}
}
