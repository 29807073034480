import {
	Input,
	PageHeading,
	Textarea,
} from 'components/global'
import { Button } from 'components/global/Button'
import React from 'react'
import { Container } from 'reactstrap'

import styles from './styles.scss'

const Help = () => (
	<section>
		<PageHeading
			title='Свяжитесь с нами'
			breadcrumbs
		/>

		<Container>
			<div className={styles.wrapper}>
				<form action="" className={styles.form}>
					<Input
						id='help:name'
						description='Имя'
						onChange={() => null}
						required
					/>
					<Input
						id='help:email'
						description='Email'
						onChange={() => null}
						required
					/>
					<Textarea
						id='help:message'
						description='Сообщение'
						required
					/>
					<div className={styles.button}>
						<Button title='Отправить' color="black" />
					</div>
				</form>
			</div>
		</Container>
	</section>
)

export { Help }
