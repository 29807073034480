import Radio, { NativeRadioControl } from '@material/react-radio'
import '@material/react-radio/dist/radio.css'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.css'

/**
 * Render animated readio button
 *
 * @param {String} id
 * @param {String} label
 *
 * @return {*}
 * @constructor
 */
export const RadioBtn = ({ id, label, ...props }) => (
	<Radio label={label} key='dog'>
		<NativeRadioControl id={id} value={id} {...props} />
	</Radio>
)

RadioBtn.propTypes = {
	id:    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.string,
}

RadioBtn.defaultProps = {
	label: '',
	id:    '',
}
