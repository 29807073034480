/**
 * Category filters
 */
import { PropertyType as PropTypes } from '@kakadu-dev/base-frontend-components'

class PropertyType extends PropTypes
{
	/**
	 * Get visible items
	 *
	 * @returns {Array}
	 */
	getVisibleItems = () => this.model.items.slice(0, 5)

	/**
	 * Get hidden items
	 *
	 * @returns {Array}
	 */
	getHiddenItems = () => this.model.items

	/**
	 * Get min range
	 *
	 * @returns {number}
	 */
	getMinRange = () => this.model?.range?.min ?? 0

	/**
	 * Get max range
	 *
	 * @returns {number}
	 */
	getMaxRange = () => this.model?.range?.max ?? 0
}

/**
 * CategoryFilters class
 */
export default class CategoryFilters
{
	model = {}


	constructor(object)
	{
		this.model = object?.result?.model ?? object?.result ?? object
	}

	/**
	 * Create
	 * @param object CategoryFilters model
	 *
	 * @returns {CategoryFilters}
	 */
	static create = object => new this(object)

	/**
	 * Get category id
	 *
	 * @returns {number}
	 */
	getCategoryId = () => this.model.categoryId

	/**
	 * Get sort
	 *
	 * @returns {{label: unknown, value: string}[]}
	 */
	getSort = () => Object.entries(this.model.sort || {}).map(([value, label]) => ({ label, value }))


	/**
	 * Get sort default
	 *
	 * @returns {string}
	 */
	getSortDefault = () => this.model.sortDefault


	/**
	 * Get filters list
	 *
	 * @returns {Array<PropertyType>}
	 */
	getFilters = () => PropertyType.createList(this.model?.filters ?? [])
}
