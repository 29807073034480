import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render birthday fields
 *
 * @param {Object} field
 * @param {Function} handler
 * @param {string} value
 * @param {Function} onBlur
 *
 * @return{*}
 */
export const BirthdayField = ({ field, handler, value, onBlur, error }) => {
	const { id, title, disabled, required } = field

	const onChange = ({ target: { value: nextBirthday } }) => {
		handler(id, nextBirthday)
	}

	return (
		<TextField
			id={id}
			label={title}
			name={id}
			type="date"
			value={value}
			disabled={disabled}
			onChange={onChange}
			onBlur={onBlur}
			autoComplete="off"
			size="small"
			variant="outlined"
			required={required}
			error={error}
		/>
	)
}

BirthdayField.propTypes = {
	field:   PropTypes.object,
	handler: PropTypes.func,
	value:   PropTypes.string,
	onBlur:    PropTypes.func,
}

BirthdayField.defaultProps = {
	field:   {},
	handler: () => null,
	value:   '',
	onBlur:    () => null,
}
