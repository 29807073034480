import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	intlState:        state.intl,
	userState:        StateSelector.user.getUser(state),
	userCityState:    StateSelector.user.getUserCity(state),
	menuState:        StateSelector.categories.getHierarchical(state),
	metaState:        StateSelector.seo.getMeta(state),
	staticPagesState: StateSelector.staticPages.pages(state),
	bannersState:     StateSelector.staticPages.banners(state),
	articlesState:    StateSelector.staticPages.articles(state),
	isShowPanel:      state.visionPanel.isShow,
	siteBackground:   state.visionPanel.siteBackground,
	isImg:            state.visionPanel.isImg,
	isFontNormal:     state.visionPanel.isFontNormal,
	isKerningNormal:  state.visionPanel.isKerningNormal,
})
const mapDispatchToProps = {
	getUser:     DispatchSelector.user.getUser,
	getUserCity: DispatchSelector.user.getUserCity,
	getMenu:     DispatchSelector.categories.getHierarchical,
	getMeta:     DispatchSelector.seo.getMeta,
	getPages:    DispatchSelector.staticPages.pages,
	getArticles: DispatchSelector.staticPages.getArticles,
	getBanners:  DispatchSelector.staticPages.banners,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
