import {
	CustomerFavorite,
	Product,
} from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import Icon from 'assets/icomoon/Icon'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useMemo,
	useState,
} from 'react'
import { Spinner } from 'reactstrap'
import styles from './styles.scss'

/**
 * Render product favorite toggler
 *
 * @param {Product} productModel
 * @param {number} userId
 * @param {Function} deleteFavorite
 * @param {Function} addToFavorite
 * @param {boolean} lg
 *
 * @return {*}
 * @constructor
 */
export const ProductFavorites = ({
	productModel,
	userId,
	deleteFavorite,
	addToFavorite,
	lg,
}) => {
	const [isFavorite, setIsFavorite]   = useState(false)
	const [isFetching, setIsFetching]   = useState(false)
	const [isShowModal, setIsShowModal] = useState(false)

	const [favorite, entityId] = useMemo(() => [productModel.isFavorite(), productModel.getId()],
		[productModel])

	useEffect(() => {
		if (userId < 0) {
			setIsFavorite(false)
		} else {
			setIsFavorite(favorite)
		}
	}, [userId, favorite])

	const showModal = () => {
		if (lg) {
			setIsShowModal(true)
			setTimeout(() => setIsShowModal(false), 1500)
		}
	}

	const checkResponse = response => {
		if (!response.error) {
			setIsFavorite(!isFavorite)
			showModal()
		}

		setTimeout(() => setIsFetching(false), 400)
	}

	const toggleFavorite = () => {
		if (userId < 0) {
			showModal()
			return
		}

		setIsShowModal(false)
		setIsFetching(true)

		const searchQuery = DataProvider
			.buildQuery()
			.addBody({
				type:       CustomerFavorite.types.product,
				entityId,
				customerId: userId,
			})
			.setSuccessCallback(checkResponse)

		const id = [CustomerFavorite.types.product, userId, entityId].join(',')

		if (isFavorite) {
			const sq = DataProvider
				.buildQuery()
				.addBody({ id })
				.setSuccessCallback(checkResponse)

			deleteFavorite(sq)
		} else {
			addToFavorite(searchQuery)
		}
	}

	const getMessage = () => (userId < 0 && 'Войдите, чтобы добавить в избранное...') ||
							 (isFavorite && 'Товар добавлен в избранное') || 'Товар удален из избранного'

	if (userId < 0 && !lg) {
		return false
	}

	return (
		<button
			type='button'
			tabIndex={0}
			title={isFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
			className={[styles.btn, isFavorite && styles.btn_active, lg && styles.lg].join(' ')}
			disabled={isFetching}
			onClick={toggleFavorite}
		>
			<div className={[styles.modal, isShowModal && styles.modal_active, userId < 0 && styles.noLogin].join(' ')}>
				{getMessage()}
			</div>
			{!isFetching ?
				<Icon icon="heart" className={styles.icon} size={lg ? 26 : 16} /> :
				<Spinner />}
		</button>
	)
}

ProductFavorites.propTypes = {
	addToFavorite:  PropTypes.func,
	deleteFavorite: PropTypes.func,
	lg:             PropTypes.bool,
	productModel:   PropTypes.object,
	userId:         PropTypes.number,
}

ProductFavorites.defaultProps = {
	addToFavorite:  () => null,
	deleteFavorite: () => null,
	lg:             false,
	productModel:   Product.create({}),
	userId:         -1,
}
