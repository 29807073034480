import { StaticPageWrapper } from 'components/static/StaticPageWrapper'
import { ourProductsImgs } from 'containers/Static/OurProduction/data'
import { ImagesList } from 'containers/Static/OurProduction/ImagesList'
import React, { useEffect } from 'react'
import { MetaService } from 'services/MetaService'

const title = 'Наше производство'

const setMetaInfo = () => MetaService.setInfo({}, { title })

/**
 * Render purchases page
 *
 * @return {*}
 * @constructor
 */
export const OurProduction = () => {
	useEffect(() => {
		setMetaInfo()
	}, [])

	return (
		<StaticPageWrapper title={title} extraBreadcrumbs={[{ title: 'Сотрудничество', path: '' }]}>
			<h1 className="primary-text">СВЕЖАЯ ВЫПЕЧКА ОПТОМ</h1>
			<p>
				Предлагаем приобрести кондитерскую продукцию собственного производства
				нашей столовой.
			</p>
			<br />
			<p>
				Выпечка от ОАО «ГУМ» - это свежие и аппетитные булочки, круасаны, слойки,
				печенье и пироги, приготовленные с любовью нашими кондитерами.
			</p>
			<ImagesList list={ourProductsImgs} />
			<p>
				Ассортимент продукции постоянно расширяется.
			</p>

			<h3 className="primary-text mt-4">
				Будем рады ответить на все ваши вопросы. Приглашаем к взаимовыгодному
				сотрудничеству.
			</h3>

			<div className="mb-4">
				<span className="red font-16">{'Контактные телефоны: '}</span>
				<span>в рабочие дни с 9 до 17</span>
			</div>
			<a href="tel:+375173255893" className="mb-3 d-block">
				+375(17)325-58-93 (столовая)
			</a>
			<a href="tel:+375173231822" className="mb-3 d-block">
				+375(17)323-18-22 (товаровед)
			</a>
		</StaticPageWrapper>
	)
}
