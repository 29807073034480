import Icon from 'assets/icomoon/Icon'
import telephones from 'components/home/Header/Telephone/data'
import styles from 'components/home/Header/Telephone/styles.scss'
import * as PropTypes from 'prop-types'
import React from 'react'

export const Telephone = ({ isMobile }) => {
	return telephones.length !== 0 && (
		<div className={styles.telephones}>
			{telephones.map(telephone => (
				<a
					className={styles.telephone}
					href={`tel:${telephone.telephone}`}
					key={`header-tel:${telephone.id}`}
				>
					{!isMobile && (
						<Icon icon={telephone.icon} size={telephone.iconSize} />
					)}
					<p>
						{telephone.telephone}
					</p>
				</a>
			))}
		</div>
	)
}

Telephone.propTypes = {
	isMobile: PropTypes.bool,
}

Telephone.defaultProps = {
	isMobile: false,
}
