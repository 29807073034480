export const data = [
	{
		id: 1,
		name: 'name',
		title: 'Фамилия, имя, отчество / Прозвiшча, iмя, iмя па бацьку:',
		type: 'text',
	},
	{
		id: 2,
		name: 'address',
		title: 'Адрес места жительства (места пребывания) / Адрас месца жыхарства (месца знаходжання):',
		type: 'text',
	},
	{
		id: 3,
		name: 'email',
		title: 'Ваш e-mail:',
		type: 'email',
	},
	{
		id: 4,
		name: 'message',
		title: 'Текст сообщения / Тэкст паведамлення:',
		type: 'text',
	},
]
