import img1 from 'assets/images/home/about/1.png'
import img2 from 'assets/images/home/about/2.png'

export const title = 'ГУМ — первый крупнейший универсальный магазин г. Минска. Впервые распахнул свои двери для ' +
					 'покупателей и гостей 5 ноября 1951 года. \n ГУМ — достопримечательность Беларуси. Здание ' +
					 'универмага является памятником архитектуры и градостроительства ХХ века.'

export const description = 'Здесь легко и просто можно заказать с доставкой на дом все необходимое. Ассортимент ' +
						   'полезных и качественных товаров постоянно обновляется и пополняется новинками от популярных ' +
						   'брендов. Благодаря взаимовыгодному партнерству с проверенными временем продавцами мы можем ' +
						   'предложить вам витамины, игрушки, средства для ухода и другие товары по самым ' +
						   'привлекательным ценам. Gym неустанно развивается нашими лучшими специалистами, искренне ' +
						   'любящими свое дело.'

export const images = [
	{ id: 1, image: img1, link: '/stocks/s-in-the-hum' },
	{ id: 2, image: img2, link: '/by/about-company' },
]
