import { isFetching as _ } from 'helpers/FetchingHelper'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const {
		  products:        { view: productView }, productsAttachments: { list: attachmentsList },
		  productsReviews: { list: reviewsList }, categories: { getHierarchical: hierarchical },
	  } = StateSelector

const mapStateToProps    = state => ({
	productState:      productView(state),
	attachmentsState:  attachmentsList(state),
	reviewsState:      reviewsList(state),
	userState:         StateSelector.user.getUser(state),
	productsListState: StateSelector.products.list(state),
	categoryState:     StateSelector.categories.view(state),
	isFetching:        _(productView(state), attachmentsList(state), reviewsList(state), hierarchical(state)),
})
const mapDispatchToProps = {
	getProduct:      DispatchSelector.products.view,
	getProductsList: DispatchSelector.products.list,
	getReviews:      DispatchSelector.productsReviews.list,
	getAttachments:  DispatchSelector.productsAttachments.list,
	getCategory:     DispatchSelector.categories.view,
	addToCart:       DispatchSelector.cart.create,
	viewCart:        DispatchSelector.cart.view,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
