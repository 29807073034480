import styles from 'components/static/promo/PromoItem/styles.scss'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Render promo item
 *
 * @param {string} image
 * @param {string} discount
 * @param {string} title
 * @param {string} date
 * @param {string} link
 *
 * @return {*}
 * @constructor
 */
export const PromoItem = ({
	image,
	discount,
	title,
	date,
	link,
}) => (
	<div className={styles.item}>
		<Link to={link} className={styles.image}>
			<img src={image} alt="promo" />
		</Link>
		<div className={styles.content}>
			<p className={styles.title}>{title}</p>
			<p className={styles.discount}>{discount}</p>
		</div>
		{date && <p className={styles.date}>{date}</p>}
	</div>
)

PromoItem.propTypes = {
	date:     PropTypes.string,
	image:    PropTypes.string.isRequired,
	link:     PropTypes.string,
	title:    PropTypes.string.isRequired,
	discount: PropTypes.string,
}

PromoItem.defaultProps = {
	date:     null,
	link:     null,
	discount: null,
}
