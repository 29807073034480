import { BlankButton } from 'components/global/BlankButton'
import { Arrow } from 'components/global/Slider/Arrow'
import styles from 'components/global/Slider/styles.scss'
import PropTypes from 'prop-types'
import React from 'react'
import SlickSlider from 'react-slick'

export const getArrayFromFiles = (...files) => {
	let id = Math.random()

	return files.map(file => ({ id: `${id += 1}`, file }))
}

const getSettings = images => ({
	customPaging:   idx => (
		<BlankButton>
			<img src={images[idx].file} alt="preview" />
		</BlankButton>
	),
	nextArrow:      <Arrow direction="right" />,
	prevArrow:      <Arrow direction="left" />,
	dots:           true,
	dotsClass:      styles.dots,
	infinite:       true,
	speed:          500,
	slidesToShow:   1,
	slidesToScroll: 1,
	adaptiveHeight: true,
})

/**
 * Render slider
 *
 * @param {Array} images
 * @param {Object} settings
 *
 * @return {*}
 * @constructor
 */
export const Slider = ({ images, settings }) => (
	!!images.length &&
	<div className={styles.slider}>
		<SlickSlider {...{ ...getSettings(images), ...settings }}>
			{images.map(image => (
				<div key={image.id}>
					<img className={styles.image} src={image.file} alt="about-us" />
				</div>
			))}
		</SlickSlider>
	</div>
)

Slider.propTypes = {
	images:   PropTypes.array,
	settings: PropTypes.object,
}

Slider.defaultProps = {
	images:   [],
	settings: {},
}


