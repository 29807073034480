import {
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	bannersState:     StateSelector.staticPages.banners(state),
})
const mapDispatchToProps = {
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
