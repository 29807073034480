import { UncontrolledCollapse } from 'components/global/CollapseText'
import { StaticPageWrapper } from 'components/static/StaticPageWrapper'
import { textParts } from 'containers/Static/Delivery/data'
import styles from 'containers/Static/Delivery/styles.scss'
import React, { useEffect } from 'react'
import { MetaService } from 'services/MetaService'

const title       = 'Доставка на дом'
const setMetaInfo = () => MetaService.setInfo({}, { title })

/**
 * Render delivery page
 *
 * @return {*}
 * @constructor
 */
export const DeliveryPage = () => {
	useEffect(() => {
		setMetaInfo()
	}, [])

	return (
		<StaticPageWrapper title={title} extraBreadcrumbs={[{ title: 'Покупателям', path: '' }]}>
			<h1 className={styles.title}>Доставка ГУМа</h1>
			<p>
				Для обеспечения высокого качества обслуживания ГУМ для своих покупателей бесплатно
				осуществляет доставку в квартиру с подъемом на этаж следующих товаров:
			</p>
			<ul>
				<li>холодильников, морозильников;</li>
				<li>стиральных машин;</li>
				<li>электро- и газовых плит;</li>
				<li>телевизоров.</li>
			</ul>
			<UncontrolledCollapse title="Подробнее">
				{textParts.map(part => (
					<p key={part.slice(50, 60)} className={styles.br}>
						{part}
					</p>
				))}
			</UncontrolledCollapse>
			<div className={styles.contacts}>
				<div>Справки по тел.</div>
				<a href="tel:+375(17)323-10-48">+375(17)323-10-48</a>
				<a href="tel:+375(29)304-50-45">+375(29)304-50-45</a>
			</div>
		</StaticPageWrapper>
	)
}
