import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	countriesState: StateSelector.countries.list(state),
	userCityState:  StateSelector.user.getUserCity(state),
	citiesState:    StateSelector.cities.list(state),
})
const mapDispatchToProps = {
	searchCity:       DispatchSelector.cities.list,
	updateUserFields: DispatchSelector.user.updateUserFields,
	updateUserCity:   DispatchSelector.user.setUserCity,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
