import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { ProductOption } from '@kakadu-dev/base-frontend-components'
import styles from 'components/market/ProductCard/styles.scss'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import AnimateHeight from 'react-animate-height'
import { Spinner } from 'reactstrap'

/**
 * Add product to cart
 *
 * @param {Product|number} model
 * @param {function} setIsFetching
 * @param {function} addToCart
 *
 * @return {undefined}
 */
const addProductToCart = (model, setIsFetching, addToCart) => {
	setIsFetching(true)
	const productId = typeof model === 'object' ? model.getId() : model

	const searchQuery = DataProvider
		.buildQuery()
		.addBody({
			productId,
			isReal: 0,
			count:  1,
		})
		.addExpands('product', 'offer')
		.addCustomParams({
			mergeResponse: true,
		})
		.setSuccessCallback(() => setIsFetching(false))

	addToCart(searchQuery)
}

const compare = ({ value: valueA }, { value: valueB }) => {
	const a = parseInt(valueA.split('-')[0], 10)
	const b = parseInt(valueB.split('-')[0], 10)
	return a - b
}

/**
 * Render footer of ProductCard component
 *
 * @param {boolean} isActive
 * @param {boolean} isFetching
 * @param {function} setIsFetching
 * @param {Object} productModel
 * @param {function} changeProduct
 * @param {function} addToCart
 *
 * @return {*}
 * @constructor
 */
const ProductCardFooter = ({
	isActive,
	isFetching,
	setIsFetching,
	productModel,
	changeProduct,
	addToCart,
}) => {
	const productOptions = useMemo(() =>
		ProductOption.createList(productModel.getOptions()), [productModel])

	const isDisableButton = useMemo(
		() => isFetching || productModel?.isNotInStock?.(), [isFetching, productModel])

	return (
		<AnimateHeight
			duration={350}
			height={isActive ? 'auto' : '0'}
			animateOpacity
			className={styles.collapse}
		>
			{productOptions && productOptions.map(option => {
				const sortingOption = option.model.items.sort(compare)
				return (
					<div key={option.model.alias} className={styles.row}>
						<div className={styles.option_name}>{option.model.name}</div>
						<div className={styles.option_items_wrapper}>
							{sortingOption.map(optionItem =>
								<button
									type='button'
									disabled={isFetching || optionItem.current}
									className={[
										styles.option_item,
										optionItem.current ? styles.option_item_active : '',
										!optionItem.isAvailable && styles.disabled,
									].join(' ')}
									key={optionItem.id}
									onClick={() => changeProduct(optionItem.relationId)}
								>
									{optionItem.value}
								</button>)}
						</div>
					</div>
				)
			})
			}
			<button
				type='button'
				disabled={isDisableButton}
				className={[styles.btn, isDisableButton ? styles.disabled : ''].join(' ')}
				onClick={() => addProductToCart(productModel, setIsFetching, addToCart)}
			>
				{isFetching ?
					<div className={styles.loading}>
						<Spinner type="grow" color="light" />
					</div> : <span>{'Добавить в корзину'}</span>}
			</button>
		</AnimateHeight>
	)
}


ProductCardFooter.propTypes = {
	addToCart:     PropTypes.func,
	changeProduct: PropTypes.func,
	isActive:      PropTypes.bool,
	isFetching:    PropTypes.bool,
	productModel:  PropTypes.object,
	setIsFetching: PropTypes.func,
}

ProductCardFooter.defaultProps = {
	addToCart:     () => null,
	changeProduct: () => null,
	isActive:      false,
	isFetching:    false,
	productModel:  {},
	setIsFetching: () => null,
}

export default ProductCardFooter

