import { Location } from 'components/contacts/Location'
import { Pages } from 'components/pages'
import styles from 'containers/Static/Contacts/styles.scss'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useMemo,
} from 'react'
import { useLocation } from 'react-router'
import { MetaService } from 'services/MetaService'

const setMetaInfo = title => MetaService.setInfo({}, { title })

/**
 * Render contacts page
 *
 * @return {*}
 * @constructor
 */
export const Contacts = ({ staticPagesState, hierarchicalState }) => {
	const { pathname } = useLocation()

	const hierarchical = useMemo(() => hierarchicalState.result, [hierarchicalState])

	const page = useMemo(() => {
		const list = staticPagesState?.result?.list || []
		const page = list.find((element) => element.alias === pathname)
		return { title: page?.title || '', body: page?.body || '', alias:page?.alias || '' }
	}, [staticPagesState, pathname])

	useEffect(() => {
		setMetaInfo(page.title)
	}, [page])

	return (
		<Pages page={page} hierarchical={hierarchical}>
			<div className={styles.map}>
				<Location />
			</div>
		</Pages>
	)
}

Contacts.propTypes = {
	staticPagesState:  PropTypes.object,
	hierarchicalState: PropTypes.object,
}
Contacts.defaultProps = {
	staticPagesState:  {},
	hierarchicalState: {},
}
