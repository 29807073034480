import { ArrowForwardIosRounded as Arrow } from '@material-ui/icons'
import styles from 'components/static/promo/PromoBlock/styles.scss'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Render promo block
 *
 * @param {string} title
 * @param {string} link
 * @param {Node} children
 *
 * @return {*}
 * @constructor
 */
export const PromoBlock = ({ title, link, children }) => (
	<div className={styles.container}>
		<div className={styles.main}>
			<div className={styles.header}>
				<h2 className={styles.title}>{title}</h2>
				<Link to={link} className={styles.link}>
					Смотреть всё
					<Arrow />
				</Link>
			</div>
			{/*<div>*/}
			{/*	<Button title="Активные" style={styles.btn} />*/}
			{/*	<BlankButton title="Архив" className={styles.btn} />*/}
			{/*</div>*/}
		</div>
		<div className={styles.children}>
			{children}
		</div>
	</div>
)

PromoBlock.propTypes = {
	title:    PropTypes.string.isRequired,
	link:     PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
}
