import { connect } from 'react-redux'
import { EveningWithStylist } from './index'
import {
	mapDispatchToProps,
	mapStateToProps
} from './index.props'

const EveningWithStylistInStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(EveningWithStylist)

export { EveningWithStylistInStore }
