import {
	City,
	Country,
} from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import Icon from 'assets/icomoon/Icon'
import * as PropTypes from 'prop-types'
import React, {
	useCallback,
	useState,
} from 'react'
import { DropdownList } from 'react-widgets'
import styles from './styles.scss'

/**
 * Render modal content
 *
 * @param {Object} countriesState
 * @param {Object} citiesState
 * @param {Object} userCityState
 * @param {Function} searchCity
 * @param {Function} updateUserFields
 * @param {Function} updateUserCity
 * @param {Function} toggleModal
 *
 * @return {*}
 * @constructor
 */
export const ModalContent = ({
	countriesState,
	citiesState,
	userCityState,
	searchCity,
	updateUserFields,
	updateUserCity,
	toggleModal,
}) => {
	const [countryId, setCountryId] = useState(null)

	const changeCity = city => {
		updateUserCity(city)
		updateUserFields({ cityId: city.id })
		toggleModal()
	}

	const countriesService = StateService.create(countriesState)
	const citiesService    = StateService.create(citiesState)
	const userCity         = City.create(userCityState)

	const currectCitiesList = citiesService.getList().filter(city => city.countryId === countryId)

	const searchCities = useCallback(string => {
		const searchQuery = DataProvider
			.buildQuery()
			.addBody({
				query: {
					filter: {
						title: { 'like': `%${string}%` },
					},
				},
			})

		searchCity(searchQuery)
	}, [searchCity])

	return (
		<>
			<div className={styles.wrapper}>
				<p className={styles.chooseCity}>
					Выберите населенный пункт
				</p>
				<p className={styles.currentCity}>
					{`Текущий город: ${userCity.getTitle()}`}
				</p>
				<div className={styles.delivery}>
					<Icon icon='delivery-truck' size={30} />
					<p>Доставка работает в странах:</p>
				</div>

				<div className={styles.countries}>
					{Country.map(countriesService.getList(), country => (
						<button
							type="button"
							className={[styles.country, countryId === country.getId() && styles.active].join(' ')}
							onClick={() => setCountryId(country.getId())}
							key={country.getId()}
						>
							{country.getTitle()}
						</button>
					))}
				</div>

				{countryId && <DropdownList
					filter
					busy={citiesService.isFetching()}
					data={currectCitiesList}
					valueField='id'
					textField='title'
					containerClassName={styles.dropdown}
					placeholder='Введите населенный пункт ...'
					onSearch={searchCities}
					onChange={changeCity}
				/>}
			</div>
		</>
	)
}

ModalContent.propTypes = {
	countriesState:   PropTypes.object,
	userCityState:    PropTypes.object,
	citiesState:      PropTypes.object,
	searchCity:       PropTypes.func,
	updateUserFields: PropTypes.func,
	updateUserCity:   PropTypes.func,
	toggleModal:      PropTypes.func,
}

ModalContent.defaultProps = {
	countriesState:   {},
	userCityState:    {},
	citiesState:      {},
	searchCity:       () => null,
	updateUserFields: () => null,
	updateUserCity:   () => null,
	toggleModal:      () => null,
}
