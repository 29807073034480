import {
	mapDispatchToProps,
	mapStateToProps,
} from 'containers/User/Payment/index.props'
import { connect } from 'react-redux'
import { Payment } from './index'

export const PaymentFormStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Payment)
