import * as PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { HierarchicalService } from 'services/HierarchicalService'
import styles from './styles.scss'

const compare = ({ value: valueA }, { value: valueB }) => {
	const a = parseInt(valueA.split('-')[0], 10)
	const b = parseInt(valueB.split('-')[0], 10)
	return a - b
}
/**
 * Render product options
 *
 * @param {Product} product
 *
 * @return {*[]}
 * @constructor
 */
export const ProductOptions = ({ product }) => product.getOptions().map(options => {
	const optionsFiltered = options.items.sort(compare)

	return (
		<div key={options.alias} className={styles.container}>
			<div className={styles.name}>{ `${options.name}:` }</div>
			<div className={styles.block}>
				{optionsFiltered.map(item => {
					return (
						<div
							key={item.id}
							className={[
								styles.item,
								item.current && styles.active,
								!item.isAvailable && styles.disabled,
							].join(' ')}
						>
							{item.relationId ?
								<Link to={HierarchicalService.getProductLink(product, item.relationId)}>
									{item.value}
								</Link> : item.value}
						</div>
					)
				})}
			</div>
		</div>
	)
})

ProductOptions.propTypes = {
	product: PropTypes.object,
}

ProductOptions.defaultProps = {
	product: {},
}
