import { icons } from 'components/market/Home/Sidebar/icons'

/**
 * Get desktop menu item icon
 *
 * @param {string} alias
 *
 * @return {string}
 */
export const getIcon = alias => icons[alias] || icons.default
