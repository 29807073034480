import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import Icon from 'assets/icomoon/Icon'
import { BlankButton } from 'components/global/BlankButton'
import DropdownPlaceholder from 'components/utils/placeholders/dropdown'
import styles from 'containers/Market/ProductsList/styles.scss'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import {
	Dropdown,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap'
import CategoryFilters from 'services/CategoryFilters'

/**
 * Render dropdown sorter
 *
 * @param {Object} filtersState
 * @param {boolean} isVisible
 * @param {Function} setSort
 *
 * @return {*}
 * @constructor
 */
export const DropdownSorter = ({ filtersState, isVisible, setSort }) => {
	const [isOpen, setIsOpen]     = useState(false)
	const [selected, setSelected] = useState(null)

	const filters    = useMemo(() => CategoryFilters.create(filtersState), [filtersState])
	const isFetching = StateService.create(filtersState).isFetching()

	const [sortList, sortDefault] = useMemo(() => [filters.getSort(), filters.getSortDefault()], [filters])

	useEffect(() => {
		if (sortDefault && !!sortList.length) {
			setSelected(sortList.find(sort => sort.value === sortDefault))
		}
	}, [sortDefault, sortList])

	const sortHandler = useCallback(sort => {
		setSelected(sort)
		setIsOpen(false)
		setSort(sort.value)
	}, [setSort])

	return isFetching && <DropdownPlaceholder /> ||
		   isVisible && selected &&
		   <Dropdown
			   isOpen={isOpen}
			   toggle={() => setIsOpen(!isOpen)}
			   className={styles.sorter}
		   >
			   <DropdownToggle
				   tag="label"
				   onClick={() => setIsOpen(!isOpen)}
				   data-toggle="dropdown"
				   aria-expanded={isOpen}
			   >
				   <span className={styles.header_sort}>
					   {selected.label}
				   </span>
			   </DropdownToggle>

			   <DropdownMenu className={styles.dropdown}>
				   {sortList.map(sort => {
					   const isActive = selected.value === sort.value

					   return (
						   <BlankButton
							   key={sort.value}
							   disabled={isActive}
							   className={[styles.sort, isActive && styles.sort_active].join(' ')}
							   onClick={() => sortHandler(sort)}
						   >
							   <div className={styles.icon}>
								   <Icon icon='catalog-checkbox' color={'#4e4c4c'} size={15} />
							   </div>

							   {sort.label}
						   </BlankButton>
					   )
				   })}
			   </DropdownMenu>
		   </Dropdown>
}

DropdownSorter.propTypes = {
	filtersState: PropTypes.object,
	isVisible:    PropTypes.bool,
	setSort:      PropTypes.func,
}

DropdownSorter.defaultProps = {
	filtersState: {},
	isVisible:    false,
	setSort:      () => null,
}
