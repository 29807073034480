import { isFetching as _ } from 'helpers/FetchingHelper'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const { categories: { getHierarchical, filters, list }, products: { list: productsList } } = StateSelector

const mapStateToProps    = state => ({
	categoriesState: getHierarchical(state),
	productsState:   productsList(state),
	filtersState:    filters(state),
	categoryState:   list(state),
	isFetching:      _(getHierarchical(state), filters(state), list(state), productsList(state)),
})
const mapDispatchToProps = {
	getProducts:   DispatchSelector.products.list,
	getFilters:    DispatchSelector.categories.filters,
	getCategory:   DispatchSelector.categories.list,
	setCategories: DispatchSelector.categories.setList,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
