import { images } from 'components/home/Brands/data'
import { DataBlock } from 'components/home/DataBlock'
import React from 'react'
import styles from './styles.scss'

/**
 * Render brands
 *
 * @return {*}
 * @constructor
 */
export const Brands = () => (
	<DataBlock title="Известные бренды в универмаге">
		<section className={styles.container}>
			<div className={styles.brands}>
				{images.map(image => (
					<div key={image.id} className={styles.image}>
						<img src={image.image} alt={`brand-${image.id}`} />
					</div>
				))}
			</div>
		</section>
	</DataBlock>
)
