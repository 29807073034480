import {Product as oldProduct} from '@kakadu-dev/base-frontend-components'

/**
 * Product class
 */

export class Product extends oldProduct {
	constructor(...args) {
		super(...args)
		this.isPromo = () => {
			const minPrices = this.getMinPrices()
			return minPrices && minPrices[0] && minPrices[0].isPromo || false
		}

		this.isNotInStock = () => Boolean(this.model.isNotInStock)

	}

}
