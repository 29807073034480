import { StateSelector } from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	addToCartState: StateSelector.cart.create(state),
	productState:   StateSelector.products.view(state),
})
const mapDispatchToProps = {}

export {
	mapStateToProps,
	mapDispatchToProps,
}