import logo from 'assets/images/header/logo.png'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.scss'

/**
 * Render logo
 *
 * @param {boolean} isHome
 *
 * @return {*}
 * @constructor
 */
export const Logo = ({ isHome }) => {
	const image = (
		<div className={styles.logo}>
			<img src={logo} alt="logo" />
		</div>
	)
	return isHome ? image : <Link to="/">{image}</Link>
}

Logo.propTypes = {
	isHome: PropTypes.bool,
}

Logo.defaultProps = {
	isHome: false,
}
