import { connect } from 'react-redux'
import { UserPage } from './index'
import {
	mapDispatchToProps,
	mapStateToProps,
} from './index.props'

export const ProfileStore = connect(
	mapStateToProps,
	mapDispatchToProps,
)(UserPage)
