import _ from 'lodash'
import * as PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import Select from 'react-select'
import { Label } from 'reactstrap'
import { CustomSelectInput } from '../../../utils/UIKit'

/**
 * Dropdown input component
 *
 * @param {object} props
 *
 * @return {*}
 * @constructor
 */
export const DropdownInput = (props) => {
	const { inputLabel, name, selectedValue, data, onChange } = props

	return (
		<Fragment>
			<Label for={name}>{inputLabel}</Label>
			<Select
				components={{ Input: CustomSelectInput }}
				className="react-select"
				classNamePrefix="react-select"
				name={name}
				value={_.find(data, obj => obj.value === selectedValue)}
				onChange={onChange}
				options={data}
				placeholder="Выберите..."
			/>
		</Fragment>
	)
}

DropdownInput.propTypes = {
	onChange:      PropTypes.func,
	selectedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	inputLabel:    PropTypes.string,
	name:          PropTypes.string,
	data:          PropTypes.array,
}

DropdownInput.defaultProps = {
	onChange:      () => null,
	selectedValue: '',
	inputLabel:    '',
	name:          '',
	data:          [],
}
