import * as PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const MenuTab = props => {
	const {
			  activeTab,
			  title,
			  tabClass,
			  tabActiveClass,
			  href,
			  onMouseEnter,
		  } = props

	let className = tabClass

	if (activeTab === title) {
		className = [
			tabClass,
			tabActiveClass,
		].join(' ')
	}

	return (
		<Link to={href}>
			<div
				tabIndex='0'
				role='button'
				className={className}
				onMouseEnter={() => onMouseEnter(title)}
				onKeyDown={() => onMouseEnter(title)}
			>
				{title}
			</div>
		</Link>
	)
}

MenuTab.propTypes = {
	activeTab:      PropTypes.string.isRequired,
	title:          PropTypes.string.isRequired,
	href:           PropTypes.string.isRequired,
	onMouseEnter:   PropTypes.func.isRequired,
	tabClass:       PropTypes.string.isRequired,
	tabActiveClass: PropTypes.string.isRequired,
}

export { MenuTab }
