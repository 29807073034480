import * as PropTypes from 'prop-types'
import React from 'react'
import { Spinner } from 'reactstrap'
import styles from './styles.scss'

const Loading = ({ large }) => {
	return (
		<Spinner color="success" className={large ? styles.loader : ''} />
	)
}

Loading.propTypes = {
	large: PropTypes.bool,
}

Loading.defaultProps = {
	large: false,
}

export { Loading }
