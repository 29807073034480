import { Category } from '@kakadu-dev/base-frontend-components'
import {
	SiteMapBlock,
	SiteMapDataItem,
} from 'components/static/sitemap'
import { StaticPageWrapper } from 'components/static/StaticPageWrapper'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { MetaService } from 'services/MetaService'

const title = 'Карта сайта'

const setMetaInfo = () => MetaService.setInfo({}, { title })

/**
 * Render site map
 *
 * @param {object} hierarchicalState
 *
 * @return {*}
 * @constructor
 */
export const SiteMap = ({ hierarchicalState }) => {
	useEffect(() => {
		setMetaInfo()
	}, [])

	const categories = Category.createList(hierarchicalState)

	return (
		<StaticPageWrapper title={title}>
			{categories.map(category => (
				<SiteMapBlock
					key={category.getId()}
					title={category.getTitle()}
					href={`/${category.getAlias()}`}
				>
					{category.getChildrens() && Category.map(category.getChildrens(), subCategory => (
						<SiteMapDataItem
							key={subCategory.getId()}
							category={subCategory}
						/>
					))}
				</SiteMapBlock>
			))}
		</StaticPageWrapper>
	)
}

SiteMap.propTypes = {
	hierarchicalState: PropTypes.object,
}

SiteMap.defaultProps = {
	hierarchicalState: {},
}
