import React from 'react'
import ContentLoader from 'react-content-loader'
import { Container } from 'reactstrap'

const ProductPagePlaceholder = () => (
	<Container>
		<ContentLoader
			speed={1.5}
			width={1000}
			height={800}
			viewBox="0 0 1000 800"
			backgroundColor="#e9e9e9"
			foregroundColor="#f5f5f5"
			uniqueKey="productPagePlaceholder"
		>
			<rect x="10" y="10" rx="10" ry="10" width="50" height="50" />
			<rect x="10" y="84" rx="10" ry="10" width="50" height="50" />
			<rect x="10" y="150" rx="10" ry="10" width="50" height="50" />
			<rect x="10" y="220" rx="10" ry="10" width="50" height="50" />
			<rect x="160" y="10" rx="10" ry="10" width="250" height="250" />
			<rect x="550" y="33" rx="10" ry="10" width="400" height="43" />
			<rect x="550" y="100" rx="10" ry="10" width="320" height="36" />
			<rect x="550" y="290" rx="10" ry="10" width="180" height="45" />
			<rect x="550" y="450" rx="5" ry="5" width="240" height="60" />
			<rect x="840" y="450" rx="5" ry="5" width="60" height="60" />
			<rect x="550" y="553" rx="10" ry="10" width="340" height="15" />
			<rect x="550" y="579" rx="10" ry="10" width="300" height="15" />
			<rect x="550" y="607" rx="10" ry="10" width="320" height="15" />
			<rect x="550" y="634" rx="10" ry="10" width="240" height="15" />
			<rect x="30" y="710" rx="10" ry="10" width="236" height="25" />
			<rect x="30" y="760" rx="10" ry="10" width="260" height="25" />
			<rect x="550" y="160" rx="10" ry="10" width="440" height="23" />
		</ContentLoader>
	</Container>
)

export default ProductPagePlaceholder
