import { all } from 'redux-saga/effects'
import modulesSagas from './modules/sagas'
import rootSagas from './sagas'

function* SagaManager() {
	yield all([
		...rootSagas,
		...modulesSagas,
	])
}

export {
	SagaManager
}
