import {
	PhoneAndroidRounded as Phone,
	QueryBuilderRounded as Time,
} from '@material-ui/icons'
import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import styles from './styles.scss'

/**
 * Render contacts info
 *
 * @return {*}
 * @constructor
 */
const Contacts = ({ intl }) => (
	<div className={styles.container}>
		<div className={styles.infoWrapper}>
			<Time />
			<div className={styles.info}>
				<div className={styles.timeTable}>
					<div>Пн – Cб:</div>
					<div>09.00 – 21.00</div>
				</div>
				<div className={styles.timeTable}>
					<div>{`${intl.messages['general.sunday']}:`}</div>
					<div>10.00 – 20.00</div>
				</div>
			</div>
		</div>
		<div className={styles.infoWrapper}>
			<Phone />
			<div className={styles.info}>
				<a href="tel:+375(17)323-10-48">+375(17)323-10-48</a>
				<a href="tel:+375(29)304-50-45">+375(29)304-50-45</a>
			</div>
		</div>
	</div>
)

Contacts.propTypes = {
	intl: PropTypes.object,
}
Contacts.defaultProps = {
	intl: {},
}

export default injectIntl(Contacts)
