import { useEffect } from 'react'

/**
 * Toggle scrollbar width for padding in "overflow: hidden"
 *
 * @param isOverflowed
 *
 * @return {null}
 * @constructor
 */
export const OverflowScrollBarWidth = ({ isOverflowed }) => {
	useEffect(() => {
		if (isOverflowed) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = ''
		}
	}, [isOverflowed])

	return null
}
