import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styles from './styles.scss'

/**
 * Render collapse point of pagination
 *
 * @param {number} currentPage
 * @param {function} goToPage
 * @param {Array} points
 *
 * @return {*}
 * @constructor
 */
const CollaplsePoint = ({ currentPage, goToPage, points }) => {
	const [isActive, setIsActive] = useState(false)

	/**
	 *  Switch points block visibility
	 */
	const toggleActive = () => setIsActive(!isActive)


	return points.length > 0 && (
		<div className={styles.collapseWrapper}>
			<div
				tabIndex={0}
				role={'button'}
				className={styles.currentPoint}
				onClick={toggleActive}
			>
				<div>{currentPage}</div>
				<div className={styles.upIcon}></div>
			</div>
			<div className={[styles.collapsePoints, isActive && styles.active].join(' ')}>
				{points.map(point => {
					return (
						<div
							tabIndex={0}
							role={'button'}
							key={point}
							onClick={() => {
								if (point !== currentPage) {
									goToPage(point)
									toggleActive()
								}
							}}
							className={[styles.point,
								point === currentPage && styles.current,
								point % 5 === 0 && styles.roundPoint].join(' ')}
						>
							{point}
						</div>
					)
				})}
			</div>
		</div>
	)
}

CollaplsePoint.propTypes = {
	currentPage: PropTypes.number,
	goToPage:    PropTypes.func,
	points:      PropTypes.array,
}

CollaplsePoint.defaultProps = {
	currentPage: 1,
	goToPage:    () => null,
	points:      [],
}

export { CollaplsePoint }

