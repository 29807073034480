import belcart from 'assets/images/payment/belkart.png'
import mastercard from 'assets/images/payment/mastercard.svg'
import visa from 'assets/images/payment/visa.svg'
import { DottedLine } from 'components/global/DottedLine'
import React from 'react'
import document from 'assets/media/contract.pdf'
import styles from './styles.scss'

/**
 * Render basket summary footer
 *
 * @return {*}
 * @constructor
 */
export const Footer = () => (
	<>
		<div className={styles.payment}>
			<img src={visa} alt="visa" />
			<img src={mastercard} alt="mastercard" />
			<img src={belcart} alt="belcart" />
		</div>
		<div className={styles.description}>
			Мы принимаем оплату наличными или банковскими картами при получении.
			<br />
			Онлайн банковской картой через систему WebPay
		</div>
		<DottedLine />
		<div className={styles.agreement}>
			Оформляя заказ на gum.by, Вы соглашаетесь с
			<a href={document} target="_blank" rel="noopener noreferrer">Договором публичной оферты</a>
		</div>
	</>
)
