import { ProductReview } from '@kakadu-dev/base-frontend-components'
import bad from 'assets/images/reviews/bad.svg'
import good from 'assets/images/reviews/like.svg'
import { StarRating } from 'components/global/StarRating'
import { Index } from 'components/market/ProductPage/ProductReviewItems/Avatar'
import styles from 'components/market/ProductPage/ProductReviewItems/styles.scss'
import { getTime } from 'helpers/TimeHelper'
import * as PropTypes from 'prop-types'
import React from 'react'

/**
 * Render product review items
 *
 * @param {Array.<ProductReview>} reviews
 *
 * @return {*}
 * @constructor
 */
export const ProductReviewItems = ({ reviews }) => (
	<div className={styles.reviews}>
		{reviews.map(reviewItem => {
			const review = ProductReview.create(reviewItem)

			return (
				<div className={styles.item} key={`review-id-${review.getId()}`}>
					<div className={styles.wrapper}>
						<Index user={review.getCustomer()} />
						<div className={styles.member_info}>
							<div className={styles.name}>
								{review.getCustomer().getFirstName() || 'Аноним'}
							</div>
							<div className={styles.date}>
								<p>
									{getTime(new Date(review.getCreatedAt() * 1000))}
								</p>
							</div>
						</div>
					</div>
					<StarRating
						initialRating={review.getVote()}
						size={18}
						showRateString
					/>
					<p className={styles.text}>
						{review.getText()}
					</p>
					<div className={styles.good_bad}>
						<div>
							<div className={styles.good}><img src={good} alt="good" /></div>
							<div className={styles.advantages}>
								<p>{review.getAdvantages()}</p>
							</div>
						</div>
						<div>
							<div className={styles.bad}><img src={bad} alt="bad" /></div>
							<div className={styles.disadvantages}>
								<p>{review.getDisadvantages()}</p>
							</div>
						</div>
					</div>
				</div>
			)
		})}
	</div>
)

ProductReviewItems.propTypes = {
	reviews: PropTypes.array,
}

ProductReviewItems.defaultProps = {
	reviews: [],
}
