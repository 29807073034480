import { useStopScrolling } from 'components/global/useStopScrolling'
import PropTypes from 'prop-types'
import React from 'react'
import { Spinner as Loader } from 'reactstrap'
import styles from './styles.module.scss'

/**
 * Render spinner
 *
 * @param {boolean} block
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const Spinner = ({ block, isFetching }) => {
	useStopScrolling(block && isFetching)

	return (
		isFetching &&
		<div className={block ? styles.bockPage : styles.notBlock}>
			<div className={styles.wrapper}>
				<Loader style={{ width: '3rem', height: '3rem', color: 'rgb(105, 209, 122)' }} />
				<div className={styles.title}>Загрузка...</div>
			</div>
		</div>
	)
}

Spinner.propTypes = {
	block:      PropTypes.bool,
	isFetching: PropTypes.bool,
}

Spinner.defaultProps = {
	block:      false,
	isFetching: false,
}
