import { addLocaleData } from 'react-intl'
import ruLang from './entries/ru-RU'
import byLang from './entries/by-BY'

const AppLocale = {
	ru: ruLang,
	by: byLang,
}
addLocaleData(AppLocale.ru.data)
addLocaleData(AppLocale.by.data)

export default AppLocale
