import Icon from 'assets/icomoon/Icon'
import { Price } from 'components/global/Price'
import * as PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { HierarchicalService } from 'services/HierarchicalService'
import styles from './styles.scss'

/**
 * Render preview cart product
 *
 * @param {CartProduct} product
 * @param {boolean} fetchingOnDelete
 * @param {function} removeProduct
 *
 * @return {*}
 * @constructor
 */
const PreviewCartProduct = ({ product, fetchingOnDelete, removeProduct }) => {
	const link = HierarchicalService.getProductLink(product.getProduct())
	const sellingPrice = useMemo(() => product.getRawModel()?.minPrice?.sellingPrice || 0, [product])

	return (
		<div
			key={product.getId()}
			className={styles.wrapper}
		>
			<div className={styles.imgWrapper}>
				<Link to={link} className={styles.href}>
					<img
						className={styles.img}
						src={product.getProduct().getPreview()}
						alt="preview"
					/>

					{product.getCount() > 1 && (
						<div className={styles.count}>
							<span className={styles.count_x}>x</span>

							{product.getCount()}
						</div>
					)}
				</Link>
			</div>

			<div className={styles.info}>
				<Link to={link} className={styles.text_box}>
					{product.getProduct().getTitle()}
				</Link>

				<div className={styles.price}>
					<Price value={sellingPrice} />
				</div>

				<div
					className={styles.delete}
					role='button'
					tabIndex='0'
					{...(!fetchingOnDelete && {
						onClick: () => removeProduct(product.getId()),
					})}
				>
					{fetchingOnDelete
						? <Spinner color="success" />
						: <Icon icon='close' size={20} />
					}
				</div>
			</div>
		</div>
	)
}

PreviewCartProduct.propTypes = {
	product:          PropTypes.object,
	fetchingOnDelete: PropTypes.bool,
	removeProduct:    PropTypes.func,
}

PreviewCartProduct.defaultProps = {
	product:          {},
	fetchingOnDelete: false,
	removeProduct:    () => null,
}

export { PreviewCartProduct }
