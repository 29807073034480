import { BirthdayField } from 'components/Form/fields/Birthday'
import React from 'react'

const options = [
	{ label: 'Женский', value: 'female' },
	{ label: 'Мужской', value: 'male' },
]

export const userPersonalDataFields = [
	{ id: 'firstName', title: 'Имя' },
	{ id: 'lastName', title: 'Фамилия' },
	{ id: 'middleName', title: 'Отчество' },
	{ id: 'gender', title: 'Пол', type: 'select', options },
	{ id: 'birthDay', title: 'День рождения', component: <BirthdayField /> },
	// { id: 'password', title: 'Пароль' },
]
