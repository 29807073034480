/* eslint-disable react-hooks/exhaustive-deps */
import {
	Customer,
	CustomerFavorite,
} from '@kakadu-dev/base-frontend-components'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { ProductCardStore } from 'components/market/ProductCard/index.store'
import { Pagination } from 'components/market/ProductsList/Pagination'
import { ProductCardPlaceholder } from 'components/utils/placeholders/product-card'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styles from './styles.scss'

/**
 * Render list of favorite products
 *
 * @param {Object} userState
 * @param {Function} getFavorites
 * @param {string} type
 * @param {Object} favoritesState
 *
 * @return {*}
 * @constructor
 */
export const FavoritesList = ({ userState, getFavorites, type, favoritesState }) => {
	const productService = StateService.create(favoritesState)
	const productList    = CustomerFavorite.createList(favoritesState)
	const pagination     = productService.getPagination()
	const currentPage    = pagination.getCurrentPage()
	const totalPages     = pagination.getPageCount()
	const totalItems     = pagination.getTotalItems()
	const perPage        = pagination.getPerPage()

	const getFavoriteList = (page = 1) => {
		const user = Customer.create(userState)

		// TODO add filter by customerID
		const favoritesQuery = DataProvider
			.buildQuery()
			.addExpands(...[
				'product.categories.category.allParents',
				'product.isFavorite',
				'product.minPrices',
				'product.offers',
			])
			.addBody({
				query: {
					filter:  { type },
					page,
					perPage: 4,
				},
			})
		// .addFilter([
		// 	{ customerId: user.getId() },
		// ])
		getFavorites(favoritesQuery)
	}

	const isFetchNeeded = () => {
		if (productList.length <= perPage / 2 && totalPages > 1 && currentPage === 1 ||
			!productList.length && currentPage !== 1 && totalItems > 0) {
			return true
		}
		return false
	}

	useEffect(() => {
		getFavoriteList()
	}, [isFetchNeeded(), type])

	return (
		<>
			<div className={styles.favoritesList}>
				<div className={[styles.dropDown, productService.isFetching() && styles.show].join(' ')} />
				{!productList.length && productService.isFetching() &&
				 <ProductCardPlaceholder colProps={{ lg: 3 }} count={4} />
				 || productList.length > 0 &&
				 productList.map(favorite => (
					 <ProductCardStore
						 colProps={{ xl: 3 }}
						 key={favorite.getProduct().getId()}
						 product={favorite.getProduct()}
					 />
				 ))
				 || <div className={styles.empty}>Эта категория пуста...</div>}
			</div>
			{!!productList.length &&
			 <Pagination
				 service={productService}
				 goToPage={getFavoriteList}
			 />}
		</>
	)
}

FavoritesList.propTypes = {
	favoritesState: PropTypes.object,
	getFavorites:   PropTypes.func,
	type:           PropTypes.string,
	userState:      PropTypes.object,
}

FavoritesList.defaultProps = {
	favoritesState: {},
	getFavorites:   () => null,
	type:           '',
	userState:      {},
}
