import paymentImage from 'assets/images/payment/payment-systems.jpg'
import React from 'react'
import styles from './styles.scss'

/**
 * Render payment
 *
 * @return {*}
 * @constructor
 */
export const Payment = () => (
	<div className={styles.payment}>
		<p>Способы оплаты</p>
		<img src={paymentImage} alt="payment-methods" />
	</div>
)
