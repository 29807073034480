import { connect } from 'react-redux'
import { News } from './index'
import {
	mapDispatchToProps,
	mapStateToProps
} from './index.props'

const NewsInStore = connect(
	mapStateToProps,
	mapDispatchToProps
)(News)

export { NewsInStore }
