import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const mapStateToProps    = state => ({
	staticPagesState:  StateSelector.staticPages.pages(state),
	hierarchicalState: StateSelector.categories.getHierarchical(state)
})
const mapDispatchToProps = {
	getMeta:  DispatchSelector.seo.getMeta,
	getPages: DispatchSelector.staticPages.pages,
}

export {
	mapStateToProps,
	mapDispatchToProps,
}
