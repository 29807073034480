import { BreadCrumb } from 'components/global/BreadCrumbs/BreadCrumb'
import * as PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import styles from './styles.scss'

/**
 * Render Breadcrumbs
 *
 * @param {Object} data
 * @param {string} title
 * @param {Object} extraBreadcrumbs
 *
 * @returns {*}
 * @constructor
 */
export const BreadCrumbs = injectIntl(({ data, title, extraBreadcrumbs, intl }) => {
	const breadCrumbs = data[0] ? data :
		[{
			title: intl.messages['general.breadCrumbs.title'],
			path:  '/',
		}, ...extraBreadcrumbs, {
			title,
			path: '',
		}]

	let id       = 1
	const isLast = number => number === breadCrumbs.length - 1

	return (
		<div className={styles.breadcrumb}>
			{breadCrumbs.map((crumb, idx) => (
				<div key={id += 1} className={styles.item}>
					<BreadCrumb crumb={crumb} isLast={isLast(idx)} />
				</div>
			))}
		</div>
	)
})

BreadCrumbs.propTypes = {
	data:             PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
	extraBreadcrumbs: PropTypes.array,
	title:            PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

BreadCrumbs.defaultProps = {
	data:             [],
	extraBreadcrumbs: [],
	title:            '',
}
